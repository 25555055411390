import Styled from 'styled-components';

export const DetalhesContainer = Styled.div`
  display: flex;
  justify-content:space-between;
  flex-wrap: wrap;
  text-align : left;
  padding: 1rem 1rem;

  p {
    display: block;
    vertical-align: middle;
    font-size: 16px;
    width: 280px;
    margin-right: 3rem;

    button {
      margin: 0 0 0.5rem 0.5rem;
      color:#000 ;
    }
  }
`;
