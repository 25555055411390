import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Form } from 'reactstrap';
import { FaUsers } from 'react-icons/fa';
import FuncionariosNewEdit from '../../components/Forms/FuncionariosNewEdit';
import SubmitBtn from '../../components/Buttons/SubmitBtn';
import CadastroClienteIndividual from '../../components/Forms/CadastroClienteIndividual';

function CadastrarFuncionario(props) {

  const { 
    openModalCadastroFuncionario,
    modal,
    setModal,
    getFuncionarios,
    cadastrarFuncionarioForm,
    handleFuncionarioOnChange,
    funcionarios,
    adicionarDependente,
    resultBuscaProd,
    inputResponsavelDep,
    dependentList,
    dependentes,
    handleDependentesOnChange,
    loading,
    idEmpresa,
    infosEmpresas
  } = props;

  return (
    <div>
      <Button color="danger" onClick={openModalCadastroFuncionario}></Button>
      <Modal isOpen={modal} toggle={openModalCadastroFuncionario} size="lg">
        <ModalHeader toggle={openModalCadastroFuncionario}><FaUsers />&nbsp;&nbsp;Cadastrar Novo Funcionário</ModalHeader>
        
        <CadastroClienteIndividual
          mostraInfoPagamento={false}
          mostraInfoValores={false}
          mostraInfoDependente={true}
          clienteEmpresarial={true}
          setModalFuncionario={setModal}
          modalFuncionario={modal}
          idEmpresa={idEmpresa}
          modal={modal}
          direcionaCadastroFunc={true}
          openModalCadastroFuncionario={openModalCadastroFuncionario}
          getFuncionarios={getFuncionarios}
          empresaInfo={infosEmpresas}
        />

        {/* <Form onSubmit={cadastrarFuncionarioForm}>
          <ModalHeader toggle={openModalCadastroFuncionario}><FaUsers />&nbsp;&nbsp;Cadastrar Novo Funcionário</ModalHeader>
          <ModalBody>
            
            <FuncionariosNewEdit
              funcionarios={funcionarios}
              handleFuncionarioOnChange={handleFuncionarioOnChange} 
              handleDependentesOnChange={handleDependentesOnChange}
              adicionarDependente={adicionarDependente}
              resultBuscaProd={resultBuscaProd}
              inputResponsavelDep={inputResponsavelDep}
              dependentList={dependentList}
              dependentes={dependentes}
            />
          
          </ModalBody>
          <ModalFooter>
          <Button color="danger" outline onClick={() => openModalCadastroFuncionario()}>Fechar</Button>&nbsp;&nbsp;
          <SubmitBtn
            label="Cadastrar"
            labelOnClick="aguarde..."
            loading={loading}
            typeSubmit="submit"
            metodo={cadastrarFuncionarioForm}
            color="#0A7A73"
          />
        </ModalFooter>
        </Form> */}
      </Modal>
    </div>
  )
}

export default CadastrarFuncionario;
