import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
function MaterialSelect({
  inicialState = '',
  handleChange,
  options,
  label,
  loading = false,
  ...rest
}) {
  const classes = useStyles();

  const [value, setValue] = useState(inicialState);

  const handleChangeValue = e => {
    handleChange(e.target.value);
    setValue(e.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        id={label}
        labelId={label}
        onChange={handleChangeValue}
        value={value}
        {...rest}
      >
        {loading ? (
          <MenuItem value="">Carregando...</MenuItem>
        ) : (
          options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
}

export default MaterialSelect;
