import styled from 'styled-components';

export const NavbarComponent = styled.div`
  .navbar-content {
    background-color: #2d2d2d;
  }

  .collapse {
    color: transparent;
    flex-direction: row;
    justify-content: flex-end;
  }
  .collapse > ul > li > a {
    color: #b5b5b5 !important;
    font-weight: 700;
    font-size: 13px;
    font-family: "Archivo";
  }
  .dropdown-menu {
    font-family: "Archivo";
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    font-size: 13px;
    letter-spacing: 1px;
    font-weight : 700;
  }
  .dropdown-item > svg {
    margin-right: 15px;
  }
`;

export const FooterVersionArea = styled.div`
  color: #fff;
  font: 400 0.7rem Archivo;
  opacity: 0.6;
  text-align: left;
  margin-left: 15px;
`;