import styled from 'styled-components';

export const TitleHeaderComponent = styled.div`
  margin-top: 30px;
  padding-bottom: 20px;
  border-bottom: 3px solid #538a88;

  h4 > svg {
    font-size: 40px;
  }

  h4 {
    font-family: 'Titillium';
    font-size: 30px;
    font-weight: 700;
    color: #383838;
    animation: fadeIn;
    animation-duration: 1.5s;
    --animate-delay: 2s;
  }
`;

export const TitleCardComponent = styled.div`
  display: flex;
  align-items: center;
  color: #538a88;
  letter-spacing: 2px;
  font-size: 28px;
  word-wrap: initial;
  font-weight: 200 !important;
`;
