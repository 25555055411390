import Api from './api';
import { alertWarning } from '../components/Alerts/Alerts';

export const formatDate = date => {
  const newDate = date.split('-');
  return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
};

export const somaDoisDiasProximoPgto = date => {
  const dias = 2;
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + dias);

  let mes = newDate.getMonth();
  let dia = newDate.getDate();

  if (newDate.getMonth() + 1 <= 9) {
    mes = `0${newDate.getMonth()}`;
  }

  if (newDate.getDate() <= 9) {
    dia = `0${newDate.getDate()}`;
  }

  return `${newDate.getFullYear()}-${mes}-${dia}`;
};

export const ordenaPorId = array => {
  array.sort((a, b) => {
    if (a.id !== b.id) {
      return a.id - b.id;
    }
  });
};

export const formatDateTime = date => {
  const dt = formatDate(date.split(' ')[0]);
  const hour = date.split(' ')[1];
  return `${dt} ${hour}`;
};

export const verificaCamposVaziosInput = objInputs => {
  let valida = false;
  objInputs.map(el => {
    if (el.valorInput === '' || el.valorInput === undefined) {
      alertWarning(`O campo de ${el.nomeInput} deve ser informado`);
      return (valida = true);
    }
  });
  return valida;
};

export const dateToSave = dateValue => {
  const newValue = dateValue.split('/');
  const year = newValue[2];
  const month = newValue[1];
  const day = newValue[0];
  return `${year}-${month}-${day}`;
};

export const transformaUpperCase = (value, type) => {
  if (type === 'text') {
    return value.toUpperCase();
  }
  return value;
};

export const testaCPF = strCPF => {
  // retira os pontos e os traços do cpf
  strCPF = strCPF.replace('.', '');
  strCPF = strCPF.replace('.', '');
  strCPF = strCPF.replace('-', '');

  let Soma;
  let Resto;
  Soma = 0;

  if (strCPF === '00000000000') return false;

  for (let i = 1; i <= 9; i++)
    Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
};

export const verificaIdadeLimite = (dataNascimento, idadeMax) => {
  const date = new Date();
  dataNascimento = dataNascimento.split('-');

  let ano_calculado = date.getFullYear() - dataNascimento[0];
  let mes_calculado = date.getMonth() - dataNascimento[1] + 1;
  let dia_calculado = date.getDate() - dataNascimento[2];

  if (mes_calculado < 0) {
    ano_calculado -= 1;
    mes_calculado = 12 + mes_calculado;
  }

  if (dia_calculado < 0) {
    if (mes_calculado === 0) {
      ano_calculado -= 1;
    } else {
      mes_calculado -= 1;
    }
    dia_calculado = 31 + dia_calculado;
  }

  if (ano_calculado > idadeMax) {
    return false;
  }
  return true;
};

export const renovaApolice = (periodoVigencia, inicioVigencia) => {
  const diasParaRenovar = 45;
  let renovar = false;
  if (inicioVigencia !== '0000-00-00') {
    const dia = inicioVigencia.split('-')[2];
    const mes = inicioVigencia.split('-')[1] - 1;
    const ano = inicioVigencia.split('-')[0];

    const fimVigenciaNotFormat = somaDatas(
      new Date(ano, mes, dia),
      periodoVigencia,
    );
    const nowDays = new Date();
    const diferencaDias = dateDiffInDays(fimVigenciaNotFormat, nowDays);
    if (diferencaDias <= diasParaRenovar) {
      renovar = true;
    } else {
      renovar = false;
    }
  }
  return renovar;
};

export const verificaIdadeMinima = (dataNascimento, idadeMin) => {
  const date = new Date();
  dataNascimento = dataNascimento.split('/');

  let ano_calculado = date.getFullYear() - dataNascimento[0];
  let mes_calculado = date.getMonth() - dataNascimento[1] + 1;
  let dia_calculado = date.getDate() - dataNascimento[2];

  if (mes_calculado < 0) {
    ano_calculado -= 1;
    mes_calculado = 12 + mes_calculado;
  }

  if (dia_calculado < 0) {
    if (mes_calculado === 0) {
      ano_calculado -= 1;
    } else {
      mes_calculado -= 1;
    }
    dia_calculado = 31 + dia_calculado;
  }

  if (ano_calculado < idadeMin) {
    return false;
  }
  return true;
};

const procuraMaeNaApolice = (dependentList, clientGender) => {
  let maeNaApolice = false;
  let conjugeNaApolice = false;
  let filhoNaApolice = false;

  dependentList.map(el => {
    if (el.dependentParent === 'Mãe' || el.dependentParent === 'Sogro(a)')
      maeNaApolice = true;
    if (el.dependentParent === 'Cônjuge') conjugeNaApolice = true;
    if (el.dependentParent === 'Filho(a)') filhoNaApolice = true;
  });

  if (conjugeNaApolice && clientGender === 'm' && filhoNaApolice) {
    maeNaApolice = true;
  }

  if (clientGender === 'f' && filhoNaApolice) {
    maeNaApolice = true;
  }

  return maeNaApolice;
};

const procuraPaiNaApolice = (dependentList, clientGender) => {
  let paiNaApolice = false;
  let conjugeNaApolice = false;
  let filhoNaApolice = false;

  dependentList.map(el => {
    if (el.dependentParent === 'Pai' || el.dependentParent === 'Sogro(a)')
      paiNaApolice = true;
    if (el.dependentParent === 'Cônjuge') conjugeNaApolice = true;
    if (el.dependentParent === 'Filho(a)') filhoNaApolice = true;
  });

  if (conjugeNaApolice && clientGender === 'f' && filhoNaApolice) {
    paiNaApolice = true;
  }

  if (clientGender === 'm' && filhoNaApolice) {
    paiNaApolice = true;
  }

  return paiNaApolice;
};

export const verificaCodigoDesconto = async (
  IDcodigoDesconto,
  setInvalidCupom,
  token,
) => {
  const codigoDesconto = IDcodigoDesconto;

  const response = await Api.post(
    `/cupom/validar`,
    { codigo: codigoDesconto },
    { headers: { Authorization: `Bearer ${token}` } },
  );
  setInvalidCupom(!response.data.ok);

  return response.data.ok;
};

export const alteraValorCompra = (
  valorTitular,
  valorDep1,
  valorDep2,
  dependentList,
  clientQtyPay,
  codigoDescontoAtivo,
  convenio,
  taxaAdesao,
) => {
  let novoValor = parseFloat(valorTitular);
  let qtdDep1 = 0;

  dependentList.map(el => {
    if (el.tipoDependente === '2') {
      novoValor += parseFloat(valorDep2);
    } else {
      if (codigoDescontoAtivo) {
        if (qtdDep1 > 0) {
          novoValor += parseFloat(valorDep1);
        }
      } else {
        novoValor += parseFloat(valorDep1);
      }
      qtdDep1++;
    }
  });

  if (convenio) {
    if (Object.keys(dependentList).length > 0) {
      novoValor -= parseFloat(valorDep1);
    }
  }

  const qtdParcelas = clientQtyPay;

  novoValor = (novoValor * 100 * 12) / Number(qtdParcelas);

  if (qtdParcelas === 1) {
    // Desconto campanha à vista
    novoValor -= novoValor * 0.25;
  }

  if (taxaAdesao) {
    novoValor += 3000;
  }

  novoValor = Math.round(novoValor);
  novoValor /= 100;

  return novoValor;
};

export const somaDatas = (date, months) => {
  const d = date.getDate();

  date.setMonth(date.getMonth() + +months);

  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
};

export const dateDiffInDays = (finalVigencia, nowDate) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  const utc2 = Date.UTC(
    finalVigencia.getFullYear(),
    finalVigencia.getMonth(),
    finalVigencia.getDate(),
  );
  const utc1 = Date.UTC(
    nowDate.getFullYear(),
    nowDate.getMonth(),
    nowDate.getDate(),
  );

  // const utc2 = Date.UTC(2020, 9, 23); //Quando Vence
  // const utc1 = Date.UTC(2020, 7, 28); //Dia de Hoje

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const verificaAcessoAssessor = nivelUsuario => {
  if (parseInt(nivelUsuario) != 2) {
    return true;
  }
  return false;
};

export const verificaCorretor = (nivelUsuario, idCorretor) => {
  if (nivelUsuario == 3) {
    if (idCorretor != undefined) {
      return true;
    }
  } else {
  }
  return false;
};

export const deixaSomenteInteiros = a => {
  return a.replace(/([^\d])+/gim, '');
};

export const verificaEmail = emailValue => {
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  );
  if (!pattern.test(emailValue)) return false;
  return true;
};

export const padLeadingZeros = (num, size) => {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
};
