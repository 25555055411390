import React, { useState } from 'react';
import { Row, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Form, Button } from 'reactstrap';
import { BodyFormComponent, SectionFormHeader } from '../styles';
import { FaUser, FaPhone, FaMobile, FaEnvelope } from 'react-icons/fa';
import InputMask from 'react-input-mask';
import Api from '../../services/api';
import { connect } from 'react-redux';
import SubmitBtn from '../../components/Buttons/SubmitBtn';
import { alertError, alertSuccess } from '../../components/Alerts/Alerts';
import { ToastContainer } from 'react-toastify';

function CadastrarContatoConvenio(props) {
  const { toggle } = props;

  const [ loading, setLoading ] = useState(false);
  const [ contatoInput, setContatoInput ] = useState({
    nome_responsavel: "",
    telefone: "",
    celular: "",
    email: "",
    id_estipulante: props.idConvenio,
  });

  const cadastrarContato = async e => {
    setLoading(true);
    e.preventDefault();
    const authStr = `Bearer ${props.user.user.token}`;
    
    const result = await Api.post(`convenio/contato`, contatoInput, { headers: { Authorization: authStr }} );
    
    if(result.data.ok){
      alertSuccess("Contato cadastrado com sucesso.");
      toggle();
      window.location.reload();
    }else{
      alertError("Algo deu errado");
    }
    setLoading(false);
  }
  
  return (
    <Form onSubmit={cadastrarContato}>
      <ToastContainer/>
      <BodyFormComponent>
        <Row className="section-header">
          <Col>
            <SectionFormHeader>Informações do Contato</SectionFormHeader>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col lg={12}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><FaUser/></InputGroupText>
              </InputGroupAddon>
              <Input name="nome_responsavel" required placeholder="Nome do Responsável" value={contatoInput.nome_responsavel} onChange={e => setContatoInput({ ...contatoInput, nome_responsavel: e.target.value })} />
            </InputGroup>
          </Col>
          <Col lg={12}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><FaPhone/></InputGroupText>
              </InputGroupAddon>
              <InputMask mask="(99) 99999-9999" name="telefone" required placeholder="Telefone" value={contatoInput.telefone} onChange={e => setContatoInput({ ...contatoInput, telefone: e.target.value })} />
            </InputGroup>
          </Col>
          <Col lg={12}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><FaMobile/></InputGroupText>
              </InputGroupAddon>
              <InputMask mask="(99) 99999-9999" name="celular" placeholder="Celular" value={contatoInput.celular} onChange={e => setContatoInput({ ...contatoInput, celular: e.target.value })} />
            </InputGroup>
          </Col>
          <Col lg={12}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><FaEnvelope/></InputGroupText>
              </InputGroupAddon>
              <Input type="email" required name="email" placeholder="E-mail" value={contatoInput.email} onChange={e => setContatoInput({ ...contatoInput, email: e.target.value })} />
            </InputGroup>
          </Col>
        </Row>
      </BodyFormComponent>
      <Row className="mt-4">
        <Col style={{display: "flex"}}>
          <Button color="danger" onClick={toggle}>Fechar</Button>&nbsp;&nbsp;
          <SubmitBtn 
            label="Cadastrar Contato"
            labelOnClick="Salvando..."
            loading={loading}
            typeSubmit="submit"
            metodo={cadastrarContato}
            color="#0A7A73"
          />
        </Col>
      </Row>
    </Form>
  )
}

const mapStateToProps = store => ({
  user: store.user
})

export default connect(mapStateToProps)(CadastrarContatoConvenio);