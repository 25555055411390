import React, { useState } from 'react';
import { Row, Col, Container, Button, Modal, ModalHeader, ModalBody, Input, Form } from 'reactstrap';
import TitleContent from '../../components/TitleContent/TitleContent';
import Loading from '../../components/Loading/Loading';
import ListaCupons from '../../views/Cupons/ListaCupons';
import { FaFileContract, FaPlus, FaExclamationCircle } from 'react-icons/fa';
import { MsgErrorDataTable, BodyFormComponent, LabelForm, DetailLabelInput } from '../../views/styles';
import { alertError } from '../../components/Alerts/Alerts';
import Api from '../../services/api';
import { ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const titleHeader = <><FaFileContract/>&nbsp;&nbsp;Cupons</>;

function Page(props) {
  const { token } = props;
  const [ cupomInput, setCupomInput ] = useState({
    codigo: "",
    descricao: "",
    qtd: "",
    validade: "",
  });

  const [ modal, setModal ] = useState(false);
  const toggle = () => setModal(!modal);
  const history = useHistory();

  const handleChange = e => {
    const { value, name } = e.target;
    if(name === 'codigo'){
      value.replace(/\s/g, "");
    }
    setCupomInput({ ...cupomInput, [name]: value });
  }

  const criaCupom = async e => {
    e.preventDefault();
    if(Object.keys(cupomInput.codigo).length < 5){
      alertError("O nome do código não pode ter menos de 5 letras");
      return;
    }
    const response = await Api.post('cupom', cupomInput, { headers: { Authorization: `Bearer ${token}`}});
    if(response.data.ok){
      window.location.reload();
    }else{
      alertError(response.data.message);
    }
  }

  const renderModal = _ => (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}><FaFileContract/>&nbsp;Cadastrar Cupom</ModalHeader>
      <ModalBody>
        <BodyFormComponent>
          <Form onSubmit={criaCupom} method='post'>
            <Row>
              <Col md={12}>
                <LabelForm>Nome do Código</LabelForm><br/>
                <DetailLabelInput>* O nome do código deve conter mais de 5 letras e sem espaço</DetailLabelInput>
                <Input name="codigo" required value={cupomInput.codigo} onChange={e => handleChange(e)}/>
              </Col>
              <Col md={12} className="mt-2">
                <LabelForm>Descrição(Campanha)</LabelForm>
                <Input type="textarea" required value={cupomInput.descricao} name="descricao" onChange={e => handleChange(e)}/>
              </Col>
              <Col md={12} className="mt-2">
                <LabelForm>Quantidade</LabelForm>
                <Input type="number" required value={cupomInput.qtd} name="qtd" onChange={e => handleChange(e)}/>
              </Col>
              <Col md={12} className="mt-2">
                <LabelForm>Validade</LabelForm>
                <Input type="date" required value={cupomInput.validade} name="validade" onChange={e => handleChange(e)} />
              </Col>
            </Row>
            <Row className="text-right mt-4">
              <Col md={12}>
                <Button type="submit" color="success" size="sm" >
                  <FaPlus/>&nbsp;Cadastrar
                </Button>
              </Col>
            </Row>
          </Form>
        </BodyFormComponent>
      </ModalBody>
    </Modal>
  )

  return(
    <Container fluid={true}>
       <ToastContainer />
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
        <Col className="text-left mt-4" md={6}>
        <Button color="info" size="sm" onClick={() => history.push('/cupons/relacao-de-uso')}>
          Relação de uso de cupons
        </Button>
        </Col>
        <Col className="text-right mt-4" md={6}>
        <Button color="success" size="sm" onClick={() => toggle()}>
            <FaPlus />&nbsp;&nbsp;Cadastrar Novo Cupom
          </Button>
          {
            modal&&
            renderModal()
          }
        </Col>
      </Row>
      <Row className="mb-4">
        {
          props.loading
          ?
          <Col className="text-center">
            <Loading loading={props.loading} />
          </Col>
          :
          props.cupons.output&&
          props.cupons.output.length > 0
          ?
            <ListaCupons {...props}/>
          :
          (<MsgErrorDataTable><FaExclamationCircle />&nbsp;&nbsp;{props.cupons.message}</MsgErrorDataTable>)
        }
      </Row>
    </Container>
  );
}

export default Page;
