import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaBuilding } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import Convenios from '../../views/Convenios/BuscaConvenio';
import Loading from '../../components/Loading/Loading';

const titleHeader = <><FaBuilding/>&nbsp;&nbsp;Convênios</>;

export default function Page(props) {
  return (
    <Container fluid={true}>
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row className="mb-4">
        {
          props.loading
          ?
          <Col className="text-center">
            <Loading loading={props.loading} />
          </Col>
          :
          <Convenios {...props} />

        }
      </Row>
    </Container>
  );
}
