import React, { useState } from 'react'
import { Button, Container, Row, Col } from 'reactstrap';
import { FaPlus, FaHandshake } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import ModalCadastroParceiro from '../../components/Modal/ModalCadastroParceiro';
import ListaBeneficio from '../../views/Beneficios/ListaBeneficio';

export default function Page(props) {
  
  const { cadastrarBeneficio, handleOnChange, parceiro, terceirosList } = props;
  const titleHeader = <><FaHandshake/>&nbsp;&nbsp; Benefícios</>;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <Container fluid={true}>
      <Row className="text-left mt-4 mb-4">
        <Col lg={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row className="text-right">
        <Col>
          <Button color="success" size="sm" onClick={() => setModal(true)}><FaPlus /> Cadastrar Benefícios</Button>
        </Col>
      </Row>
      {
        (modal)&&
        <ModalCadastroParceiro 
          toggle={toggle} 
          modal={modal} 
          cadastrarBeneficio={cadastrarBeneficio} 
          handleOnChange={handleOnChange} 
          parceiro={parceiro} 
        />
      }
      <Row className="mb-4">
        <Col lg={12}>
          <ListaBeneficio terceirosList={terceirosList} />
        </Col>
      </Row>
    </Container>
  )
}
