import React, { useState } from 'react';
import TablesGrid from '../../components/TablesGrid/TablesGrid';
import { FaDollarSign } from 'react-icons/fa';
import { Button } from 'reactstrap';
import ComissaoCorretorModal from './ComissaoCorretorModal';

function CorretorLista(props) {

    let columns = [
        { name: 'nome', title: 'Assessor', getCellValue: row => (row.nome) },
        { name: 'email', title: 'E-mail', getCellValue: row => (row.email) },
        { name: 'telefone', title: 'Telefone', getCellValue: row => (row.telefone) },
        { name: 'cpf', title: 'CPF', getCellValue: row => (row.cpf) },
        { name: '', title: 'Comissão', getCellValue: row => (actions(row))},
    ];
    const [ idUser, setIdUser ] = useState();
    const [modal, setModal] = useState(false);
    const [ idUsuario, setIdUsuario ] = useState(); 

    const openModal = id => {
        setIdUser(id);
        setModal(!modal);
    }
    const toggleModal = () => setModal(!modal);

    const abreModal = id => {
        setIdUsuario(id)
        toggleModal();
    }

    const actions = row => (
        <>
            <Button size="sm" color="primary" outline onClick={() => abreModal(row.id_usuario) }>
                <FaDollarSign />
            </Button>
        </>
    );

    return (
        <>
            <TablesGrid rows={props.corretores} columns={columns}/>;
            {
                modal&&
                (<ComissaoCorretorModal toggle={toggleModal} modal={modal} idUser={idUser} idUsuario={idUsuario} />)
            }
        </>
    );
}

export default CorretorLista;