import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Input,
  Card,
  Col,
  Row,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from 'reactstrap';
import {
  FaUsers,
  FaSearch,
  FaExclamationCircle,
  FaUndoAlt,
} from 'react-icons/fa';
import classnames from 'classnames';

import { CardComponent, MsgErrorDataTable } from '../styles';

import Loading from '../../components/Loading/Loading';
import SimpleTable from '../../components/TablesGrid/SimpleTable';
import formatValueToPrice from '../../utils/format';
import BotaoRelatorioFuncionariosAtivos from './BotaoRelatorioFuncionariosAtivos';

function ResumosFuncionario(props) {
  const history = useHistory();

  const {
    produtos,
    segurados,
    idEstipulante,
    handleMudarProdutoFuncionario,
    handleAtivarProdutoFuncionario,
    funcInativos,
    loadingAlterarProdutos,
    loadingAtivarProdutos,
    handleDesfazerAlteracaoFuncionario,
    handleCancelarAlteracoesFuncionarios,
    submitMudarProdutoFuncionarios,
    submitAtivarProdutosFuncionarios,
    handleCancelarAlteracoesFuncionariosInativos,
    idApolice,
    getArquivosApolice,
  } = props;

  const [activeTab, setActiveTab] = useState('1');
  const [params, setParams] = useState({
    idProduto: 0,
  });
  const [seguradosFiltrados, setSeguradosFiltrados] = useState(segurados.data);
  const [seguradosEditados, setSeguradosEditados] = useState(0);
  const [seguradosInativosEditados, setSeguradosInativosEditados] = useState(0);

  useEffect(() => {
    setSeguradosEditados(
      segurados.data.filter(segurado => segurado.editado).length,
    );
    setSeguradosInativosEditados(
      funcInativos.data.filter(segurado => segurado.editado).length,
    );
  }, [segurados, funcInativos]);

  useEffect(() => {
    if (produtos.length) {
      if (params.idProduto != 0) {
        const { nome } = produtos.find(
          produto => produto.id == params.idProduto,
        );
        setSeguradosFiltrados(
          segurados.data.filter(segurado => segurado.produto === nome),
        );
      } else {
        setSeguradosFiltrados(segurados.data);
      }
    }
  }, [params, segurados, produtos]);

  const columns = [
    { name: 'id', title: '#', getCellValue: row => row.index },
    { name: 'nome', title: 'NOME', getCellValue: row => row.nome },
    {
      name: 'qtd_dependentes_tipo_1',
      title: 'Qtd. Dep.',
      getCellValue: row => row.qtd_dep_1,
    },
    {
      name: 'qtd_dependentes_tipo_2',
      title: 'Qtd. Titular 2',
      getCellValue: row => row.qtd_dep_2,
    },
    { name: 'plano', title: 'Plano', getCellValue: row => selectPlano(row) },
    {
      name: 'valor_total',
      title: 'Valor Total',
      getCellValue: row => formatValueToPrice(row.total_segurado),
    },
    { name: 'tipo', title: 'Funções', getCellValue: row => actions(row) },
  ];

  const inactiveColumns = [
    { name: 'nome', title: 'NOME', getCellValue: row => row.nome },
    {
      name: 'plano',
      title: 'Plano',
      getCellValue: row => selectAtivarPlano(row),
    },
    {
      name: 'tipo',
      title: 'Funções',
      getCellValue: row => inactiveActions(row.id),
    },
  ];

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const actions = funcionario => (
    <div>
      <Button
        outline
        className="mr-1"
        size="sm"
        color="primary"
        onClick={() =>
          history.push({
            pathname: `/funcionario/${funcionario.id_segurado}`,
            state: { idEstipulante },
          })
        }
      >
        <FaSearch />
      </Button>
    </div>
  );
  const inactiveActions = idFuncionario => (
    <div>
      <Button
        outline
        className="mr-1"
        size="sm"
        color="primary"
        onClick={() =>
          history.push({
            pathname: `/funcionario/${idFuncionario}`,
            state: { idEstipulante },
          })
        }
      >
        <FaSearch />
      </Button>
    </div>
  );
  const selectPlano = useCallback(
    funcionario => (
      <div className="d-flex">
        <Input
          type="select"
          onChange={event =>
            handleMudarProdutoFuncionario(
              event.target.value,
              funcionario?.id_segurado,
            )
          }
          value={
            funcionario.editado ? funcionario.novoProduto : funcionario.produto
          }
        >
          <option value="Inativar">Inativar</option>
          {produtos.length &&
            produtos.map(produto => {
              const nomePlano = produto.nome.split(' ')[1];
              return (
                <option key={produto.id} value={produto.nome}>
                  {nomePlano}
                </option>
              );
            })}
        </Input>
        {funcionario.editado && (
          <Button
            className="ml-1"
            size="sm"
            color="warning"
            title="Desfazer"
            onClick={() =>
              handleDesfazerAlteracaoFuncionario(funcionario.id_segurado)
            }
          >
            <FaUndoAlt />
          </Button>
        )}
      </div>
    ),
    [
      produtos,
      handleMudarProdutoFuncionario,
      handleDesfazerAlteracaoFuncionario,
    ],
  );

  const selectAtivarPlano = useCallback(
    funcionario => (
      <Input
        type="select"
        onChange={event =>
          handleAtivarProdutoFuncionario(event.target.value, funcionario.id)
        }
        value={funcionario.novoProduto}
      >
        <option value="">Nenhum</option>
        {produtos.length &&
          produtos.map(produto => {
            const nomePlano = produto.nome.split(' ')[1];
            return (
              <option key={produto.id} value={produto.nome}>
                {nomePlano}
              </option>
            );
          })}
      </Input>
    ),
    [produtos, handleAtivarProdutoFuncionario],
  );

  const handleChangeProduto = e => {
    const id = e.target.value;
    setParams({ ...params, idProduto: id });
  };

  let produtoSelecionado = [];
  if (params.idProduto !== 0) {
    produtoSelecionado = produtos.find(
      produto => produto.id == params.idProduto,
    );
  }

  return (
    <Card>
      <CardComponent>
        <Col className="header-content text-left">
          <h2>
            <FaUsers />
            &nbsp;&nbsp;Funcionários
          </h2>
        </Col>
      </CardComponent>
      <hr />
      <Row className="text-left mb-4">
        {activeTab == 1 && (
          <Col md={2}>
            <Input type="select" onChange={handleChangeProduto}>
              <option value="">Todos</option>
              {produtos.map(el => {
                return (
                  <option key={el.id} value={el.id}>
                    {' '}
                    {el.nome}{' '}
                  </option>
                );
              })}
            </Input>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{
                  fontSize: '14px',
                  cursor: 'pointer',
                  color: activeTab == 1 ? '#212529' : '#6d6d6d',
                  fontWeight: activeTab == 1 ? 'bold' : 'normal',
                }}
                onClick={() => {
                  toggle('1');
                }}
                title="Todos os funcionários vinculados a este estipulante e a uma apólice dele"
                className={classnames({ active: activeTab === '1' })}
              >
                Ativos{' '}
                {params.idProduto !== 0 &&
                  produtoSelecionado &&
                  ` - ${produtoSelecionado.nome}`}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{
                  fontSize: '14px',
                  cursor: 'pointer',
                  color: activeTab == 0 ? '#212529' : '#6d6d6d',
                  fontWeight: activeTab == 0 ? 'bold' : 'normal',
                }}
                onClick={() => {
                  toggle('0');
                  setParams({ ...params, apoliceProduto: 0 });
                }}
                title="Todos os funcionários vinculados a este estipulante mas sem nenhuma apólice"
                className={classnames({ active: activeTab === '0' })}
              >
                Inativos
              </NavLink>
            </NavItem>
          </Nav>
          {!segurados.loading ? (
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {!segurados.error ? (
                  <SimpleTable
                    height="320px"
                    rows={seguradosFiltrados}
                    columns={columns}
                  />
                ) : (
                  <MsgErrorDataTable className="ml-2">
                    <FaExclamationCircle />
                    &nbsp;&nbsp;{segurados.error}
                  </MsgErrorDataTable>
                )}
                <Row className="my-4 justify-content-between">
                  <Col xs="auto">
                    <BotaoRelatorioFuncionariosAtivos
                      idEmpresa={idEstipulante}
                      idApolice={idApolice}
                      getArquivosApolice={getArquivosApolice}
                    />
                  </Col>
                  <Col xs="auto">
                    <Button
                      className="mr-2"
                      color="danger"
                      size="sm"
                      onClick={handleCancelarAlteracoesFuncionarios}
                      disabled={!seguradosEditados || loadingAlterarProdutos}
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="success"
                      size="sm"
                      onClick={() => submitMudarProdutoFuncionarios()}
                      disabled={!seguradosEditados || loadingAlterarProdutos}
                    >
                      {loadingAlterarProdutos ? (
                        <>
                          <Spinner className="mr-2" color="white" size="sm" />
                          <span>Confirmando...</span>
                        </>
                      ) : (
                        'Confirmar'
                      )}
                    </Button>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="0">
                {!funcInativos.error ? (
                  <SimpleTable
                    height="320px"
                    rows={funcInativos.data}
                    columns={inactiveColumns}
                  />
                ) : (
                  <MsgErrorDataTable className="mb-3">
                    <FaExclamationCircle />
                    &nbsp;&nbsp;{funcInativos.error}
                  </MsgErrorDataTable>
                )}
                <Row className="my-4">
                  <Col className="d-flex justify-content-end">
                    <Button
                      className="mr-2"
                      color="danger"
                      size="sm"
                      onClick={handleCancelarAlteracoesFuncionariosInativos}
                      disabled={
                        !seguradosInativosEditados || loadingAtivarProdutos
                      }
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="success"
                      size="sm"
                      onClick={() => submitAtivarProdutosFuncionarios()}
                      disabled={
                        !seguradosInativosEditados || loadingAtivarProdutos
                      }
                    >
                      {loadingAtivarProdutos ? (
                        <>
                          <Spinner className="mr-2" color="white" size="sm" />
                          <span>Confirmando...</span>
                        </>
                      ) : (
                        'Confirmar'
                      )}
                    </Button>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          ) : (
            <Loading className="mb-4" />
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default ResumosFuncionario;
