import React, { useState } from 'react';
import { FaHandHoldingUsd, FaSearch } from 'react-icons/fa';
import { Container, Row, Card, Col, Input, Spinner } from 'reactstrap';
import { Box, TextField } from '@material-ui/core';
import TitleContent from '../../components/TitleContent/TitleContent';

import { CardComponent } from '../styles';

import SeguradosApolices from '../../views/SeguradosApolices/SeguradosApolices';
import FaturasPendentes from '../../views/Faturas/FaturasPendentes';
import FaturasEmpresariais from '../../views/Faturas/FaturasEmpresariais';
import MaterialButton from '../../components/Buttons/MaterialButton';
import TableSelect from '../../views/Faturas/TableSelect';
import PrimeirasFaturas from '../../views/Faturas/PrimeirasFaturas';
import MaterialSelect from '../../components/Inputs/MaterialSelect';

export default function Page(props) {
  const {
    faturas,
    inputs,
    setInputs,
    primeirasFaturas,
    assessorias,
    corretores,
    nivelUsuario,
    apolicesDeclinadas,
    faturasEmpresariais,
    history,
    policies,
    setPage,
    setFaturas,
    setFaturasEmpresariais,
    primeirasFaturasRenovacoes,
    user,
  } = props;

  const titleHeader = (
    <>
      <FaHandHoldingUsd />
      &nbsp;&nbsp;Cobranças
    </>
  );
  let titleSearch = '';
  const [pesquisaBox, setPesquisaBox] = useState(0); // 0 - Apólices; 1 - Faturas Pendentes
  switch (pesquisaBox) {
    case 0:
      titleSearch = 'Apólices Suspensas';
      break;

    case 1:
      titleSearch = 'Faturas Pendentes';
      break;

    case 2:
      titleSearch = 'Primeira Fatura Pendente';
      break;

    case 3:
      titleSearch = 'Apólices Declinadas por desistência';
      break;

    case 4:
      titleSearch = 'Faturas Empresariais';
      break;

    case 5:
      titleSearch = 'Primeira Pendente Renovações';
      break;

    default:
      titleSearch = 'Cobranças';
      break;
  }
  const handleFiltrarFaturas = e => {
    e.preventDefault();
    const form = e.target;
    const initDate = form.initDate.value;
    const endDate = form.endDate.value;
    console.log(initDate, endDate);

    // Usado na paginação
    setFaturas({
      loading: false,
      data: [],
      rows: 0,
    });
    setFaturasEmpresariais({
      loading: false,
      data: [],
      rows: 0,
    });
    setPage(0);

    if (initDate !== '' || endDate !== '') {
      setInputs({ ...inputs, initDate, endDate });
    }
  };

  const handleLimparFiltros = () => {
    const form = document.forms.filtrarFaturas;
    form.initDate.value = '';
    form.endDate.value = '';

    // Usado na paginação
    setFaturas({
      loading: false,
      data: [],
      rows: 0,
    });
    setFaturasEmpresariais({
      loading: false,
      data: [],
      rows: 0,
    });
    setPage(0);

    setInputs({ ...inputs, initDate: '', endDate: '' });
  };

  const handleChangeAssessoria = e => {
    setFaturas({
      loading: false,
      data: [],
      rows: 0,
    });
    setFaturasEmpresariais({
      loading: false,
      data: [],
      rows: 0,
    });
    setPage(0);

    if (e.target.value == '') {
      setInputs({
        ...inputs,
        assessoriaSelected: e.target.value,
        corretorSelected: 0,
      });
    } else {
      setInputs({
        ...inputs,
        assessoriaSelected: e.target.value,
      });
    }
  };

  const handleChangeCorretor = e => {
    setFaturas({
      loading: false,
      data: [],
      rows: 0,
    });
    setFaturasEmpresariais({
      loading: false,
      data: [],
      rows: 0,
    });
    setPage(0);
    setInputs({ ...inputs, corretorSelected: e.target.value });
  };

  return (
    <Container fluid className="pb-3">
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mt-4">
          <Card>
            <CardComponent className="mb-4">
              <Box ml="1rem" textAlign="left">
                <h2>
                  {faturas.loading ? <Spinner color="info" /> : <FaSearch />}
                  &nbsp;Pesquisa de {titleSearch}
                </h2>
              </Box>

              <Row>
                {(nivelUsuario == 1 || nivelUsuario == 5) && (
                  <Col md={2} className="mb-2">
                    <p className="mb-1 text-left">Distribuidor</p>
                    <Input
                      type="select"
                      onChange={handleChangeAssessoria}
                      disabled={assessorias.loading}
                      value={inputs.assessoriaSelected}
                    >
                      {(nivelUsuario == 1 || nivelUsuario == 5) && (
                        <option value="">
                          {assessorias.loading ? 'Carregando...' : 'Todos'}
                        </option>
                      )}
                      {assessorias.data.map(ass => {
                        return (
                          <option key={ass.id} value={ass.id}>
                            {ass.razao_social ? ass.razao_social : '(Sem nome)'} / {ass.nome}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                )}
                <Col md={2}>
                  <p className="mb-1 text-left">Corretor</p>
                  <Input
                    type="select"
                    disabled={
                      corretores.loading || inputs.assessoriaSelected == 0
                    }
                    onChange={handleChangeCorretor}
                    id="selectCorretor"
                    value={inputs.corretorSelected}
                  >
                    {(nivelUsuario == 1 || nivelUsuario == 5) &&
                      corretores.loading && (
                        <option value="">Carregando...</option>
                      )}
                    {corretores.data.map((ass, i) => {
                      return (
                        <option key={i} value={ass.id}>
                          {ass.nome}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
              </Row>
              <Row className="text-left">
                <Col md={12}>
                  <TableSelect
                    pesquisaBox={pesquisaBox}
                    setPesquisaBox={setPesquisaBox}
                    faturas={faturas.rows}
                    primeiras={primeirasFaturas.data.length}
                    suspensas={policies.data.length}
                    declinadas={apolicesDeclinadas.data.length}
                    empresariais={faturasEmpresariais.data.length}
                    renovacoes={primeirasFaturasRenovacoes.data.length}
                  />
                </Col>
              </Row>
              {(pesquisaBox === 1 || pesquisaBox === 4) && (
                <form name="filtrarFaturas" onSubmit={handleFiltrarFaturas}>
                  <Box
                    display="flex"
                    width="100%"
                    mt="1rem"
                    alignItems="center"
                    justifyContent="start"
                    mx="1rem"
                    flexWrap="wrap"
                  >
                    <Box
                      my="1rem"
                      mr="2rem"
                      display="flex"
                      justifyContent="start"
                      flexWrap="wrap"
                    >
                      <TextField
                        className="mr-2 mb-2"
                        id="initDate"
                        label="Data Inicial"
                        type="date"
                        name="initDate"
                        defaultValue={new Date()}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        id="endDate"
                        label="Data Final"
                        type="date"
                        name="endDate"
                        defaultValue={new Date()}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                    <Box display="flex" justifyContent="start" mr="2rem">
                      <MaterialButton
                        color="dashboard"
                        disabled={pesquisaBox === 2}
                        size="small"
                        type="submit"
                      >
                        Filtrar
                      </MaterialButton>
                      <Box ml={2}>
                        <MaterialButton
                          color="warning"
                          disabled={pesquisaBox === 2}
                          size="small"
                          onClick={handleLimparFiltros}
                        >
                          Limpar filtros
                        </MaterialButton>
                      </Box>
                    </Box>
                  </Box>
                </form>
              )}
            </CardComponent>
          </Card>
        </Col>
      </Row>
      {pesquisaBox === 0 && (
        <SeguradosApolices
          setPage={setPage}
          policies={policies.data}
          history={history}
          loading={policies.loading}
        />
      )}
      {pesquisaBox === 1 && (
        <FaturasPendentes
          setPage={setPage}
          faturas={faturas.data}
          inputs={inputs}
          loading={faturas.loading}
          rowsQuantity={faturas.rows}
          user={user}
        />
      )}
      {pesquisaBox === 2 && (
        <PrimeirasFaturas
          setPage={setPage}
          faturas={primeirasFaturas.data}
          inputs={inputs}
          loading={primeirasFaturas.loading}
          user={user}
        />
      )}
      {pesquisaBox === 3 && (
        <SeguradosApolices
          setPage={setPage}
          policies={apolicesDeclinadas.data}
          history={history}
          loading={apolicesDeclinadas.loading}
        />
      )}
      {pesquisaBox === 4 && (
        <FaturasEmpresariais
          setPage={setPage}
          faturas={faturasEmpresariais.data}
          inputs={inputs}
          loading={faturasEmpresariais.loading}
          rowsQuantity={faturasEmpresariais.rows}
        />
      )}
      {pesquisaBox === 5 && (
        <PrimeirasFaturas
          setPage={setPage}
          faturas={primeirasFaturasRenovacoes.data}
          inputs={inputs}
          loading={primeirasFaturasRenovacoes.loading}
        />
      )}
    </Container>
  );
}
