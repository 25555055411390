import React from 'react';
import { Box } from '@material-ui/core';

import { CardResumo } from './styles';
import formatValueToPrice from '../../utils/format';
import CustomSkeleton from '../../components/Loading/Skeleton';

function ComissaoDistribuidor(props) {
  const { comissaoDistribuidor } = props;

  return (
    <Box textAlign="left" mb="2.5rem">
      <Box display="flex" justifyContent="space-between">
        <CardResumo>
          <h5>Primeiras Faturas</h5>
          {comissaoDistribuidor.loading ? (
            <p>
              <CustomSkeleton width={40} height={20} />
            </p>
          ) : (
            <p>{comissaoDistribuidor.data.numPrimeirasFaturas}</p>
          )}
        </CardResumo>
        <CardResumo>
          <h5>Demais Faturas</h5>
          {comissaoDistribuidor.loading ? (
            <p>
              <CustomSkeleton width={40} height={20} />
            </p>
          ) : (
            <p>{comissaoDistribuidor.data.numDemaisFaturas}</p>
          )}
        </CardResumo>
        <CardResumo card="valor" color="dashboard">
          <h5>Valor</h5>
          {comissaoDistribuidor.loading ? (
            <p>
              <CustomSkeleton width={60} height={20} />
            </p>
          ) : (
            <p>{formatValueToPrice(comissaoDistribuidor.data.valor)}</p>
          )}
        </CardResumo>
      </Box>
      {/* <TableContainer component={Paper} variant="outlined">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Primeiras Faturas</TableCell>
              <TableCell>Demais Faturas</TableCell>
              <TableCell>Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {comissaoDistribuidor.loading ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <CustomSkeleton height={20} />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell>
                  {comissaoDistribuidor.data.numPrimeirasFaturas}
                </TableCell>
                <TableCell>
                  {comissaoDistribuidor.data.numDemaisFaturas}
                </TableCell>
                <TableCell>
                  {formatValueToPrice(comissaoDistribuidor.data.valor)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer> */}
    </Box>
  );
}

export default ComissaoDistribuidor;
