import React from 'react';
import { Card, Col, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FaSearch } from 'react-icons/fa';
import { CardComponent } from '../styles';

function SelecionaTerceiro(props) {
	
	const { terceiros, setInputSelect, getSeguradosAtivosPorTerceiro, setNomeTerceiros } = props;
	
	const renderTerceiros = _ => {
		if(terceiros){
			return(
				<InputGroup>
					<Input type="select" name="inputSelect" onChange={e => setInputSelect(e.target.value)}>
						<option value="">Selecione</option>
						{terceiros.map((el, i) => (
							<option key={i} onClick={() => setNomeTerceiros(el.nome) } value={el.id}>{el.nome}</option>
						))}
					</Input> 
					<InputGroupAddon addonType="append">
						<InputGroupText style={{cursor: "pointer"}} onClick={() => getSeguradosAtivosPorTerceiro()}>
							<FaSearch/>
						</InputGroupText>
					</InputGroupAddon>
				</InputGroup>
			);
		}
	}

  return (
    <Card>
			<CardComponent>
				<Col className="header-content">
					<h2><FaSearch />&nbsp;Selecione um Terceiro</h2>
				</Col>
				<Col className="mt-4 mb-4">
					{renderTerceiros()}
				</Col>
			</CardComponent>
    </Card>
  )
}

export default SelecionaTerceiro;
