import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Api from '../../services/api';
import Page from './Page';

function Controller(props) {
  const [idAssessoriaSelecionada, setIdAssessoriaSelecionada] = useState();
  const [assessorias, setAssessorias] = useState([]);
  const [estipulantes, setEstipulantes] = useState([]);
  const [relatorioAssessoriaSelecionada, setRelatorioAssessoriaSelecionada] =
    useState();
  const [corretorRepresentante, setCorretorRepresentante] = useState();
  const [graficoValorTotalMes, setGraficoValorTotalMes] = useState([]);
  const [loadingGraph, setLoadingGraph] = useState(false);
  const [gruposVenda, setGruposVenda] = useState();
  const authStr = `Bearer ${props.user.user.token}`;
  const history = useHistory();

  useEffect(() => {
    buscarAssessorias();
    getGruposVenda();
    getEstipulantes();
    if (
      idAssessoriaSelecionada !== '' &&
      idAssessoriaSelecionada !== undefined
    ) {
      buscarRelatorioAssessoriaSelecionada();
      buscarCorretorRepresentante();
    }
  }, [idAssessoriaSelecionada]);

  const handleOnChange = e => {
    const { name, value } = e.target;
    setIdAssessoriaSelecionada(value);
  };

  const buscarIdAssessoriaPeloUsuario = async () => {
    const request = await Api.get('/assessoria/usuario', {
      headers: { Authorization: authStr },
    });
    setIdAssessoriaSelecionada(request.data);
  };

  const buscarAssessorias = async () => {
    const request = await Api.get('/assessorias/', {
      headers: { Authorization: authStr },
    });
    setAssessorias(request.data);
  };

  const getMesAno = date => {
    const newDate = date.split('/');
    return `${newDate[2]}/${newDate[1]}`;
  };

  const getGruposVenda = async () => {
    const fetchGrupoVendas = await Api.get('/grupovendas', {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });
    if (fetchGrupoVendas.data.ok) {
      setGruposVenda(fetchGrupoVendas.data.output);
    }
  };

  const getEstipulantes = async () => {
    const request = await Api.get('/empresas/', {
      headers: { Authorization: authStr },
    });
    setEstipulantes(request.data.output);
  };

  const buscarRelatorioAssessoriaSelecionada = async () => {
    if (idAssessoriaSelecionada) {
      const request = await Api.get(
        `relatorio-venda/assessoria/${idAssessoriaSelecionada}`,
        { headers: { Authorization: authStr } },
      );

      setLoadingGraph(true);
      const objValorTotalMes = [];

      if (request.data.output) {
        const newRequest = request.data.output.sort(
          (a, b) => b.dataVenda - a.dataVenda,
        );
        newRequest.map((el, i) => {
          if (el.statusPrimeiroPagamento === 'Pago') {
            if (el.dataVenda !== '' && el.valorMensal !== null) {
              const mesAno = getMesAno(el.dataVenda);
              objValorTotalMes[mesAno] = objValorTotalMes[mesAno]
                ? objValorTotalMes[mesAno] + parseFloat(el.valorMensal)
                : parseFloat(el.valorMensal);
            }
          }
        });
      }

      setGraficoValorTotalMes(objValorTotalMes);
      setLoadingGraph(false);
      setRelatorioAssessoriaSelecionada(request.data.output);
    }
  };

  const buscarCorretorRepresentante = async () => {
    if (idAssessoriaSelecionada) {
      const request = await Api.get(
        `corretor-representante/assessoria/${idAssessoriaSelecionada}`,
        { headers: { Authorization: authStr } },
      );
      setCorretorRepresentante(request.data);
    }
  };
  return (
    <>
      {assessorias && (
        <Page
          assessorias={assessorias}
          relatorioAssessoriaSelecionada={relatorioAssessoriaSelecionada}
          graficoValorTotalMes={graficoValorTotalMes}
          corretorRepresentante={corretorRepresentante}
          idAssessoriaSelecionada={idAssessoriaSelecionada}
          handleOnChange={handleOnChange}
          history={history}
          loadingGraph={loadingGraph}
          gruposVenda={gruposVenda}
          estipulantes={estipulantes}
        />
      )}
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
