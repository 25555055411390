import React, {useState} from 'react';
import TablesGrid from '../../components/TablesGrid/TablesGrid';
import { FaSearch } from 'react-icons/fa';
import { Label, Input, Button, Col, Badge, Row, Card } from 'reactstrap';
import { TableContent, CardComponent } from '../styles';
import { Link } from 'react-router-dom';

export default function EdicaoSeguradosApolices(props) {
    const { setCheckedApolices, checkedApolices, handleChangeCheckbox, tipoAcao } = props;

    let columns = [
        { name: 'checkbox', title: 'Selecionar', getCellValue: row => checkbox(row) },
        { name: 'cpf_segurado', title: 'CPF', getCellValue: row => (row.cpf_segurado) },
        { name: 'nome_segurado', title: 'NOME', getCellValue: row => (row.nome_segurado) },
        { name: 'inicio_vigencia', title: 'INICIO VIGÊNCIA', getCellValue: row => row.inicio_vigencia},
        { name: 'vendedor', title: 'CONSULTOR', getCellValue: row => (row.vendedor) },
        { name: 'descricao_apolice', title: 'DESCRIÇÃO APÓLICE', className: 'text-right' ,getCellValue: row => row.descricao_apolice },
        { name: 'status', title: 'STATUS', getCellValue: row => {
            let renovar = false;

            return (row.status)
        } },
        { name: '', title: '', getCellValue: row => (actions(row))},
    ];

    const checkbox = row => {
        return(
            <>
                <input type="checkbox" key={row.id_apolice} id={row.id_apolice} name={`apolice_${row.id_apolice}`} onChange={handleChangeCheckbox}/>
            </>
        )
    }

    const actions = row => {
        return(
            <div>
                <Link to={`/segurado/${row.id_segurado}`} target="_blank" title="Ver mais em outra aba.">
                    <Button type="button" size="sm" outline color="primary">
                        <FaSearch />
                    </Button>
                </Link>
            </div>
        );
    }

    return (
        <Col md={12}>
            {( props.policies && props.policies.length > 0)
                ?
                <TableContent>
                    <form name="acoesApolices">
                        <input type="hidden" name="tipoAcao" value={tipoAcao}/>
                        <TablesGrid rows={props.policies} columns={columns} pageSize={25} />
                    </form>
                </TableContent>
                :
                <Card>
                    <CardComponent className="mb-3">
                        Nenhuma apólice encontrada
                    </CardComponent>
                </Card>
            }
        </Col>
    );
}
