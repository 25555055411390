import Styled from 'styled-components';

export const ModalHeader = Styled.div`
  border-bottom: 1px solid #ccc;
  padding: 1rem;

  h1{
    text-transform: uppercase;
    font-size: 1.5rem;
    margin: 0 ;
  }
`;

export const ModalBody = Styled.div`
  padding: 1rem 2rem;
`;

export const ModalFooter = Styled.div`
  display:flex;
  justify-content:flex-end;
  border-top: 1px solid #ccc;
  padding: 1rem;

  .confirm {
    margin-left:0.5rem;
  }
`;
