import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { FaDollarSign } from 'react-icons/fa';
import { connect } from 'react-redux';
import { BodyFormComponent, PrependInputLabel, LabelForm } from '../styles';
import SubmitBtn from '../../components/Buttons/SubmitBtn';
import Api from '../../services/api';
import { alertError } from '../../components/Alerts/Alerts';

function ComissaoCorretorModal(props) {
  const { modal, toggle, idUsuario } = props;
  const [metodoSalvar, setMetodoSalvar] = useState(false);
  const [comissaoInput, setComissaoInput] = useState({
    primeiraParcela: '',
    carteira: '',
    pagamentoAVista: '',
    idUsuario,
  });
  const [loading, setLoading] = useState(false);

  const handleOnChange = e => {
    const { name, value } = e.target;
    setComissaoInput({ ...comissaoInput, [name]: value });
  };

  useEffect(() => {
    getComissao();
  }, []);

  const getComissao = async () => {
    const response = await Api.get(`/comissao/${idUsuario}`, {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });
    if (Object.keys(response.data.output).length > 0) {
      setComissaoInput({
        primeiraParcela: response.data.output.primeira_parcela,
        carteira: response.data.output.carteira,
        pagamentoAVista: response.data.output.pagamento_a_vista,
        idUsuario,
      });
    } else {
      setMetodoSalvar(true);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    let response = '';
    console.log('comissaoInput - ', comissaoInput);
    if (metodoSalvar) {
      response = await Api.post(`/comissao`, comissaoInput, {
        headers: { Authorization: `Bearer ${props.user.user.token}` },
      });
    } else {
      response = await Api.put(`/comissao`, comissaoInput, {
        headers: { Authorization: `Bearer ${props.user.user.token}` },
      });
    }

    if (response.data.ok) {
      window.location.reload();
    } else {
      alertError('Erro ao salvar valor de comissão');
    }
    setLoading(false);
  };

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <FaDollarSign /> &nbsp;Cadastro de Comissão - Corretor
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit} method="post">
          <BodyFormComponent>
            <Row>
              <Col md={12} className="mb-2">
                <LabelForm>1ª Parcela</LabelForm>
                <InputGroup>
                  <Input
                    name="primeiraParcela"
                    value={comissaoInput.primeiraParcela}
                    onChange={e => handleOnChange(e)}
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>%</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={12} className="mb-2">
                <LabelForm>Carteira</LabelForm>
                <InputGroup>
                  <Input
                    name="carteira"
                    type="number"
                    value={comissaoInput.carteira}
                    onChange={e => handleOnChange(e)}
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>%</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={12} className="mb-2">
                <LabelForm>Pagamento à vista</LabelForm>
                <InputGroup>
                  <Input
                    name="pagamentoAVista"
                    type="number"
                    value={comissaoInput.pagamentoAVista}
                    onChange={e => handleOnChange(e)}
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>%</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </BodyFormComponent>
          <Row className="text-right">
            <Col>
              <SubmitBtn
                label="Salvar"
                labelOnClick="Aguarde"
                loading={loading}
                typeSubmit="submit"
                metodo={handleSubmit}
                color="#0A7A73"
              />
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(ComissaoCorretorModal);
