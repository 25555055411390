import React, { forwardRef } from 'react';
import {
  FormGroup,
  Input as ReactstrapInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Spinner,
} from 'reactstrap';

import { InputContainer, PrependInputLabel } from '../../views/styles';

const InputBase = (props, ref) => {
  const {
    error,
    options,
    type,
    label,
    children,
    leftAddon,
    loading = false,
    ...rest
  } = props;

  return (
    <InputContainer>
      <FormGroup className="text-left m-0">
        <div>
          <Label className="font-weight-bold">{label}</Label>
        </div>
        {loading ? (
          <div className="w-100 d-flex pt-2">
            <Spinner size="sm" className="m-auto" />
          </div>
        ) : (
          <InputGroup>
            {children && (
              <InputGroupAddon addonType="prepend">
                <InputGroupText>{children}</InputGroupText>
              </InputGroupAddon>
            )}

            <ReactstrapInput type={type} invalid={!!error} ref={ref} {...rest}>
              {options && (
                <>
                  <option value="">Selecione</option>
                  {options.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </>
              )}
            </ReactstrapInput>
            {leftAddon && (
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <PrependInputLabel>{leftAddon}</PrependInputLabel>
                </InputGroupText>
              </InputGroupAddon>
            )}
            {!!error && <div className="invalid-feedback">{error.message}</div>}
          </InputGroup>
        )}
      </FormGroup>
    </InputContainer>
  );
};

export const CustomInput = forwardRef(InputBase);
