import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  alertError,
  alertSuccess,
  alertWarning,
} from '../../components/Alerts/Alerts';
import Api from '../../services/api';

import Page from './Page';

function Controller(props) {
  const { id, idEmpresa } = useParams();
  const { user } = props;

  const authStr = `Bearer ${user.user.token}`;
  const { usuario } = user.user;

  const [segurados, setSegurados] = useState({
    data: [],
    loading: true,
    error: '',
  });

  const [funcInativos, setFuncInativos] = useState({
    data: [],
    loading: true,
    error: '',
  });
  const [detalhesApolice, setDetalhesApolice] = useState({});
  const [faturas, setFaturas] = useState({
    data: [],
    loading: true,
    error: '',
  });
  const [produtos, setProdutos] = useState([]);
  const [policieConfigPayment, setPolicieConfigPayment] = useState({});
  const [valorApolice, setValorApolice] = useState(0.0);
  const [archivesPolicies, setArchivePolicie] = useState({
    data: [],
    loading: true,
    error: '',
  });
  const [movimentacao, setMovimentacao] = useState({
    data: [],
    loading: true,
    error: '',
  });
  const [anotacoes, setAnotacoes] = useState({
    data: [],
    loading: true,
    error: '',
  });
  const [loadingAlterarProdutos, setLoadingAlterarProdutos] = useState(false);
  const [loadingAtivarProdutos, setLoadingAtivarProdutos] = useState(false);
  const [motivoCancelamento, setMotivoCancelamento] = useState();

  const getSeguradosByApolice = useCallback(async () => {
    setSegurados({ ...segurados, loading: true });
    try {
      const response = await Api.get(
        `/apolice/relacao-cobranca-empresarial/${id}`,
        { headers: { Authorization: authStr } },
      );

      if (response.data.ok) {
        setSegurados({
          data: response.data.output.map((segurado, key) => ({
            ...segurado,
            editado: false,
            novoProduto: '',
            index: key + 1,
          })),
          loading: false,
        });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setSegurados({ error: error.message, loading: false, data: [] });
      console.log('Erro ao buscar segurados: ', error.message);
      // alertError("Erro ao buscar segurados: ", error.message);
    }
  }, [segurados]);

  const getFuncInativos = useCallback(async () => {
    setFuncInativos({ ...funcInativos, loading: true });
    try {
      const response = await Api.get(
        `/segurado/${idEmpresa}/${id}/nao-inclusos/`,
        { headers: { Authorization: authStr } },
      );
      if (response.data.ok) {
        setFuncInativos({
          data: response.data.output.map(func => ({
            ...func,
            editado: false,
            novoProduto: '',
          })),
          loading: false,
        });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setFuncInativos({ error: error.message, loading: false, data: [] });
      console.log('Erro ao buscar funcionários inativos: ', error.message);
      // alertError("Erro ao buscar funcionários inativos: ",error.message);
    }
  }, [funcInativos]);

  const getProdutos = useCallback(async () => {
    try {
      const response = await Api.get(`/produtos/`, {
        headers: { Authorization: authStr },
      });
      if (response.data.ok) {
        setProdutos(response.data.output);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError('Erro ao buscar produtos: ', error.message);
    }
  }, []);

  const getDetalhesApolice = useCallback(async () => {
    setDetalhesApolice({});
    try {
      const response = await Api.get(`apolice/${id}`, {
        headers: { Authorization: authStr },
      });
      if (response.data.ok) {
        setDetalhesApolice(response.data.output);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.log('Erro ao buscar detalhes da apólice: ', error.message);
      // alertError('Erro ao buscar detalhes da apólice: ',error.message);
    }
  }, []);

  const getFaturas = useCallback(async () => {
    setFaturas({ data: [], loading: true });
    try {
      const response = await Api.get(`faturas/apolice/${id}`, {
        headers: { Authorization: authStr },
      });
      if (response.data.ok) {
        setFaturas({ data: response.data.output, loading: false });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setFaturas({ error: error.message, loading: false, data: [] });
      console.log('Erro ao buscar faturas: ', error.message);
      // alertError("Erro ao buscar faturas: ",error.message);
    }
  }, []);

  const getConfigPayment = useCallback(async () => {
    try {
      const response = await Api.get(`apolice/config-pagamento/${id}`, {
        headers: { Authorization: authStr },
      });
      if (response.data.ok) {
        setPolicieConfigPayment(response.data.output);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.log('Erro ao buscar configurações de pagamento: ', error.message);
      // alertError("Erro ao buscar configurações de pagamento: ",error.message);
    }
  }, []);

  const getValorApolice = useCallback(async () => {
    try {
      const response = await Api.get(`/apolice/busca-valor/${id}`, {
        headers: { Authorization: authStr },
      });
      if (response.data.ok) {
        setValorApolice(response.data.output.valor);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.log('Erro ao buscar valor da apólice: ', error.message);
      // alertError("Erro ao buscar valor da apólice: ",error.message);
    }
  }, []);

  const gerarCobrancaEmpresarial = useCallback(async dataVenc => {
    try {
      const body = { dataVencimento: dataVenc };
      const response = await Api.post(
        `apolice/${id}/cobranca-empresarial`,
        body,
        { headers: { Authorization: authStr } },
      );
      if (response.data.ok) {
        getFaturas();
        alertSuccess('Cobrança criada com sucesso.');
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError('Erro ao gerar cobrança empresarial: ', error.message);
    }
  }, []);

  const getArquivosApolice = useCallback(async () => {
    setArchivePolicie({ data: [], loading: true });
    try {
      const response = await Api.get(`apolice/arquivos/${id}`, {
        headers: { Authorization: authStr },
      });
      if (response.data.ok) {
        setArchivePolicie({ data: response.data.output, loading: false });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setArchivePolicie({ error: error.message, loading: false, data: [] });
      console.log('Erro ao buscar documentos: ', error.message);
      // alertError("Erro ao buscar documentos: ", error.message);
    }
  }, []);

  const getMovimentacao = useCallback(async () => {
    try {
      const response = await Api.get(`/movimentacoes/${id}`, {
        headers: { Authorization: authStr },
      });

      if (response.data.ok) {
        setMovimentacao({ data: response.data.output, loading: false });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setMovimentacao({ error: error.message, loading: false, data: [] });
      console.log('Erro ao buscar movimentações: ', error.message);
      // alertError("Erro ao buscar movimentações: ", error.message);
    }
  }, []);

  const getAnotacoes = useCallback(async () => {
    setAnotacoes({ data: [], loading: true });
    try {
      const response = await Api.get(`anotacao/${id}`, {
        headers: { Authorization: authStr },
      });
      setAnotacoes({ data: response.data.output, loading: false });
    } catch (error) {
      setAnotacoes({ error: error.message, loading: false, data: [] });
      console.log('Erro ao buscar anotações: ', error.message);
      // alertError("Erro ao buscar anotações: ", error.message);
    }
  }, []);

  const getMotivosCancelamento = async () => {
    try {
      const response = await Api.get(`/motivos-cancelamento`, {
        headers: { Authorization: authStr },
      });
      if (response.data.ok) {
        setMotivoCancelamento(response.data.output);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError(error.message);
    }
  };

  const handleMudarProdutoFuncionario = useCallback(
    (nomeProduto, idSegurado) => {
      setSegurados({
        data: segurados.data.map(segurado =>
          segurado.id_segurado == idSegurado
            ? {
                ...segurado,
                editado: segurado.produto != nomeProduto,
                novoProduto: nomeProduto,
              }
            : segurado,
        ),
        loading: false,
      });
    },
    [segurados],
  );

  const handleAtivarProdutoFuncionario = useCallback(
    (nomeProduto, idSegurado) => {
      setFuncInativos({
        data: funcInativos.data.map(func =>
          func.id == idSegurado
            ? {
                ...func,
                editado: !!nomeProduto,
                novoProduto: nomeProduto,
              }
            : func,
        ),
        loading: false,
      });
    },
    [funcInativos],
  );

  const handleDesfazerAlteracaoFuncionario = useCallback(
    idSegurado => {
      setSegurados({
        data: segurados.data.map(segurado =>
          segurado.id_segurado == idSegurado
            ? {
                ...segurado,
                editado: false,
              }
            : segurado,
        ),
        loading: false,
      });
    },
    [segurados],
  );

  const handleCancelarAlteracoesFuncionarios = useCallback(() => {
    if (
      !window.confirm(
        'Alerta:\nTem certeza que deseja desfazer todas as modificações?',
      )
    ) {
      return;
    }
    setSegurados({
      data: segurados.data.map(segurado => ({
        ...segurado,
        editado: false,
      })),
      loading: false,
    });
  }, [segurados]);

  const handleCancelarAlteracoesFuncionariosInativos = useCallback(() => {
    if (
      !window.confirm(
        'Alerta:\nTem certeza que deseja desfazer todas as modificações?',
      )
    ) {
      return;
    }
    setFuncInativos({
      data: funcInativos.data.map(segurado => ({
        ...segurado,
        editado: false,
        novoProduto: '',
      })),
      loading: false,
    });
  }, [funcInativos]);

  const inactivatePlanoFuncionario = useCallback(
    async segurado => {
      const data = {
        id_apolice: id,
        id_produto: segurado.id_produto,
        id_segurado: segurado.id_segurado,
        id_usuario: usuario,
      };

      try {
        const response = await Api.post(
          `apolice/produto-segurado/inativar`,
          data,
          { headers: { Authorization: authStr } },
        );
        if (!response.data.ok) {
          throw new Error(response.data.message);
        } else {
          alertSuccess(`${segurado.nome} inativado com sucesso!`);
          return 'ok';
        }
      } catch (error) {
        alertError(`Erro ao inativar ${segurado.nome}: ${error.message}`);
        return 'erro';
      }
    },
    [produtos, segurados, getFuncInativos, getSeguradosByApolice],
  );

  const submitMudarProdutoFuncionarios = useCallback(async () => {
    setLoadingAlterarProdutos(true);
    const warningText = segurados.data.reduce(
      (acc, current) =>
        current.editado
          ? `${acc}\n${current.nome} - de ${current.produto} para ${current.novoProduto}`
          : acc,
      '',
    );

    if (
      !window.confirm(
        `Deseja mesmo fazer as seguintes alterações:\n${warningText}`,
      )
    ) {
      alertWarning('Nenhuma alteração feita.');
      setLoadingAlterarProdutos(false);
      return;
    }

    const seguradosEditados = segurados.data.filter(
      segurado => segurado.editado,
    );

    const responses = await Promise.all(
      seguradosEditados.map(async segurado => {
        if (segurado.novoProduto == 'Inativar') {
          return inactivatePlanoFuncionario(segurado);
        }
        const novoProdutoId = produtos.find(
          produto => produto.nome == segurado.novoProduto,
        ).id;

        const data = {
          id_apolice: id,
          id_produto: novoProdutoId,
          id_segurado: segurado.id_segurado,
          id_usuario: usuario,
        };

        try {
          const response = await Api.post(
            `apolice/produto-segurado/ativar`,
            data,
            { headers: { Authorization: authStr } },
          );
          if (!response.data.ok) {
            throw new Error(response.data.message);
          } else {
            alertSuccess(`${segurado.nome} teve o plano alterado com sucesso!`);
            return 'ok';
          }
        } catch (error) {
          alertError(`Erro ao ativar ${segurado.nome}: ${error.message}`);
          return 'erro';
        }
      }),
    );

    if (
      !responses.find(response => response !== 'ok') &&
      responses.length > 1
    ) {
      alertSuccess('Todas as alterações foram feitas com sucesso!');
    }
    getSeguradosByApolice();
    getFuncInativos();
    setLoadingAlterarProdutos(false);
  }, [
    segurados,
    getSeguradosByApolice,
    produtos,
    getFuncInativos,
    inactivatePlanoFuncionario,
  ]);

  const submitAtivarProdutosFuncionarios = useCallback(async () => {
    setLoadingAtivarProdutos(true);
    const warningText = funcInativos.data.reduce(
      (acc, current) =>
        current.editado
          ? `${acc}\n${current.nome} - adicionar ao ${current.novoProduto}`
          : acc,
      '',
    );

    if (
      !window.confirm(
        `Deseja mesmo fazer as seguintes alterações:\n${warningText}`,
      )
    ) {
      alertWarning('Nenhuma alteração feita.');
      setLoadingAtivarProdutos(false);
      return;
    }

    const funcAtivados = funcInativos.data.filter(segurado => segurado.editado);

    const responses = await Promise.all(
      funcAtivados.map(async func => {
        const novoProdutoId = produtos.find(
          produto => produto.nome == func.novoProduto,
        ).id;

        const data = {
          id_apolice: id,
          id_produto: novoProdutoId,
          id_segurado: func.id,
          id_usuario: usuario,
        };

        try {
          const response = await Api.post(
            `apolice/produto-segurado/ativar`,
            data,
            { headers: { Authorization: authStr } },
          );
          if (!response.data.ok) {
            throw new Error(response.data.message);
          } else {
            alertSuccess(`${func.nome} ativado com sucesso!`);
            return 'ok';
          }
        } catch (error) {
          alertError(`Erro ao ativar ${func.nome}.`);
          return { mensagem: error.message, segurado: func.nome };
        }
      }),
    );

    if (
      !responses.find(response => response !== 'ok') &&
      responses.length > 1
    ) {
      alertSuccess('Todas as alterações feitas com sucesso!');
    }
    getSeguradosByApolice();
    getFuncInativos();

    setLoadingAtivarProdutos(false);
  }, [
    funcInativos,
    produtos,
    getFuncInativos,
    getSeguradosByApolice,
    segurados,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSeguradosByApolice();
    getProdutos();
    getFuncInativos();
    getDetalhesApolice();
    getConfigPayment();
    getFaturas();
    getValorApolice();
    getArquivosApolice();
    getMovimentacao();
    getAnotacoes();
    getMotivosCancelamento();
  }, []);

  return (
    <Page
      segurados={segurados}
      produtos={produtos}
      detalhesApolice={detalhesApolice}
      idEstipulante={idEmpresa}
      funcInativos={funcInativos}
      handleMudarProdutoFuncionario={handleMudarProdutoFuncionario}
      handleAtivarProdutoFuncionario={handleAtivarProdutoFuncionario}
      configPayment={policieConfigPayment}
      faturas={faturas}
      getDetalhesApolice={getDetalhesApolice}
      archivesPolicies={archivesPolicies}
      idUsuario={usuario}
      valorApolice={valorApolice}
      gerarCobrancaEmpresarial={gerarCobrancaEmpresarial}
      idApolice={id}
      movimentacao={movimentacao}
      anotacoes={anotacoes}
      submitMudarProdutoFuncionarios={submitMudarProdutoFuncionarios}
      handleDesfazerAlteracaoFuncionario={handleDesfazerAlteracaoFuncionario}
      loadingAlterarProdutos={loadingAlterarProdutos}
      handleCancelarAlteracoesFuncionarios={
        handleCancelarAlteracoesFuncionarios
      }
      loadingAtivarProdutos={loadingAtivarProdutos}
      submitAtivarProdutosFuncionarios={submitAtivarProdutosFuncionarios}
      handleCancelarAlteracoesFuncionariosInativos={
        handleCancelarAlteracoesFuncionariosInativos
      }
      getAnotacoes={getAnotacoes}
      idEmpresa={idEmpresa}
      getArquivosApolice={getArquivosApolice}
      user={user}
      motivoCancelamento={motivoCancelamento}
      getValorApolice={getValorApolice}
      getConfigPayment={getConfigPayment}
    />
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
