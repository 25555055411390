export const msg = {
  "erro_login":" E-mail ou senha incorretos! Tente novamente.",
  "user_not_found":" Usuário não encontrado.",
  "password_changed":" Senha alterada com sucesso.",
  "password_incorrect":" Insira a senha antiga corretamente.",
  "error_system":" Ops! Houve algum erro inesperado. Tente novamente mais tarde.",
  "unrepeated_password":" Repita a senha corretamente.",
  "company_created":"Empresa cadastrada com sucesso.",
  "company_error_created":"Erro ao cadastrar empresa.",
  "company_updated":"Empresa editada com sucesso.",
  "status_apolice_updated":"Status da apolice atualizado com sucesso.",
  "company_error_updated":"Erro ao editar empresa.",
  "companies_not_found":"Nenhuma empresa encontrada.",
  "convenios_not_found":"Nenhum convênio encontrado.",
  "files_not_found_on_apolice":"Nenhum arquivo encontrado.",
  "movimentacoes_not_found":"Nenhuma movimentação encontrado ou pesquisa não realizada.",
  "contacts_not_found":"Nenhum contato cadastrado.",
  "apolice_not_found":"Nenhuma apolice cadastrada.",
  "staff_not_found":"Nenhum funcionário cadastrado.",
  "clients_not_found":"Nenhum cliente cadastrado.",
  "faturas_not_found":"Nenhuma fatura cadastrada.",
  "assessoria_not_found":"Nenhuma assessoria cadastrada.",
  "dependentes_not_found":"Nenhum dependente cadastrado.",
  "product_not_found_on_apolice":"Nenhum produto adicionado à apolice.",
  "confirm_delete_contact":"Deseja mesmo deletar este contato?",
  "contact_deleted":"Contato deletado com sucesso.",
  "confirm_delete_staff":"Deseja mesmo deletar este Funcionario?",
  "confirm_insert_apolice":"Deseja cadastrar uma nova Apolice?",
  "staff_deleted":"Funcionario deletado com sucesso.",
  "staff_edited":"Segurado editado com sucesso.",
  "confirm_delete_apolice":"Deseja mesmo deletar esta Apolice?",
  "arquivo_gerado":"Arquivo Excel gerado com Sucesso.",
  "protocolo_gerado":"Protocolo gerado com sucesso.",
  "carne_gerado":"Carnê gerado com sucesso.",
  "funcionarios_changed":"Funcionários alterados com sucesso.",
  "apolice_deleted":"Apolice deletada com sucesso.",
  "dependente_not_included_form":"Ops! Parece que você esqueceu de adicionar o dependente.",
  "cpf_invalid":"Você inseriu um CPF invalido.",
  "codigo_desconto_not_found":"Código de desconto inválido.",
  "codigo_desconto_ok":"Código de desconto válido.",
  "historico_email_empty":"Nenhum e-mail foi enviado sobre esta apolice.",
  "sales_not_found":"Nenhuma venda encontrada para este usuário.",
  "new_cliente_inserted":"Novo Cliente inserido com sucesso."

}