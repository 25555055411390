import React, { useState } from 'react';
import { Card, Col, Button, Table, Spinner } from 'reactstrap';
import {
  FaTrash,
  FaPlus,
  FaExclamationCircle,
  FaAddressBook,
} from 'react-icons/fa';
import { CardComponent, OverflowContainer, MsgErrorDataTable } from '../styles';

function InformacoesEmpresa(props) {
  const { deletarContato, infosContatos, openModalCadastroContato } = props;

  const [loadingDeleteContato, setLoadingDeleteContato] = useState(false);

  const handleDeletarContato = async id => {
    if (window.confirm(`Deseja mesmo excluir a apólice ${id}?`)) {
      setLoadingDeleteContato(true);
      await deletarContato(id);
      setLoadingDeleteContato(false);
    }
  };

  return (
    <Card>
      <CardComponent>
        <Col className="header-content">
          <h2>
            <FaAddressBook className="mr-2" />
            Contato dos Responsáveis
          </h2>
        </Col>
        <OverflowContainer>
          {
            infosContatos.output && infosContatos.output.length > 0 ? (
              <Table>
                <thead>
                  <tr>
                    <th>Nome Responsável</th>
                    <th>Telefone</th>
                    <th>Celular</th>
                    <th>E-mail</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {infosContatos.output.map((elContato, i) => {
                    return (
                      <tr key={i}>
                        <td>{elContato.nome_responsavel}</td>
                        <td>{elContato.telefone}</td>
                        <td>{elContato.celular}</td>
                        <td>{elContato.email}</td>
                        <td>
                          <Button
                            onClick={() => handleDeletarContato(elContato.id)}
                            size="sm"
                            color="danger"
                            outline
                            disabled={loadingDeleteContato}
                          >
                            {loadingDeleteContato ? (
                              <Spinner size="sm" />
                            ) : (
                              <FaTrash />
                            )}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <MsgErrorDataTable>
                <FaExclamationCircle />
                &nbsp;&nbsp;{infosContatos.message}
              </MsgErrorDataTable>
            )
            // <TablesGrid rows={infosContatos.output} columns={columns}/>
            // :
            // <MsgErrorDataTable><FaExclamationCircle/>&nbsp;&nbsp;{infosContatos.message}</MsgErrorDataTable>
          }
        </OverflowContainer>

        <Col className="my-3 text-right">
          <Button
            color="success"
            outline
            size="sm"
            onClick={() => openModalCadastroContato()}
          >
            <FaPlus />
            &nbsp;&nbsp;Cadastrar Contato
          </Button>
        </Col>
      </CardComponent>
    </Card>
  );
}

export default InformacoesEmpresa;
