import React, { useState } from 'react';
import { Card, Input, Button, Row, Col, InputGroup, InputGroupAddon, InputGroupText, Form } from 'reactstrap';
import { FaBuilding, FaRoad, FaInfo, FaMapMarkerAlt, FaMapMarkedAlt, FaGlobeAmericas, FaArrowLeft } from 'react-icons/fa';
import { BodyFormComponent, SectionFormHeader, PrependInputLabel } from '../styles';
import InputMask from 'react-input-mask';
import SubmitBtn from '../../components/Buttons/SubmitBtn';
import Api from '../../services/api';

function EditarConvenio(props) {

  const { setResultConvenio, resultConvenio, token, history } = props;
  const { cnpj, nome, cep, numero_endereco, logradouro, complemento_endereco, bairro, cidade, uf } = props.resultConvenio;
  const [ loading, setLoading ] = useState(false);

  const handleOnChange = event => {
    const { name, value } = event.target;
    setResultConvenio({ ...resultConvenio, [name]: value });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    const response = await Api.post('convenio/editar', resultConvenio, { headers: { Authorization: `Bearer ${token}` }} );

    if(response.data.ok){
      setLoading(false);
      window.history.back();
    }
  }

  return (
    <Card style={{ marginTop: "30px" }}>
      <Form onSubmit={handleSubmit}>
      <BodyFormComponent>
        <Row className="mt-2 mb-4">
          <Col md={12}><SectionFormHeader>Dados do Convênio</SectionFormHeader></Col>
          <Col md={6} className="mt-2">
            <InputGroup>
							<InputGroupAddon addonType="prepend">
								<InputGroupText><PrependInputLabel>CNPJ</PrependInputLabel></InputGroupText>
							</InputGroupAddon>
              <InputMask mask="99.999.999/9999-99" type="text" name="cnpj" value={cnpj} placeholder="Cnpj" onChange={e => handleOnChange(e)} />
						</InputGroup>
          </Col>
          <Col md={6} className="mt-2">
            <InputGroup>
							<InputGroupAddon addonType="prepend">
								<InputGroupText><FaBuilding /></InputGroupText>
							</InputGroupAddon>
              <Input type="text" name="nome" value={nome} placeholder="Nome da Empresa" onChange={e => {handleOnChange(e)}} />
						</InputGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={12}>
            <SectionFormHeader>Informações de Endereço</SectionFormHeader>
          </Col>
          <Col md={4} className="mt-2">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><PrependInputLabel>CEP</PrependInputLabel></InputGroupText>
              </InputGroupAddon>
              <InputMask mask="99999-999" name="cep" value={cep} onChange={e => {handleOnChange(e)}}/>
            </InputGroup>
          </Col>
          <Col md={6} className="mt-2">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText><FaRoad /></InputGroupText>
                </InputGroupAddon>
                <Input name="logradouro" value={logradouro} placeholder="Rua" onChange={e => {handleOnChange(e)}} />
              </InputGroup>
          </Col>
          <Col md={2} className="mt-2">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Nº</InputGroupText>
              </InputGroupAddon>
              <Input type="number" name="numero_endereco" value={numero_endereco} placeholder="Rua" onChange={e => {handleOnChange(e)}} />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={4} className="mt-2">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><FaInfo /></InputGroupText>
              </InputGroupAddon>
              <Input type="text" name="complemento_endereco" value={complemento_endereco} placeholder="Complemento" onChange={e => {handleOnChange(e)}} />
            </InputGroup>
          </Col>
          <Col md={3} className="mt-2">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><FaMapMarkerAlt /></InputGroupText>
              </InputGroupAddon>
              <Input type="text" value={bairro} name="bairro" placeholder="Bairro" onChange={e => {handleOnChange(e)}}/>
            </InputGroup>
          </Col>
          <Col md={3} className="mt-2">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><FaMapMarkedAlt /></InputGroupText>
              </InputGroupAddon>
              <Input type="text" value={cidade} name="cidade" placeholder="Cidade" onChange={e => {handleOnChange(e)}}/>
            </InputGroup> 
          </Col>
          <Col md={2} className="mt-2">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><FaGlobeAmericas /></InputGroupText>
              </InputGroupAddon>
              <Input type="text" value={uf} maxLength="2" name="uf" placeholder="uf" onChange={e => {handleOnChange(e)}}/>
            </InputGroup> 
          </Col>    
        </Row>
        <Row className="mt-4 text-right">
          <Col>
            <Button color="danger" onClick={() => history.goBack()}><FaArrowLeft />&nbsp;&nbsp;Voltar</Button>&nbsp;
            <SubmitBtn 
              label="Salvar Alterações"
              labelOnClick="Aguarde..."
              loading={loading}
              typeSubmit="submit"
              metodo={handleSubmit}
              color="#0A7A73"
            />
          </Col>
        </Row>
      </BodyFormComponent>
      </Form>
    </Card>
  )
}

export default EditarConvenio;
