import React, { useState } from 'react';
import { Card, Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import TablesGrid from '../../components/TablesGrid/TablesGrid';
import { FaFileExcel, FaFileDownload } from 'react-icons/fa';
import Api from '../../services/api';
import { connect } from 'react-redux';
import { formatDate } from '../../services/service';
import { alertError } from '../../components/Alerts/Alerts';
import { ToastContainer } from 'react-toastify';

function BuscaMesMovimentacoes(props) {

	const [ modal, setModal ] = useState(false);
	const toggle = () => setModal(!modal);

  const { movimentacoes, inputs } = props;
	const [ linkBaixar, setLinkBaixar ] = useState("");

  let columns = [
    { name: 'id_apolice', title: '#ID Apolice', getCellValue: row => (row.id_apolice) },
    { name: 'nome_usuario', title: 'Consultor', getCellValue: row => (row.nome_usuario) },
    { name: 'descricao_apolice', title: 'Descrição Apolice', getCellValue: row => (row.descricao_apolice) },
    { name: 'cpf_segurado', title: 'CPF', getCellValue: row => (row.cpf_segurado) },
    { name: 'nome_segurado', title: 'Nome Segurado', getCellValue: row => (row.nome_segurado) },
    { name: 'data_nascimento_segurado', title: 'Data Nascimento', getCellValue: row => (formatDate(row.data_nascimento_segurado)) },
    { name: 'tipo', title: 'Tipo ?', getCellValue: row => (row.tipo) },
    { name: 'data', title: 'Hora', getCellValue: row => (row.data) },
	]
	
	const exportarMovimentacao = async _ => {
		const { token } = props.user.user;
	    const authStr = `Bearer ${token}`;
		const result = await Api.get(`movimentacoes/exportar/${inputs.inputMesAno}/${inputs.inputTerceiro}`, { headers: { Authorization: authStr }})
		if(result.data.ok){
			setLinkBaixar(result.data.output.arquivo)
			setModal(true)
		}else{
			alertError(result.data.message)
		}
	}
	
  	return (
		<Card className="px-4 py-4">
			<ToastContainer/>
			<Row className="text-left">
				<Col>
					<Button color="secondary" outline size="sm" onClick={() => exportarMovimentacao()}><FaFileExcel /> Exportar XLSX</Button>
					{
					modal&&
					<Modal isOpen={modal} toggle={toggle}>
						<ModalHeader toggle={toggle}><FaFileDownload /> Seu arquivo está pronto!</ModalHeader>
						<ModalBody>
							<Row className="text-right">
								<Col>
								<a href={`${process.env.REACT_APP_API_ERP}/${linkBaixar}`} rel="noopener noreferrer" download={`${process.env.REACT_APP_API_ERP}/${linkBaixar}`}>
									<Button color="success" size="sm" outline>Baixar Arquivo</Button>
								</a>
								</Col>
							</Row>
						</ModalBody>
					</Modal>
					}
				</Col>
			</Row>
			<Row>
				<Col>
					<TablesGrid rows={movimentacoes.output} columns={columns} />
				</Col>
			</Row>
		</Card>
  	)
}

const mapStateToProps = store => ({
	user: store.user
})

export default connect(mapStateToProps)(BuscaMesMovimentacoes);
