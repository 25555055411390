import React from 'react';
import { Card, Col, Form, Row, Input, InputGroup, InputGroupAddon, InputGroupText  } from 'reactstrap';
import { CardComponent } from '../styles';
import { FaSearch } from 'react-icons/fa';

function BuscaFaturas(props) {
  const { inputs, handleOnChange, terceiros, getMovimentacoes } = props ;
  return (
    <Card>
      <CardComponent>
        <Col className="header-content">
          <h2><FaSearch />&nbsp;Pesquisa por Mês</h2>
        </Col>
        <Form >
          <Row>
            <Col md={2} className="mb-4">
              {
                terceiros.output
                ?
                <Input type="select" name="inputTerceiro" value={inputs.inputTerceiro} onChange={ e => handleOnChange(e) } >
                  <option value="">Selecione</option>
                  {terceiros.output.map((el, i) => (
                    <option key={i} value={el.id}>{el.nome}</option>
                  ))}
                </Input>
                :
                <span style={{color: "red"}}>{terceiros.message}</span>
              }
            </Col>
            <Col md={2} className="mb-4">
              <InputGroup>
                <Input type="month" name="inputMesAno" value={inputs.inputMesAno} onChange={e => {handleOnChange(e)}}/>
                <InputGroupAddon addonType="append">
                  <InputGroupText style={{cursor: "pointer"}} onClick={() => getMovimentacoes()}>
                    <FaSearch/>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </CardComponent>
    </Card>
  )
}

export default BuscaFaturas;
