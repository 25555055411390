import imgFamilia from '../assets/img/family.jpg';
import imgDependentes from '../assets/img/dependentes.jpg';
import imgPagamento from '../assets/img/pagamento.jpg';
// import imgProduto from '../assets/img/treinamento_produto.jpeg';
import imgProduto from '../assets/img/treinamento_produto.png';
import imgRedeCredenciadaTabela from '../assets/img/rede_credenciada_impressa.png';
import imgComparacaoPlanos from '../assets/img/comparacao_planos.png';
import imgPlanosFamiliares from '../assets/img/planos_familiares.png';
import imgEspecialidadesMedicas from '../assets/img/especialidades_medicas.png';
import imgRedeCredenciada from '../assets/img/treinamento_rede_credenciada.png';
import imgManualPlanoViver from '../assets/img/manual_plano_viver.png';
import imgScriptVendas from '../assets/img/script_vendas.png';

export const treinamentos = [
  // {
  //   id: 0,
  //   nome: 'APRESENTAÇÃO VIVER ',
  //   descricao: 'Treinamento de Produto para vendedores',
  //   linkApresentacao:
  //     'https://www.canva.com/design/DAEa_vXA-Ko/lohAnX8iGeZv8gPkLgPuHA/watch?utm_content=DAEa_vXA-Ko&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink',
  //   imagem: imgProduto,
  // },
  // {
  //   id: 1,
  //   nome: "MANUAL VIVER",
  //   descricao: "Manual do Produto para vendedores",
  //   linkApresentacao: "https://www.canva.com/design/DADgoc-Wzao/I6lTKdWXFmaVuWY-7lBH2g/view?utm_content=DADgoc-Wzao&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
  //   imagem: imgPassoPassoVenda,
  // },
  // {
  //   id: 2,
  //   nome: "SCRIPT VIVER",
  //   descricao: "Passo a passo da venda com o Lead",
  //   linkApresentacao: "https://www.canva.com/design/DAEbHLyS7oE/ChvnfPLs3XYFyKpYm5lkJQ/view?utm_content=DAEbHLyS7oE&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
  //   imagem: imgTreinamentoRevendedor,
  // },
  {
    id: 3,
    nome: 'Venda Familiar',
    descricao: 'Como realizar uma venda Familar',
    linkVideo: 'https://www.youtube.com/embed/M1X7TmZ4Bi4',
    imagem: imgFamilia,
  },
  {
    id: 4,
    nome: 'Dependentes',
    descricao: 'Adicionando dependentes na venda empresarial',
    linkVideo: 'https://www.youtube.com/embed/tV645H6G-6M',
    imagem: imgDependentes,
  },
  {
    id: 5,
    nome: 'Meio de Pagamento',
    descricao: 'Configurando o meio de pagamento na venda',
    linkVideo: 'https://www.youtube.com/embed/K0IdxRbHHtk',
    imagem: imgPagamento,
  },
  {
    id: 6,
    nome: 'Treinamento do Produto',
    descricao: 'Treinamento de Produto para vendedores',
    linkApresentacao: 'https://drive.google.com/file/d/1tiy6qJcU7OErt3FhDtlYyzWaP4AQ9has/view?usp=sharing',
    imagem: imgProduto,
  },
  {
    id: 7,
    nome: 'Comparação com Outros Planos',
    descricao: 'Criamos uma tabela com a comparação do plano viver com outros planos',
    linkApresentacao: 'https://drive.google.com/file/d/1SBKl19Gib6SJmNM2gsSOGhYDD1CvpfTc/view?usp=sharing',
    imagem: imgComparacaoPlanos,
  },
  {
    id: 8,
    nome: 'Gestão de Planos Familiares',
    descricao: 'Treinamento planos familiares dentro do ERP',
    linkApresentacao: 'https://drive.google.com/file/d/1WWx_uCFSksJ3SxOirfO6KXBZ8SaHvNz0/view?usp=sharing',
    imagem: imgPlanosFamiliares,
  },
  {
    id: 9,
    nome: 'Principais Especialidades Médicas',
    descricao: 'Com intuito de agregar conhecimento, temos aqui as principais especialidades médicas',
    linkApresentacao: 'https://drive.google.com/file/d/1cQs9FuyYZNJ7A5aGv-rI0WwLuT-GRyfU/view?usp=sharing',
    imagem: imgEspecialidadesMedicas,
  },
  {
    id: 10,
    nome: 'Rede Credenciada',
    descricao: 'Principais clinicas parceiras do plano viver.',
    linkApresentacao: 'https://drive.google.com/file/d/1pxD8VeZ5aNiDSNiVCUKxblUQp4jVFu3p/view?usp=sharing',
    imagem: imgRedeCredenciada,
  },
  {
    id: 11,
    nome: 'Tabela Rede Credenciada',
    descricao: 'Rede Credenciada completa com todos os nossos parceiros',
    linkApresentacao: 'https://drive.google.com/file/d/1hDp8aH6zJzrqLjlXoYhVcH_rkVYjxgEH/view?usp=sharing',
    imagem: imgRedeCredenciadaTabela,
  },
  {
    id: 12,
    nome: 'Manual Plano Viver',
    descricao: 'Todas as informações necessárias para vender o nosso produto',
    linkApresentacao: 'https://drive.google.com/file/d/1P_RXK3WgD3asQXR-6iOO_4v5rVKy8aPK/view?usp=sharing',
    imagem: imgManualPlanoViver,
  },
  // {
  //   id: 13,
  //   nome: 'Script de Vendas',
  //   descricao: 'Documento padrão que funciona como um passo a passo para orientar os vendedores.',
  //   linkApresentacao: 'https://drive.google.com/file/d/1paQBtDt7qkgqThXEF70dh_YZbw5UbdqC/view?usp=sharing',
  //   imagem: imgScriptVendas,
  // },
];
