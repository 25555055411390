import React from 'react';
import { Col, Row, Button } from "reactstrap";
import { FaSearch, FaExclamationCircle } from 'react-icons/fa';
import TablesGrid from '../../components/TablesGrid/TablesGrid';
import { MsgErrorDataTable } from '../styles'

export default function Convenios(props){

  let columns = [
    { name: 'id', title: '#ID', getCellValue: row => parseInt(row.id) },
    { name: 'cpf', title: 'CNPJ', getCellValue: row => (row.cnpj) },
    { name: 'nome', title: 'NOME', getCellValue: row => (row.nome) },
    { name: 'email', title: 'USUÁRIO', getCellValue: row => (row.nome_usuario) },
    { name: 'telefone', title: 'STATUS', getCellValue: row => (row.apolice_status) },
    { name: '', title: '', getCellValue: row => (actions(row))},
  ];

  const actions = row => {
    return(
      <div className="btn-group">
        <Button size="sm" color="primary" outline onClick={() => props.history.push(`/convenio/${row.id}`) }>
          <FaSearch />
        </Button>&nbsp;
      </div>
    );
  }

  return(
    <>
      <Row className="mt-4">
        <Col md={12}>
          {
          (props.resultConvenio)
          ?
          (<TablesGrid rows={props.resultConvenio.output} columns={columns} />)
          :
          (<MsgErrorDataTable><FaExclamationCircle/>&nbsp;{props.resultConvenio.message}</MsgErrorDataTable>)
          }
        </Col>
      </Row>
    </>
  );

}