import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import {
  FaPercent,
  FaRegMoneyBillAlt,
  FaVuejs,
  FaWallet,
} from 'react-icons/fa';
import { CardComponent } from '../styles';
import Loading from '../../components/Loading/Loading';

function ComissaoAssessoria(props) {
  const { assessoria } = props;
  return (
    <Card>
      <CardComponent>
        <Row className="justify-content-between m-0 p-0">
          <Col className="header-content mb-2" xs="auto">
            <h2 className="m-0 align-baseline">
              <FaPercent className="mr-2" />
              Comissões
            </h2>
          </Col>
        </Row>
        <hr />
        {assessoria.loading ? (
          <Loading />
        ) : (
          <Row className="text-left mt-2">
            <Col xs={12}>
              <p>
                <FaRegMoneyBillAlt className="ml-2" /> <b>Primeira Parcela:</b>{' '}
                {assessoria.comissao.primeira_parcela} %
              </p>
            </Col>
            <Col xs={12}>
              <p>
                <FaWallet className="ml-2" /> <b>Carteira:</b>{' '}
                {assessoria.comissao.carteira} %
              </p>
            </Col>
            <Col xs={12}>
              <p>
                <FaVuejs className="ml-2" />
                <b>Pagamento à vista:</b>{' '}
                {assessoria.comissao.pagamento_a_vista} %
              </p>
            </Col>
          </Row>
        )}
      </CardComponent>
    </Card>
  );
}

export default ComissaoAssessoria;
