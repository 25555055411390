import React, { useState } from 'react';
import { FaPen, FaTools } from 'react-icons/fa';
import { Card, Container, Row, Col, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import TitleContent from '../../components/TitleContent/TitleContent';
import { CardComponent } from '../styles';
import EdicaoSeguradosApolices from '../../views/EdicaoSeguradosApolices/EdicaoSeguradosApolices';
import { Link } from 'react-router-dom';
import { alertError } from '../../components/Alerts/Alerts';


const titleHeader = <><FaPen />&nbsp;&nbsp;Ações de Apólices</>;

export default function Page (props) {
    const { loading, apolices, renovacoes, params, setParams, checkedApolices, setCheckedApolices, handleAlterarEmMassa, handleRenovarEmMassa, baixarCartoesBoasVindas, loadingBaixarCartoes, handleGerarExcel } = props;
    const [ modalAcao, setModalAcao ] = useState(false);
    const [ modalRenovacao, setModalRenovacao ] = useState(false);
    const [ acao, setAcao ] = useState(0);

    const toggleModalAcao = _ => {
       setModalAcao(!modalAcao);
    }

    const toggleModalRenovacao = _ => {
        setModalRenovacao(!modalRenovacao);
    }

    const mudarFiltroStatus = e => {
        let nextStatus = '';

        switch(e.target.value) {
            case 'Pedido de Cartão':
                nextStatus = 'Envelope em Produção';
            break;
            case 'Envelope em Produção':
                nextStatus = 'Cartão Enviado';
            break;
            case 'Cartão Enviado':
                nextStatus = 'Em Vigência';
            break;
            default:
                nextStatus = '';
            break;
        }
        setParams({ ...params, ['apoliceStatus']: e.target.value, ['nextStatus']: nextStatus });
    }

    const handleAcao = () => {
        const form = document.forms.acoesApolices;
        if (!form) {
            return;
        }
        const elements = form.elements;
        let inputs = [];
        // Iterate over the form controls
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].nodeName === "INPUT" && elements[i].type === "checkbox" && elements[i].checked === true ) {
            // Update text input
                inputs.push(elements[i].id)
            }
        }

        if(elements.tipoAcao.value==="apolice") {
            toggleModalAcao();
            setCheckedApolices(inputs);
        }else if(elements.tipoAcao.value==="renovacao") {
            toggleModalRenovacao();
            setCheckedApolices(inputs);
        }
    }

    const selecionarTodasApolices = _ => {
        const form = document.forms.acoesApolices;
        if (!form) {
            return;
        }
        const elements = form.elements;
        let inputs = [];
        // Iterate over the form controls
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].nodeName === "INPUT" && elements[i].type === "checkbox" ) {
            // Update checkbox input
                elements[i].checked=true;
            }
        }
    }

    const handleBaixarCartoes = () => {
      const form = document.forms.acoesApolices;
      if (!form) {
          return;
      }
      const elements = form.elements;
      let inputs = [];
      // Iterate over the form controls
      for (let i = 0; i < elements.length; i++) {
          if (elements[i].nodeName === "INPUT" && elements[i].type === "checkbox" && elements[i].checked === true ) {
          // Update text input
          console.log('...elements[i].id', elements[i].id);
              inputs.push(elements[i].id)
          }
      }

      setCheckedApolices(inputs);

      if (inputs.length == 0) {
        alertError("Selecione pelo menos uma apólice.");
        return;
      }

      if(window.confirm('Deseja mesmo baixar os cartões? Esta ação pode levar alguns minutos.')) {
          baixarCartoesBoasVindas();
      }
    }

    return (
        <Container className="mb-4" fluid={true}>
            <Row className="text-left" >
                <Col md={12}>
                    <TitleContent titleHeader={titleHeader} />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={12}>
                    <Card>
                        <CardComponent className="mb-3">
                            <Row className="text-left">
                                <Col>
                                    <Button color="primary" size="sm" disabled={(acao===1)} onClick={() => setAcao(1)} className="mr-4" >Kit do Cliente</Button>
                                    <Button color="primary" size="sm" disabled={(acao===2)} className="mr-4" onClick={() => {setAcao(2)}} >Renovações</Button>
                                </Col>
                            </Row>
                            <Row className="text-left">
                                {(acao===1)&&
                                    <>
                                        <Col md={2}>
                                            <Input type="select" onChange={mudarFiltroStatus}>
                                                <option value="">Filtrar por status</option>
                                                <option value="Pedido de Cartão" >Pedido de Cartão</option>
                                                <option value="Envelope em Produção" >Envelope em Produção</option>
                                                <option value="Cartão Enviado" >Cartão Enviado</option>
                                            </Input>
                                        </Col>
                                        <Col md={3}>
                                            {(params.apoliceStatus!=="")&&
                                                <>
                                                    <Button color="info" size="sm" className="mr-2" onClick={selecionarTodasApolices} >Selecionar tudo</Button>
                                                    <Button color="warning" size="sm" onClick={handleAcao}>Executar</Button>
                                                </>
                                            }
                                        </Col>
                                        {(params.apoliceStatus==="Envelope em Produção")&&
                                            <Col md={3}>
                                                    <>
                                                        <Button color="success" size="sm" disabled={loadingBaixarCartoes} onClick={handleBaixarCartoes} >
                                                            {
                                                                (loadingBaixarCartoes)
                                                                ?
                                                                    <Spinner size="sm" color="light" />
                                                                :
                                                                'Baixar Cartões de Boas-Vindas'
                                                            }
                                                        </Button>
                                                    </>
                                            </Col>
                                        }
                                        {(params.apoliceStatus==="Cartão Enviado")&&
                                            <Col md={6} className="text-right">
                                                <Button color="success" size="sm" onClick={handleGerarExcel} >Gerar Excel</Button>
                                            </Col>
                                        }
                                    </>
                                }
                                {(acao===2)&&
                                    <>
                                        <Col md={4}>
                                            <Button color="info" size="sm" className="mr-2" onClick={selecionarTodasApolices} >Selecionar tudo</Button>
                                            <Button color="warning" size="sm" onClick={handleAcao}>Renovar</Button>
                                        </Col>
                                    </>
                                }
                            </Row>
                        </CardComponent>
                    </Card>
                </Col>
            </Row>
            {(acao===0)&&
                <Row className="mt-2">
                    <Col md={12}>
                        <Card>
                            <CardComponent className="mb-3">
                                Escolha a ação
                            </CardComponent>
                        </Card>
                    </Col>
                </Row>
            }

            {(acao===1 && params.apoliceStatus!=="")&&
                <Row className="mt-2">
                    <EdicaoSeguradosApolices tipoAcao="apolice" policies={apolices} history={props.history} />
                </Row>
            }
            {(acao===1 && params.apoliceStatus==="")&&
                <Row className="mt-2">
                    <Col md={12}>
                        <Card>
                            <CardComponent className="mb-3">
                                Escolha um status
                            </CardComponent>
                        </Card>
                    </Col>
                </Row>
            }

            {(acao===2)&&
                <Row className="mt-2">
                    <EdicaoSeguradosApolices tipoAcao="renovacao" policies={props.renovacoes} history={props.history} />
                </Row>
            }

            {(modalAcao)&&
            <Modal isOpen={modalAcao} toggle={toggleModalAcao} >
                <ModalHeader toggle={toggleModalAcao}>Mudar status da Apólices</ModalHeader>
                <ModalBody>
                    <p>Esta ação irá mudar o status de todas as apólices selecionadas para <strong style={{ fontWeight: 'bold'}}>{params.nextStatus} </strong>.</p>

                    <Container className="themed-container mt-3">
                        {
                            checkedApolices.map( (el, index) => {
                                const info = apolices.find( (item) => {
                                    return item.id_apolice==el
                                })

                                return(
                                    <Row>
                                        <Link to={`/segurado/${info.id_segurado}`} target="_blank" key={index}>
                                            Apólice {el} ( {info.status} )
                                        </Link>
                                    </Row>
                                )
                            })
                        }
                        {(checkedApolices.length===0)&&
                            <Row className="mt-3 text-center">
                                <Col>
                                Nenhuma apólice selecionada.
                                </Col>
                            </Row>
                        }
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" size="sm" onClick={toggleModalAcao}>Cancelar</Button>
                    <Button color="warning" size="sm" onClick={handleAlterarEmMassa} disabled={loading} >
                        {(loading)
                        ?
                            <Spinner size="sm" color="light" />
                        :
                            'Confirmar'
                        }

                    </Button>
                </ModalFooter>
            </Modal>
            }
            {(modalRenovacao)&&
            <Modal isOpen={modalRenovacao} toggle={toggleModalRenovacao} >
                <ModalHeader toggle={toggleModalRenovacao}>Renovar Apólices</ModalHeader>
                <ModalBody>
                    <p>Todas as apólices selecionadas serão renovadas. <br/> Esta ação pode levar alguns minutos.</p>

                    <Container className="themed-container mt-3">
                        {
                            checkedApolices.map( (el, index) => {
                                const info = renovacoes.find( (item) => {
                                    return item.id_apolice==el
                                })

                                return(
                                    <Row>
                                        <Link to={`/segurado/${info.id_segurado}`} target="_blank" key={index}>
                                            Apólice {el} ( {info.status} )
                                        </Link>
                                    </Row>
                                )
                            })
                        }
                        {(checkedApolices.length===0)&&
                            <Row className="mt-3 text-center">
                                <Col>
                                Nenhuma apólice selecionada.
                                </Col>
                            </Row>
                        }
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" size="sm" onClick={toggleModalRenovacao}>Cancelar</Button>
                    <Button color="warning" size="sm" onClick={handleRenovarEmMassa} disabled={loading} >
                        {(loading)
                        ?
                            <Spinner size="sm" color="light" />
                        :
                            'Confirmar'
                        }

                    </Button>
                </ModalFooter>
            </Modal>
            }
        </Container>
    )
}
