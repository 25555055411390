import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Page from './Page';
import Api from '../../services/api';
// import { alertError } from '../../components/Alerts/Alerts';

const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
  new Date(),
);
const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
  new Date(),
);

const currentDate = `${year}-${month}`;

function Controller(props) {
  const { user } = props;

  const isUsuarioAssessor =
    user.user.nivel_usuario == 2 || user.user.nivel_usuario == 5;

  const idAssessoria = user.user.assessoria;

  const [baseTotal, setBaseTotal] = useState(false);
  const [loadingBaseTotal, setLoadingBaseTotal] = useState(true);
  const [planosAtivosPorMes, setPlanosAtivosPorMes] = useState({
    loading: true,
    data: [],
    filteredData: [],
  });

  const [novosPlanosMes, setNovosPlanosMes] = useState({
    loading: true,
    data: [],
    filteredData: [],
  });

  const [renovacoesPorMes, setRenovacoesPorMes] = useState({
    loading: true,
    data: [],
    filteredData: [],
  });

  const [ticketMedioMes, setTicketMedioMes] = useState({
    loading: true,
    data: [],
    filteredData: [],
  });

  const [churnMes, setChurnMes] = useState({
    loading: true,
    data: [],
    filteredData: [],
  });

  const [assessorias, setAssessorias] = useState([]);
  const [idAssessoriaValue, setIdAssessoriaValue] = useState(0);
  const [urlAssessoria, setUrlAssessoria] = useState(
    isUsuarioAssessor ? `assessoria/${idAssessoria}/` : '',
  );
  const [baseTotalSuspensos, setBaseTotalSuspensos] = useState({
    loading: true,
    data: {},
  });
  const [faturasPendentes, setFaturasPendentes] = useState({
    loading: true,
    data: 0,
  });
  const [primeirasFaturasPendentes, setPrimeirasFaturasPendentes] = useState({
    loading: true,
    data: 0,
  });

  const [filtroPeriodo, setFiltroPeriodo] = useState({
    inicio: '2021-01',
    fim: currentDate,
  });

  const authStr = `Bearer ${user.user.token}`;

  useEffect(() => {
    if (!isUsuarioAssessor) {
      getAssessorias();
    }
    getBaseTotal();
    getPlanosAtivosPorMes();
    getNovosPlanosMes();
    getRenovacoesPorMes();
    getTicketMedioMes();
    getChurnMes();
    getBaseTotalSuspensos();
    getFaturasPendentes();
    getPrimeirasFaturasPendentes();
  }, [urlAssessoria]);

  useEffect(() => {
    handleFiltrarPeriodo();
  }, [filtroPeriodo]);

  useEffect(() => {
    if (planosAtivosPorMes.data.length && churnMes.data.length) {
      const filteredChurn = filtraArrayPorPeriodo(churnMes.data, 'mes');

      setChurnMes({
        ...churnMes,
        filteredData: filteredChurn.map(data => {
          const lastAtivosData = planosAtivosPorMes.data.filter(planos => {
            const dif =
              new Date(data.mes.split('/')[1], Number(data.mes.split('/')[0])) -
              new Date(
                planos.mes.split('/')[1],
                Number(planos.mes.split('/')[0]),
              );
            return (
              dif >= 1000 * 60 * 60 * 24 * 25 && // verifica se a diferença é de 1 mês
              dif <= 1000 * 60 * 60 * 24 * 35
            );
          });

          const lastAtivos = lastAtivosData.length
            ? lastAtivosData[0].total
            : 0;

          return {
            ...data,
            churnRate: Number(((data.churn * 100) / lastAtivos).toFixed(2)),
            mes: `${data.mes} - ${data.churn}`,
          };
        }),
      });
    }
  }, [churnMes.data, planosAtivosPorMes]);

  const getAssessorias = async () => {
    try {
      const response = await Api.get(`assessorias/`, {
        headers: { Authorization: authStr },
      });

      if (!response.data.ok) {
        throw new Error(response.data.message);
      }

      setAssessorias(
        response.data.output.map(assessor => ({
          name: assessor.nome,
          id: assessor.id,
          id_usuario: assessor.id_usuario,
          razao_social: assessor.razao_social,
        })),
      );
    } catch (error) {
      console.log('Erro ao buscar assessorias: ', error.message);
      // alertError('Erro ao buscar assessorias');
    }
  };

  const getBaseTotal = async () => {
    setLoadingBaseTotal(true);
    try {
      const response = await Api.get(`/dashboard/${urlAssessoria}base-total`, {
        headers: { Authorization: authStr },
      });
      if (!response.data.ok) {
        throw new Error(response.data.message);
      }
      setBaseTotal(response.data.output);
    } catch (error) {
      console.log('Erro ao buscar clientes na base: ', error.message);
      setBaseTotal(0);
      // alertError('Erro ao buscar clientes na base');
    } finally {
      setLoadingBaseTotal(false);
    }
  };

  const getPlanosAtivosPorMes = async () => {
    setPlanosAtivosPorMes({
      loading: true,
      data: [],
      filteredData: [],
    });

    try {
      const response = await Api.get(
        `/dashboard/${urlAssessoria}planos-ativos/mes`,
        {
          headers: { Authorization: authStr },
        },
      );

      if (!response.data.ok) {
        throw new Error(response.data.message);
      }
      setPlanosAtivosPorMes({
        loading: false,
        data: response.data.output,
        filteredData: filtraArrayPorPeriodo(response.data.output, 'mes'),
      });
    } catch (error) {
      console.log('Erro ao buscar planos ativos por mês: ', error.message);
      // alertError('Erro ao buscar planos ativos por mês');
      setPlanosAtivosPorMes({
        loading: false,
        data: [],
        filteredData: [],
      });
    }
  };

  const getNovosPlanosMes = async () => {
    setNovosPlanosMes({
      loading: true,
      data: [],
      filteredData: [],
    });
    try {
      const response = await Api.get(
        `/dashboard/${urlAssessoria}novos-planos/mes`,
        {
          headers: { Authorization: authStr },
        },
      );

      if (!response.data.ok) {
        throw new Error(response.data.message);
      }

      setNovosPlanosMes({
        loading: false,
        data: response.data.output,
        filteredData: filtraArrayPorPeriodo(response.data.output, 'dataVenda'),
      });
    } catch (error) {
      console.log('Erro ao buscar novos planos por mes: ', error.message);
      // alertError('Erro ao buscar novos planos por mes');
      setNovosPlanosMes({
        loading: false,
        data: [],
        filteredData: [],
      });
    }
  };

  const getRenovacoesPorMes = async () => {
    setRenovacoesPorMes({
      loading: true,
      data: [],
      filteredData: [],
    });

    try {
      const response = await Api.get(
        `/dashboard/${urlAssessoria}renovacoes/mes`,
        {
          headers: { Authorization: authStr },
        },
      );
      if (!response.data.ok) {
        throw new Error(response.data.message);
      }
      setRenovacoesPorMes({
        loading: false,
        data: response.data.output,
        filteredData: filtraArrayPorPeriodo(response.data.output, 'dataVenda'),
      });
    } catch (error) {
      console.log('Erro ao buscar renovações por mês: ', error.message);
      // alertError('Erro ao buscar renovações por mês');
      setRenovacoesPorMes({
        loading: false,
        data: [],
        filteredData: [],
      });
    }
  };

  const getTicketMedioMes = async () => {
    setTicketMedioMes({
      loading: true,
      data: [],
      filteredData: [],
    });
    try {
      const response = await Api.get(
        `/dashboard/${urlAssessoria}ticket-medio/mes`,
        {
          headers: { Authorization: authStr },
        },
      );

      if (!response.data.ok) {
        throw new Error(response.data.message);
      }

      const fixedData = response.data.output.map(ticket => ({
        ...ticket,
        ticketMedio: ticket.ticketMedio.toFixed(2),
      }));

      setTicketMedioMes({
        loading: false,
        data: fixedData,
        filteredData: filtraArrayPorPeriodo(fixedData, 'dataVenda'),
      });
    } catch (error) {
      console.log('Erro ao buscar ticket medio por mes: ', error.message);
      // alertError('Erro ao buscar ticket medio por mes');
      setTicketMedioMes({
        loading: false,
        data: [],
        filteredData: [],
      });
    }
  };

  const getChurnMes = async () => {
    setChurnMes({
      loading: true,
      data: [],
      filteredData: [],
    });
    try {
      const response = await Api.get(`/dashboard/${urlAssessoria}churn/mes`, {
        headers: { Authorization: authStr },
      });

      if (!response.data.ok) {
        throw new Error(response.data.message);
      }

      setChurnMes({
        loading: false,
        data: response.data.output,
        filteredData: filtraArrayPorPeriodo(response.data.output, 'mes'),
      });
    } catch (error) {
      console.log('Erro ao buscar Churn Mês: ', error.message);
      // alertError('Erro ao buscar Churn Mês');
      setChurnMes({
        loading: false,
        data: [],
        filteredData: [],
      });
    }
  };
  const getPrimeirasFaturasPendentes = async () => {
    setPrimeirasFaturasPendentes({ loading: true, data: 0 });

    try {
      const response = await Api.get(
        `/dashboard/${urlAssessoria}total-primeiras-faturas-pendentes`,
        {
          headers: { Authorization: authStr },
        },
      );
      if (!response.data.ok) {
        throw new Error(response.data.message);
      }
      setPrimeirasFaturasPendentes({
        loading: false,
        data: response.data.output.totalPrimeirasFaturasPendentes,
      });
    } catch (error) {
      console.log('Erro ao buscar primeiras faturas ', error.message);
      // alertError('Erro ao buscar ');
      setPrimeirasFaturasPendentes({ loading: false, data: 0 });
    }
  };

  const getFaturasPendentes = async () => {
    setFaturasPendentes({ loading: true, data: 0 });

    try {
      const response = await Api.get(
        `/dashboard/${urlAssessoria}faturas-pendentes`,
        {
          headers: { Authorization: authStr },
        },
      );
      if (!response.data.ok) {
        throw new Error(response.data.message);
      }
      setFaturasPendentes({
        loading: false,
        data: response.data.output.totalFaturasPendentes,
      });
    } catch (error) {
      console.log('Erro ao buscar faturas pendentes', error.message);
      // alertError('Erro ao buscar ');
      setFaturasPendentes({ loading: false, data: 0 });
    }
  };

  const getBaseTotalSuspensos = async () => {
    setBaseTotalSuspensos({ loading: true, data: [] });

    try {
      const response = await Api.get(
        `/dashboard/${urlAssessoria}base-total-suspensos/mes`,
        {
          headers: { Authorization: authStr },
        },
      );
      if (!response.data.ok) {
        throw new Error(response.data.message);
      }
      setBaseTotalSuspensos({ loading: false, data: response.data.output });
    } catch (error) {
      console.log('Erro ao buscar base total suspensos ', error.message);
      // alertError('Erro ao buscar clientes suspensos na base');
      setBaseTotalSuspensos({
        loading: false,
        data: { totalBaseSuspensos: 0 },
      });
    }
  };

  const handleChangeAssessoria = async e => {
    if (e.target.value == 0) {
      setUrlAssessoria('');
      setIdAssessoriaValue(0);
    } else {
      setIdAssessoriaValue(e.target.value);
      setUrlAssessoria(`assessoria/${e.target.value}/`);
    }
  };

  const handleFiltrarPeriodo = () => {
    setPlanosAtivosPorMes({
      ...planosAtivosPorMes,
      filteredData: filtraArrayPorPeriodo(planosAtivosPorMes.data, 'mes'),
    });

    setNovosPlanosMes({
      ...novosPlanosMes,
      filteredData: filtraArrayPorPeriodo(novosPlanosMes.data, 'dataVenda'),
    });

    setRenovacoesPorMes({
      ...renovacoesPorMes,
      filteredData: filtraArrayPorPeriodo(renovacoesPorMes.data, 'dataVenda'),
    });

    setTicketMedioMes({
      ...ticketMedioMes,
      filteredData: filtraArrayPorPeriodo(ticketMedioMes.data, 'dataVenda'),
    });
    setChurnMes({
      ...churnMes,
      filteredData: filtraArrayPorPeriodo(churnMes.data, 'mes'),
    });
  };

  const filtraArrayPorPeriodo = (array, dataName) =>
    array.filter(periodo => {
      const periodoAlvo = periodo[dataName].split('/');

      return isBetweenDate(...periodoAlvo);
    });

  const isBetweenDate = (mes, ano) => {
    const mesToIndex = Number.parseInt(mes, 10) - 1;
    const dateAlvo = new Date(ano, mesToIndex);
    const dateFim = new Date(
      filtroPeriodo.fim.split('-')[0],
      filtroPeriodo.fim.split('-')[1] - 1,
    );
    const dateInicio = new Date(
      filtroPeriodo.inicio.split('-')[0],
      filtroPeriodo.inicio.split('-')[1] - 1,
    );

    return dateAlvo >= dateInicio && dateAlvo <= dateFim;
  };
  return (
    <Page
      baseTotal={baseTotal}
      loadingBaseTotal={loadingBaseTotal}
      churnMes={churnMes}
      ticketMedioMes={ticketMedioMes}
      novosPlanosMes={novosPlanosMes}
      assessorias={assessorias}
      handleChangeAssessoria={handleChangeAssessoria}
      planosAtivosPorMes={planosAtivosPorMes}
      renovacoesPorMes={renovacoesPorMes}
      baseTotalSuspensos={baseTotalSuspensos}
      idAssessoriaValue={idAssessoriaValue}
      urlAssessoria={urlAssessoria}
      setFiltroPeriodo={setFiltroPeriodo}
      filtroPeriodo={filtroPeriodo}
      faturasPendentes={faturasPendentes}
      primeirasFaturasPendentes={primeirasFaturasPendentes}
      nivelUsuario={user.user.nivel_usuario}
    />
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
