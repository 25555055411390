import React from 'react';
import { connect } from 'react-redux';
import Page from './Page';

function Controller(props) {

  const { nivel_usuario } = props;

  return (
    <>
      <Page nivelUsuario={nivel_usuario}/>
    </>
  )
}

const mapStateToProps = store => ({
  user: store.user
})

export default connect(mapStateToProps)(Controller);