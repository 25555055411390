import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap'; 
import { FaArrowLeft, FaBuilding } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import InformacoesConvenio from '../../views/Convenio/InformacoesConvenio';
import ResumosFuncionarios from '../../views/Convenio/ResumosFuncionario';

function Page(props) {
  const { history, convenio, funcionarios, contatos, idEstipulante, authStr } = props;
  const titleHeader = <><FaBuilding/> {convenio.nome} </>;
  
  return (
    <Container className="mb-4" fluid={true}>
      <Row className="mt-4">
        <Col md={2}>
          <Button className="btn-back w-100" outline size="sm" color="danger" onClick={() => history.goBack()}>
            <FaArrowLeft/>&nbsp;&nbsp;Voltar
          </Button>
        </Col>
      </Row>
      <Row className="text-left mt-4 mb-4">
        <Col lg={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={6}>
          {
            convenio.output&&
            <InformacoesConvenio convenio={convenio.output} contatos={contatos} history={history} />
          }
        </Col>
        <Col lg={6}>
          {
            funcionarios.output&&
            <ResumosFuncionarios funcionarios={funcionarios.output} history={history} idEstipulante={idEstipulante} authStr={authStr} />
          }
        </Col>
      </Row>
    </Container>
  )
}

export default Page;
