import React from 'react';
import { FaMoneyBillWave, FaFileInvoiceDollar } from 'react-icons/fa';

import { Button, Card, CardHeader, Col, Row, Spinner } from 'reactstrap';
import { TitleCardComponent } from '../../components/TitleContent/styles';
import formatValueToPrice from '../../utils/format';

export default function ResumoValores(props) {
  const { resumoValores, loading, hasIdApoliceParams, desconto, tipoDesconto } =
    props;

  return (
    <Card
      style={{ maxWidth: '600px' }}
      className="d-flex text-left my-4 mx-auto"
    >
      <CardHeader>
        <TitleCardComponent>
          <FaFileInvoiceDollar className="mr-3" /> Resumo
        </TitleCardComponent>
      </CardHeader>
      {hasIdApoliceParams || (
        <>
          <Row className="pt-3">
            <Col>
              <h4 className="text-right">Valor:</h4>
            </Col>
            <Col>
              <h4 className="text-success">{resumoValores.valorTotal}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="text-right">Desconto:</h4>
            </Col>
            <Col>
              <h4 className="text-danger">
                {tipoDesconto == 1 ? (
                  <>- {resumoValores.valorDesconto}</>
                ) : (
                  <>
                    - {resumoValores.valorDesconto} ({desconto}%)
                  </>
                )}
              </h4>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col>
          <h1 className="mt-2 text-right">
            <FaMoneyBillWave /> Total:
          </h1>
        </Col>
        <Col>
          <h1 className="mt-2">{resumoValores.valorTotalComDesconto}</h1>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col className="d-flex justify-content-center">
          <Button
            color="primary"
            className="mt-2 w-50 font-weight-bold"
            type="submit"
            disabled={loading}
          >
            {loading ? <Spinner size="sm" /> : 'Efetivar Contrato'}
          </Button>
        </Col>
      </Row>
    </Card>
  );
}
