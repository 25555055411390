import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Api from '../../services/api';
import Page from './Page';
import { alertError } from '../../components/Alerts/Alerts';
import { ToastContainer } from 'react-toastify';

function Controller(props) {

  const [ movimentacoes, setMovimentacoes ] = useState();
  const [ terceiros, setTerceiros ] = useState();
  const authStr = `Bearer ${props.user.user.token}`;

  const [ inputs, setInputs ] = useState({
    inputTerceiro: '',
    inputMesAno: ''
  });

  const handleOnChange = e => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  }

  useEffect(() => {
    getTerceiros();
  }, []);

  const getTerceiros = async _ => {
    const request = await Api.get("terceiros/", { headers: { Authorization: authStr }});
    setTerceiros(request.data);
  }

  const getMovimentacoes = async _ => {
    if(inputs.inputTerceiro !== '' || inputs.inputMesAno !== '' ){
      const request = await Api.get(`movimentacoes/${inputs.inputMesAno}/${inputs.inputTerceiro}`, { headers: { Authorization: authStr }});
      setMovimentacoes(request.data);
    }else{
      alertError("Informe os campos de busca");
      return false;
    }
  }
  
  return (
    <>
      {
        terceiros&&
        (<>
          <ToastContainer />
          <Page 
            inputs={inputs}
            handleOnChange={handleOnChange} 
            movimentacoes={movimentacoes} 
            terceiros={terceiros}
            getMovimentacoes={getMovimentacoes}
          />
        </>
        )
      }
    </>
  )
}

const mapStateToProps = store => ({
  user: store.user
})

export default connect(mapStateToProps)(Controller);
