import React, { useEffect, useState} from 'react';
import { FaUsers } from 'react-icons/fa';
import { Card, Container, Row, Col, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TitleContent from '../../components/TitleContent/TitleContent';
import SeguradosApolices from '../../views/SeguradosApolices/SeguradosApolices';
import Loading from '../../components/Loading/Loading';
import { alertError, alertWarning } from '../../components/Alerts/Alerts';
import { CardComponent } from '../styles';
import { Link } from 'react-router-dom';

const titleHeader = <><FaUsers />&nbsp;&nbsp;Segurados Apólices</>;

export default function Page(props) {
    const { params, setParams, history } = props;
    const [ modalAlterarStatus , setModalAlterarStatus ] = useState(false);
    const handleChangeStatus = e => {
        history.push(`segurados-apolices?status=${e.target.value}`)
        setParams({ ...params, ['apoliceStatus']: e.target.value })
    }

    return (
        <Container fluid className="pb-3">
            <Row className="text-left" >
                <Col md={12}>
                    <TitleContent titleHeader={titleHeader} />
                </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12} >
                  <Card>
                      <CardComponent className="mb-3">
                          <Col className="header-content">
                              <h2>Filtrar</h2>
                          </Col>
                          <Row>
                              <Col md={2}>
                                  <Input type="select" onChange={handleChangeStatus} value={params.apoliceStatus}>
                                      <option value="">Filtrar por status</option>
                                      <option value="Não preenchida" >Não preenchida</option>
                                      <option value="Proposta Aprovada" >Proposta Aprovada</option>
                                      <option value="Aguardando primeiro pagamento" >Aguardando primeiro pagamento</option>
                                      <option value="Aguardando confirmação de e-mail para trial" >Aguardando confirmação de e-mail para trial</option>
                                      <option value="Pedido de Cartão" >Pedido de Cartão</option>
                                      <option value="Envelope em Produção" >Envelope em Produção</option>
                                      <option value="Cartão Enviado" >Cartão Enviado</option>
                                      <option value="Em Vigência" >Em Vigência</option>
                                      <option value="Renovada" >Renovada</option>
                                      <option value="Suspensa" >Suspensa</option>
                                      <option value="Upsell" >Upsell</option>
                                      <option value="Cancelada" >Cancelada</option>
                                      <option value="Declinada por desistência" >Declinada por desistência</option>
                                      <option value="Expirada" >Expirada</option>
                                  </Input>
                              </Col>
                          </Row>
                      </CardComponent>
                  </Card>
              </Col>
              <Col md={12} className="mt-3">
                <Card>
                  {
                    props.loadingApolices
                    ?
                    <Col className="text-center">
                        <Loading loading={props.loadingApolices} />
                    </Col>
                    :
                    <SeguradosApolices
                        policies={props.policies}
                        history={props.history}
                    />
                  }
                </Card>
              </Col>
            </Row>
        </Container>
    );
}
