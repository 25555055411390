import React from 'react';
import { Container,
  Navbar,
  NavbarBrand,
  NavbarText,
  Nav } from 'reactstrap';
import Login from '../../views/Login/Login';
import logoViver from '../../assets/img/logo.png';
import styled from 'styled-components';

const ButtonSaibaMais = styled.button`
  border-radius: 17px;
  border: none;
  background-color: #0b7a73;
  color: #ffffff;
  font: 700 0.9rem Poppins;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 150px;
  height: 40px;
`

export default function PageLogin() {
  return (
    <>
      <Navbar expand="md">
        <NavbarBrand>
          <img src={logoViver} alt="Logo Planos Viver" width={150} />
        </NavbarBrand>
        <Nav className="mr-auto" navbar>
        </Nav>
        <NavbarText>
          <a href="https://planoviver.com.br/">
            <ButtonSaibaMais>Saiba Mais</ButtonSaibaMais>
          </a>
        </NavbarText>
      </Navbar> 
      <Container >
        <Login/>
      </Container>
    </>
  );
}
