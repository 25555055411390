import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { connect } from 'react-redux';
import Page from './Page';
import { useHistory, useParams } from 'react-router-dom';


function Controller(props) {

    const authStr = `Bearer ${props.user.user.token}`;
    let history = useHistory();
    let { id } = useParams();
    const [ convenio, setConvenio ] = useState("");
    const [ contatos, setContatos ] = useState("");
    const [ funcionarios, setFuncionarios ] = useState([{}]);
    const [ detalhesPlano ,setDetalhesPlano ] = useState("");

    useEffect(
        () => {
            getConvenio();
            getContatos();
            getFuncionarios();
            getPlanoViver();
        }, []
    )

    const getConvenio = async _ => {
        const requestConvenios = await Api.get(`convenio/${id}`, { headers: { Authorization: authStr }});
        setConvenio(requestConvenios.data);
    }
    const getContatos = async _ => {
        const requestContatos = await Api.get(`convenio/contatos/${id}`, { headers: { Authorization: authStr }});
        setContatos(requestContatos.data);
    }

    const compare = ( a, b ) => {
      if ( a.last_nom < b.last_nom ){
        return -1;
      }
      if ( a.last_nom > b.last_nom ){
        return 1;
      }
      return 0;
    }

    const getFuncionarios = async _ => {
        const requestFunc = await Api.get(`segurados/${id}`, { headers: { Authorization: authStr }});

        const output = requestFunc.data.output ? requestFunc.data.output : [];
        if (output.length > 0) {
          output.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
        }

        setFuncionarios(requestFunc.data);
    }

    const getPlanoViver = async _ => {
        const requestDetalhes = await Api.get(`produto/15`, { headers: { Authorization: authStr }});
        setDetalhesPlano(requestDetalhes.data);
    }

    return (
        <Page
            convenio={convenio}
            contatos={contatos}
            funcionarios={funcionarios}
            detalhesPlano={detalhesPlano}
            history={history}
            idEstipulante={id}
            authStr={authStr}
        />
    );
}

const mapStateToProps = store => ({
    user: store.user,
  });

export default connect(mapStateToProps)(Controller);
