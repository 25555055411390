import React, { useCallback, useEffect } from 'react';
import { Container, Row, Col, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { FaFileMedicalAlt } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import ListaFuncionarios from '../../views/CadastroPlanoFuncionarios/ListaFuncionarios';
import ResumoValores from '../../views/CadastroPlanoFuncionarios/ResumoValores';
import EfetivarContratoForm from '../../views/CadastroPlanoFuncionarios/EfetivarContratoForm';

const titleHeader = (
  <>
    <FaFileMedicalAlt />
    &nbsp;&nbsp;Cadastrar Planos dos Funcionários
  </>
);
const titleHeaderFinish = (
  <>
    <FaFileMedicalAlt />
    &nbsp;&nbsp;Finalize o Cadastro Empresarial{' '}
  </>
);

const dataHojeInicio = new Date().setHours(0, 0, 0, 0);

const efetivarContratoFormSchema = yup.object().shape({
  distribuidor: yup.string().when('isUsuarioVendedor', {
    is: true,
    then: yup.string().notRequired(),
    otherwise: yup.string().required('Selecione o distribuidor'),
  }),
  corretor: yup.string().when('isUsuarioVendedor', {
    is: true,
    then: yup.string().notRequired(),
    otherwise: yup.string().required('Selecione o corretor'),
  }),
  primeiroDepGratuito: yup.string().when('isSecondReqDone', {
    is: true,
    then: yup.string().notRequired(),
    otherwise: yup.string().required('Escolha uma opção'),
  }),
  tipoDesconto: yup.string().when('isSecondReqDone', {
    is: true,
    then: yup.string().notRequired(),
    otherwise: yup.string().required('Escolha uma opção'),
  }),
  dataPrimeiraCobranca: yup
    .date('Escolha a data da primeira cobrança')
    .min(new Date(dataHojeInicio), 'A data deve ser de hoje em diante')
    .required('Escolha a data da primeira cobrança'),
  emailCobranca: yup
    .string()
    .required('Informe um e-mail')
    .email('E-mail inválido'),
  isUsuarioVendedor: yup.bool(),
  isSecondReqDone: yup.bool(),
});

export default function Page(props) {
  const {
    funcionarios,
    produtos,
    handleChangePlan,
    resumoValores,
    handleEfetivarContrato,
    distribuidorOptions,
    corretorOptions,
    emailCobrancaOptions,
    handleChangeDistribuidor,
    loading,
    hasIdApoliceParams,
    desconto,
    handleChangeDesconto,
    setIsPrimeiroDepGratuito,
    isPrimeiroDepGratuito,
    isUsuarioVendedor,
    isUsuarioDistribuidor,
    user,
    isSecondRequestDone,
    setTipoDesconto,
    tipoDesconto,
  } = props;

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(efetivarContratoFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    if (Object.keys(errors).length) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [errors]);

  const handleSubmitForm = useCallback(
    values => {
      let confirm;

      if (hasIdApoliceParams) {
        handleEfetivarContrato(values);
        return;
      }

      const funcionariosSemPlano = funcionarios.filter(
        funcionario => funcionario.plano_id == 0,
      );

      if (funcionariosSemPlano.length === funcionarios.length) {
        window.alert(
          'Erro:\nÉ preciso ter pelo menos um funcionário em um plano!',
        );
        return;
      }

      if (funcionariosSemPlano.length) {
        const stringFunc = funcionariosSemPlano.reduce(
          (acc, current) => `${acc}\n${current.nome}`,
          '',
        );
        confirm = window.confirm(
          `Aviso:\nOs seguintes funcionários estão sem plano, deseja continuar mesmo assim? ${stringFunc}`,
        );
      } else {
        handleEfetivarContrato(values);
      }

      if (confirm) {
        handleEfetivarContrato(values);
      }
    },
    [funcionarios, handleEfetivarContrato, hasIdApoliceParams],
  );

  return (
    <Container fluid>
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <Row className="text-left">
          <Col className="font-weight-bold">
            {hasIdApoliceParams ? (
              <TitleContent titleHeader={titleHeaderFinish} />
            ) : (
              <TitleContent titleHeader={titleHeader} />
            )}
          </Col>
        </Row>
        <EfetivarContratoForm
          register={register}
          errors={errors}
          distribuidorOptions={distribuidorOptions}
          corretorOptions={corretorOptions}
          emailCobrancaOptions={emailCobrancaOptions}
          handleChangeDistribuidor={handleChangeDistribuidor}
          loading={loading}
          hasIdApoliceParams={hasIdApoliceParams}
          desconto={desconto}
          handleChangeDesconto={handleChangeDesconto}
          isPrimeiroDepGratuito={isPrimeiroDepGratuito}
          setIsPrimeiroDepGratuito={setIsPrimeiroDepGratuito}
          isUsuarioVendedor={isUsuarioVendedor}
          setValue={setValue}
          isUsuarioDistribuidor={isUsuarioDistribuidor}
          user={user}
          isSecondRequestDone={isSecondRequestDone}
          setTipoDesconto={setTipoDesconto}
        />

        {hasIdApoliceParams || (
          <Row>
            <Col>
              <ListaFuncionarios
                funcionarios={funcionarios}
                produtos={produtos}
                handleChangePlan={handleChangePlan}
                loading={loading.funcionarios}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <ResumoValores
              resumoValores={resumoValores}
              desconto={desconto}
              funcionarios={funcionarios}
              errors={errors}
              loading={loading.efetivar}
              hasIdApoliceParams={hasIdApoliceParams}
              tipoDesconto={tipoDesconto}
            />
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
