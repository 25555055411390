import React, { useState, useEffect } from 'react';

import { Container, Row, Col } from 'reactstrap';
import GraficosDashboard from '../../components/GraficosDashboard/GraficosDashboard';
import formatValueToPrice from '../../utils/format';
import BaseTotal from '../../views/Dashboard/BaseTotal';
import Filtros from '../../views/Dashboard/Filtros';
import { IndexContainer } from '../../views/styles';

function Page(props) {
  const {
    baseTotal,
    loadingBaseTotal,
    churnMes,
    ticketMedioMes,
    novosPlanosMes,
    planosAtivosPorMes,
    renovacoesPorMes,
    baseTotalSuspensos,
    assessorias,
    handleChangeAssessoria,
    idAssessoriaValue,
    urlAssessoria,
    setFiltroPeriodo,
    filtroPeriodo,
    faturasPendentes,
    primeirasFaturasPendentes,
    nivelUsuario,
  } = props;

  const [mediaPlanosAtivos, setMediaPlanosAtivos] = useState(0);
  const [mediaNovosPlanos, setMediaNovosPlanos] = useState(0);
  const [mediaRenovacoes, setMediaRenovacoes] = useState(0);
  const [mediaTicketMedio, setMediaTicketMedio] = useState(0);
  const [mediaChurn, setMediaChurn] = useState(0);

  const calculaSomaArray = (array, campo) => {
    return array.length
      ? array.reduce((acc, cur) => Number(cur[campo]) + acc, 0)
      : 0;
  };

  useEffect(() => {
    const dateDiffMilliseconds =
      new Date(filtroPeriodo.fim) - new Date(filtroPeriodo.inicio);
    const dateDiffMonths =
      dateDiffMilliseconds / (1000 * 60 * 60 * 24 * 30) + 1;

    setMediaPlanosAtivos(
      calculaSomaArray(planosAtivosPorMes.filteredData, 'total') /
        dateDiffMonths,
    );
    setMediaNovosPlanos(
      calculaSomaArray(novosPlanosMes.filteredData, 'novosPlanos') /
        dateDiffMonths,
    );
    setMediaRenovacoes(
      calculaSomaArray(renovacoesPorMes.filteredData, 'renovacoes') /
        dateDiffMonths,
    );
    setMediaTicketMedio(
      calculaSomaArray(ticketMedioMes.filteredData, 'ticketMedio') /
        dateDiffMonths,
    );

    if (planosAtivosPorMes.filteredData.length) {
      setMediaChurn(
        calculaSomaArray(churnMes.filteredData, 'churn') /
          (dateDiffMonths * planosAtivosPorMes.filteredData[0].total),
      );
    }
  }, [
    planosAtivosPorMes,
    novosPlanosMes,
    renovacoesPorMes,
    ticketMedioMes,
    filtroPeriodo,
    churnMes,
  ]);

  return (
    <Container fluid className="pb-4">
      <Row className="my-4 justify-content-between">
        <Filtros
          assessorias={assessorias}
          handleChangeAssessoria={handleChangeAssessoria}
          idAssessoriaValue={idAssessoriaValue}
          setFiltroPeriodo={setFiltroPeriodo}
          filtroPeriodo={filtroPeriodo}
        />
      </Row>
      <Row
        style={{ display: 'flex', justifyContent: 'space-between' }}
        className="mt-4"
      >
        <BaseTotal
          title="Clientes na Base"
          tooltip="Vendas com status: Cartão Enviado, Pedido de Cartão, Em Vigência,
            Envelope em Produção e Suspensa"
          totalBase={baseTotal.totalBase}
          loadingBaseTotal={loadingBaseTotal}
          id="clientesNaBase"
        />
        {nivelUsuario != 5 && nivelUsuario != 2 ? (
          <Col md={8} className="mb-4">
            <GraficosDashboard
              metricaValor={
                !planosAtivosPorMes.loading
                  ? mediaPlanosAtivos.toFixed(2)
                  : 'loading'
              }
              dadosGrafico={planosAtivosPorMes.filteredData || []}
              cardTitle="Planos Ativos"
              nomeAtributoX="mes"
              nomeAtributoY="total"
              loading={planosAtivosPorMes.loading}
            />
          </Col>
        ) : null}
      </Row>
      <Row className="">
        <Col md={12}>
          <GraficosDashboard
            metricaValor={
              !novosPlanosMes.loading ? mediaNovosPlanos.toFixed(2) : 'loading'
            }
            dadosGrafico={novosPlanosMes.filteredData || []}
            cardTitle="Novos Planos"
            nomeAtributoX="dataVenda"
            nomeAtributoY="novosPlanos"
            loading={novosPlanosMes.loading}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={12}>
          <GraficosDashboard
            metricaValor={
              !renovacoesPorMes.loading ? mediaRenovacoes.toFixed(2) : 'loading'
            }
            dadosGrafico={renovacoesPorMes.filteredData || []}
            cardTitle="Renovações"
            nomeAtributoX="dataVenda"
            nomeAtributoY="renovacoes"
            loading={renovacoesPorMes.loading}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={12}>
          <GraficosDashboard
            metricaValor={
              !ticketMedioMes.loading
                ? formatValueToPrice(mediaTicketMedio)
                : 'loading'
            }
            dadosGrafico={ticketMedioMes.filteredData || []}
            cardTitle="Ticket Médio"
            nomeAtributoX="dataVenda"
            nomeAtributoY="ticketMedio"
            loading={ticketMedioMes.loading}
          />
        </Col>
      </Row>
      <Row className="my-4">
        <Col md={12}>
          <GraficosDashboard
            metricaValor={
              !churnMes.loading
                ? `${(mediaChurn * 100).toFixed(2)} %`
                : 'loading'
            }
            dadosGrafico={churnMes.filteredData || []}
            cardTitle="Cancelamentos (Churn)"
            nomeAtributoX="mes"
            nomeAtributoY="churnRate"
            loading={churnMes.loading}
          />
        </Col>
      </Row>
      <IndexContainer>
        <BaseTotal
          title="Tempo de vida médio"
          tooltip="Tempo de vida médio de um cliente em meses"
          totalBase={mediaChurn && `${(1 / mediaChurn).toFixed(0)}`}
          loadingBaseTotal={churnMes.loading}
          id="tvm"
        />
        <BaseTotal
          title="1º pagamento"
          totalBase={primeirasFaturasPendentes.data}
          loadingBaseTotal={baseTotalSuspensos.loading}
          id="primeiroPgmto"
        />
        <BaseTotal
          title="Pendentes"
          totalBase={faturasPendentes.data}
          loadingBaseTotal={faturasPendentes.loading}
          id="pendentes"
        />
        <BaseTotal
          title="Suspensos"
          totalBase={baseTotalSuspensos.data.totalBaseSuspensos}
          loadingBaseTotal={baseTotalSuspensos.loading}
          id="suspensos"
        />
      </IndexContainer>
    </Container>
  );
}

export default Page;
