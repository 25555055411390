import React from 'react';
import CadastroContatoEmpresaResponsavel from '../../components/Forms/CadastroContatoEmpresaResponsavel';

function CadastroContatoEmpresa(props) {
  return (
    <>
      <CadastroContatoEmpresaResponsavel {...props} />
    </>
  )
}

export default CadastroContatoEmpresa;
