import React from 'react';
import { Card, Container, Row, Table } from 'reactstrap';
import { TableContent } from '../styles';
import TitleCardPolicies from '../../components/TitleContent/TitleCardPolicies';
import { FaEnvelope, FaExclamationTriangle } from 'react-icons/fa';

const titleCard = <><h3><FaEnvelope/></h3>&nbsp;&nbsp;Histórico de E-mails</>

export default function Apolice(props) {
    const { historyEmail } = props;
    const setDate = fullDate => {
        let dateArr = fullDate.split(" ");
        let newDate = dateArr[0].split("-");
        return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
    }
    const setTime = fullDate => {
        let timeArr = fullDate.split(" ");
        return timeArr[1];
    }

  return (
        <Card>
            <Container>
                <TableContent>
                    <Row className="mt-4 ml-4 mb-4"><TitleCardPolicies titleCard={titleCard}/></Row>
                    {
                    historyEmail.output.length > 0
                    ?
                        <Table>
                            <thead className="text-left">
                                <tr>
                                    <th>Assunto</th>
                                    <th>Data</th>
                                    <th>Hora</th>
                                </tr>
                            </thead>
                            <tbody className="text-left">
                                {
                                    historyEmail.output.map((el, index) => (
                                    <tr key={index}>
                                        <td>{el.assunto}</td>
                                        <td>{setDate(el.data)}</td>
                                        <td>{setTime(el.data)}</td>
                                    </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    :
                    <div className="mb-4 text-left">
                        <span style={{color: "red"}}> <FaExclamationTriangle /> {historyEmail.message}</span>
                    </div>
                    }
                </TableContent>
            </Container>
        </Card>
    );
}
