import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap';
import {
  FaRoad,
  FaSearch,
  FaGlobeAmericas,
  FaMapMarkerAlt,
  FaMapMarkedAlt,
  FaInfo,
} from 'react-icons/fa';
import { getAddress } from 'address-br';
import { SectionFormHeader, LabelForm } from '../../../views/styles';

function InfoEndereco(props) {
  const { clienteEndereco, setClienteEndereco, edit, clienteEnderecoInput } =
    props;

  useEffect(() => {
    if (edit) setClienteEndereco(clienteEnderecoInput);
  }, []);

  const handleCepOnChange = event => {
    const { name, value } = event.target;
    if (name === 'cep') {
      const cepOnlyNumbers = value.replace(/\D/g, '');
      if (cepOnlyNumbers.length <= 8) {
        setClienteEndereco({ ...clienteEndereco, [name]: cepOnlyNumbers });
      }
    } else {
      setClienteEndereco({ ...clienteEndereco, [name]: value });
    }
  };

  const updateAdress = () => {
    const cepOnlyNumbers = clienteEndereco.cep.replace(/\D/g, '');
    getAddress(cepOnlyNumbers).then(vl => {
      setClienteEndereco({
        ...clienteEndereco,
        cep: clienteEndereco.cep,
        logradouro: vl.rua || '',
        cidade: vl.cidade || '',
        bairro: vl.bairro || '',
        uf: vl.estado || '',
      });
    });
  };

  return (
    <>
      <hr />
      <Row className="section-header">
        <Col>
          <SectionFormHeader>Informações de Endereço</SectionFormHeader>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={4}>
          <LabelForm>CEP *</LabelForm>
          <InputGroup>
            <Input
              type="text"
              placeholder="Informe o CEP (sem pontuação)"
              name="cep"
              value={clienteEndereco.cep}
              onChange={e => handleCepOnChange(e)}
            />
            <Button size="sm" onClick={() => updateAdress()}>
              <FaSearch />
            </Button>
          </InputGroup>
        </Col>
        <Col lg={6}>
          <LabelForm>Rua *</LabelForm>
          <InputGroup>
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <FaRoad />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Informe a rua"
              name="logradouro"
              value={clienteEndereco.logradouro}
              onChange={e => handleCepOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col lg={2}>
          <LabelForm>Número *</LabelForm>
          <Input
            type="text"
            placeholder="Nº"
            name="numero_endereco"
            value={clienteEndereco.numero_endereco}
            onChange={e => handleCepOnChange(e)}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={3}>
          <LabelForm>Complemento *</LabelForm>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaInfo />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Informe o complemento"
              name="complemento_endereco"
              value={clienteEndereco.complemento_endereco}
              onChange={e => handleCepOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col lg={3}>
          <LabelForm>Bairro *</LabelForm>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaMapMarkerAlt />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Informe o bairro"
              name="bairro"
              value={clienteEndereco.bairro}
              onChange={e => handleCepOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col lg={3}>
          <LabelForm>Cidade *</LabelForm>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaMapMarkedAlt />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Informe a cidade"
              name="cidade"
              value={clienteEndereco.cidade}
              onChange={e => handleCepOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col lg={3}>
          <LabelForm>Estado *</LabelForm>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaGlobeAmericas />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Informe o estado"
              name="uf"
              value={clienteEndereco.uf}
              onChange={e => handleCepOnChange(e)}
            />
          </InputGroup>
        </Col>
      </Row>
    </>
  );
}

export default InfoEndereco;
