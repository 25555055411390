import React from 'react';
import { Card, Container, Row, Col, Table, Button } from 'reactstrap';
import { FaExchangeAlt, FaExclamationTriangle, FaSearch, FaFileExcel } from 'react-icons/fa';
import { formatDate } from '../../services/service';
import TitleCardPolicies from '../../components/TitleContent/TitleCardPolicies';
import { TableContent } from '../styles'; 
import Api from '../../services/api';

const titleCard = <><h3><FaExchangeAlt/></h3>&nbsp;&nbsp;Movimentação</>

function Movimentacao(props) {

    const { movimentacao, idApolice } = props;

    const exportarMovimentacao = async _ => {
        const response = await Api.get(`movimentacoes/${idApolice}/exportar/`, '');
        if(response.data.ok){
            window.open(`${process.env.REACT_APP_API_ERP}/${response.data.output.path}`, "_blank");

            setTimeout((e) => {
                Api.post(`/historico-status/excluir-pdf/`, response.data.output.path)
                .then(function(response){});
            }, 5000);

        }
    }

    return (
        <Card>
            <TableContent>
            <Container>
                <Row className="mt-4 ml-4 mb-4"><TitleCardPolicies titleCard={titleCard}/></Row>
                {
                movimentacao.output.length > 0
                ?
                    <div style={{ overflowX: 'scroll' }}>
                        <Table>
                            <thead className="text-left">
                                <tr>
                                    <th>Nome</th>
                                    <th>Tipo</th>
                                    <th>Nome Segurado</th>
                                    <th>Data Nacsimento</th>
                                    <th>Cpf</th>
                                    <th>Data</th>
                                    <th>Hora</th>
                                    
                                </tr>
                            </thead>
                            <tbody className="text-left">
                                {
                                    movimentacao.output.map((el, i) => {
                                        let date = el.data.split(' ')[0];
                                        let time = el.data.split(' ')[1];
                                        return(
                                            <tr key={i}>
                                                <td>{el.nome_terceiro}</td>
                                                <td>{el.tipo}</td>
                                                <td>{el.nome_segurado}</td>
                                                <td>{formatDate(el.data_nascimento_segurado)}</td>
                                                <td>{el.cpf_segurado}</td>
                                                <td>{formatDate(date)}</td>
                                                <td>{time}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                :
                    <div className="mb-4 text-left">
                        <span style={{color: "red"}}><FaExclamationTriangle/> {movimentacao.message}</span>
                    </div>
                }
            </Container>
            </TableContent>
            <Row className="mt-2 mb-2">
                <Col className="text-right">
                    <Button color="success" outline size="sm" onClick={() => exportarMovimentacao()}><FaFileExcel /> Exportar</Button>
                </Col>
            </Row>
            
        </Card>
    );
}

export default Movimentacao;