import React from 'react';
import { FaHandHoldingUsd } from 'react-icons/fa';
import { Container, Row, Col } from 'reactstrap';
import TitleContent from '../../components/TitleContent/TitleContent';
import RelacaoUsoCupons from '../../views/Cupons/RelacaoUsoCupons';

const titleHeader = (
  <>
    <FaHandHoldingUsd />
    &nbsp;&nbsp;Relação de uso de Cupons
  </>
);

function Page(props) {
  const {
    relacaoUsoCupons
  } = props;

  return (
    <Container fluid className="pb-3">
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mt-4">
          <RelacaoUsoCupons
            relacaoUsoCupons={relacaoUsoCupons.data}
            loading={relacaoUsoCupons.loading}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Page;
