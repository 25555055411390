import Styled from 'styled-components';

export const ProductsInfoContent = Styled.div`
    margin-bottom: 30px;
    .product-card {
        box-shadow: none;
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #505050;
    }
    .card-body {
        text-align: left;
    }

    .card-body > div > svg {
        color: #DA2626;
        font-size: 35px;
    }
    .card-content-footer > svg {
        font-size: 14px !important;
        color: #000 !important;
    }
    .card-content-footer{
        font-size: 12px;
        font-weight: 600;
    }
    .card-body > div > span {
        margin-top: 50px;
        font-size: 25px;
        color: #4c4c4c;
    }
    .card-body > div > p {
        margin-top: 20px;
        line-height: 1.4rem;
        font-size: 12px;
        color: #4c4c4c
    }
    .table > thead > tr > th {
        font-size: 10px;
        font-weight: 600;
    }
    .table > tbody > tr > td {
        font-size: 10px;
    }
`;

export const EmailContent = Styled.div`
    .table > thead > tr > th {
        font-size: 14px;
        font-weight: 600;
    }
    .table > tbody > tr > td {
        font-size: 12px;
    }
`;

export const DetailPaymentContent = Styled.div`
    span {
        font-weight: 600;
    }

    p {
        font-size: 12px;
        color: #585858
    }

    .formPgmto {

      display: flex;
      justify-content: space-between;

      div {
        display: inline-block;
      }

      svg {
        transition: color 0.5s ;
        &:hover {
          cursor:pointer;
          color: #000;
        }
      }
    }
`;

export const Warning = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 250px;
  margin: 1rem auto 0;
  padding: 5px;

  font-size: 0.7rem;
  text-align: center;

  border-radius: 5px;
  background-color: #eee;

  svg {
    color: #e4cd05;
    font-size: 1rem;
    margin-left: 0.5rem;
  }

  p {
    margin: 0;
  }
`;
