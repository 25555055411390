import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import TitleContent from '../../components/TitleContent/TitleContent';
import CadastroContatoEmpresa from '../../views/CadastroContatoEmpresa/CadastroContatoEmpresa';
import CadastroClienteIndividual from '../../components/Forms/CadastroClienteIndividual';
import { FaHeadset, FaUser, FaFileDownload } from 'react-icons/fa';
import ModalEnviaCsv from '../../views/Empresa/ModalEnviaCsv';

const titleHeader = <><FaHeadset/>&nbsp;&nbsp;Cadastro do Responsável da Empresa </>;
const titleHeaderCadastroFunc = <><FaUser/>&nbsp;&nbsp;Cadastro do Funcionário</>;

export default function Page(props) {

  const [ modalImportar, setModalImportar ] = useState(false);
  const toggleImportar = () => setModalImportar(!modalImportar);
  
  return (
    <Container className="mb-4" fluid={true}>
      {
        props.direcionaCadastroFunc
        ?
        <>
          <Row className="text-left" style={{alignItems: "center"}} >
            <Col md={12}>
              <TitleContent titleHeader={titleHeaderCadastroFunc} />
            </Col>
          </Row>
          <Row className="text-left mt-4">
            <Col>
              <Button outline size="sm" color="success" onClick={() => toggleImportar()}><FaFileDownload/>&nbsp;Importar Funcionários</Button>
              {
                modalImportar&&
                (
                  <ModalEnviaCsv 
                    modalImportar={modalImportar} 
                    toggleImportar={toggleImportar} 
                    idEmpresa={props.idEstipulante} 
                    token={props.token}
                  />
                )
              }
            </Col>
          </Row>
          <CadastroClienteIndividual 
            mostraInfoPagamento={false}
            mostraInfoValores={false}
            mostraInfoEndereco={false}
            mostraInfoDependente={true}
            clienteEmpresarial={true}
            direcionaCadastroFunc={props.direcionaCadastroFunc}
            empresaInfo={props.empresaInfo}
            idEmpresa={props.idEstipulante}
            setDirecionaCadastroFunc={props.setDirecionaCadastroFunc}
          />
          
        </>
        :
        <>
          <Row className="text-left" style={{alignItems: "center"}} >
            <Col md={12}>
              <TitleContent titleHeader={titleHeader} />
            </Col>
          </Row>
          <Row>
            <Col>
              <CadastroContatoEmpresa {...props} />
            </Col>
          </Row>
        </>
      }
    </Container>
  );
}
