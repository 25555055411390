import * as React from 'react';
import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridOverlay,
} from '@material-ui/data-grid/';
import { useState, useEffect } from 'react';
import { LinearProgress } from '@material-ui/core';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

export default function MaterialTable({
  rows,
  columns,
  loading,
  pageSize = 5,
}) {
  const [rowsState, setRowsState] = useState(rows);
  const [columnsState, setColumnsState] = useState(columns);
  const [pageSizeState, setPageSizeState] = useState(pageSize);

  useEffect(() => {
    setRowsState(rows);
    setColumnsState(columns);
  }, [rows, columns]);

  return (
    <div
      style={{
        width: '100%',
        background: '#fff',
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '100%',
        }}
      >
        <DataGrid
          density="compact"
          autoHeight
          localeText={ptBR.props.MuiDataGrid.localeText}
          rows={rowsState}
          columns={columnsState}
          pagination
          pageSize={pageSizeState}
          onPageSizeChange={params => setPageSizeState(params.pageSize)}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          checkboxSelection
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: CustomLoadingOverlay,
          }}
          loading={loading}
        />
      </div>
    </div>
  );
}

// const columns = [
//   { field: 'id', headerName: 'ID', width: 90 },
//   {
//     field: 'firstName',
//     headerName: 'First name',
//     width: 150,
//     editable: true,
//   },
//   {
//     field: 'lastName',
//     headerName: 'Last name',
//     width: 150,
//     editable: true,
//   },
//   {
//     field: 'age',
//     headerName: 'Age',
//     type: 'number',
//     width: 110,
//     editable: true,
//   },
//   {
//     field: 'fullName',
//     headerName: 'Full name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     valueGetter: params =>
//       `${params.getValue(params.id, 'firstName') || ''} ${
//         params.getValue(params.id, 'lastName') || ''
//       }`,
//   },
// ];

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];
