import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React from 'react';
import { Col } from 'reactstrap';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginRight: theme.spacing(2),
  },
}));

function Filtros(props) {
  const {
    assessorias,
    handleChangeAssessoria,
    idAssessoriaValue,
    setFiltroPeriodo,
    filtroPeriodo,
  } = props;

  const classes = useStyles();

  return (
    <>
      <Col xs="auto" className="d-flex">
        <FormControl className={classes.formControl}>
          <InputLabel id="distribuidor">Distribuidor</InputLabel>
          <Select
            labelId="distribuidor"
            id="distribuidor"
            value={idAssessoriaValue}
            onChange={handleChangeAssessoria}
          >
            <MenuItem value={0}>Todos</MenuItem>
            {assessorias.map((ass, i) => {
              return (
                <MenuItem key={i} value={ass.id}>
                  {ass.razao_social ? ass.razao_social : '(Sem nome)'} / {ass.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Col>
      <Col xs="auto" className="d-flex flex-wrap">
        <FormControl className={classes.formControl}>
          <TextField
            name="inicioPeriodo"
            label="De"
            id="inicioPeriodo"
            type="month"
            value={filtroPeriodo.inicio}
            className={classes.textField}
            onChange={e => {
              setFiltroPeriodo({
                ...filtroPeriodo,
                inicio: e.target.value,
              });
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            name="fimPeriodo"
            label="Até"
            id="fimPeriodo"
            type="month"
            value={filtroPeriodo.fim}
            className={classes.textField}
            onChange={e => {
              setFiltroPeriodo({ ...filtroPeriodo, fim: e.target.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </Col>
    </>
  );
}

export default Filtros;
