import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import themes from '../../utils/theme';

export default function MaterialButton({
  color = 'dashboard',
  loading,
  children,
  ...rest
}) {
  const useStyles = makeStyles({
    button: {
      color: '#fff',
      backgroundColor: themes[color],
      '&:hover': {
        color: '#fff',
        backgroundColor: themes[color],
        filter: 'brightness(0.9)',
      },
      '&:disabled': {
        cursor: 'wait',
        color: '#9F9E9C',
        backgroundColor: '#D6D5D2',
      },
    },
  });

  const classes = useStyles();

  return (
    <Button disabled={loading} className={classes.button} {...rest}>
      {loading ? (
        <>
          <CircularProgress size="1rem" /> &nbsp;&nbsp;Carregando...
        </>
      ) : (
        <>{children}</>
      )}
    </Button>
  );
}
