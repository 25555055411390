import React from 'react';
import { Box, FormControl, makeStyles, TextField } from '@material-ui/core';
import { FaSearch } from 'react-icons/fa';
import MaterialButton from '../../components/Buttons/MaterialButton';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginRight: theme.spacing(2),
  },
}));

function Filtros(props) {
  const {
    // assessorias,
    // getComissoes,
    loadingComissoes,
    // user,
    filtros,
    setFiltros,
    idDistribuidor,
  } = props;

  const classes = useStyles();

  const handleChange = event => {
    setFiltros({ ...filtros, [event.target.name]: event.target.value });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="start"
      mt="2rem"
      mb="1.5rem"
      flexWrap="wrap"
    >
      <FormControl className={classes.formControl}>
        <TextField
          name="periodo"
          label="Período"
          id="periodo"
          type="month"
          value={filtros.periodo}
          className={classes.textField}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      <Box mt="12px">
        <MaterialButton
          variant="contained"
          color="dashboard"
          href={`comissao-assessoria/${idDistribuidor}?periodo=${filtros.periodo}`}
          loading={loadingComissoes}
        >
          <FaSearch />
          &nbsp;&nbsp;Buscar
        </MaterialButton>
      </Box>
    </Box>
  );
}

export default Filtros;
