import React, { useState } from 'react';
import { Card, Row, Col, Button, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { CardComponent, ButtonCardFooter } from '../styles';
import { FaUsers, FaSearch, FaDownload, FaFileDownload } from 'react-icons/fa';
import Api from '../../services/api';
import { alertError } from '../../components/Alerts/Alerts';

function ResumosFuncionario(props) {

  const { funcionarios, history, idEstipulante, authStr } = props;
  const [ modal, setModal ] = useState(false);
  const toggle = () => setModal(!modal);
  const [ linkBaixar, setLinkBaixar ] = useState("");

  const exportarAtivos = async _ => {

    const response = await Api.get(`/convenio/${idEstipulante}/relatorio/ativos`, { headers: { Authorization: authStr }});
    if(response.data.ok){
      setLinkBaixar(response.data.output.arquivo)
      setModal(true)
    }else{
      alertError(response.data.message);
    }
  }

  return (
    <Card>
      <CardComponent>
        <Row>
          <Col className="header-content">
            <h2><FaUsers/> Funcionários </h2>
          </Col>
        </Row>


        {
          funcionarios&&
          funcionarios.length > 0
          ?
          <>
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>id</th>
                    <th>Nome</th>
                    <th>Qtd. Dep.</th>
                    <th>Qtd. Titular 2</th>
                    <th>Funções</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    funcionarios.map((elFunc, i) => (
                      <tr key={i}>
                        <td>{elFunc.id}</td>
                        <td>{elFunc.nome}</td>
                        <td>{elFunc.qtd_dependentes_tipo_1}</td>
                        <td>{elFunc.qtd_dependentes_tipo_2}</td>
                        <td>
                          <Button color="primary" outline size="sm" onClick={() => history.push(`../segurado/${elFunc.id}`) }>
                            <FaSearch/>
                          </Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="mt-4">
            {/* <ButtonCardFooter>
              Exportar Ativos
            </ButtonCardFooter> */}
            <Button className="w-100" color="success" onClick={() => exportarAtivos()}><FaDownload/> Exportar Ativos</Button>
            { modal&&
              <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}><FaFileDownload /> Seu arquivo está pronto!</ModalHeader>
                <ModalBody>
                  <Row className="text-right">
                    <Col>
                      <a href={`${process.env.REACT_APP_API_ERP}/${linkBaixar}`} rel="noopener noreferrer" download={`${process.env.REACT_APP_API_ERP}/${linkBaixar}`}>
                        <Button color="success" size="sm" outline>Baixar Arquivo</Button>
                      </a>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            }
          </Row>
          </>
          :
          <Row className="mb-2">
            <Col>
              <span style={{color: "red"}}>{funcionarios.message}</span>
            </Col>
          </Row>
        }
      </CardComponent>
    </Card>
  )
}

export default ResumosFuncionario;
