import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaWrench } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import EditarConvenio from '../../views/EditarConvenio/EditarConvenio';

export default function Page(props) {
  
  const { nome } = props.resultConvenio;
  const titleHeader = <><FaWrench/>&nbsp;&nbsp;Editar: {nome}</>;

  return (
    <Container fluid={true}>
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row className="mb-4">
        <EditarConvenio {...props} />
      </Row>
    </Container>
  );
}