import api from './api';

export const fetchFromApi = async (props, route) => {
    const { token } = props.user.user;
    const authStr = `Bearer ${token}`;
    const res = await api.get(route, { headers: { Authorization: authStr }} );
    if(res.data.ok){
        if(Object.keys(res.data.output).length > 0){
            let fetchArr = [];
            for(const i in res.data.output){
                fetchArr = [ ...fetchArr, res.data.output[i] ];
            }
            return fetchArr;
        }
    } 
}

export const fetchById = async (props, route) => {
    const { token } = props.user.user;
    const authStr = `Bearer ${token}`;
    const res = await api.get(route, { headers: { Authorization: authStr }} );
    if(res.data.ok){
        if(res.data.output){
            return res.data.output;
        }else{
            return res.data.result;
        }
    }else{
        return res.data.message
    }
}

export const sendFormData = async (route, formData, props) => {
    const { token } = props.user.user;
    const authStr = `Bearer ${token}`;
    return await api.post(route, formData, { headers: { Authorization: authStr }});
}
