import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import {
  FaAddressBook,
  FaMailBulk,
  FaMobileAlt,
  FaPhoneSquareAlt,
  FaUser,
} from 'react-icons/fa';
import { CardComponent } from '../styles';
import Loading from '../../components/Loading/Loading';

function DadosBancariosAssessoria(props) {
  const { assessoria } = props;
console.log('DadosBancariosAssessoria', assessoria);
  return (
    <Card>
      <CardComponent>
        <Row className="justify-content-between m-0 p-0">
          <Col className="header-content mb-2" xs="auto">
            <h2 className="m-0 align-baseline">
              <FaAddressBook className="mr-2" />
              Dados bancários
            </h2>
          </Col>
        </Row>
        <hr />
        {assessoria.loading ? (
          <Loading />
        ) : (
          <Row className="text-left mt-2">
            <Col xs={12}>
              <p>
                <b>Banco: </b>
                {assessoria.dados_bancarios.banco}
                {assessoria.dados_bancarios.nome_banco ? ' - ' + assessoria.dados_bancarios.nome_banco : ''}
              </p>
            </Col>
            <Col xs={12}>
              <p>
                <b>Agencia: </b>
                {assessoria.dados_bancarios.agencia}
              </p>
            </Col>
              <Col xs={12}>
                <p>
                  <b>Conta: </b>
                  {assessoria.dados_bancarios.conta}
                </p>
              </Col>
            <Col xs={12}>
              <p>
                <b>Pix: </b>
                {assessoria.dados_bancarios.pix}
              </p>
            </Col>
          </Row>
        )}
      </CardComponent>
    </Card>
  );
}

export default DadosBancariosAssessoria;
