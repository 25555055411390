import React, { useState, useEffect } from 'react';
import Page from './Page';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Api from '../../services/api';

function Controller(props) {

  let history = useHistory();
  let { idEstipulante } = useParams();
  const { token } = props.user.user;
  const authStr = `Bearer ${props.user.user.token}`;

  const [ infosFuncionarios, setInfosFuncionarios ] = useState({});
  const [ resultBuscaProd, setResultBuscaProd ] = useState({});
  const [ assessoria, setAssessoria ] = useState([{ id: '', name: '' }]);


  useEffect(
    () => {
      getFuncionarios();
      buscarProdutos();
      buscarAssessoria();
    }, []
  );

  const getFuncionarios = async _ => {
    const request = await Api.get(`/segurados/${idEstipulante}`, { headers: { Authorization: authStr }} );
    // if(Object.keys(request.data.output).length > 0){
      // ordenaPorId(request.data.output);
    // }
    setInfosFuncionarios(request.data);
  }

  const buscarProdutos = async _ => {
    const request = await Api.get(`/produtos/`, { headers: { Authorization: authStr }});
    let produtoPlanoPrata = [];
    if(request.data){
      if(Object.keys(request.data.output).length > 0){
        request.data.output.map(produto => {
          if((produto.nome == "Plano Prata") || (produto.plano_empresarial == 1 && produto.produto_adicional == 1) ){
            produtoPlanoPrata = [ ...produtoPlanoPrata, produto ];
          }
        })
      }
    }
    // setResultBuscaProd(request.data);
    setResultBuscaProd(produtoPlanoPrata);
  }

  const buscarAssessoria = async _ => {
    const request = await Api.get(`/assessorias/`, { headers: { Authorization: authStr }});
    let assessoriaArr = [];
    for(const i in request.data.output){
      let tmpObj = {
        name: request.data.output[i].nome,
        id: request.data.output[i].id,
        id_usuario: request.data.output[i].id_usuario,
      };
      assessoriaArr = [ ...assessoriaArr, tmpObj ];
    }
    setAssessoria(assessoriaArr);
  }

  return (
    <>
      <Page 
        history={history} 
        infosFuncionarios={infosFuncionarios} 
        resultBuscaProd={resultBuscaProd} 
        idEstipulante={idEstipulante}
        assessoria={assessoria}
        token={token}
      />
    </>
  )
};

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
