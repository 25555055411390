import React from 'react';
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import {
  FaPlus,
  FaChartBar,
  FaExclamationTriangle,
  FaLink,
} from 'react-icons/fa';
import TodasMinhasVendas from '../../views/MinhasVendas/TodasMinhasVendas';
import TitleContent from '../../components/TitleContent/TitleContent';
import Loading from '../../components/Loading/Loading';

export default function Page(props) {
  const {
    vendas,
    loading,
    msgNotFound,
    corretorRepresentanteMsg,
    idCorretorRepresentante,
    nivel_usuario,
  } = props;
  const history = useHistory();
  const titleHeader = (
    <>
      <FaChartBar />
      &nbsp;&nbsp;Minhas Vendas
    </>
  );

  return (
    <Container fluid>
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
        <Col className="text-right mt-4" md={12}>
          {idCorretorRepresentante !== 0 && (
            <Button
              color="primary"
              size="sm"
              onClick={() =>
                window.open(
                  `https://orcamento.viverbeneficios.com.br/corretor/${idCorretorRepresentante}`,
                  '_blank',
                )
              }
            >
              <FaLink />
              &nbsp;Meu link auto compra
            </Button>
          )}
          &nbsp;
          {nivel_usuario != 5 && (
            <Button
              color="success"
              size="sm"
              onClick={() => history.push('/cadastrar-cliente-individual')}
            >
              <FaPlus />
              &nbsp;Cadastrar Novo Cliente
            </Button>
          )}
        </Col>
      </Row>

      <Row className="mb-4">
        {loading ? (
          <Col className="text-center">
            <Loading loading={loading} />
          </Col>
        ) : vendas ? (
          <TodasMinhasVendas sales={vendas} nivel_usuario={nivel_usuario} />
        ) : (
          msgNotFound && (
            <Card style={{ width: '100%' }} className="mt-3">
              <CardBody>
                <Row className="text-left">
                  <span style={{ color: 'red' }}>
                    <FaExclamationTriangle />
                    {msgNotFound}
                  </span>
                </Row>
              </CardBody>
            </Card>
          )
        )}
      </Row>
      <Row className="mb-4">
        {corretorRepresentanteMsg && (
          <Card style={{ width: '100%' }}>
            <CardBody>
              <Row className="text-left">
                <span style={{ color: 'red' }}>
                  <FaExclamationTriangle />
                  {corretorRepresentanteMsg}
                </span>
              </Row>
            </CardBody>
          </Card>
        )}
      </Row>
    </Container>
  );
}
