import React from 'react';
import { Card, Container, Row, Table } from 'reactstrap';
import TitleCardPolicies from '../../components/TitleContent/TitleCardPolicies';
import { FaFileArchive, FaSearch, FaExclamationTriangle } from 'react-icons/fa';
import { TableContent } from '../styles';
const titleCard = <><h3><FaFileArchive/></h3>&nbsp;&nbsp;Documentos</>

export default function ListArquivos(props) {

    const { archivesPolicies } = props;
    const setDate = fullDate => {
        let dateArr = fullDate.split(" ");
        let newDate = dateArr[0].split("-");
        return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
    }
    const setTime = fullDate => {
        let timeArr = fullDate.split(" ");
        return timeArr[1];
    }

  return (
        <Card>
            <TableContent>
            <Container>
                    <Row className="mt-4 ml-4 mb-4"><TitleCardPolicies titleCard={titleCard}/></Row>
                    {
                    archivesPolicies.output.length > 0
                    ?
                    
                    <Table>
                        <thead className="text-left">
                            <tr>
                                <th>Data Inclusão</th>
                                <th>Hora Inclusão</th>
                                <th>Documento</th>
                                <th>Link Arq.</th>
                            </tr>
                        </thead>
                        <tbody className="text-left">
                            {
                                archivesPolicies.output.map((el, index) => (
                                    <tr key={index}>
                                        <td>{setDate(el.data_inclusao)}</td>
                                        <td>{setTime(el.data_inclusao)}</td>
                                        <td>{el.descricao}</td>
                                        <td className="text-center">
                                            <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_API_ERP}/files/docs/${el.link_arquivo}`}>
                                                <FaSearch/>
                                            </a>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                    :
                        <div className="mb-4 text-left">
                            <span style={{color: "red"}}><FaExclamationTriangle/> {archivesPolicies.message}</span>
                        </div>
                    }
            </Container>
            </TableContent>
        </Card>
    );
}
