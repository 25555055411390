import React, { useState } from 'react';
import { Card, CardHeader, CardBody, CardFooter, Col, Row, Table, Button, } from 'reactstrap';
import { CardComponent } from '../styles';
import { FaUsers, FaSearch, FaExclamationCircle, FaClipboardCheck, FaPlus, FaFileAlt, FaHandHoldingUsd, FaFileDownload } from 'react-icons/fa';
import { PriceComponent } from '../../components/FinalPrice/styles';

function FuncionariosProdutosEmpresa(props) {
  const { infosFuncionarios, idEstipulante, history, resultBuscaProd } = props;
  
  const [ valorTotal, setValorTotal ] = useState(0);

  console.log("resultBusca Produ - ",resultBuscaProd)
  console.log("infos Funcionarios - ",infosFuncionarios)

  // const renderLinhasFuncionarios = _ => {
  //   if(Object.keys(infosFuncionarios.output).length > 0){
  //     infosFuncionarios.output.map((elFuncionarios, i) => {
  //       console.log("elFuncionarios",elFuncionarios.nome)
  //       return(
  //         <tr key={i}>
  //           <td>{elFuncionarios.nome}</td>
  //           <td></td>
  //           <td></td>
  //           <td>{elFuncionarios.qtd_dependentes_tipo_1}</td>
  //           <td>{elFuncionarios.qtd_dependentes_tipo_2}</td>
  //         </tr>
  //       )
  //     });
  //   }else{
  //     return "Sem funcionários inseridos";
  //   }
  // }

  const renderFuncProdutos = _ => {

    let valorPlanoPrata = 28.70;


    return(
      <Table>
        <thead>
          <tr>
            <th>Nome</th>
            {
              Object.values(resultBuscaProd).map((elProd) => <th>{elProd.nome}</th>)
            }
            <th>Dep. 1</th>
            <th>Titular 2</th>
            <th>Valor Segurado</th>
          </tr>
        </thead>
        <tbody>
          {/* {
            renderLinhasFuncionarios()
          }   */}
          {
          (Object.keys(infosFuncionarios.output).length > 0)
          ?
            infosFuncionarios.output.map((elFuncionarios, i) => {
              
              // let descontoTitular = 0;
              // valor = (valorPlanoPrata) * descontoTitular + (precoDependenteTipo1 * el.qtd_dependentes_tipo_1) + (precoDependenteTipo2 * el.qtd_dependentes_tipo_2);

              return(
                <tr key={i}>
                  <td>{elFuncionarios.nome}</td>
                  <td>{valorPlanoPrata}</td>
                  <td></td>
                  <td>{elFuncionarios.qtd_dependentes_tipo_1}</td>
                  <td>{elFuncionarios.qtd_dependentes_tipo_2}</td>
                  <td></td>
                </tr>
              )
            })
          :
            "Sem funcionários inseridos"
          }
        </tbody>
      </Table>
    );
    
  //   Object.values(props.policieProduct.output.produtos).map((produto, i) => {

  //   let preco = produto.preco;
  //   let precoDependenteTipo1 = produto.preco_dependente_tipo_1;
  //   let precoDependenteTipo2 = produto.preco_dependente_tipo_2;
  //   let valorTotal = 0;
    
  //   if(produto.plano_adicional == 1){
  //       return 
  //   }
  //   let valorTotalApolice = 0;
  //   return (
  //       <Card className="product-card" key={i} >
  //           <CardHeader style={{cursor: "pointer"}} id={`toggler${i}`}>
  //               <Col className="text-left">
  //                   <span><FaClipboardCheck/></span>
  //                   <span>&nbsp;Produto: {produto.nome}</span>
  //               </Col>
  //               <Col className="text-right">
  //                   <span>Ver mais&nbsp;&nbsp;<FaPlus/></span>
  //               </Col>
  //           </CardHeader>

  //           {/* <UncontrolledCollapse toggler={`#toggler${i}`}> */}

  //               <CardBody>
  //                   <Col className="title-card-content"><FaFileAlt/>&nbsp;&nbsp;<span>Descrição</span></Col>
  //                   <Col><p>{produto.descricao}</p></Col>
  //                   <Col className="mt-3 card-content-footer"><FaHandHoldingUsd />&nbsp;&nbsp;Preço Titular: R$&nbsp;{produto.preco}</Col>
  //                   <Col className="mt-3">
  //                       {
  //                           (produto.nome === 'Plano Viver' || produto.nome === 'Plano Viver - 50%')&&
  //                           <p>
  //                               <FaHandHoldingUsd />&nbsp;&nbsp;Preço Dependente:&nbsp; R$&nbsp;{produto.preco_dependente_tipo_1} |
  //                               <FaHandHoldingUsd />&nbsp;&nbsp;Preço Titular 2:&nbsp; R$&nbsp;{produto.preco_dependente_tipo_2}
  //                           </p>
  //                       }
  //                   </Col>
                    
  //                   <Col>

  //                       <Table>
  //                           <thead>
  //                               <tr>
  //                                   <th>Nome</th>
  //                                   <th>Qtd. Dep. 1</th>
  //                                   <th>Qtd. Titular 2</th>
  //                                   <th>Qtd. Dep. 1 Grátis</th>
  //                                   <th>Preço Total</th>
  //                               </tr>
  //                           </thead>
  //                           <tbody>
  //                               {
  //                               produto.segurados.map((el,indexMap) => {
                                    
  //                                   let descontoDep = 0;
  //                                   let descontoTitular = 0;
  //                                   let valor = 0;
                                    
  //                                   // if(policieConfigPayment.ok && (policieConfigPayment.output.DEP_1_GRATIS === "1") && (el.qtd_dependentes_tipo_1 > 0)){
  //                                   //     descontoDep = produto.preco_dependente_tipo_1;
                                    
  //                                   //     descontoTitular = (100 - policieConfigPayment.output.desconto_titular) / 100
  //                                   // }else{
  //                                   //     descontoDep = 0;
  //                                   //     descontoTitular = 1;
  //                                   // }
                                    
  //                                   valor = (preco) * descontoTitular + (precoDependenteTipo1 * el.qtd_dependentes_tipo_1) + (precoDependenteTipo2 * el.qtd_dependentes_tipo_2);
  //                                   valor = (valor - descontoDep).toFixed(2);
                                    
  //                                   valorTotal = valorTotal + parseFloat(valor);

  //                                   // valorTotalApolice += valorTotal;
                                    
  //                                   return (
  //                                       <tr key={indexMap}>
  //                                           <td>{el.nome}</td>
  //                                           <td>{el.qtd_dependentes_tipo_1} Dep(s).</td>
  //                                           <td>{el.qtd_dependentes_tipo_2} Dep(s).</td>
  //                                           <td>{(descontoDep > 0) ? descontoDep = 1 : descontoDep  = 0} Dep(s).</td>
  //                                           <td>R$ {valor}</td>
  //                                       </tr>
  //                                   )
  //                               })
  //                               }
  //                               {valorTotalApolice += valorTotal}
  //                           </tbody>
  //                       </Table>

  //                       <Row className="w-100">
  //                           <Col sm={12}>
  //                               <Row className="text-left mb-4">
  //                                   {/* {
  //                                       idEstipulante !== "1" //Outras empresas
  //                                       ?
  //                                       <Button color="secondary" size="sm" onClick={() => toggleModal(produto.id)} ><FaWrench /> Inserir/Excluir funcionarios da apolice</Button>
  //                                       : // Viver
  //                                       <Button outline color="success" size="sm" onClick={() => toggleModalAltera(produto.nome)}><FaPlus />&nbsp;&nbsp;Alterar Produto</Button>
  //                                   } */}
  //                               </Row>
  //                               {
  //                               // nome_estipulante === "ASPMJ"&&
  //                               // <Row className="text-left mt-4 mb-4">
  //                               //     <Col>
  //                               //         <Button outline size="sm" color="success" onClick={() => baixarProtocoloAutorizacao(valorTotal)}>
  //                               //             {
  //                               //                 loading
  //                               //                 ?
  //                               //                 <><Spinner size="sm" color="success"/> Aguarde</> 
  //                               //                 :
  //                               //                 <><FaDownload/> Protocolo de Autorização</>

  //                               //             }
  //                               //         </Button>
  //                               //     </Col>
  //                               // </Row>
  //                               }
  //                           </Col>
  //                       </Row>
  //                   </Col>
  //               </CardBody>
  //           {/* </UncontrolledCollapse> */}
  //       </Card>
  //   );
  //   })
  }


  return (
    <Card>
      <CardComponent>
        <Col className="header-content text-left">
          <h2><FaUsers/>&nbsp;&nbsp;Funcionários e Produtos</h2>
        </Col>
        <hr/>
        {
          renderFuncProdutos()
        }
      </CardComponent>

      <Col className="mr-2">
        <PriceComponent>
          <h5>Valor <span>Apólice</span></h5>
          <h1>R$ {valorTotal}</h1>
        </PriceComponent>
      </Col>

      <CardFooter>
        <Row className="text-right mt-4">
          <Col>
            <Button color="success" size="sm" onClick={() => {}}><FaFileDownload /> Importar Funcionários</Button>&nbsp;
            <Button color="success" size="sm" onClick={() => {}}><FaPlus /> Cadastrar Funcionário</Button>
          </Col>
        </Row>
      </CardFooter>
    </Card>   
  )
}

export default FuncionariosProdutosEmpresa;