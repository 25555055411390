import React from 'react';
import { Card } from 'reactstrap';
import CadastroClienteIndividual from '../../components/Forms/CadastroClienteIndividual';

export default function EditarFuncionario(props) {
  return (
    <Card style={{ marginTop: "30px" }}>
      <CadastroClienteIndividual 
        {...props}
        mostraInfoDependente={true}
        mostraInfoEndereco={true}
      />
    </Card>
  );
}