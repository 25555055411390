import React, { useState } from "react";
import { Button, Popover, PopoverHeader, PopoverBody, Row, Col, Spinner } from "reactstrap";

function PopoverItem(props) {
  const {handleInactivatePlanoFuncionario,loadingInactivate,item,id } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  // const [ loadingInactivate, setLoadingInactivate] = useState(false);

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const handleConfirm = async (item) => {
    await handleInactivatePlanoFuncionario(item);
    togglePopover();
  }

  return (
    <Row  className="my-2" key={id}>
      <Col className="d-flex align-items-center">
        <p className="m-0" >Apólice {item.id_apolice} - {item.nome_produto}</p>
      </Col>
      <Col className="d-flex justify-content-center">
        <Button size="sm" id={`Popover-${id}`} type="button" color="danger" onClick={togglePopover}>
          Inativar
        </Button>
        <Popover
          trigger="legacy"
          isOpen={popoverOpen}
          target={`Popover-${id}`}
          toggle={togglePopover}
        >
          <PopoverHeader>Inativar plano?</PopoverHeader>
          <PopoverBody>
            <Row>
              {loadingInactivate 
                ?
                <Col>
                  <Spinner color="primary" size="sm"/>
                </Col>
                :
                <>
                  <Col>
                    <Button size="sm" type="button" color="danger" onClick={togglePopover}>
                      Cancelar
                    </Button>
                  </Col>

                  <Col>
                    <Button 
                    color="warning"
                    size="sm"
                    onClick={() => handleConfirm(item)}
                    >Confimar</Button>
                  </Col>
                </>
              }
            </Row>
          </PopoverBody>
        </Popover>
      </Col>
    </Row>
  )
}

const PopoverMulti = props => {
  const {funcionarioApolices,handleInactivatePlanoFuncionario,loadingInactivate } = props;

  return (
    <>
      {funcionarioApolices.map((item, i) => {
        return <PopoverItem
          key={i}
          id={i} 
          item={item} 
          handleInactivatePlanoFuncionario={handleInactivatePlanoFuncionario}
          loadingInactivate={loadingInactivate}
        />;
      })}
    </>
  );
};

export default PopoverMulti;