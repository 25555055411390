import React from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FaBuilding, FaExclamationTriangle, FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import TitleContent from '../../components/TitleContent/TitleContent';
import BuscaEmpresas from '../../views/Empresas/BuscaEmpresas';

const titleHeader = (
  <>
    <FaBuilding />
    &nbsp;&nbsp;Empresas
  </>
);

export default function Page(props) {
  const history = useHistory();
  const { nivel_usuario } = props;

  console.log('nivel_usuario', nivel_usuario);
  return (
    <Container fluid>
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      {Object.keys(props.empresas).length > 0 ? (
        <BuscaEmpresas {...props} />
      ) : (
        <Card className="my-4">
          <Row className="px-3 mt-3 justify-content-between align-items-center">
            {nivel_usuario != 2 && nivel_usuario != 5 && (
              <Button
                color="success"
                size="sm"
                outline
                onClick={() => history.push('/cadastrar-empresa')}
              >
                <FaPlus />
                &nbsp;&nbsp;Cadastrar Empresa {}
              </Button>
            )}
          </Row>
          <Row className="mt-4">
            <span style={{ color: 'red' }} className="m-3">
              <FaExclamationTriangle />
              {props.msgNotFound}
            </span>
          </Row>
        </Card>
      )}
    </Container>
  );
}
