import React from 'react';
import { Modal, ModalBody, ModalHeader, Form, Button, Row, Col, Input, InputGroup, InputGroupText, InputGroupAddon, Spinner } from 'reactstrap';
import { FaHeadset, FaUser, FaPhone, FaMobileAlt, FaEnvelope, FaPlus } from 'react-icons/fa';
import { BodyFormComponent, SectionFormHeader } from '../../views/styles';
import InputMask from 'react-input-mask';
import SubmitBtn from '../../components/Buttons/SubmitBtn';

function CadastroContatoEmpresaResponsavel(props) {
  
  const { cadastrarContatoForm, contato, handleContatoOnChange, loading, setDirecionaCadastroFunc } = props;

  return (
    <>
      <BodyFormComponent>
        <Row className="mb-4">
          <Col md={12}><SectionFormHeader>Informações do Contato</SectionFormHeader></Col>
        </Row>
        <Col md={12} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText><FaUser /></InputGroupText>
            </InputGroupAddon>
            <Input required name="nome_responsavel" value={contato.nome_responsavel} placeholder="Nome do Responsável" onChange={e => handleContatoOnChange(e)}/>
          </InputGroup>
        </Col>
        <Col md={6} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText><FaPhone /></InputGroupText>
            </InputGroupAddon>
            <InputMask mask="(99) 99999-9999" required className="form-control" name="telefone" value={contato.telefone} placeholder="Telefone" onChange={e => handleContatoOnChange(e)}/>
          </InputGroup>
        </Col>
        <Col md={6} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText><FaMobileAlt /></InputGroupText>
            </InputGroupAddon>
            <InputMask mask="(99) 99999-9999" name="celular" value={contato.celular} className="form-control" placeholder="Celular" onChange={e => handleContatoOnChange(e)}/>
          </InputGroup>
        </Col>
        <Col md={12} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText><FaEnvelope /></InputGroupText>
            </InputGroupAddon>
            <Input type="email" required name="email" value={contato.email} placeholder="E-mail" onChange={e => handleContatoOnChange(e)}/>
          </InputGroup>
        </Col>
        <Row>
          <Col md={12} className="text-right mt-4">
            {
              loading
              ?
              <Spinner color="primary" size="sm"/>
              :
              <Button color="success" onClick={() => cadastrarContatoForm()}>
                <FaPlus/> Cadastrar Contato
              </Button>
            }
          </Col>
        </Row>
      </BodyFormComponent>
    </>
  )
}

export default CadastroContatoEmpresaResponsavel;