import React, { useState, useEffect } from 'react';
import {
  Card,
  Container,
  Row,
  Input,
  Table,
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  ListGroup,
  ListGroupItem,
  Badge,
} from 'reactstrap';
import {
  FaFileInvoiceDollar,
  FaDownload,
  FaLink,
  FaCalendar,
  FaExclamationTriangle,
  FaMoneyBillAlt,
  FaSync,
} from 'react-icons/fa';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { TableContent, OverflowContainer } from '../styles';
import TitleCardPolicies from '../../components/TitleContent/TitleCardPolicies';
import Api from '../../services/api';
import { formatDate } from '../../services/service';
import ResgatarFaturasIugu from './ResgatarFaturasIugu';
import {
  alertError,
  alertSuccess,
  alertWarning,
} from '../../components/Alerts/Alerts';
import TooltipStatusFatura from './TooltipStatusFatura';

const titleCard = (
  <>
    <h3>
      <FaFileInvoiceDollar />
    </h3>
    &nbsp;&nbsp;Faturas
  </>
);
const titleCanceladas = (
  <>
    <h3>
      <FaFileInvoiceDollar />
    </h3>
    &nbsp;&nbsp;Canceladas
  </>
);

function Faturas(props) {
  const arrayLinksPendentes = [];
  const {
    detalhesApolice,
    faturas,
    archivesPolicies,
    configPayment,
    idUsuario,
    valorApolice,
    relacaoSeguradosValor,
    gerarCobrancaEmpresarial,
    loadingCobrancaEmpresarial,
  } = props;
  const [loading, setLoading] = useState(false);
  const [loadingBaixa, setLoadingBaixa] = useState(false);
  const [loadingCobranca, setLoadingCobranca] = useState(false);
  const [loadingAtualizarAssinatura, setLoadingAtualizarAssinatura] =
    useState(false);
  const [loadingReagendar, setLoadingReagendar] = useState(false);
  const [modalSelected, setModalSelected] = useState({
    valor: '',
    dataVencimento: '',
  });
  const [cancelarFaturaInput, setCancelarFaturaInput] = useState('');
  const [modalCancelar, setModalCancelar] = useState(false);
  const [modalDarBaixa, setModalDarBaixa] = useState(false);
  const [modalCobranca, setModalCobranca] = useState(false);
  const [modalCobrancaEmpresarial, setModalCobrancaEmpresarial] =
    useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [postData, setPostData] = useState({
    dataReagendamento: '',
    idFatura: '',
    primeiroPagamento: false,
    idUsuario,
  });

  const toggleModalCancelar = idIugu => {
    setPostData({ idFatura: idIugu });
    setModalCancelar(!modalCancelar);
  };

  const toggleModalDarBaixa = idIugu => {
    setPostData({ idFatura: idIugu });
    setModalDarBaixa(!modalDarBaixa);
  };

  const toggleModalCobrancaEmpresarial = () => {
    setModalCobrancaEmpresarial(!modalCobrancaEmpresarial);
  };

  const toggleModalCobranca = () => {
    setModalCobranca(!modalCobranca);
  };

  const toggleModalOpen = (valor, dataVencimento, idFatura) => {
    setModalSelected({
      ...modalSelected,
      valor,
      dataVencimento,
    });
    setPostData({
      ...postData,
      idFatura,
    });
    setModalOpen(!modalOpen);
  };

  const [faturasRenderButton, setFaturasRenderButton] = useState();
  // const [actionBtn, setActionBtn] = useState(false);

  const baixarCarne = async () => {
    setLoading(true);
    const data = { links: arrayLinksPendentes };
    console.log('LINKS - ', data);
    const result = await Api.post(`faturas/gerar-carne`, data, {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });

    if (result.data.ok) {
      window.open(
        `${process.env.REACT_APP_API_ERP}/${result.data.output.link}`,
      );
    } else {
      alertError('Erro ao baixar Carnê');
    }

    setLoading(false);
  };

  const cancelaFatura = async () => {
    if (cancelarFaturaInput === 'SIM') {
      const result = await Api.post('fatura/cancelar', postData, {
        headers: { Authorization: `Bearer ${props.user.user.token}` },
      });
      if (result.data.ok) {
        window.location.reload();
      } else {
        alertError(result.data.message);
      }
    } else {
      alertWarning('Informe corretamente a palavra para cancelar a fatura');
      return false;
    }
  };

  const darBaixaFatura = async () => {
    const form = document.forms.formDarBaixaFatura;
    const motivo = form.motivo.value;
    const descricao = form.descricao.value;

    if (motivo === '') {
      alertWarning('Selecione o MOTIVO.');
      return;
    }
    setLoadingBaixa(true);
    const objBaixaFatura = {
      idFatura: postData.idFatura,
      motivo,
      descricao,
      idUsuario,
    };

    const result = await Api.post('fatura/baixa', objBaixaFatura, {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });
    if (result.data.ok) {
      setLoadingBaixa(false);
      window.location.reload();
    } else {
      setLoadingBaixa(false);
      alertError(result.data.message);
    }
  };

  const reagendarFatura = async () => {
    setLoadingReagendar(true);
    console.log(postData);
    const authStr = `Bearer ${props.user.user.token}`;
    const result = await Api.post('fatura/alterar-data', postData, {
      headers: { Authorization: authStr },
    });
    console.log(result);
    if (result.data.ok) {
      window.location.reload();
    } else {
      alertError(result.data.message);
    }
    setLoadingReagendar(false);
  };

  const gerarCobranca = async () => {
    const form = document.forms.formGerarCobranca;
    const descricao = form.descricao.value;
    const dataCobranca = form.dataCobranca.value;
    const price = form.price.value;

    if (descricao === '') {
      alertWarning('Descrição não pode ser nula.');
      return;
    }

    if (dataCobranca === '') {
      alertWarning('Escolha a data de vencimento');
      return;
    }

    if (price === '' || price <= 0) {
      alertWarning('O valor é inválido.');
      return;
    }

    setLoadingCobranca(true);
    const authStr = `Bearer ${props.user.user.token}`;
    const objNewFatura = {
      idApolice: detalhesApolice.output.id,
      cobrancaInfo: {
        description: descricao,
        dataCobranca,
        price,
      },
    };
    const result = await Api.post('faturas/gerar-cobranca', objNewFatura, {
      headers: { Authorization: authStr },
    });
    if (result.data.ok) {
      setLoadingCobranca(false);
      window.location.reload();
    } else {
      setLoadingCobranca(false);
      console.log('Erros: ', result.data.output);
      alertError(result.data.message);
    }

    setLoadingCobranca(false);
  };

  useEffect(() => {
    renderButtons();
  }, []);

  const renderButtons = () => {
    let urlIugu = '';
    let posicaoContratoArray = '';
    let contrato = '';
    let linkCompleto = '';
    const renderBtn = [];
    if (detalhesApolice.output.status === 'Aguardando primeiro pagamento') {
      setPostData({ ...postData, primeiroPagamento: true });

      faturas.output.map((el, index) => {
        if (el.status === 'Pendente') {
          urlIugu = el.link_arquivo.split('/');
          archivesPolicies.output.forEach((element, i) => {
            if (element.contrato === '1') {
              posicaoContratoArray = i;
            }
          });
          contrato = archivesPolicies.output[posicaoContratoArray];

          linkCompleto = contrato
            ? `${process.env.REACT_APP_API_ERP.replace(
                '/api',
                '',
              )}pagamento-primeira-fatura/?fatura=${urlIugu[3]}&contrato=${
                contrato.link_arquivo
              }`
            : '';
          renderBtn.push(
            <a
              key={index}
              style={{
                width: '100%',
                marginTop: '20px',
                marginBottom: '20px',
                fontSize: '12px',
              }}
              href={linkCompleto}
              rel="noopener noreferrer"
              className="btn btn-danger"
              target="_blank"
            >
              BAIXAR PRIMEIRA FATURA
            </a>,
          );
        } else {
          // setActionBtn(true);
        }
      });
      setFaturasRenderButton(renderBtn);
    } else {
      setFaturasRenderButton(
        <Button
          style={{ marginTop: '20px', marginBottom: '20px' }}
          color="danger"
          outline
          size="sm"
          onClick={() => baixarCarne()}
        >
          <FaDownload />
          &nbsp;&nbsp;Baixar Carnê
        </Button>,
      );
    }
  };

  const handleGerarCobrancaEmpresarial = () => {
    const form = document.forms.formCobrancaEmpresarial;
    const dataVenc = form.inputDataVenc.value;
    if (dataVenc === '') {
      alertWarning('Selecione a data de vencimento da cobrança.');
      return;
    }

    gerarCobrancaEmpresarial(dataVenc);
  };

  const handleAtualizarAssinatura = async () => {
    setLoadingAtualizarAssinatura(true);
    try {
      const result = await Api.post(
        `apolice/${detalhesApolice.output.id}/atualizar-assinatura`,
        {},
        { headers: { Authorization: `Bearer ${props.user.user.token}` } },
      );
      if (result.data.ok) {
        alertSuccess(result.data.message);
      } else {
        alertWarning(result.data.message);
      }
    } catch (err) {
      alertError(
        'Houve um erro na requisição. Caso persistir, contate o suporte técnico.',
      );
    }
    setLoadingAtualizarAssinatura(false);
  };

  return (
    <>
      <Card>
        <ToastContainer />
        <Container>
          <TableContent>
            <Row className="mt-4 ml-4 mb-4">
              <TitleCardPolicies titleCard={titleCard} />
            </Row>
            <Row className="text-left">
              <Col md={5}>
                <ResgatarFaturasIugu
                  idClienteIugu={configPayment.output.id_cliente_iugu}
                  props={props}
                  idApolice={detalhesApolice.output.id}
                />
              </Col>
              {detalhesApolice.output.id_estipulante != 1 && (
                <Col md={7}>
                  <Button
                    color="secondary"
                    outline
                    size="sm"
                    disabled={loadingAtualizarAssinatura}
                    onClick={handleAtualizarAssinatura}
                  >
                    {loadingAtualizarAssinatura ? (
                      <span>
                        <Spinner color="primary" size="sm" /> Atualizar
                        assinatura no IUGU
                      </span>
                    ) : (
                      <span>
                        <FaSync /> Atualizar assinatura no IUGU
                      </span>
                    )}
                  </Button>
                </Col>
              )}
              <Col md={12}>
                {loading ? (
                  <Button
                    color="danger"
                    outline
                    size="sm"
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                  >
                    <Spinner color="danger" size="sm" /> Gerando carnê...
                  </Button>
                ) : (
                  faturasRenderButton
                )}
                {detalhesApolice.output.id_estipulante != 1 && (
                  <Button
                    color="success"
                    outline
                    size="sm"
                    style={{
                      marginTop: '20px',
                      marginBottom: '20px',
                      marginLeft: '10px',
                    }}
                    onClick={toggleModalCobrancaEmpresarial}
                  >
                    Gerar Cobrança Empresarial
                  </Button>
                )}
              </Col>
            </Row>
            {faturas.output.length > 0 ? (
              <Table>
                <thead className="text-left">
                  <tr>
                    <th>Valor do Boleto</th>
                    <th>Data Vencimento</th>
                    <th>Status</th>
                    <th>Link Boleto</th>
                    <th />
                    <th>Funções</th>
                  </tr>
                </thead>
                <tbody className="text-left">
                  {faturas.output.map((el, i) => {
                    if (el.status !== 'Cancelada' && el.tipo_cobranca == 1) {
                      if (el.status === 'Pendente') {
                        arrayLinksPendentes.push(el.link_arquivo);
                      }
                      return (
                        <tr key={i}>
                          <td>R$ {el.valor}</td>
                          <td>{formatDate(el.data_vencimento)}</td>
                          <td  style={{ position: 'relative'}}>{el.status}
                            <TooltipStatusFatura
                            fatura={el}
                            style={{
                                position: 'absolute',
                                right: '15px',
                                top: '20px',
                                cursor: 'pointer'}}
                            />
                          </td>
                          <td>
                            <a
                              href={el.link_arquivo}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <FaLink />
                              &nbsp;Clique Aqui
                            </a>
                          </td>
                          <td className="btn-group">
                            <span
                              style={{
                                display: 'flex',
                                color: '#007bff',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                toggleModalOpen(
                                  el.valor,
                                  el.data_vencimento,
                                  el.id_iugu,
                                )
                              }
                            >
                              <FaCalendar />
                              &nbsp;Reagendar
                            </span>
                          </td>
                          <td>
                            {/* <Button
                                                            disabled={actionBtn}
                                                            size="sm"
                                                            color="warning"
                                                            onClick={() => toggleModalOpen(el.valor, el.data_vencimento, el.id_iugu)}>
                                                            <FaCalendar/>
                                                        </Button> */}
                            {props.user.user.nivel_usuario == 1 && (
                              <>
                                <Button
                                  disabled={
                                    !(
                                      el.status === 'Pendente' ||
                                      el.status === 'Expirada'
                                    )
                                  }
                                  size="sm"
                                  color="warning"
                                  onClick={() =>
                                    toggleModalDarBaixa(el.id_iugu)
                                  }
                                  title="Dar baixa"
                                  style={{ marginLeft: 2 }}
                                >
                                  <FaMoneyBillAlt
                                    color="white"
                                    title="Dar baixa"
                                  />
                                </Button>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
            ) : (
              <div className="mb-4 text-left">
                <span style={{ color: 'red' }}>
                  <FaExclamationTriangle /> {faturas.message}
                </span>
              </div>
            )}
          </TableContent>

          <TableContent>
            <Row className="mt-4 ml-4 mb-4">
              <TitleCardPolicies titleCard="Outras cobranças" />
            </Row>
            {props.user.user.nivel_usuario == 1 && (
              <Col md={12}>
                <Button
                  color="success"
                  style={{ marginBottom: '10px', width: '100%' }}
                  onClick={toggleModalCobranca}
                >
                  Gerar uma cobrança
                </Button>
              </Col>
            )}
            {faturas.output.length > 0 ? (
              <Table>
                <thead className="text-left">
                  <tr>
                    <th>Valor do Boleto</th>
                    <th>Data Vencimento</th>
                    <th>Status</th>
                    <th>Link Boleto</th>
                    <th />
                    <th>Funções</th>
                  </tr>
                </thead>
                <tbody className="text-left">
                  {faturas.output.map((el, i) => {
                    if (el.status !== 'Cancelada' && el.tipo_cobranca == 2) {
                      if (el.status === 'Pendente') {
                        arrayLinksPendentes.push(el.link_arquivo);
                      }
                      return (
                        <tr key={i}>
                          <td>R$ {el.valor}</td>
                          <td>{formatDate(el.data_vencimento)}</td>
                          <td>{el.status}</td>
                          <td>
                            <a
                              href={el.link_arquivo}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <FaLink />
                              &nbsp;Clique Aqui
                            </a>
                          </td>
                          <td className="btn-group">
                            <span
                              style={{
                                display: 'flex',
                                color: '#007bff',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                toggleModalOpen(
                                  el.valor,
                                  el.data_vencimento,
                                  el.id_iugu,
                                )
                              }
                            >
                              <FaCalendar />
                              &nbsp;Reagendar
                            </span>
                          </td>
                          <td>
                            {/* <Button
                                                            disabled={actionBtn}
                                                            size="sm"
                                                            color="warning"
                                                            onClick={() => toggleModalOpen(el.valor, el.data_vencimento, el.id_iugu)}>
                                                            <FaCalendar/>
                                                        </Button> */}
                            {props.user.user.nivel_usuario == 1 && (
                              <>
                                <Button
                                  disabled={el.status !== 'Pendente'}
                                  size="sm"
                                  color="warning"
                                  onClick={() =>
                                    toggleModalDarBaixa(el.id_iugu)
                                  }
                                  title="Dar baixa"
                                  style={{ marginLeft: 2 }}
                                >
                                  <FaMoneyBillAlt
                                    color="white"
                                    title="Dar baixa"
                                  />
                                </Button>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
            ) : (
              <div className="mb-4 text-left">
                <span style={{ color: 'red' }}>
                  <FaExclamationTriangle /> {faturas.message}
                </span>
              </div>
            )}
          </TableContent>

          <TableContent>
            <Row className="mt-4 ml-4 mb-4">
              <TitleCardPolicies titleCard={titleCanceladas} />
            </Row>
            <Table>
              <thead className="text-left">
                <tr>
                  <th>Valor do Boleto</th>
                  <th>Data Vencimento</th>
                  <th>Status</th>
                  <th>Link Boleto</th>
                </tr>
              </thead>
              <tbody className="text-left">
                {faturas.output.map((el, i) => {
                  if (el.status === 'Cancelada') {
                    return (
                      <tr key={i}>
                        <td>R$ {el.valor}</td>
                        <td>{formatDate(el.data_vencimento)}</td>
                        <td>{el.status}
                            <TooltipStatusFatura
                              fatura={el}
                              style={{marginLeft: '2px', cursor: 'pointer'}}
                            />
                        </td>
                        <td>
                          <a
                            href={el.link_arquivo}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <FaLink />
                            &nbsp;Clique Aqui
                          </a>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </Table>
          </TableContent>
        </Container>
      </Card>
      {modalOpen && (
        <Modal isOpen={modalOpen} toggle={toggleModalOpen}>
          <ModalHeader toggle={toggleModalOpen}>Reagendar Fatura</ModalHeader>
          <ModalBody>
            <p>
              Reagendamento de R${modalSelected.valor} da data{' '}
              {formatDate(modalSelected.dataVencimento)}
            </p>
            <Input
              type="date"
              name="dataReagendamento"
              value={postData.dataReagendamento}
              onChange={e =>
                setPostData({
                  ...postData,
                  dataReagendamento: e.target.value,
                })
              }
            />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" size="sm" onClick={toggleModalOpen}>
              Cancelar
            </Button>
            <Button
              color="warning"
              size="sm"
              disabled={loadingReagendar}
              onClick={() => reagendarFatura()}
            >
              {loadingReagendar ? (
                <Spinner color="white" size="sm" />
              ) : (
                'Reagendar'
              )}
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {modalCancelar && (
        <Modal isOpen={modalCancelar} toggle={toggleModalCancelar}>
          <ModalHeader toggle={toggleModalCancelar}>
            CANCELAR Fatura
          </ModalHeader>
          <ModalBody>
            <p>Se você deseja CANCELAR essa fatura escreva SIM</p>
            <Input
              placeholder="Deseja Continuar?"
              name="cancelarFaturaInput"
              value={cancelarFaturaInput}
              onChange={e => setCancelarFaturaInput(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" size="sm" onClick={toggleModalCancelar}>
              Cancelar
            </Button>
            <Button color="success" size="sm" onClick={() => cancelaFatura()}>
              Confirmar
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {modalDarBaixa && (
        <Modal isOpen={modalDarBaixa} toggle={toggleModalDarBaixa}>
          <form name="formDarBaixaFatura">
            <ModalHeader toggle={toggleModalDarBaixa}>
              Dar baixa na Fatura
            </ModalHeader>
            <ModalBody>
              <p>
                Dar baixa nesta fatura irá cancelar no IUGU e mudar o status no
                ERP para &apos;Baixa Manual&apos; ou &apos;Cancelada&apos;
              </p>
              <Input type="select" name="motivo">
                <option value="">Selecione o motivo:</option>
                <option value="Pagamento em dinheiro">
                  Pagamento em dinheiro
                </option>
                <option value="Pagamento na máquina de cartão">
                  Pagamento na máquina de cartão
                </option>
                <option value="Campanha ou Desconto">
                  Campanha ou Desconto
                </option>
                <option value="Renegociado">Renegociado</option>
                <option value="Cancelada">Cancelada</option>
              </Input>
              <Input
                name="descricao"
                type="textarea"
                placeholder="Descrição: Cliente pagou a primeira fatura no dinheiro e teve que dar troco; Fatura com baixa devido à campanha X ou desconto."
                style={{ minHeight: '100px', marginTop: 4 }}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="danger" size="sm" onClick={toggleModalDarBaixa}>
                Voltar
              </Button>
              <Button
                color="success"
                size="sm"
                onClick={() => darBaixaFatura()}
                disabled={loadingBaixa}
              >
                {loadingBaixa ? (
                  <Spinner color="white" size="sm" />
                ) : (
                  'Confirmar'
                )}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      )}
      {modalCobranca && (
        <Modal isOpen={modalCobranca} toggle={toggleModalCobranca}>
          <form name="formGerarCobranca">
            <ModalHeader toggle={toggleModalCobranca}>
              Gerar uma cobrança
            </ModalHeader>
            <ModalBody>
              <p>
                Criar uma cobrança irá gerar um boleto para o cliente desta
                apólice.
              </p>

              <Row>
                <Col md={12}>
                  <label htmlFor="descricao">Descrição</label>
                  <Input
                    name="descricao"
                    type="text"
                    placeholder="Ex.: Abatimento de 2 boletos atrasados."
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 6 }}>
                <Col md={6}>
                  <label htmlFor="dataCobranca">Data de Vencimento</label>
                  <Input name="dataCobranca" type="date" />
                </Col>

                <Col md={6}>
                  <label htmlFor="price">Valor</label>
                  <Input name="price" type="number" placeholder="Ex.: 27.80" />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" size="sm" onClick={toggleModalCobranca}>
                Cancelar
              </Button>
              <Button
                color="success"
                size="sm"
                onClick={() => gerarCobranca()}
                disabled={loadingCobranca}
              >
                {loadingCobranca ? (
                  <Spinner color="white" size="sm" />
                ) : (
                  'Confirmar'
                )}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      )}
      {modalCobrancaEmpresarial && (
        <Modal
          isOpen={modalCobrancaEmpresarial}
          toggle={toggleModalCobrancaEmpresarial}
        >
          <ModalHeader toggle={toggleModalCobrancaEmpresarial}>
            Gerar Cobrança Empresarial
          </ModalHeader>
          <ModalBody>
            <p>
              Esta ação irá gerar uma fatura com base nos segurados ativos nesta
              apólice e seus produtos. Criará também um relátorio CSV que deve
              ser enviado juntamente com a fatura para o cliente.
            </p>
            <OverflowContainer height="180px">
              <ListGroup>
                {relacaoSeguradosValor.map((el, i) => {
                  return (
                    <ListGroupItem key={i}>
                      {' '}
                      {el.nome}{' '}
                      <Badge color="info" size="lg">
                        {el.produto}
                      </Badge>{' '}
                      <Badge color="success" size="lg">
                        R$ {el.total_segurado.toFixed(2)}
                      </Badge>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            </OverflowContainer>

            <form name="formCobrancaEmpresarial">
              <Row>
                <Col md={6}>
                  <label htmlFor="inputDataVenc">Data de Vencimento</label>
                  <Input type="date" name="inputDataVenc" />
                </Col>
              </Row>
            </form>

            <Row className="mt-3 text-right">
              <Col> Valor da fatura: R$ {valorApolice.toFixed(2)} </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              size="sm"
              onClick={toggleModalCobrancaEmpresarial}
            >
              Cancelar
            </Button>
            <Button
              color="success"
              disabled={loadingCobrancaEmpresarial}
              size="sm"
              onClick={handleGerarCobrancaEmpresarial}
            >
              Confirmar
              {loadingCobrancaEmpresarial ? (
                <Spinner color="info" size="sm" />
              ) : (
                'Confirmar'
              )}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Faturas);
