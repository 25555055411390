import React, { useState } from 'react';
import { Card, Row, Col, Table, Button, Spinner } from 'reactstrap';
import { FaExchangeAlt, FaExclamationTriangle, FaFileExcel } from 'react-icons/fa';
import { formatDate } from '../../services/service';
import { CardComponent, OverflowContainer } from '../styles'; 
import Api from '../../services/api';
import Loading from '../../components/Loading/Loading';
import { alertError } from '../../components/Alerts/Alerts';

function Movimentacao(props) {

    const { movimentacao, idApolice } = props;
    const [ loading, setLoading ] = useState(false)

    const exportarMovimentacao = async _ => {
        setLoading(true);
        try {
            const response = await Api.get(`movimentacoes/${idApolice}/exportar/`, '');
            if(response.data.ok){
                window.open(`${process.env.REACT_APP_API_ERP}/${response.data.output.path}`, "_blank");
    
                setTimeout((e) => {
                    Api.post(`/historico-status/excluir-pdf/`, {path:response.data.output.path})
                    .then(function(response){});
                }, 5000);
            } else {
                throw new Error(response.data.message)
            }
        } catch (error) {
            alertError("Falha ao exportar movimentações: ",error.message)
        }
        setLoading(false);
    }

    return (
        <Card className="mb-4">
            <CardComponent className="mb-3">
                <Col className="header-content text-left">
                    <h2><FaExchangeAlt className="mr-2"/>Movimentação</h2>
                </Col>
            </CardComponent>
            <OverflowContainer>
                {
                !movimentacao.loading
                ?
                    movimentacao.error
                    ?
                        <div className="ml-2 mb-4 text-left">
                            <span style={{color: "red"}}><FaExclamationTriangle/> {movimentacao.error}</span>
                        </div>
                    :
                        <Table>
                            <thead className="text-left">
                                <tr>
                                    <th>Nome</th>
                                    <th>Tipo</th>
                                    <th>Nome Segurado</th>
                                    <th>Data Nacsimento</th>
                                    <th>Cpf</th>
                                    <th>Data</th>
                                    <th>Hora</th>
                                    
                                </tr>
                            </thead>
                            <tbody className="text-left">
                                {
                                    movimentacao.data.length
                                    ?
                                    movimentacao.data.map((el, i) => {
                                        let date = el.data.split(' ')[0];
                                        let time = el.data.split(' ')[1];
                                        return(
                                            <tr key={i}>
                                                <td>{el.nome_terceiro}</td>
                                                <td>{el.tipo}</td>
                                                <td>{el.nome_segurado}</td>
                                                <td>{formatDate(el.data_nascimento_segurado)}</td>
                                                <td>{el.cpf_segurado}</td>
                                                <td>{formatDate(date)}</td>
                                                <td>{time}</td>
                                            </tr>
                                        );
                                    })
                                    :
                                    <tr className="text-center">
                                        <td>Sem movimentações</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                :
                    <Loading></Loading>
                }
            </OverflowContainer>
            <Row>
                <Col className="text-right">
                    <Button 
                        className="m-4"
                        color="success" 
                        outline 
                        size="md" 
                        onClick={() => exportarMovimentacao()}
                        disabled={loading}
                    >   
                    {
                        loading
                        ?
                            <>
                                <Spinner className="mr-1" size="sm"></Spinner>
                                Exportando...
                            </>
                        :
                            <>
                                <FaFileExcel className="mr-1"/> 
                                Exportar
                            </>
                    }   
                        </Button>
                </Col>
            </Row>
            
        </Card>
    );
}

export default Movimentacao;