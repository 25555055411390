import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Tooltip } from 'reactstrap';
import { FaQuestionCircle } from 'react-icons/fa';
import { CircularProgress } from '@material-ui/core';
import { IndexDashboard } from '../styles';

function BaseTotal(props) {
  const { totalBase, loadingBaseTotal, title, tooltip, id } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <IndexDashboard>
      <Card>
        <CardHeader className="card-title">
          <span>{title}</span>
          {tooltip && (
            <>
              <FaQuestionCircle href="#" id={id} className="tooltip-title" />
              <Tooltip
                placement="top-start"
                isOpen={tooltipOpen}
                target={id}
                toggle={toggle}
              >
                <span>{tooltip}</span>
              </Tooltip>
            </>
          )}
        </CardHeader>
        <CardBody>
          {loadingBaseTotal ? (
            <CircularProgress color="inherit" />
          ) : (
            <div className="card-body-value">{totalBase}</div>
          )}
        </CardBody>
      </Card>
    </IndexDashboard>
  );
}

export default BaseTotal;
