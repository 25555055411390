import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Nav,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import {
  FaUsers,
  FaFileContract,
  FaBuilding,
  FaCaretDown,
  FaTicketAlt,
  FaBoxes,
  FaAngleLeft,
  FaAngleRight,
  FaHandshake,
} from 'react-icons/fa';
import logo from '../../assets/img/logo-viver-white.png';
import { NavbarComponent, FooterVersionArea } from '../styles';

function Sidebar(props) {
  const { toggleMenu } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenAjustes, setDropdownOpenAjustes] = useState(false);

  const { nivel_usuario, assessoria } = props.user;

  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggleAjustes = () => setDropdownOpenAjustes(!dropdownOpenAjustes);

  const activeRoute = routeName => {
    return props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  return (
    <div
      className="sidebar"
      style={{
        width: props.width,
        minHeight: props.height,
        transform: `translatex(${props.posicaoX}px)`,
      }}
      data-color="#2d2d2d"
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <div className="logo-normal ml-3">
          <NavLink to='/'>
            <img alt="Planos Viver" src={logo} width={100} height={40} />
          </NavLink>
        </div>
      </div>
      <button
        onClick={toggleMenu}
        type="button"
        className="toggle-menu"
        style={{
          transform: `translate(${props.width}px, 10vh)`,
          color: 'white',
        }}
      >
        {props.posicaoX ? (
          /* <span className="menu-btn">Menu</span> */ <FaAngleRight />
        ) : (
          <FaAngleLeft />
        )}
      </button>
      <div className="sidebar-wrapper">
        <NavbarComponent>
          <Nav>
            {props.routes.map((prop, key) => {
              if (prop.acessoUsuario) {
                if (
                  prop.acessoUsuario.indexOf(parseInt(nivel_usuario, 10)) !== -1
                ) {
                  if (prop.invisible) return null;
                  if (prop.redirect) return null;

                  if (prop.name === 'Clientes') {
                    return (
                      <Dropdown
                        nav
                        isOpen={dropdownOpen}
                        toggle={toggle}
                        key={key}
                      >
                        <DropdownToggle nav>
                          <i className={prop.icon} />{' '}
                          <p>
                            Clientes&nbsp;&nbsp;
                            <FaCaretDown />
                          </p>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem href="/segurados">
                            <FaUsers />
                            &nbsp;Segurados
                          </DropdownItem>
                          <DropdownItem href="/segurados-apolices">
                            <FaFileContract />
                            &nbsp;Segurados Apólices
                          </DropdownItem>
                          <DropdownItem href="/acoes-apolices">
                            <FaFileContract />
                            &nbsp;Ações Apólices
                          </DropdownItem>
                          <DropdownItem href="/empresas">
                            <FaBuilding />
                            &nbsp;Empresas
                          </DropdownItem>
                          <DropdownItem href="/convenios">
                            <FaBuilding />
                            &nbsp;Convênios
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    );
                  }
                  if (prop.name === 'Ajustes') {
                    return (
                      <Dropdown
                        nav
                        isOpen={dropdownOpenAjustes}
                        toggle={toggleAjustes}
                        key={key}
                      >
                        <DropdownToggle nav>
                          <i className={prop.icon} />{' '}
                          <p>
                            Ajustes&nbsp;&nbsp;
                            <FaCaretDown />
                          </p>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem href="/cupons">
                            <FaTicketAlt />
                            &nbsp;Cupons
                          </DropdownItem>
                          <DropdownItem href="/assessorias">
                            <FaUsers />
                            &nbsp;Distribuidores
                          </DropdownItem>
                          <DropdownItem href="/produtos">
                            <FaBoxes />
                            &nbsp;Produtos
                          </DropdownItem>
                          <DropdownItem href="/beneficios">
                            <FaHandshake />
                            &nbsp;Benefícios
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    );
                  }
                  if (prop.path === '/comissao-assessoria/:id') {
                    if (nivel_usuario == 1) {
                      return;
                    }
                    if (nivel_usuario == 2 || nivel_usuario == 5) {
                      return (
                        <li className={activeRoute(prop.path)} key={key}>
                          <NavLink
                            to={`/comissao-assessoria/${assessoria}`}
                            className="nav-link"
                            activeClassName="active"
                            onClick={() => {
                              if (window.innerWidth < '768')
                                props.setPosicaoX(-200);
                            }}
                          >
                            <i className={prop.icon} />
                            <p>{prop.name}</p>
                          </NavLink>
                        </li>
                      );
                    }
                  }
                  return (
                    <li className={activeRoute(prop.path)} key={key}>
                      <NavLink
                        to={prop.path ? prop.path : ''}
                        className="nav-link"
                        activeClassName="active"
                        onClick={() => {
                          if (window.innerWidth < '768')
                            props.setPosicaoX(-200);
                        }}
                      >
                        <i className={prop.icon} />
                        <p>{prop.name}</p>
                      </NavLink>
                    </li>
                  );
                }
              }
            })}
          </Nav>
        </NavbarComponent>
      </div>
      <FooterVersionArea>
        <div>
          <span>Versão 2.2.4</span>
        </div>
      </FooterVersionArea>
    </div>
  );
}

export default Sidebar;
