import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Input,
  Card,
  Spinner,
  Alert,
  Form,
  Button,
} from 'reactstrap';
import { FaHeart, FaExclamationCircle } from 'react-icons/fa';
import { saveUser } from '../../actions/user';
import { FormLoginComponent } from '../styles';
import Api from '../../services/api';

function Login(props) {
  const [loginUser, setLoginUser] = useState({
    loginUserEmail: '',
    loginUserPass: '',
  });
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [loading, setLoading] = useState(false);

  const submitLoginUser = async e => {
    e.preventDefault();
    setLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.set('email', loginUser.loginUserEmail);
    bodyFormData.set('senha', loginUser.loginUserPass);

    const response = await Api.post('usuario/login', bodyFormData);

    if (response.data.ok) {
      // console.log("response.data.output -",response.data.output)

      // const { primeiro_nome_usuario, usuario, nivel_usuario, token } = response.data.output

      // let user = {
      //     response.data.output
      // }
      // let user = {
      //     primeiro_nome_usuario,
      //     usuario,
      //     nivel_usuario,
      //     token
      // }

      // if (response.data.output.corretor_representante) {
      //     user = {
      //         ...user,
      //         corretor_representante: response.data.output.corretor_representante,
      //         assessoria: response.data.output.assessoria
      //     }
      // }

      props.saveUser(response.data.output);
      // console.log("user - ",props.user);
      window.location.href = '/home';
    } else {
      setNotAuthorized(true);
      setMessageError(response.data.message);
      setLoading(false);
    }
  };

  const handleOnChange = event => {
    const { name, value } = event.target;
    setLoginUser({ ...loginUser, [name]: value });
  };

  return (
    <>
      <FormLoginComponent>
        <Card>
          <Form onSubmit={submitLoginUser} method="post">
            <Row className="pb-4">
              <Col>
                <Input
                  type="text"
                  name="loginUserEmail"
                  placeholder="E-mail"
                  value={loginUser.loginUserEmail}
                  onChange={e => handleOnChange(e)}
                  required
                />
              </Col>
            </Row>
            <Row className="pb-4">
              <Col>
                <Input
                  type="password"
                  name="loginUserPass"
                  placeholder="Senha"
                  value={loginUser.loginUserPass}
                  onChange={e => handleOnChange(e)}
                  required
                />
              </Col>
            </Row>
            <Row>
              {loading ? (
                <Col className="text-center">
                  <Spinner color="primary" />
                </Col>
              ) : (
                <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                  <Button
                    className="btn-login"
                    type="submit"
                    style={{ backgroundColor: 'transparent', border: '0px' }}
                  >
                    {/* <Col className="btn-login" onClick={() => submitLoginUser()}> */}
                    ENTRAR
                    {/* </Col> */}
                  </Button>
                </Col>
              )}
            </Row>

            {notAuthorized && (
              <Alert color="danger" className="mt-4">
                <FaExclamationCircle />
                &nbsp;&nbsp;{messageError}{' '}
              </Alert>
            )}
          </Form>
        </Card>

        <Col className="footer pl-2 pr-2">
          <span>
            © 2020 Feito com <FaHeart style={{ color: '#ff0000b5' }} /> por
            Planos Viver Administrativo
          </span>
          <br />
          <span>© Todos os direitos reservados. </span>
          <br />
        </Col>
      </FormLoginComponent>
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

const mapDispatchToProps = {
  saveUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
