import React from 'react';
import ListaMaterialVendas from '../../views/MaterialApoioVenda/ListaMaterialVendas';
import { FaBriefcase } from 'react-icons/fa';
import { Container, Row, Col } from 'reactstrap';
import TitleContent from '../../components/TitleContent/TitleContent';

function Page(props) {
  
  const titleHeader = <><FaBriefcase/>&nbsp;&nbsp; Material de Venda </> ;

  return (
    <Container className="mb-4" fluid={true}>
      <Row className="text-left mt-4 mb-4">
        <Col lg={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row className="">
        <Col lg={3}>
          <ListaMaterialVendas {...props} />
        </Col>
      </Row>
    </Container>
  )
}

export default Page;
