import React, { useState } from 'react';
import { BodyFormComponent, LabelForm, SectionFormHeader } from '../styles';
import { FaArrowLeft, FaBuilding } from 'react-icons/fa';
import { Button, Card, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import Api from '../../services/api';
import { Switch } from '@material-ui/core';
import SubmitBtn from '../../components/Buttons/SubmitBtn';
import { alertSuccess, alertError, alertWarning } from '../../components/Alerts/Alerts';

function EditarProduto(props) {

  const { history, resultProduto, setResultProduto, token } = props;

  const { nome, descricao, ativo, limite_idade } = props.resultProduto;

  const [ loading, setLoading ] = useState(false);

  const handleOnChange = event => {
    const { name, value } = event.target;
    setResultProduto({ ...resultProduto, [name]: value });
  }

  const handleSubmit = async e => {

    e.preventDefault();
    setLoading(true);

    const response = await Api.post('produto/editar', resultProduto, { headers: { Authorization: `Bearer ${token}` }} );

    if(response.data.ok){
      setLoading(false);
      window.history.back();
      alertSuccess('Atualizado com sucesso');
    }
  }

  const toggleTaxaAdesao = e => {
    setResultProduto({ ...resultProduto, ativo: e.target.checked });
  };

  const toggleLimiteIdade = e => {
    setResultProduto({ ...resultProduto, limite_idade: e.target.checked });
  };

  return (
     <Card style={{ marginTop: "30px" }}>
      <Form onSubmit={handleSubmit}>
      <BodyFormComponent>
        <Row className="mt-2 mb-4">
          <Col md={12}><SectionFormHeader>Dados do produto</SectionFormHeader></Col>
          <Col md={6} className="mt-2">
          <LabelForm>Nome</LabelForm>
            <InputGroup>
							<InputGroupAddon addonType="prepend">
								<InputGroupText><FaBuilding /></InputGroupText>
							</InputGroupAddon>
              <Input
                type="text"
                name="nome"
                value={nome}
                placeholder="Nome do produto"
                onChange={e => {handleOnChange(e)}} />
						</InputGroup>
          </Col>
          <Col md={3} className="mt-2">
            <LabelForm style={{marginTop: '32px' }}>Possui limite de idade? &nbsp;</LabelForm>
            <Switch
              checked={limite_idade ? limite_idade : 0}
              onChange={toggleLimiteIdade}
              name="limite_idade"
              size="small"
              color="secondary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Col>
          <Col md={3} className="mt-2">
            <LabelForm style={{marginTop: '32px' }}>Ativo? &nbsp;</LabelForm>
            <Switch
              checked={ativo ? ativo : 0}
              onChange={toggleTaxaAdesao}
              name="ativo"
              size="small"
              color="secondary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Col>
        </Row>
        <Row className="mt-2 mb-4">
          <Col md={12} className="mt-2">
            <LabelForm>Descrição</LabelForm>
            <Input
              type="textarea"
              name="descricao"
              rows="6"
              value={descricao}
              onChange={e => handleOnChange(e)}
            />
          </Col>
        </Row>
        <Row className="mt-4 text-right">
          <Col>
            <Button color="danger" onClick={() => history.goBack()}><FaArrowLeft />&nbsp;&nbsp;Voltar</Button>&nbsp;
            <SubmitBtn
              label="Salvar Alterações"
              labelOnClick="Aguarde..."
              loading={loading}
              typeSubmit="submit"
              metodo={handleSubmit}
              color="#0A7A73"
            />
          </Col>
        </Row>
      </BodyFormComponent>
      </Form>
    </Card>
  )
}

export default EditarProduto;
