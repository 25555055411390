import React, { useState } from 'react';
import { Button, Input, ModalHeader, Modal, ModalBody, Col, Row } from 'reactstrap';
import { FaDownload } from 'react-icons/fa';
import Api from '../../services/api';
import { alertError } from '../../components/Alerts/Alerts';
import { ToastContainer } from 'react-toastify';

function RelatorioComissaoCarteira(props) {

	const { idAssessoriaSelecionada, token, history } = props;
	const [ mesAno, setMesAno, ] = useState("");
	const [ linkToDownload, setLinkToDownload ] = useState();
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

  const relatorioVendasAssessoriaForm = async _ => {
		const authStr = `Bearer ${token}`;
		const result = await Api.get(`/relatorio-venda/${mesAno}/assessoria/${idAssessoriaSelecionada}/carteira/excel` ,{ headers: { Authorization: authStr }});

		if(result.data.ok){
			setModal(true);
			setLinkToDownload(result.data.output.arquivo)
		}else{
			alertError(result.data.message);			
		}

  }

  return (
    <>
		<ToastContainer/>
		
			<Input type="month" name="mesAno" required value={mesAno} onChange={e => setMesAno(e.target.value)} />
		
			<Button className="mt-4" style={{width: "100%", height: "50px"}} color="success" size="sm" onClick={relatorioVendasAssessoriaForm}>
				<FaDownload />&nbsp;Baixar Relatório Comissão
			</Button>
		{
		modal&&
		<Modal isOpen={modal} toggle={toggle}>
			<ModalHeader toggle={toggle}>Baixar arquivo</ModalHeader>
			<ModalBody className="text-right">
				<a href={`${process.env.REACT_APP_API_ERP}/${linkToDownload}`} rel="noopener noreferrer" download={`${process.env.REACT_APP_API_ERP}/${linkToDownload}`}>
					<Button size="sm" color="success"><FaDownload /> Baixar</Button>
				</a>
			</ModalBody>
		</Modal>
		}
    </>
  )
}

export default RelatorioComissaoCarteira;
