import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Page from './Page';

function Controller(props) {
  
  let history = useHistory();
  let { id } = useParams();

  const [ resultConvenio, setResultConvenio ] = useState({

  });

  useEffect(
    () => {
      getInfosConvenio();
    }, []
  )

  const getInfosConvenio = async _ => {
    const request = await Api.get(`convenio/${id}`, { headers: { Authorization: `Bearer ${props.user.user.token}` }})
    setResultConvenio(request.data.output);
    console.log("result Convenios - ",request.data.output);
  }

  return (
    <>
      {resultConvenio&&
        (<Page resultConvenio={resultConvenio} setResultConvenio={setResultConvenio} history={history} token={props.user.user.token}/>)
      }
    </>
  )
}

const maapStateToProps = store => ({
  user: store.user,
})

export default connect(maapStateToProps)(Controller);