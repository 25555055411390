import Styled from 'styled-components';

export const PanelEdited = Styled.div`
        background: #FFFFFF;
        margin-top: 20px;
        margin-bottom: 20px;
        padding:30px;
        box-shadow: 2px 2px 3px -1px rgba(0,0,0,0.09);
        border-radius:2px;
    
        h1 {
            color: #ca7474;
        }
        p {
            color: #6b6b6b;
        }
    
    h2 svg{
        color: #0A7A73;
    }
`;

export const CardComponent = Styled.div`
  padding-top: 30px;
  font-family: "Archivo";

  b {
    font-weight: 700;
  }
  h2 {
    color: #538a88;
  }
  h6 {
    font-weight: 800;
  }
  
  .row {
    margin-top: 30px;
  }
  .card-body {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
  .header-content{
    text-align: left;
  }
  span, td {
    font-size: 11px;
  }
  .cnpj-content { 
    font-size: 12px;
  }
  .nav-tabs {
    cursor: pointer;
    padding-left: 14px;  
  }
  .nav-tabs > .nav-item {
    background-color: #ececec;
    border: 1px solid #dee2e6;
    border-radius: 4px;
  }
  
  .nav li.active a,
  .nav li a:hover,
  .nav li.active a:focus,
  .nav li.active a:hover{
    background-color: #dcdcdc;
  }

  .nav-tabs > .nav-item > .nav-link {
    font-size: 15px;
    color: #27478dd9;
  }
`;