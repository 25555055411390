import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { FaArrowLeft, FaBuilding } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import InformacoesEmpresa from '../../views/Empresa/InformacoesEmpresa';
import ResumosFuncionario from '../../views/Empresa/ResumosFuncionario';
import ContatosEmpresa from '../../views/Empresa/ContatosEmpresa';
import ApolicesEmpresa from '../../views/Empresa/ApolicesEmpresa';
import CadastrarFuncionario from '../../views/Empresa/CadastrarFuncionario';
import CadastrarContatoEmpresa from '../../views/Empresa/CadastrarContatoEmpresa';

function Page(props) {
  const {
    modal,
    setModal,
    getFuncionarios,
    modalContatos,
    infosFuncionarios,
    openModalCadastroFuncionario,
    openModalCadastroContato,
    handleFuncionarioOnChange,
    funcionarios,
    cadastrarFuncionarioForm,
    adicionarDependente,
    resultBuscaProd,
    infosApolice,
    infosContatos,
    // assessoria,
    // vendedor,
    // disabled,
    // handleOnChange,
    // clientInput,
    // cadastrarApolice,
    // msgError,
    inputResponsavelDep,
    dependentList,
    dependentes,
    handleDependentesOnChange,
    deletarContato,
    contato,
    cadastrarContatoForm,
    handleContatoOnChange,
    token,
    idEstipulante,
    loading,
    // user,
    getApolices,
    funcAtivos,
    funcInativos,
    params,
    setParams,
    handleMudarProdutoFuncionario,
    btnDisabled = { btnDisabled },
    getFuncionarioApolices,
    funcionarioApolices,
    inactivatePlanoFuncionario,
    infosEmpresas,
  } = props;

  const history = useHistory();

  const titleHeader = (
    <>
      <FaBuilding />
      &nbsp;&nbsp;{infosEmpresas.output && infosEmpresas.output.nome}{' '}
    </>
  );

  const [modalImportar, setModalImportar] = useState(false);
  const toggleImportar = () => setModalImportar(!modalImportar);

  console.log(props.infosEmpresas);
  return (
    <Container className="pb-5" fluid>
      <Row className="mt-4">
        <Col md={2}>
          <Button
            className="btn-back w-100"
            outline
            color="danger"
            size="sm"
            onClick={() => history.push('/empresas')}
          >
            <FaArrowLeft />
            &nbsp;&nbsp;Voltar
          </Button>
        </Col>
      </Row>
      <Row className="text-left mt-4 mb-4">
        <Col lg={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
        <Col lg={12} className="mt-4">
          <Button
            color="success"
            onClick={() => history.push(`/lista-funcionarios/${idEstipulante}`)}
          >
            Funcionários{' '}
          </Button>
          {/* <Button outline size="sm" color="success" onClick={() => toggleImportar()}><FaFileDownload/>&nbsp;Importar Funcionários</Button>
          {
            modalImportar&&
            (<ModalEnviaCsv modalImportar={modalImportar} toggleImportar={toggleImportar} idEmpresa={idEstipulante} token={token}/>)
          } */}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6} className="mb-4">
          {infosEmpresas.output && (
            <InformacoesEmpresa infosEmpresas={infosEmpresas.output} />
          )}
        </Col>
        <Col md={6} className="mb-4">
          {infosContatos && (
            <ContatosEmpresa
              deletarContato={deletarContato}
              infosContatos={infosContatos}
              openModalCadastroContato={openModalCadastroContato}
              idEstipulante={idEstipulante}
            />
          )}
          {modalContatos && (
            <Row>
              <Col sm={12}>
                <CadastrarContatoEmpresa
                  openModalCadastroContato={openModalCadastroContato}
                  modalContatos={modalContatos}
                  handleContatoOnChange={handleContatoOnChange}
                  contato={contato}
                  loading={loading}
                  cadastrarContatoForm={cadastrarContatoForm}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          {infosFuncionarios && (
            <ResumosFuncionario
              openModalCadastroFuncionario={openModalCadastroFuncionario}
              infosFuncionarios={infosFuncionarios}
              toggleImportar={toggleImportar}
              modalImportar={modalImportar}
              idEstipulante={idEstipulante}
              token={token}
              infosEmpresas={infosEmpresas.output}
              funcAtivos={funcAtivos}
              funcInativos={funcInativos}
              infosApolice={infosApolice}
              params={params}
              setParams={setParams}
              resultBuscaProd={resultBuscaProd}
              handleMudarProdutoFuncionario={handleMudarProdutoFuncionario}
              btnDisabled={btnDisabled}
              getFuncionarioApolices={getFuncionarioApolices}
              funcionarioApolices={funcionarioApolices}
              inactivatePlanoFuncionario={inactivatePlanoFuncionario}
            />
          )}
          {modal && (
            <Row>
              <Col sm={12}>
                <CadastrarFuncionario
                  openModalCadastroFuncionario={openModalCadastroFuncionario}
                  modal={modal}
                  handleFuncionarioOnChange={handleFuncionarioOnChange}
                  funcionarios={funcionarios}
                  cadastrarFuncionarioForm={cadastrarFuncionarioForm}
                  adicionarDependente={adicionarDependente}
                  resultBuscaProd={resultBuscaProd}
                  inputResponsavelDep={inputResponsavelDep}
                  dependentList={dependentList}
                  dependentes={dependentes}
                  handleDependentesOnChange={handleDependentesOnChange}
                  loading={loading}
                  getFuncionarios={getFuncionarios}
                  setModal={setModal}
                  infosEmpresas={infosEmpresas.output}
                  idEmpresa={idEstipulante}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {infosApolice && (
            <ApolicesEmpresa
              idEstipulante={idEstipulante}
              infosApolice={infosApolice}
              history={history}
              token={token}
              resultBuscaProd={resultBuscaProd}
              getApolices={getApolices}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Page;
