import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Page from './Page';
import Api from '../../services/api';

function Controller(props) {
  
  const [ seguradosAtivos, setSeguradosAtivos ] = useState();
  const [ terceiros, setTerceiros ] = useState();
  const [ inputSelect, setInputSelect ] = useState("");
  const [ nomeTerceiros, setNomeTerceiros ] = useState("");
  const [ msgAlert, ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const authStr = `Bearer ${props.user.user.token}`;

  useEffect(() => {
    getTerceiros();
  },[]);

  const getSeguradosAtivosPorTerceiro = async _ => {
    setLoading(true);
    const request = await Api.get(`terceiro/${inputSelect}/segurados/ativos`, { headers: { Authorization: authStr }});
    
    if(request.data.ok){
      setSeguradosAtivos(request.data);
    }
    
    setLoading(false);
  }

  const getTerceiros = async _ => {
    const request = await Api.get( "/terceiros/", { headers: { Authorization: authStr }});
    console.log("Req",request)
    setTerceiros(request.data.output);
  }

  return (
    <>
      {
      terceiros&&
      <Page 
        seguradosAtivos={seguradosAtivos} 
        terceiros={terceiros} 
        setInputSelect={setInputSelect} 
        inputSelect={inputSelect} 
        getSeguradosAtivosPorTerceiro={getSeguradosAtivosPorTerceiro}
        msgAlert={msgAlert}
        setNomeTerceiros={setNomeTerceiros}
        loading={loading}
      />
      }
    </>
  )
}

const mapStateToProps = store => ({
  user: store.user
});

export default connect(mapStateToProps)(Controller);
