import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'reactstrap';
import { Line } from 'react-chartjs-2';

function GraficoRelatorioAssessorias(props) {
    
    const { relatorioAssessoriaSelecionada, graficoValorTotalMes, loadingGraph } = props;
    const [ valores, setValores ] = useState([]);
    const [ datas, setDatas ] = useState([]);

    useEffect(() => {
        getValorData();
        
    }, [relatorioAssessoriaSelecionada]);

    const getValorData = _ => {
        let datas = [];
        let valores = [];
        
        for(const i in graficoValorTotalMes){
            datas.push(i);
            valores.push(graficoValorTotalMes[i].toFixed(2));
        }
       
        setDatas(datas);
        setValores(valores);
    }

    const data = {
        labels: datas,
        datasets: [{
            label: "Relatório Mensal (R$)",
            backgroundColor: 'rgba(232, 64, 64, 0.9)',
            borderColor: 'rgb(232, 64, 64)',
            data:valores,
        }]
    }

  return (
    <Row className="mt-4">
        <Col>
            {
                loadingGraph
                ?
                <Spinner color="primary"/>
                :
                <article className="canvas-container">
                    <Line data={data}/>
                </article>
            }   
        </Col>
    </Row>
  )
}

export default GraficoRelatorioAssessorias;



