import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Api from '../../services/api';
import Loading from '../../components/Loading/Loading';
import Page from './Page';

function Controller(props) {
  const { nivel_usuario, usuario, assessoria } = props.user.user;
  const [empresas, setEmpresas] = useState('');
  const [loading, setLoading] = useState(false);
  const [msgNotFound, setMsgNotFound] = useState('');
  const authStr = `Bearer ${props.user.user.token}`;
  const history = useHistory();

  useEffect(() => {
    if (nivel_usuario == 1) {
      getEmpresas();
    } else if (nivel_usuario == 2) {
      buscaVendaAssessor();
      buscaEmpresasPelaAssessoriaDoUsuario();
    } else if (nivel_usuario == 5) {
      // buscaVendaAssessor();
      buscaEmpresasPelasAssessoriasDoUsuario();
    } else if (nivel_usuario == 3 || nivel_usuario == 4) {
      buscaVendaCorretorEmpresarial(usuario);
    }
  }, []);

  const buscaVendaAssessor = async _ => {
    if (usuario) {
      const requestVendaDoAssessor = await Api.get(
        `empresas/usuario/${usuario}`,
        { headers: { Authorization: authStr } },
      );

      if (requestVendaDoAssessor.data.ok) {
        setEmpresas(...empresas, requestVendaDoAssessor.data);
      } else {
        setMsgNotFound(requestVendaDoAssessor.data.message);
      }
    }
  };

  const buscaVendaCorretorEmpresarial = async idUsuario => {
    const requestEmpresas = await Api.get(`empresas/usuario/${idUsuario}`, {
      headers: { Authorization: authStr },
    });

    if (requestEmpresas.data.ok) {
      setEmpresas(requestEmpresas.data);
    } else {
      setMsgNotFound(requestEmpresas.data.message);
    }
  };

  const buscaVendaCorretor = async idUsuario => {
    let vendasArr = [];

    const requestEmpresas = await Api.get(`empresas/usuario/${idUsuario}`, {
      headers: { Authorization: authStr },
    });

    // if (nivel_usuario == 2 || nivel_usuario == 5) {
    if (requestEmpresas.data.ok) {
      vendasArr = requestEmpresas.data.output;
    } else {
      setMsgNotFound(requestEmpresas.data.message);
    }
    return vendasArr;
    // }
  };

  const buscaEmpresasPelaAssessoriaDoUsuario = async () => {
    let empresasArr = [];
    let result = '';
    if (assessoria != undefined) {
      const url = `/corretor-representante/assessoria/${assessoria}`;

      result = await Api.get(url, { headers: { Authorization: authStr } });
      if (result.data.ok) {
        const corretores = result.data.output;
        console.log('corretores', corretores);
        corretores.map(async (elCorretor, index) => {
          const vendaCorr = await buscaVendaCorretor(elCorretor.id_usuario);
          if (vendaCorr.length > 0) {
            empresasArr = [].concat(empresasArr, vendaCorr);
          }

          if (corretores.length == index + 1) {
            setEmpresas(empresasArr);
          }
        });
      }
    }

    setLoading(false);
  };

  const buscaEmpresasPelasAssessoriasDoUsuario = async () => {
    let empresasArr = [];
    let result = '';
    if (assessoria != undefined) {
      const url = `/corretor-representante/assessoria-master/${assessoria}`;
      result = await Api.get(url, { headers: { Authorization: authStr } });

      if (result.data.ok) {
        const corretores = result.data.output;
        corretores.map(async (elCorretor, index) => {
          const vendaCorr = await buscaVendaCorretor(elCorretor.id_usuario);
          empresasArr = [].concat(empresasArr, vendaCorr);

          if (corretores.length == index + 1) {
            // setVendas(vendasArr)
            setEmpresas(empresasArr);
          }
        });
      }
    }

    setLoading(false);
  };

  const getEmpresas = async _ => {
    setLoading(true);
    const empresasReq = await Api.get('/empresas/', {
      headers: { Authorization: authStr },
    });
    if (empresasReq.data.ok) {
      setEmpresas(empresasReq.data);
    } else {
      setMsgNotFound(empresasReq.data.message);
    }
    setLoading(false);
  };
  console.log('empresas', empresas);
  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <Page
          empresas={empresas}
          authStr={authStr}
          loading={loading}
          history={history}
          msgNotFound={msgNotFound}
          nivel_usuario={nivel_usuario}
        />
      )}
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
