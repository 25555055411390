import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
} from '@material-ui/core';
import { FaArrowLeft, FaFileExcel, FaFileInvoiceDollar } from 'react-icons/fa';

import { useHistory } from 'react-router-dom';
import ComissaoDistribuidor from '../../views/ComissaoAssessoria/ComissaoDistribuidor';
import ComissoesTable from '../../views/ComissaoAssessoria/ComissoesTable';
import Filtros from '../../views/ComissaoAssessoria/Filtros';
import RegraComissao from '../../views/ComissaoAssessoria/RegraComissao';
import MaterialButton from '../../components/Buttons/MaterialButton';

function Page(props) {
  const {
    assessorias,
    getComissoes,
    comissoes,
    user,
    loadingExcel,
    getExcel,
    filtros,
    setFiltros,
    comissaoDistribuidor,
    idDistribuidor,
    regraComissao,
    assessoria,
    getExcelCorretor,
    loadingExcelCorretor,
  } = props;

  console.log(comissoes);

  const { periodo } = filtros;

  const history = useHistory();

  const date = new Date(periodo);
  const correctDate = new Date(date.setDate(date.getDate() + 1));
  const mesNome = correctDate.toLocaleString('pt-BR', { month: 'long' });

  const handleBaixarExcel = async () => {
    const excel = await getExcel();
    window.open(`${process.env.REACT_APP_API_ERP}/${excel.arquivo}`, '_blank');
  };

  return (
    <Container maxWidth="md">
      <Box display="flex" alignItems="center" my="1rem">
        <IconButton
          variant="outlined"
          color="secondary"
          onClick={() => history.goBack()}
        >
          <FaArrowLeft size="1.1rem" />
        </IconButton>
        <Box
          ml="1.5rem"
          fontSize="2.2rem"
          fontWeight="100"
          display="flex"
          alignItems="center"
        >
          <FaFileInvoiceDollar />
          <Box ml="0.7rem">Comissões</Box>
        </Box>
      </Box>
      <hr />
      <Filtros
        assessorias={assessorias}
        getComissoes={getComissoes}
        loadingComissoes={comissoes.loading}
        user={user}
        filtros={filtros}
        setFiltros={setFiltros}
        idDistribuidor={idDistribuidor}
      />
      <RegraComissao assessoria={assessoria} regraComissao={regraComissao} />
      <ComissaoDistribuidor
        comissaoDistribuidor={comissaoDistribuidor}
        user={user}
      />
      <ComissoesTable
        rows={comissoes}
        getExcelCorretor={getExcelCorretor}
        loadingExcelCorretor={loadingExcelCorretor}
      />
      <Box display="flex" justifyContent="flex-end" pb="2rem">
        <MaterialButton
          variant="contained"
          onClick={handleBaixarExcel}
          disabled={!comissoes.data.length || loadingExcel}
          loading={loadingExcel}
          color="dashboard"
        >
          <>
            <FaFileExcel />
            &nbsp;Baixar excel
          </>
        </MaterialButton>
      </Box>
    </Container>
  );
}

export default Page;
