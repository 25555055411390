import React from 'react';
import { Card, Col, Row, Container, Table, Spinner } from 'reactstrap';
import { CardComponent } from '../styles'; 
import { FaEdit } from 'react-icons/fa';
import { TableContent, OverflowContainer } from '../styles';
import { formatDate } from '../../services/service';

export default function endossosEdicao(props) {
    const { endossos, loading } = props;

    return (
      <Card>
      <CardComponent>
        <Col className="header-content">
          <h2><FaEdit style={{color: "#538a88" }} />&nbsp;&nbsp;Endossos de Edição</h2>
        </Col>
        <OverflowContainer>
          <TableContent>
              <Row>
                  <Col>
                    {

                        (loading) 
                        ? 
                            <Spinner type="grow" color="dark" />
                        :

                            endossos.length > 0 ?
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Campo</th>
                                            <th>Descrição</th>
                                            <th>Responsável</th>
                                            <th>Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            endossos.map( (el, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{el.campo}</td>
                                                        <td>{el.descricao}</td>
                                                        <td>{el.usuario_nome}</td>
                                                        <td>{el.created}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            :
                            <>Nenhum endosso encontrado.</>
                    }
                  </Col>
              </Row>
          </TableContent>
        </OverflowContainer>
      </CardComponent>
    </Card>
    );
}
