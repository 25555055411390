import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import NotFoundPage from '../../controllers/NotFoundPage/Controller';

import dashboardRoutes from '../../routes/dashboard';

function Dashboard(props) {
  const sidebarWidth = 200;

  const [backgroundColor] = useState('red');
  const [activeColor] = useState('info');
  const nivelUsuario = props.user.nivel_usuario;
  const [posicaoX, setPosicaoX] = useState(-sidebarWidth);
  const [mainPainelWidth, setMainPainelWidth] = useState();
  const [showInDesktop, setShowInDesktop] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (window.innerWidth > '768') {
      setPosicaoX(0);
      setShowInDesktop(true);
    }
  }, []);

  window.addEventListener(
    'resize',
    function (event) {
      if (window.innerWidth > '768') {
        setShowInDesktop(true);
      } else {
        setPosicaoX(-sidebarWidth);
        setShowInDesktop(false);
        setMainPainelWidth('100%');
      }
    },
    true,
  );

  const toggleMenu = () => {
    if (posicaoX < 0) {
      setPosicaoX(0);
      setMainPainelWidth();
    } else {
      setPosicaoX(-sidebarWidth);
      setMainPainelWidth('100%');
    }
  };

  const clickMobileMenu = _ => {
    if (posicaoX < 0) {
      setPosicaoX(0);
    } else {
      setPosicaoX(-sidebarWidth);
    }
  };

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        width={200}
        height="100vh"
        toggleMenu={toggleMenu}
        posicaoX={posicaoX}
        setPosicaoX={setPosicaoX}
        routes={dashboardRoutes}
        bgColor={backgroundColor}
        activeColor={activeColor}
        sidebarWidth={sidebarWidth}
        setMainPainelWidth={setMainPainelWidth}
      />
      <div className="main-panel" style={{ width: mainPainelWidth }}>
        <Header
          showInDesktop={showInDesktop}
          clickMobileMenu={clickMobileMenu}
          posicaoX={posicaoX}
        />
        {(nivelUsuario == 3 || nivelUsuario == 4) &&
          props.location.pathname === '/' &&
          history.push('/treinamentos')}
        <Switch>
          {dashboardRoutes.map((prop, key) => {
            if (prop.acessoUsuario) {
              if (prop.acessoUsuario.indexOf(parseInt(nivelUsuario)) !== -1) {
                if (prop.redirect) {
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key} />
                  );
                }
                if (!prop.component && !prop.path) {
                  return null;
                }

                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              }
            }
          })}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  user: store.user.user,
});

export default connect(mapStateToProps)(Dashboard);
