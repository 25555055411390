import React, { useState } from 'react';
import { Card, Row, Col, Label, Button, Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import { CardComponent } from '../styles';
import { FaBuilding, FaMapMarkerAlt, FaRoad, FaMapMarkedAlt, FaPlus, FaHeadset, FaEnvelope, FaMobile, FaPhone, FaUser, FaTrash, FaWrench } from 'react-icons/fa';
import CadastrarContatoConvenio from './CadastrarContatoConvenio';
import Api from '../../services/api';
import { connect } from 'react-redux'; 
import { alertError } from '../../components/Alerts/Alerts';
import { ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom';

function InformacoesConvenio(props) {
  
  const { convenio, contatos, history } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  let { id } = useParams();

  const deletaContato = async id => {
    const authStr = `Bearer ${props.user.user.token}`;
    const result = await Api.delete(`convenio/contato/${id}` ,{ headers: { Authorization: authStr }});
    if(result.data.ok){
      window.location.reload();
    }else{
      alertError("Algo deu errado ao deletar contato");
    }
  }

  return (
    <Card>
      <ToastContainer/>
      <CardComponent>
        <Row>
          <Col className="header-content">
            <h2><FaBuilding/> {convenio.nome} </h2>
            <h6>CNPJ: {convenio.cnpj}</h6>
          </Col>
          <Col className="text-right">
            <Button color="success" outline size="sm" onClick={() => history.push(`/editar-convenio/${id}`)}><FaWrench/>&nbsp;Editar</Button>
          </Col>
        </Row>
        <Row>
          <Col className="header-content">
            <h2>Endereço</h2>
            <p><Label><FaRoad /> Rua</Label><span>&nbsp;{convenio.logradouro}</span> <span>Nº {convenio.numero_endereco}</span> </p>
            <p><Label><FaMapMarkerAlt /></Label><span>&nbsp;{convenio.bairro}</span></p>
            <p><Label><FaMapMarkedAlt /></Label><span>&nbsp;{convenio.cidade} - {convenio.uf}</span></p>
          </Col>
        </Row>
        <Row className="mt-4 mb-4">
          <Col className="header-content">
            <h2>Contato dos Responsáveis</h2>
            {
              contatos.output&&
              contatos.output.length > 0
              ?
                contatos.output.map((el, i) => (
                  <Row key={i} className="mt-4 mb-4">
                    <Col>
                      <Table>
                        <thead>
                          <tr>
                            <th><FaUser/> Nome Responsável</th>
                            <th><FaPhone/> Telefone</th>
                            <th><FaMobile />Celular</th>
                            <th><FaEnvelope/> E-mail</th>
                            <th>Funções</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{el.nome_responsavel}</td>
                            <td>{el.telefone}</td>
                            <td>{el.celular}</td>
                            <td>{el.email}</td>
                            <td><Button color="danger" size="sm" onClick={() => deletaContato(el.id)}><FaTrash /></Button></td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                ))
              :
              <Row className="mb-2">
                <Col>
                  <span style={{color: "red"}}>{contatos.message}</span>
                </Col>
              </Row>
            } 
            <Button size="sm" color="success" onClick={() => toggle()}>
              <FaPlus/> Cadastrar Contato
            </Button>
          </Col>
          {
            modal&&
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}><FaHeadset /> Cadastrar Novo Contato</ModalHeader>
              <ModalBody>
                <CadastrarContatoConvenio idConvenio={convenio.id} toggle={toggle} />
              </ModalBody>
              
            </Modal>
          }
        </Row>
      </CardComponent>
    </Card>
  )
}

const mapStateToProps = store => ({
  user: store.user
})

export default connect(mapStateToProps)(InformacoesConvenio);
