import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { connect } from 'react-redux';
import Page from './Page';
import { useHistory } from 'react-router-dom';

function Controller(props) {

    const authStr = `Bearer ${props.user.user.token}`;
    const [ result, setResult ] = useState("");
    const [ loading, setLoading ] = useState(true);

    let history = useHistory();

    useEffect(
        () => {
            getConvenios();
        },[]
    )

    const getConvenios = async _ => {
        const requestConvenios = await Api.get("convenios/", { headers: { Authorization: authStr }});
        setResult(requestConvenios.data);
        setLoading(false);
    }

    return (
        <Page resultConvenio={result} history={history} loading={loading}/>
    );
}

const mapStateToProps = store => ({
    user: store.user,
  });

export default connect(mapStateToProps)(Controller);