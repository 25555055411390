import React from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

function CustomSkeleton({ color = '#ddd', highlightColor = '#eee', ...rest }) {
  return (
    <SkeletonTheme color={color} highlightColor={highlightColor}>
      <Skeleton {...rest} />
    </SkeletonTheme>
  );
}

export default CustomSkeleton;
