import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { Col, Button } from 'reactstrap';
import TablesGrid from '../../components/TablesGrid/TablesGrid';

export default function Segurados(props) {
  const columns = [
    { name: 'id', title: '#ID', getCellValue: row => parseInt(row.id) },
    { name: 'name', title: 'Nome', getCellValue: row => row.nome },
    { name: 'cpf', title: 'CPF', getCellValue: row => row.cpf },
    { name: 'telefone', title: 'Telefone', getCellValue: row => row.telefone },
    { name: 'cellphone', title: 'Celular', getCellValue: row => row.celular },
    { name: 'email', title: 'E-mail', getCellValue: row => row.email },
    {
      name: 'dependentes',
      title: 'Dependentes',
      getCellValue: row => dependentes(row),
    },
    {
      name: 'nomes_dependentes',
      title: 'Nomes dependentes',
      getCellValue: row => row.nomes_dependentes,
    },
    { name: '', title: '', getCellValue: row => actions(row) },
  ];

  const actions = row => {
    return (
      <div>
        <Button
          color="primary"
          size="sm"
          outline
          onClick={() => props.history.push(`/segurado/${row.id}`)}
        >
          <FaSearch />
        </Button>
      </div>
    );
  };

  const dependentes = row => {
    const nomes = row.nomes_dependentes;
    return <div>{nomes ? nomes.split(',').length : 0}</div>;
    return <div>{nomes}</div>;
  };

  return (
    <Col md={12}>
      {props.segurados.output && props.segurados.output.length > 0 ? (
        <TablesGrid
          rows={props.segurados.output}
          columns={columns}
          hiddenColumns={['nomes_dependentes']}
        />
      ) : (
        <span style={{ color: 'red' }}>{props.segurados.message}</span>
      )}
    </Col>
  );
}
