import React from 'react';
import { Card } from 'reactstrap';
import CadastroClienteIndividual from '../../components/Forms/CadastroClienteIndividual';

export default function ClientRegisterNew(props) {
  return (
    <Card style={{ marginTop: '30px' }}>
      <CadastroClienteIndividual
        mostraInfoValores
        mostraInfoPagamento
        mostraInfoDependentes
        clienteEmpresarial={false}
        {...props}
      />
    </Card>
  );
}
