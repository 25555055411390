import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaPeopleCarry } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import CadastrarAssessoria from '../../views/Assessorias/CadastrarAssessoria';
import Loading from '../../components/Loading/Loading';
import ListaAssessorias from '../../views/Assessorias/ListaAssessorias';

function Page(props) {
  const { assessorias, loading, getAssessorias } = props;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const titleHeader = (
    <>
      <FaPeopleCarry />
      &nbsp;&nbsp;Distribuidores Cadastrados:
    </>
  );
  return (
    <Container fluid>
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
        <Col className="text-right mt-2" md={12}>
          {modal && (
            <CadastrarAssessoria
              modal={modal}
              toggle={toggle}
              getAssessorias={getAssessorias}
              assessorias={assessorias}
              loadingAssessorias={loading}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        {loading ? (
          <Col className="text-center">
            <Loading loading={loading} />
          </Col>
        ) : Object.keys(assessorias.output).length > 0 ? (
          <ListaAssessorias
            assessorias={assessorias.output}
            loading={loading}
            toggle={toggle}
          />
        ) : (
          assessorias.message
        )}
      </Row>
    </Container>
  );
}

export default Page;
