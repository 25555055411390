import Styled from 'styled-components';

export const Quantity = Styled.div`
  background-color:#dc3545;
  border-radius: 9px;
  color:#fff;
  padding-left:9px;
  padding-right:9px;
  margin-left:1rem;
`;
