import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaKey } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import AlterarSenhaUsuario from '../../views/AlterarSenha/AlterarSenhaUsuario';

const titleHeader = <><FaKey/>&nbsp;&nbsp;Alterar Senha</>
export default function Page() {
    return (
        <Container className="mb-4" fluid={true}>
            <Row className="text-left mt-4 mb-4">
                <Col lg={12}>
                    <TitleContent titleHeader={titleHeader} />
                </Col>
            </Row>
            <Row className="text-center">
                <Col md={12}>
                    <AlterarSenhaUsuario/>
                </Col>
            </Row>
        </Container>
    )
}
