import React, { useState } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';

function TooltipStatusFatura(props) {
  const { fatura, style } = props;
  const id = `fatura-tooltip-${fatura.id}`;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      {(fatura.status == 'Baixa Manual') && (
        <>
          <FaQuestionCircle
            href="#" id={id}
            className="tooltip-title"
            style={style}
          />
          <Tooltip
            placement="top-start"
            isOpen={tooltipOpen}
            target={id}
            toggle={toggle}
          >
            <span>{fatura.motivo_baixa_fatura}: {fatura.descricao_baixa_fatura} </span>
          </Tooltip>
        </>
      )}
      {(fatura.status == 'Cancelada' && fatura.motivo_baixa_fatura)  && (
        <>
          <FaQuestionCircle
            href="#" id={id}
            className="tooltip-title"
            style={style}
          />
          <Tooltip
            placement="top-start"
            isOpen={tooltipOpen}
            target={id}
            toggle={toggle}
          >
            <span>{fatura.motivo_baixa_fatura}: {fatura.descricao_baixa_fatura} </span>
          </Tooltip>
        </>
      )}
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(TooltipStatusFatura);
