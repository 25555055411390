import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Api from '../../services/api';
import { useParams, useHistory } from 'react-router-dom';
import Page from './Page';

function Controller(props) {

  const [ resultEmpresas, setResultEmpresas ] = useState({
    cnpj: '',
    nome: '',
    cep: '',
    uf: '',
    numero_endereco: '',
    complemento_endereco: '',
    logradouro: '',
    bairro: '',
    cidade: '',
    id: ''
  });

  let history = useHistory();
  let { id } = useParams();

  useEffect(
    () => {
      getInfosEmpresa();
    }, []
  )

  const getInfosEmpresa = async _ => {
    const request = await Api.get(`empresa/${id}`, { headers: { Authorization: `Bearer ${props.user.user.token}`}} );
    setResultEmpresas(request.data.output);
  }

  return (
    <>
      {
        resultEmpresas&&
        (<Page
          resultEmpresas={resultEmpresas}
          history={history}
          token={props.user.user.token}
          setResultEmpresas={setResultEmpresas}
        />)
      }
    </>
  );
}

const mapStateToProps = store => ({
    user: store.user,
  });

export default connect(mapStateToProps)(Controller);
