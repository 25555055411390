import React, { useState } from 'react';
import { Row, Col, Input, Table, Button } from 'reactstrap';
import { FaUsers, FaRegSave } from 'react-icons/fa';
import { SectionFormHeader } from '../styles';
import Api from '../../services/api';
import { alertError, alertWarning } from '../../components/Alerts/Alerts';
import { connect } from 'react-redux';

let produtoSegurados = [];

function AdicionarProdutoFuncionarioApolice(props) {

  const { funcionarios, produtosAdicionais, produtosNaApolice, idApolice } = props;
  const funcionariosList = funcionarios.output;
  const [loading, setLoading] = useState(false);  

  const adicionarProduto = async _ => {
    setLoading(true);

    if(produtoSegurados.length === 0){
      setLoading(false);
      alertWarning("O produto deve ser selecionado");
      return
    }
    
    produtoSegurados.map(async (segurados, idProduto) => {
      
      const result = await Api.post(`segurado/apolice/${idApolice}/produto/${idProduto}`, {segurados}, { headers: { Authorization: `Bearer ${props.user.user.token}` }});
      
      if(result.data.ok){
        window.location.reload();
      }else{
        alertError(result.data.message)
      }
    });

  }

  const addOrRemoveSeguradoProdutoArray = (idProduto, idSegurado, checked) => {
    
    if (checked) {

      if (produtoSegurados[idProduto]) {
        produtoSegurados[idProduto] = [... produtoSegurados[idProduto], idSegurado]
      } else {
        produtoSegurados[idProduto] = [idSegurado];
      }

    } else {
      produtoSegurados[idProduto].splice( produtoSegurados[idProduto].indexOf(idSegurado), 1 );
    }

  }

  const produtoJaExisteNaApolice = (idProduto) => {
    if (produtosNaApolice[idProduto]) {
      return true;
    }
    return false;
  }

  return (
  
    <>
      <Row className="mt-4">
        <Col className="mb-4" md={12}><SectionFormHeader><FaUsers /> Lista de Funcionários</SectionFormHeader></Col>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>Funcionários</th>
                {
                  produtosAdicionais.output.map((produto, i) => {
                    return (<th key={i}>{produto.nome}</th>)
                  })
                }
              </tr>
            </thead>
            <tbody>
              {

                  funcionariosList&&
                  funcionariosList.map((funcionario, indexFunc) => {

                    return(
                      <tr key={indexFunc}>
                        <td>{funcionario.nome}</td>
                        {
                          produtosAdicionais.output.map((produto, i) => {
                            
                            if (!produtoJaExisteNaApolice(produto.id)) {
                              return (
                                <td key={i} className="text-center">
                                  <Input 
                                    type="checkbox"
                                    defaultChecked={false}
                                    onClick={(e) => addOrRemoveSeguradoProdutoArray(produto.id, funcionario.id, e.target.checked)}
                                  />
                                </td>
                              )
                            }
                            
                            return <td key={i} className="text-center"> Produto já adicionado* </td>
                            
                          })
                        }
                      </tr>
                    );

                })

                
              }
            </tbody>
          </Table>
          
        </Col>
      </Row>
      <Row className="mt-4 text-right">
        <Col>
              {
                loading?
                  <p>Loading</p>
                :
                  <Button size="sm" color="success" onClick={() => adicionarProduto()}><FaRegSave/> Salvar </Button>
              }
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = store => ({
  user: store.user,
})

export default connect(mapStateToProps)(AdicionarProdutoFuncionarioApolice);
