import React, { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Card,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Form,
} from 'reactstrap';
import {
  FaBuilding,
  FaRoad,
  FaInfo,
  FaMapMarkerAlt,
  FaGlobeAmericas,
  FaPlus,
  FaExclamationCircle,
} from 'react-icons/fa';
import InputMask from 'react-input-mask';
import {
  BodyFormComponent,
  SectionFormHeader,
  LabelForm,
  PrependInputLabel,
} from '../styles';
import { msg } from '../../json/msg';
import SubmitBtn from '../../components/Buttons/SubmitBtn';
import Api from '../../services/api';
import EscolhaAssessoria from '../../components/Forms/EscolhaAssessoria';

function CadastroEmpresa(props) {
  const {
    handleOnChange,
    handleCepOnChange,
    idAssessoriaDoCorretor,
    cadastrarEmpresa,
    usuario,
    nivelUsuario,
    alertErrorMsg,
    empresa,
    token
  } = props;

  const authStr = `Bearer ${token}`;

  const [assessoria, setAssessoria] = useState([
    { id: '', name: '', id_usuario: '', tipo: '', id_assessoria_master: '' },
  ]);

  useEffect(() => {
    getAssessoria();
  }, []);

  const getAssessoria = async () => {
    const assessores = await Api.get(`assessorias/`, {
      headers: { Authorization: authStr },
    });

    let assessoresArr = [];
    for (const i in assessores.data.output) {
      const tmpObj = {
        name: assessores.data.output[i].nome,
        id: assessores.data.output[i].id,
        id_usuario: assessores.data.output[i].id_usuario,
        razao_social: assessores.data.output[i].razao_social,
        tipo: assessores.data.output[i].tipo,
        id_assessoria_master: assessores.data.output[i].id_assessoria_master,
      };
      assessoresArr = [...assessoresArr, tmpObj];
    }
    setAssessoria(assessoresArr);
  };

  return (
    <>
      {alertErrorMsg && (
        <Col md={12} className="mt-4">
          <Alert color="danger">
            <FaExclamationCircle />
            &nbsp; {msg.company_error_created}
          </Alert>
        </Col>
      )}

      <Card style={{ marginTop: '30px' }}>
        <BodyFormComponent>
          <Form onSubmit={cadastrarEmpresa}>
            <Row className="section-header">
              <Col>
                <SectionFormHeader>Dados da Empresa</SectionFormHeader>
              </Col>
            </Row>
            <Row className="mb-4">
            <Col md={6}>
              <EscolhaAssessoria
                assessoria={assessoria}
                clientInput={{id: null}}
                handleOnChange={handleOnChange}
                nivelUsuario={nivelUsuario}
                usuario={usuario}
                idAssessoriaDoCorretor={idAssessoriaDoCorretor}
              />
            </Col>
            </Row>
            <Row className="mb-4">
            <Col md={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend"
>
                    <InputGroupText>
                      <PrependInputLabel>CNPJ</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="99.999.999/9999-99"
                    type="text"
                    name="cnpj"
                    value={empresa.cnpj}
                    required
                    onChange={e => handleOnChange(e)}
                    style={{width: '85%'}}

                  />
                </InputGroup>
              </Col>
              <Col md={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaBuilding />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder="Nome da Empresa"
                    name="nome"
                    required
                    value={empresa.nome}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <hr />
            <Row className="section-header mt-4">
              <Col>
                <SectionFormHeader>Informações de Endereço</SectionFormHeader>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={4}>
                <LabelForm>CEP</LabelForm>
                <Input
                  type="text"
                  name="cep"
                  value={empresa.cep}
                  required
                  onChange={e => handleCepOnChange(e)}
                />
              </Col>
              <Col md={6}>
                <LabelForm>&nbsp;</LabelForm>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaRoad />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="logradouro"
                    value={empresa.logradouro}
                    required
                    placeholder="Rua"
                    onChange={e => handleCepOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col md={2}>
                <LabelForm>Nº</LabelForm>
                <Input
                  type="number"
                  name="numero_endereco"
                  value={empresa.numero_endereco}
                  required
                  onChange={e => handleOnChange(e)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <LabelForm>&nbsp;</LabelForm>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaInfo />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="complemento_endereco"
                    placeholder="Complemento"
                    value={empresa.complemento_endereco}
                    required
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col md={3}>
                <LabelForm>&nbsp;</LabelForm>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaMapMarkerAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="bairro"
                    placeholder="Bairro"
                    value={empresa.bairro}
                    onChange={e => handleCepOnChange(e)}
                    required
                  />
                </InputGroup>
              </Col>
              <Col md={3}>
                <LabelForm>&nbsp;</LabelForm>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaMapMarkerAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="cidade"
                    placeholder="Cidade"
                    value={empresa.cidade}
                    onChange={e => handleCepOnChange(e)}
                    required
                  />
                </InputGroup>
              </Col>
              <Col md={2}>
                <LabelForm>&nbsp;</LabelForm>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaGlobeAmericas />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    size="2"
                    maxLength="2"
                    placeholder="uf"
                    name="uf"
                    value={empresa.uf}
                    onChange={e => handleCepOnChange(e)}
                    required
                  />
                </InputGroup>
              </Col>
            </Row>
            <hr />
            <Row className="text-right mt-4">
              <Col md={12}>
                <SubmitBtn
                  label="Cadastrar Empresa"
                  labelOnClick="Salvando"
                  typeSubmit="submit"
                  metodo={cadastrarEmpresa}
                  color="#0A7A73"
                />
              </Col>
            </Row>
          </Form>
        </BodyFormComponent>
      </Card>
    </>
  );
}
export default CadastroEmpresa;
