import React, { useState, useEffect } from 'react';
import {
  Card,
  Container,
  Row,
  Col,
  Input,
  Button,
  Spinner,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';

import {
  FaFileContract,
  FaFile,
  FaRegSave,
  FaWrench,
  FaEdit,
  FaExclamation,
  FaCloudDownloadAlt,
} from 'react-icons/fa';

import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import { DetailPaymentContent, Warning } from './styles';

import { formatDate } from '../../services/service';
import Api from '../../services/api';
import TitleCardPolicies from '../../components/TitleContent/TitleCardPolicies';
import { alertError, alertWarning } from '../../components/Alerts/Alerts';
import MaterialModal from '../../components/Modal/MaterialModal';

const BoxCancelamento = styled.div`
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding-bottom: 15px;
  margin: 20px 15px;
`;

function DetalhesConfigApolice(props) {
  const {
    detalhesApolice,
    configPayment,
    motivoCancelamento,
    idApolice,
    idEmpresa,
    alterarFormaPagamento,
    confirmarSegundaViaCartao,
  } = props;

  const { nivel_usuario } = props.user.user;

  const titleCard = (
    <>
      <h3>
        <FaFileContract />
      </h3>
      &nbsp;&nbsp;Apólice&nbsp;{detalhesApolice.output.id}
    </>
  );
  const [loading, setLoading] = useState(false);
  const [modalCancelamento, setModalCancelamento] = useState(false);
  const [modalAlterarPagamento, setModalAlterarPagamento] = useState(false);
  const [modalConfirmSegundaViaCartao, setModalConfirmSegundaViaCartao] =
    useState(false);

  const [loadingCarta, setLoadingCarta] = useState(false);
  const [loadingAlterarPagamento, setLoadingAlterarPagamento] = useState(false);
  const [loadingSegundaViaCartao, setLoadingSegundaViaCartao] = useState(false);
  const [salvarApoliceEffect, setSalvarApoliceEffect] = useState(false);

  const [apoliceInput, setApoliceInput] = useState({
    status: detalhesApolice.output.status,
    inicioVigencia: detalhesApolice.output.inicio_vigencia,
    periodoVigencia: 12,
    documento: [],
    id_apolice: detalhesApolice.output.id,
    motivo: '',
    anotacaoStatus: '',
    formaPgto: configPayment.output.forma_pagamento,
    proximosPgto: configPayment.output.dia_proximos_pagamentos,
    idUsuario: parseInt(props.user.user.usuario, 10),
  });

  useEffect(() => {
    if (salvarApoliceEffect) {
      salvarApolice();
    }
  }, [salvarApoliceEffect]);

  const renderPayment = () => {
    switch (configPayment.output.forma_pagamento) {
      case 'carne-impresso':
        return 'Carnê';
      case 'cartao-credito':
        return 'Cartão de crédito (assinatura)';
      case 'assinatura-empresarial':
        return 'Assinatura Empresarial';
      default:
        return 'Nenhuma forma de pagamento configurada';
    }
  };

  const salvarApolice = async () => {
    setLoading(true);
    const dataForm = new FormData();

    Object.keys(apoliceInput).map(el => {
      dataForm.append(el, apoliceInput[el]);
    });

    const result = await Api.post(`apolice/${idApolice}/status`, dataForm, {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });

    if (result.data.ok) {
      window.location.reload();
    } else {
      alertError(result.data.message);
    }
    setLoading(false);
    setSalvarApoliceEffect(false);
  };

  const toggleModalCancelamento = () => {
    setModalCancelamento(!modalCancelamento);
  };

  const toggleModalAlterarPagamento = () => {
    setModalAlterarPagamento(!modalAlterarPagamento);
  };

  const toggleModalConfirmSegundaViaCartao = () => {
    // alert('hey');
    setModalConfirmSegundaViaCartao(!modalConfirmSegundaViaCartao);
  };

  const baixaCartaCancelamento = async () => {
    setLoadingCarta(true);
    const result = await Api.get(
      `/apolice/${idApolice}/gerar-carta-cancelamento`,
      { headers: { Authorization: `Bearer ${props.user.user.token}` } },
    );
    if (result.data.ok) {
      window.open(
        `${process.env.REACT_APP_API_ERP}/${result.data.output.path}`,
      );
    } else {
      alertError(`Erro ao baixar a carta: ${result.data.message}`);
    }
    setLoadingCarta(false);
  };

  const baixaCartaoBoasVindas = async () => {
    // console.log(apoliceInput);
    console.log('baixando');
    // setLoadingCarta(true);
    const result = await Api.get(
      `/segurados/apolices/gerar-cartao-boas-vindas?idApolice=${apoliceInput.id_apolice}`,
      { headers: { Authorization: `Bearer ${props.user.user.token}` } },
    );
    if (result.data.ok) {
      window.location.href = `${process.env.REACT_APP_API_ERP}/${result.data.output.path}`;
      // window.open(
      //   `${process.env.REACT_APP_API_ERP}/${result.data.output.path}`,
      // );
    } else {
      alertError(`Erro ao baixar o cartão: ${result.data.message}`);
    }
    // setLoadingCarta(false);
  };

  const handleAlterarPagamento = async () => {
    setLoadingAlterarPagamento(true);
    await alterarFormaPagamento();
    setLoadingAlterarPagamento(false);
    toggleModalAlterarPagamento();
  };

  const handleConfirmSegundaViaCartao = async () => {
    setLoadingSegundaViaCartao(true);
    await confirmarSegundaViaCartao();
    setLoadingSegundaViaCartao(false);
    toggleModalConfirmSegundaViaCartao();
  };

  const confirmCarta = () => {
    const form = document.forms.formCancelar;
    const inputConfirm = form.confirmCarta.checked;
    const motivo = form.motivoCancelamento.value;

    if (motivo == '') {
      alertWarning('Selecione o motivo.');
      return;
    }

    if (!inputConfirm) {
      alertWarning('Você deve confirmar que coletou a assinatura do cliente.');
      return;
    }

    setApoliceInput({ ...apoliceInput, status: 'Cancelada' });

    setSalvarApoliceEffect(true);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Card>
      <ToastContainer />
      <Container>
        <DetailPaymentContent>
          <Form className="formApoliceStatus">
            <Row
              className="mt-4 ml-4 mb-4"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <TitleCardPolicies titleCard={titleCard} />
              {nivel_usuario == 1 && (
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle caret>Ações</DropdownToggle>
                  <DropdownMenu right="false">
                    <DropdownItem onClick={toggleModalConfirmSegundaViaCartao}>
                      Solicitar segunda via do cartão
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
            </Row>

            <Col className="mt-4 text-left">
              <p>
                <span>Distribuidor: </span>
                {detalhesApolice.output.razao_social
                  ? detalhesApolice.output.razao_social
                  : '(Sem nome)'}{' '}
                / {detalhesApolice.output.nome_pessoa}
              </p>
              <p>
                <span>Tipo: </span>
                {detalhesApolice.output.tipo[0]
                  ? detalhesApolice.output.tipo[0].toUpperCase() +
                    detalhesApolice.output.tipo.substr(1)
                  : null}
              </p>
              <p>
                <span>ID Cliente IUGU: </span>
                {configPayment.output.id_cliente_iugu}
              </p>
              <p className="formPgmto">
                <div>
                  <span>Forma de Pagamento: </span> {renderPayment()}
                </div>
                {configPayment.output.forma_pagamento == 'carne-impresso' &&
                  nivel_usuario == 1 && (
                    <FaEdit
                      onClick={toggleModalAlterarPagamento}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
              </p>
              {configPayment.output.forma_pagamento == 'cartao-credito' ? (
                <p>
                  <span>ID Assinatura IUGU: </span>
                  {configPayment.output.id_assinatura_iugu}
                </p>
              ) : null}
              <p className="formPgmto">
                <div>
                  <span>Status:</span> {detalhesApolice.output.status}
                </div>
                {detalhesApolice.output.status == 'Envelope em Produção' ? (
                  <FaCloudDownloadAlt
                    onClick={baixaCartaoBoasVindas}
                    style={{ cursor: 'pointer' }}
                    title="Baixar Cartão de Boas Vindas"
                  />
                ) : null}
              </p>
              <p>
                <span>Quantidade de Parcelas:</span>{' '}
                {configPayment.output.qtd_parcelas}
              </p>
              <p>
                <span>Primeiro Pagamento:</span>{' '}
                {formatDate(configPayment.output.primeiro_pagamento)}
              </p>
              <p>
                <span>Início Vigência:</span>{' '}
                {formatDate(detalhesApolice.output.inicio_vigencia)}
              </p>
              <p>
                <span>Próximos Pagamentos:</span> Dia{' '}
                {configPayment.output.dia_proximos_pagamentos}
              </p>
              <p>
                <span>Dependente Gratuito?</span>{' '}
                {configPayment.output.DEP_1_GRATIS === '0' ? 'Não' : 'Sim'}
              </p>
            </Col>
            {idEmpresa == 1 && (
              <Col md={12}>
                {apoliceInput.status == 'Cancelada' ? (
                  <Button
                    color="danger"
                    disabled
                    style={{ width: '50%' }}
                    onClick={toggleModalCancelamento}
                  >
                    Cancelada
                  </Button>
                ) : (
                  <Button
                    color="danger"
                    style={{ width: '35%', marginRight: '5%' }}
                    onClick={toggleModalCancelamento}
                  >
                    Cancelar Apólice
                  </Button>
                )}
              </Col>
            )}
            <Modal isOpen={modalCancelamento} toggle={toggleModalCancelamento}>
              <ModalHeader toggle={toggleModalCancelamento}>
                Endosso de Cancelamento
              </ModalHeader>
              <ModalBody>
                <form name="formCancelar">
                  <p>
                    O endosso de cancelamento é um conjunto de ações no sistema
                    para cancelar uma apólice.
                  </p>

                  <Col className="mb-4 mt-3 text-left">
                    <p>
                      <span style={{ color: '#f44b3f' }}>
                        <FaFile />
                        &nbsp;&nbsp;Motivo de Cancelamento
                      </span>
                    </p>
                  </Col>
                  <Col>
                    <Input
                      type="select"
                      name="motivoCancelamento"
                      value={apoliceInput.motivo}
                      onChange={e =>
                        setApoliceInput({
                          ...apoliceInput,
                          motivo: e.target.options[e.target.selectedIndex].text,
                        })
                      }
                    >
                      <option value=""> - Selecione - </option>
                      {motivoCancelamento.output &&
                        motivoCancelamento.output.map((motivo, index) => (
                          <option key={index}>{motivo.descricao}</option>
                        ))}
                    </Input>
                  </Col>
                  <Col className="mt-4 text-left">
                    <Input
                      type="textarea"
                      placeholder="Mais informações"
                      value={apoliceInput.anotacaoStatus}
                      onChange={e =>
                        setApoliceInput({
                          ...apoliceInput,
                          anotacaoStatus: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Row className="" style={{ marginTop: '3rem' }}>
                    <Col>
                      <Button
                        size="sm"
                        disabled={loadingCarta}
                        color="info"
                        onClick={baixaCartaCancelamento}
                      >
                        Baixar carta de cancelamento{' '}
                        {loadingCarta && (
                          <Spinner color="secondary" size="sm" />
                        )}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-4 text-left">
                      <p>
                        <span>
                          <FaFile />
                          &nbsp;&nbsp;Anexar Documento
                        </span>
                      </p>
                      <Input
                        type="file"
                        name="documento"
                        onChange={e =>
                          setApoliceInput({
                            ...apoliceInput,
                            documento: e.target.files[0],
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '2rem' }}>
                    <Col md={12}>
                      <Input
                        type="checkbox"
                        style={{ margin: 0 }}
                        name="confirmCarta"
                      />
                      <p style={{ marginLeft: '1rem' }}>
                        Confirmo que imprimi a{' '}
                        <strong style={{ fontWeight: 700 }}>
                          Carta de Cancelamento
                        </strong>{' '}
                        e coletei a assinatura do cliente.
                      </p>
                    </Col>
                  </Row>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={confirmCarta}
                  disabled={loading}
                >
                  {!loading ? (
                    'Confirmar'
                  ) : (
                    <>
                      Confirmar <Spinner color="primary" size="sm" />
                    </>
                  )}
                </Button>
                <Button color="secondary" onClick={toggleModalCancelamento}>
                  Voltar
                </Button>
              </ModalFooter>
            </Modal>
            <hr />
            {props.user.user.nivel_usuario == 1 && (
              <>
                {/* <Row>
                                <Col lg={6} className="mt-4 text-left">
                                    <p><span>Forma de Pagamento</span></p>
                                    <Input type="select" name="formaPgto" value={apoliceInput.formaPgto} onChange={e => setApoliceInput({ ...apoliceInput, formaPgto: e.target.value })}>
                                        <option value="cartao-credito">Assinatura Empresarial</option>
                                        <option value="carne-impresso">Carnê Impresso</option>
                                    </Input>
                                </Col>
                                <Col lg={6} className="mt-4 text-left">
                                    <p><span>Próximos Pagamentos</span></p>
                                    <Input type="select" name="proximosPgto" value={apoliceInput.proximosPgto} onChange={e => setApoliceInput({ ...apoliceInput, proximosPgto: e.target.value })}>
                                        <option value="10">Dia 10</option>
                                        <option value="20">Dia 20</option>
                                        <option value="30">Dia 30</option>
                                    </Input>
                                </Col>
                            </Row> */}
                <Col className="mt-4 text-left">
                  <p>
                    <span>Status Apólice</span>
                  </p>
                  <Input
                    type="select"
                    name="status"
                    value={apoliceInput.status}
                    onChange={e =>
                      setApoliceInput({
                        ...apoliceInput,
                        status: e.target.value,
                      })
                    }
                  >
                    <option>Não preenchida</option>
                    <option>Proposta Aprovada</option>
                    <option>Aguardando primeiro pagamento </option>
                    <option>Aguardando confirmação de e-mail para trial</option>
                    <option>Pedido de Cartão</option>
                    <option>Envelope em Produção</option>
                    <option>Cartão Enviado</option>
                    <option>Em Vigência</option>
                    <option>Renovada</option>
                    <option>Suspensa</option>
                    <option>Cancelada</option>
                    <option>Upsell</option>
                    <option>Declinada por desistência</option>
                    <option>Expirada</option>
                  </Input>
                </Col>
              </>
            )}
            {props.user.user.nivel_usuario == 1 &&
              apoliceInput.status === 'Cancelada' && (
                <BoxCancelamento>
                  <Col className="mb-4 mt-3 text-left">
                    <p>
                      <span style={{ color: '#f44b3f' }}>
                        <FaFile />
                        &nbsp;&nbsp;Motivo de Cancelamento
                      </span>
                    </p>
                  </Col>
                  <Col>
                    <Input
                      type="select"
                      name="motivoCancelamento"
                      value={apoliceInput.motivo}
                      onChange={e =>
                        setApoliceInput({
                          ...apoliceInput,
                          motivo: e.target.options[e.target.selectedIndex].text,
                        })
                      }
                    >
                      <option value=""> - Selecione - </option>
                      {motivoCancelamento.output &&
                        motivoCancelamento.output.map((motivo, index) => (
                          <option key={index}>{motivo.descricao}</option>
                        ))}
                    </Input>
                  </Col>
                  <Col className="mt-4 text-left">
                    <Input
                      type="textarea"
                      placeholder="Mais informações"
                      value={apoliceInput.anotacaoStatus}
                      onChange={e =>
                        setApoliceInput({
                          ...apoliceInput,
                          anotacaoStatus: e.target.value,
                        })
                      }
                    />
                  </Col>
                </BoxCancelamento>
              )}
            {props.user.user.nivel_usuario == 1 && (
              <>
                <Col className="mt-4 text-left">
                  <p>
                    <span>
                      <FaFile />
                      &nbsp;&nbsp;Documento
                    </span>
                  </p>
                  <Input
                    type="file"
                    name="documento"
                    onChange={e =>
                      setApoliceInput({
                        ...apoliceInput,
                        documento: e.target.files[0],
                      })
                    }
                  />
                </Col>
                <Col className="mt-4 mb-4 text-left">
                  {loading ? (
                    <Spinner color="primary" />
                  ) : (
                    <Button
                      outline
                      color="success"
                      size="sm"
                      onClick={() => salvarApolice()}
                    >
                      <FaRegSave />
                      &nbsp;&nbsp;Salvar
                    </Button>
                  )}
                </Col>
              </>
            )}
          </Form>
        </DetailPaymentContent>
        <MaterialModal
          toggle={toggleModalAlterarPagamento}
          modal={modalAlterarPagamento}
          handleConfirm={handleAlterarPagamento}
          loading={loadingAlterarPagamento}
          title="Alterar Forma de Pagamento"
        >
          <>
            Tem certeza que deseja alterar a forma de pagamento para assinatura
            cartão de crédito?
            <Warning>
              <FaExclamation />
              <p>
                Aviso: ao confirmar esta ação todas as faturas desta apólice
                serão canceladas
              </p>
            </Warning>
          </>
        </MaterialModal>
        <MaterialModal
          toggle={toggleModalConfirmSegundaViaCartao}
          modal={modalConfirmSegundaViaCartao}
          handleConfirm={handleConfirmSegundaViaCartao}
          loading={loadingSegundaViaCartao}
          title="Pedido de segunda via do cartão"
        >
          <>
            Tem certeza que deseja solicitar a segunda via do cartão?
            <Warning>
              <FaExclamation />
              <p>
                Aviso: ao confirmar esta ação será enviado um email para a LIAM,
                solicitando um novo cartão.
              </p>
            </Warning>
          </>
        </MaterialModal>
      </Container>
    </Card>
  );
}

const mapStateToProp = store => ({
  user: store.user,
});

export default connect(mapStateToProp)(DetalhesConfigApolice);
