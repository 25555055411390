import React, { useState } from 'react';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  FormGroup,
} from 'reactstrap';
import {
  FaShoppingCart,
  FaRegSave,
  FaUser,
  FaCalendarAlt,
  FaRegKissWinkHeart,
  FaPhone,
  FaMobileAlt,
  FaEnvelope,
  FaRoad,
  FaInfo,
  FaMapMarkerAlt,
  FaMapMarkedAlt,
  FaGlobeAmericas,
  FaSearch,
} from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import * as cep from 'cep-promise';
import { getAddress } from 'address-br';

import { alertError, alertSuccess } from '../../components/Alerts/Alerts';
import Api from '../../services/api';
import {
  BodyFormComponent,
  LabelForm,
  PrependInputLabel,
  SectionFormHeader,
} from '../styles';

function CadastrarCorretorRepresentante(props) {
  const { modalCorretor, toggleModalCorretor, assessorias, gruposVenda } =
    props;
  const { nivel_usuario, usuario } = props.user.user;
  console.log('nivel_usuario - ', nivel_usuario);

  const [corretorInput, setCorretorInput] = useState({
    idAssessoria: '',
    nome: '',
    cpf: '',
    data_nascimento: '',
    estado_civil: '',
    sexo: '',
    telefone: '',
    celular: '',
    email: '',
    cep: '',
    logradouro: '',
    numero_endereco: '',
    complemento_endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    tipo: '',
    id_grupo_vendas: '',
  });
  const handleOnChange = e => {
    const { name, value } = e.target;

    if (name === 'cep') {
      const cepOnlyNumbers = value.replace(/\D/g, '');
      if (cepOnlyNumbers.length <= 8) {
        setCorretorInput({ ...corretorInput, [name]: cepOnlyNumbers });
      }
    } else {
      setCorretorInput({ ...corretorInput, [name]: value });
    }
  };
  const cadastrarCorretorRepresentanteForm = async _ => {
    const result = await Api.post('corretor-representante', corretorInput, {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });
    if (result.data.ok) {
      alertSuccess('Corretor Inserido com Sucesso!');
      toggleModalCorretor();
    } else {
      const errorMessageDefault = (
        <div>Algo deu errado ao salvar corretor!</div>
      );
      let errorMessage = '';
      if (result.data.message) {
        errorMessage = result.data.message;
      }
      alertError(
        <div>
          <div>{errorMessageDefault}</div>
          <br />
          <div>{errorMessage}</div>
        </div>,
      );
    }
  };

  const handleCheckbox = e => {
    const { checked } = e.target;
    if (checked == true) {
      setCorretorInput({ ...corretorInput, id_grupo_vendas: 1 });
    } else {
      setCorretorInput({ ...corretorInput, id_grupo_vendas: 0 });
    }
  };

  const updateAdress = () => {
    const cepOnlyNumbers = corretorInput.cep.replace(/\D/g, '');
    getAddress(cepOnlyNumbers).then(vl => {
      setCorretorInput({
        ...corretorInput,
        cep: corretorInput.cep,
        logradouro: vl.rua || '',
        cidade: vl.cidade || '',
        bairro: vl.bairro || '',
        uf: vl.estado || '',
      });
    });
  };

  return (
    <Modal isOpen={modalCorretor} toggle={toggleModalCorretor} size="lg">
      <ToastContainer />
      <ModalHeader toggle={toggleModalCorretor}>
        <FaShoppingCart />
        &nbsp;Cadastrar Corretor Representante
      </ModalHeader>
      <ModalBody>
        <BodyFormComponent>
          <Row className="mt-4">
            <Col lg={12}>
              <LabelForm>Distribuidor *</LabelForm>
              <Input
                type="select"
                name="idAssessoria"
                onChange={e => handleOnChange(e)}
              >
                <option value=""> Selecione </option>
                {Object.keys(assessorias.output).length > 0 &&
                  assessorias.output.map((el, i) => {
                    if (nivel_usuario == 2 || nivel_usuario == 5) {
                      if (el.id_usuario == usuario)
                        return (
                          <option key={i} value={el.id_assessoria}>
                            {el.nome}
                          </option>
                        );
                    } else {
                      return (
                        <option key={i} value={el.id_assessoria}>
                          {el.nome}
                        </option>
                      );
                    }
                  })}
              </Input>
            </Col>
          </Row>
          <hr />
          <Row className="mt-4 section-header">
            <Col>
              <SectionFormHeader>Informações do Corretor</SectionFormHeader>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={8}>
              <LabelForm>Nome *</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaUser />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="nome"
                  placeholder="Informe o nome"
                  required
                  value={corretorInput.nome}
                  onChange={e => handleOnChange(e)}
                />
              </InputGroup>
            </Col>
            <Col lg={4}>
              <LabelForm>CPF *</LabelForm>

              <InputGroup>
                <InputMask
                  mask="999.999.999-99"
                  placeholder="999.999.999-99"
                  name="cpf"
                  value={corretorInput.cpf}
                  onChange={e => handleOnChange(e)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={4}>
              <LabelForm>Data de Nascimento *</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaCalendarAlt />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="date"
                  required
                  name="data_nascimento"
                  value={corretorInput.data_nascimento}
                  onChange={e => handleOnChange(e)}
                />
              </InputGroup>
            </Col>
            <Col lg={4}>
              <LabelForm>Estado Civil *</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaRegKissWinkHeart />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="select"
                  name="estado_civil"
                  value={corretorInput.estado_civil}
                  onChange={e => handleOnChange(e)}
                >
                  <option value="">Selecione</option>
                  <option>Solteiro(a)</option>
                  <option>Casado(a) /união estável</option>
                  <option>Divorciado(a)</option>
                  <option>Viúvo(a)</option>
                </Input>
              </InputGroup>
            </Col>
            <Col lg={4}>
              <LabelForm>Sexo *</LabelForm>
              <Input
                type="select"
                name="sexo"
                value={corretorInput.sexo}
                onChange={e => handleOnChange(e)}
              >
                <option value="">Selecione</option>
                <option>Masculino</option>
                <option>Feminino</option>
              </Input>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={4}>
              <LabelForm>Telefone *</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaPhone />
                  </InputGroupText>
                </InputGroupAddon>
                <InputMask
                  mask="(99) 99999-9999"
                  placeholder="(99) 99999-9999"
                  required
                  name="telefone"
                  value={corretorInput.telefone}
                  onChange={e => handleOnChange(e)}
                  className="form-control"
                />
              </InputGroup>
            </Col>

            <Col lg={4}>
              <LabelForm>Telefone 2</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaMobileAlt />
                  </InputGroupText>
                </InputGroupAddon>
                <InputMask
                  mask="(99) 99999-9999"
                  placeholder="(99) 99999-9999"
                  name="celular"
                  value={corretorInput.celular}
                  onChange={e => handleOnChange(e)}
                  className="form-control"
                />
              </InputGroup>
            </Col>
            <Col lg={4}>
              <LabelForm>Email *</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaEnvelope />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="email"
                  required
                  placeholder="email@email.com.br"
                  name="email"
                  value={corretorInput.email}
                  onChange={e => handleOnChange(e)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="section-header mt-4">
            <Col>
              <SectionFormHeader>Informações de Endereço</SectionFormHeader>
            </Col>
          </Row>
          <Row className="mb-4 mt-4">
            <Col lg={4}>
              <LabelForm>CEP *</LabelForm>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Informe o CEP (sem pontuação)"
                  name="cep"
                  value={corretorInput.cep}
                  onChange={e => handleOnChange(e)}
                />
                <Button size="sm" onClick={() => updateAdress()}>
                  <FaSearch />
                </Button>
              </InputGroup>
            </Col>
            <Col lg={6}>
              <LabelForm>Rua *</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaRoad />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Informe a rua"
                  name="logradouro"
                  value={corretorInput.logradouro}
                  onChange={e => handleOnChange(e)}
                />
              </InputGroup>
            </Col>
            <Col lg={2}>
              <LabelForm>Número *</LabelForm>
              <InputGroup>
                <Input
                  placeholder="000"
                  name="numero_endereco"
                  value={corretorInput.numero_endereco}
                  onChange={e => handleOnChange(e)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={3}>
              <LabelForm>Complemento *</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaInfo />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Complemento"
                  name="complemento_endereco"
                  value={corretorInput.complemento_endereco}
                  onChange={e => handleOnChange(e)}
                />
              </InputGroup>
            </Col>
            <Col lg={3}>
              <LabelForm>Bairro *</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaMapMarkerAlt />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Bairro"
                  name="bairro"
                  value={corretorInput.bairro}
                  onChange={e => handleOnChange(e)}
                />
              </InputGroup>
            </Col>
            <Col lg={3}>
              <LabelForm>Cidade *</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaMapMarkedAlt />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Cidade"
                  name="cidade"
                  value={corretorInput.cidade}
                  onChange={e => handleOnChange(e)}
                />
              </InputGroup>
            </Col>
            <Col lg={3}>
              <LabelForm>Estado *</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaGlobeAmericas />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Estado"
                  name="uf"
                  value={corretorInput.uf}
                  onChange={e => handleOnChange(e)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="section-header mt-4">
            <Col>
              <SectionFormHeader>Tipo de Corretor *</SectionFormHeader>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={12}>
              <Label>
                <Input
                  type="radio"
                  name="tipo"
                  value="3"
                  onChange={e => handleOnChange(e)}
                />
                Este corretor{' '}
                <span style={{ fontWeight: '700', color: 'red' }}> NÃO</span>{' '}
                poderá fazer VENDAS EMPRESARIAIS
              </Label>
            </Col>
            <Col lg={12}>
              <Label>
                <Input
                  type="radio"
                  name="tipo"
                  value="4"
                  onChange={e => handleOnChange(e)}
                />
                Este corretor poderá fazer VENDAS EMPRESARIAIS
              </Label>
            </Col>
          </Row>
          {gruposVenda != undefined && (
            <Row className="section-header mt-4">
              <Col>
                <SectionFormHeader>Grupos de Vendas *</SectionFormHeader>
              </Col>
            </Row>
          )}
          <Row className="mt-4">
            {gruposVenda != undefined &&
              gruposVenda.map((gpVenda, i) => {
                return (
                  <Col lg={4} key={i} className="mt-2 mb-4">
                    <FormGroup check>
                      <Label check>
                        <Input
                          id={gpVenda.id}
                          name="id_grupo_vendas"
                          type="checkbox"
                          onChange={e => handleCheckbox(e)}
                        />{' '}
                        {gpVenda.nome}
                      </Label>
                    </FormGroup>
                  </Col>
                );
              })}
          </Row>
        </BodyFormComponent>
      </ModalBody>
      <ModalFooter>
        <Row className="text-right">
          <Col>
            <Button color="danger" size="sm" onClick={toggleModalCorretor}>
              Fechar
            </Button>
            &nbsp;
            <Button
              color="success"
              size="sm"
              onClick={() => cadastrarCorretorRepresentanteForm()}
            >
              <FaRegSave />
              &nbsp;Salvar
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(CadastrarCorretorRepresentante);
