import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import { Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Store } from './store';
import './global.css';

import indexRoutes from './routes/index';
import Login from './controllers/Login/Controller';
import api from './services/api';
import { alertError } from './components/Alerts/Alerts';
import { clearState } from './localStorage';
import { clearUser } from './actions/user';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const isTokenExpired = urlParams.get('expired');

if (isTokenExpired == 1) {
  alertError('Token expirado, faça o login novamente');
}

const hist = createBrowserHistory();
let tokenUsuarioLogado = '';

const validate = async () => {
  if (JSON.parse(localStorage.getItem('state')) !== null) {
    tokenUsuarioLogado = JSON.parse(localStorage.getItem('state')).user.user
      .token;

    try {
      const response = await api.get('/usuario/validar-token', {
        headers: { Authorization: `Bearer ${tokenUsuarioLogado}` },
      });

      if (!response.data.ok) {
        throw new Error('Token expirado, faça o login novamente');
      }
    } catch (error) {
      clearUser();
      clearState();
      window.location.href = '/login?expired=1';
    }
  }
};

validate();

ReactDOM.render(
  <Provider store={Store}>
    <Router history={hist}>
      <ToastContainer />
      <Switch>
        {indexRoutes.map((prop, key) => {
          if (tokenUsuarioLogado === undefined || tokenUsuarioLogado === '') {
            return (
              <Route path={prop.path} to="/login" key={key} component={Login} />
            );
          }
          if (prop.redirect)
            return <Redirect exact from={prop.path} to={prop.to} key={key} />;
          return (
            <Route path={prop.path} key={key} component={prop.component} />
          );
        })}
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
