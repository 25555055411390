import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaSearch,
  FaFileInvoiceDollar,
  FaLink,
  FaWhatsapp,
} from 'react-icons/fa';
import { Box } from '@material-ui/core';
import { FormatValorAtrasadoFatura } from '../styles';
import { formatDate } from '../../services/service';
import formatValueToPrice from '../../utils/format';
import MaterialTableServerPagination from '../../components/TablesGrid/MaterialTableServerPagination';
import MaterialTable from '../../components/TablesGrid/MaterialTable';

function FaturasPendentes(props) {
  const { faturas, loading, setPage, rowsQuantity } = props;

  const columns = [
    {
      field: 'nome_segurado',
      headerName: 'Cliente',
      width: 175,
    },
    {
      field: 'valor',
      headerName: 'Valor',
      type: 'number',
      width: 150,
      valueFormatter: ({ value }) => formatValueToPrice(value),
    },
    {
      field: 'data_vencimento',
      headerName: 'Vencimento',
      type: 'date',
      width: 150,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      field: 'nome_consultor',
      headerName: 'Consultor',
      width: 150,
    },
    {
      field: '',
      headerName: 'Link Boleto',
      width: 150,
      sortable: false,
      renderCell: params => (
        <a
          href={params.getValue(params.id, 'link_arquivo')}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaLink />
          &nbsp;Clique Aqui
        </a>
      ),
    },
    {
      field: 'apolice',
      headerName: 'Apólice',
      width: 150,
      sortable: false,
      renderCell: params => (
        <div>
          <Link to={`/segurado/${params.getValue(params.id, 'id_segurado')}`}>
            <FaSearch /> Ver mais
          </Link>
          &nbsp;
        </div>
      ),
    },
    {
      field: 'link_whats',
      headerName: 'Link Whats',
      sortable: false,
      renderCell: params => {
        const numeroTratado = params
          .getValue(params.id, 'telefone')
          .replace(/[()-]/g, '');
        const primeiroNome = params
          .getValue(params.id, 'nome_segurado')
          .split(' ')[0];
        const nomeTratadoPascal =
          primeiroNome.charAt(0).toUpperCase() +
          primeiroNome.substr(1).toLowerCase();

        const anoMesDia = params
          .getValue(params.id, 'data_vencimento')
          .split('-');

        const valor = formatValueToPrice(params.getValue(params.id, 'valor'));

        const data = new Date(
          Date.UTC(anoMesDia[0], Number(anoMesDia[1]) - 1, anoMesDia[2], 12),
        ).toLocaleDateString('pt-BR');

        const mensagem = `Oi ${nomeTratadoPascal}!%0a%0aMe chamo ${
          props.user.user.primeiro_nome_usuario
        }, do Plano Viver, tudo bem?%0a%0a*A fatura deste mês do Viver aparece em aberta no sistema. Aconteceu algo que eu possa lhe ajudar?*%0a%0aPra *te ajudar* estou te enviando o *link para pagamento* do seu boleto do dia ${data}, no valor de ${valor}, que *encontra-se em aberto*, ok?%0a%0aLembre-se de manter suas *faturas em dia*, para poder utilizar seu plano e *cuidar da sua saúde sempre que precisar!*%0a%0a*Se preferir, podemos alterar a assinatura do plano para o seu cartão de crédito*. Assim ele vem automaticamente todos os meses sem consumir o seu limite anual.%0a%0aCaso, já tenha realizado o pagamento, gentileza desconsiderar a cobrança.%0a%0a${params.getValue(
          params.id,
          'link_arquivo',
        )}`;

        return (
          <a
            href={`https://web.whatsapp.com/send?1=pt_BR&phone=55${numeroTratado}&text=${mensagem}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaWhatsapp />
            &nbsp;Enviar no Whats
          </a>
        );
      },
      width: 175,
    },
  ];

  return (
    <Box m={2}>
      <MaterialTable rows={faturas} columns={columns} loading={loading} />
      {/* <MaterialTableServerPagination
        rows={faturas}
        columns={columns}
        loading={loading}
        setPage={setPage}
        rowsQuantity={rowsQuantity}
      /> */}
      <FormatValorAtrasadoFatura>
        <p>Valor Total: </p>
        <h2>
          <FaFileInvoiceDollar />
          <span>
            {formatValueToPrice(
              faturas.reduce((acc, current) => acc + current.valor, 0),
            )}
          </span>
        </h2>
      </FormatValorAtrasadoFatura>
    </Box>
  );
}

export default FaturasPendentes;
