import React,{ useState } from 'react';
import Page from './Page';
import { connect } from 'react-redux';
import Api from '../../services/api';
import { useParams, useHistory } from 'react-router-dom';
import { msg } from '../../json/msg';
import { alertError } from '../../components/Alerts/Alerts';
import { ToastContainer } from 'react-toastify';

function Controller(props) {

  let { idEmpresaCadastro } = useParams();
  let history = useHistory();
  const { token } = props.user.user;
  const { empresaInfo } = props.location.state;
  const [ loading, setLoading ] = useState(false);
  const [ direcionaCadastroFunc, setDirecionaCadastroFunc ] = useState(false);

  const [ contato, setContato ] = useState({
    nome_responsavel: '',
    telefone: '',
    celular: '',
    email: '',
    id_estipulante: idEmpresaCadastro
  });

  const authStr = `Bearer ${props.user.user.token}`;

  const handleContatoOnChange = e => {
    const { name, value } = e.target;
    setContato({ ...contato, [name]: value });
  }

  const cadastrarContatoForm = async e => {
    
    setLoading(true);
    // e.preventDefault();

    const result = await Api.post('empresa/contato', contato , { headers: { Authorization: authStr }});

    if(result.data.ok){
      
      setDirecionaCadastroFunc(true);
      // history.push({ pathname: `/cadastrar-cliente-individual`, state: {
      //   mostraInfoPagamento: false,
      //   mostraInfoValores: false,
      //   mostraInfoEndereco: false,
      //   mostraInfoDependente: false,
      //   clienteEmpresarial: true,
      //   idEmpresa: idEstipulante,
        
      // } });
    }else{
      return (
        alertError(result.data.message)
      )
    }
    setLoading(false);
  }

  return (
    <div>
      <ToastContainer />
      
      <Page
        direcionaCadastroFunc={direcionaCadastroFunc}
        idEstipulante={idEmpresaCadastro}
        handleContatoOnChange={handleContatoOnChange}
        contato={contato}
        cadastrarContatoForm={cadastrarContatoForm}
        setDirecionaCadastroFunc={setDirecionaCadastroFunc}
        empresaInfo={empresaInfo}
        token={token}
        loading={loading}
      />
    </div>
  )
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
