import React, { useCallback, useState } from 'react';
import { Card, Row, Table, Form, Col, Input, Button, Spinner } from 'reactstrap';
import { FaFileContract, FaExclamationTriangle, FaPlusCircle } from 'react-icons/fa';
import { connect } from 'react-redux';

import { CardComponent, OverflowContainer, BodyFormComponent } from '../styles';
import { formatDate } from '../../services/service';
import Api from '../../services/api';
import { alertError, alertWarning } from '../../components/Alerts/Alerts';
import Loading from '../../components/Loading/Loading';

function Anotacoes(props) {
    const { anotacoes, idApolice, getAnotacoes } = props;
    const [ anotacaoInput, setAnotacaoInput ] = useState({
        descricao: '',
        idApolice: idApolice,
        idUsuario: props.user.user.usuario
    });
    const [loading, setLoading] = useState(false)
    
    const authStr = `Bearer ${props.user.user.token}`;

    const salvarAnotacao = useCallback(async () => {
        setLoading(true);

        if(anotacaoInput.descricao === '') {
            alertWarning('Insira alguma descrição');
            setLoading(false);
            return;
        }

        try {
            const response = await Api.post(`anotacao`, anotacaoInput, { headers: { Authorization: authStr }});
            if(response.data.ok) {
                getAnotacoes();
                setAnotacaoInput({
                    descricao: '',
                    idApolice: idApolice,
                    idUsuario: props.user.user.usuario
                });
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            alertError("Erro ao salvar anotação: ",error.message);
        }
        setLoading(false);
    },[anotacaoInput])

    return (
    <Card className="mb-4">
        <CardComponent className="mb-3">
            <Col className="header-content text-left">
                <h2><FaFileContract className="mr-2"/>Anotações</h2>
            </Col>
        </CardComponent>
        <OverflowContainer>
            {
                !anotacoes.loading
                ?
                    anotacoes.error
                    ?
                    <div className="ml-2 mb-4 text-left">
                        <span style={{color: "red"}}><FaExclamationTriangle/> {anotacoes.error}</span>
                    </div>
                    :
                        <Table>
                            <thead className="text-left">
                                <tr>
                                    {/* <th>Id Apólice</th> */}
                                    <th className="text-center">Descrição</th>
                                    <th>Data Criação</th>
                                    <th>Hora</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    anotacoes.data.length
                                    ?
                                    anotacoes.data.map((el, i) => { 
                                        let date = el.created.split(' ')[0];
                                        let time = el.created.split(' ')[1];                                   
                                        return(<tr key={i}>
                                            {/* <td>{el.id_apolice}</td> */}
                                            <td>{el.descricao}</td>
                                            <td>{formatDate(date)}</td>
                                            <td>{time}</td>
                                        </tr>);
                                    })
                                    :
                                    <tr>
                                        <td>Sem anotações</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                :
                <Loading></Loading>
            }
        </OverflowContainer>
        <Row className="text-right">
            <Col>
                <BodyFormComponent>
                    <Form>
                        <Col>
                            <Input 
                                type="textarea" 
                                required 
                                name="anotacaoInput" 
                                value={anotacaoInput.descricao} 
                                onChange={e => setAnotacaoInput({...anotacaoInput, descricao: e.target.value})}
                                placeholder="Descrição..."
                            />
                        </Col>
                        <Row className="text-right mt-2">
                            <Col >
                                <Button 
                                    size="sm" 
                                    color="success" 
                                    onClick={() => salvarAnotacao()}
                                    disabled={loading}
                                    outline
                                >
                                    {
                                        loading
                                        ?
                                            <>
                                                <Spinner className="mr-1" size="sm"></Spinner>
                                                Adicionando...
                                            </>
                                        :
                                            <>
                                                <FaPlusCircle className="mr-1"></FaPlusCircle>
                                                Adicionar anotação
                                            </>
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </BodyFormComponent>
            </Col>
        </Row>
    </Card>
  );
}

const mapStateToProps = (store) => ({
    user: store.user,
})

export default connect(mapStateToProps)(Anotacoes);