import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FaArrowLeft, FaBuilding, FaFileDownload } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import FuncionariosProdutosEmpresa from '../../views/EmpresaFuncionarios/FuncionariosProdutosEmpresa';
import CadastrarNovaApolice from '../../views/Empresa/CadastrarNovaApolice';

function Page(props) {
  const { history, idEstipulante, infosFuncionarios, resultBuscaProd, token, assessoria, getApolices } = props;
  const titleHeader = <><FaBuilding/>&nbsp;&nbsp;Funcionários </>;
  return (
    <Container className="mb-4" fluid={true}>
      <Row className="mt-4">
        <Col md={2}>
          <Button className="btn-back w-100" outline color="danger" size="sm" onClick={() => history.push(`/empresa/${idEstipulante}`)}>
            <FaArrowLeft/>&nbsp;&nbsp;Voltar
          </Button>
        </Col>
      </Row>
      <Row className="text-left mt-4 mb-4">
        <Col lg={12}>
          <TitleContent titleHeader={titleHeader}/>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {
            (infosFuncionarios.output != undefined)&&
            <FuncionariosProdutosEmpresa 
              resultBuscaProd={resultBuscaProd} 
              infosFuncionarios={infosFuncionarios}
              history={history}
              idEstipulante={idEstipulante}
            />
          }
        </Col>
      </Row>
      <Row>
        <Col>
        {
          resultBuscaProd&&
          <Col md={6} className="mt-2">
            <CadastrarNovaApolice 
              assessoria={assessoria} 
              // handleOnChange={handleOnChange} 
              // vendedor={vendedor} 
              // disabled={disabled} 
              // clientInput={clientInput}
              resultBuscaProd={resultBuscaProd}
              // cadastrarApolice={cadastrarApolice}
              // msgError={msgError}
              idEstipulante={idEstipulante}
              token={token}
              // infosContatos={infosContatos}
              getApolices={getApolices}
              history={history}
            />
          </Col>
        } 
        </Col>
      </Row>
    </Container>    
  )
}

export default Page;