import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Form, Button, Breadcrumb, BreadcrumbItem, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { FaUsers, FaSearch, FaRegFileArchive } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SubmitBtn from '../../components/Buttons/SubmitBtn';
import { ToastContainer } from 'react-toastify';
import { alertError, alertSuccess } from '../../components/Alerts/Alerts';
import Api from '../../services/api';

function AlterarFuncionariosApolice(props) {

  const { 
    produtosSegurados, 
    funcionariosList, 
    policieConfigPayment, 
    produtos, 
    idApolice, 
    funcAtivos, 
    funcInativos 
  } = props;

  const [ stepHistorico, setStepHistorico ] = useState(false);
  const [ activeTab, setActiveTab ] = useState('1');
  const [ disabledBtn, setDisabledBtn ] = useState(false);

  let history = useHistory();
  let idUsuario = props.user.user.usuario;
  let idProduto = produtos.id;
  let descontoDep = 0;

  const [ loadingSpinner, setLoadingSpinner ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [ seguradosAdicionados, setSeguradosAdicionados ] = useState(produtosSegurados);

  const handleOnChange = (funcionarioId) => {
    
    let incluso = false;
    let posicaoSegurado;

    seguradosAdicionados.map((seguradoAdicionado, index) => {
      if(seguradoAdicionado.id_segurado == funcionarioId) {
        posicaoSegurado = index;
        incluso = true;
        return;
      }
    })

    let produtoSeguradoIdLast = seguradosAdicionados;

    if (incluso) {
      produtoSeguradoIdLast.splice(posicaoSegurado, 1);
    } else {
      produtoSeguradoIdLast.push({id_segurado:funcionarioId});
    }

    setSeguradosAdicionados(produtoSeguradoIdLast);
    setLoading(true);
  }

  const renderInputCheckbox = funcionarioId => {

    let incluso = false;
    
    seguradosAdicionados.map(seguradoAdicionado => {
      if(seguradoAdicionado.id_segurado == funcionarioId) {
        incluso = true;
        return;
      }
    })

    return(
      <Input
        type="checkbox"
        name="segurados[]"
        value={funcionarioId}
        defaultChecked={incluso}
        onChange={() => handleOnChange(funcionarioId) }
      />
    )
  }

  const alterarFuncionarioApolice = async e => {

    e.preventDefault();
    

    setLoadingSpinner(true);
    
    const authStr = `Bearer ${props.user.user.token}`;
    let segurados = [];

    for(const i in seguradosAdicionados){
      segurados = [...segurados, seguradosAdicionados[i].id_segurado];
    }

    let data = { "id_apolice": idApolice, "segurados": segurados, "id_produto": idProduto  }

    const result = await Api.post("apolice/produto-segurado/editar", data ,{ headers: { Authorization: authStr }});

    if(result.data.ok){
      alertSuccess(result.data.message);
      window.location.reload();
    }else{
      alertError(result.data.message);
    }
    setLoadingSpinner(false);
  }

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const printBreadCrambs = _ => (
    <div>
        <Breadcrumb>
            <BreadcrumbItem onClick={() => setStepHistorico(false)}><a href="#">Lista Funcionários</a></BreadcrumbItem>
            <BreadcrumbItem><a href="#">Histórico</a></BreadcrumbItem>
        </Breadcrumb>
    </div>
  )

  const inativarFuncionario = async funcionarioId => {
    setDisabledBtn(true)
    const authStr = `Bearer ${props.user.user.token}`;
    const postData = {
      "id_apolice": idApolice,
      "id_produto": idProduto,
      "id_segurado": funcionarioId,
      "id_usuario": idUsuario
    }
    
    const response = await Api.post("apolice/produto-segurado/inativar", postData, { headers: { Authorization: authStr }});

    if(response.data.ok){
      alertSuccess(response.data.message);
      toggle('0');
      window.location.reload();
    }else{
      alertError(response.data.message);
    }
    
    setDisabledBtn(false)
  }

  const ativarFuncionario = async funcionarioId => {
    setDisabledBtn(true)
    const authStr = `Bearer ${props.user.user.token}`;
    const postData = {
      "id_apolice": idApolice,
      "id_produto": idProduto,
      "id_segurado": funcionarioId,
      "id_usuario": idUsuario
    }
    
    const response = await Api.post("apolice/produto-segurado/ativar", postData, { headers: { Authorization: authStr }});

    if(response.data.ok){
      alertSuccess(response.data.message);
      toggle('1');
      window.location.reload();
    }else{
      alertError(response.data.message);
    }
    
    setDisabledBtn(false)
  }

  const renderFuncionariosTable = funcionariosList => {
    
    return(
      <Table>
        <thead>
        <tr>
            <th></th>
            <th>Nome</th>
            <th>Qtd. Dep. 1</th>
            <th>Qtd. Titular 2</th>
            <th>Qtd. Dep. 1 Grátis</th>
            <th>Preço total</th>
        </tr>
        </thead>
        <tbody>
        
            {
            funcionariosList.map((funcionario, i) => {
                
                if(policieConfigPayment){
                    if(policieConfigPayment.DEP_1_GRATIS === 1 && policieConfigPayment.qtd_dependentes_tipo_1 >= 1) {
                        descontoDep = produtos.preco_dependente_tipo_1;
                    }
                }
                
                let valor = parseFloat(produtos.preco) + (parseFloat(produtos.preco_dependente_tipo_1) * parseFloat(funcionario.qtd_dependentes_tipo_1)) + (parseFloat(produtos.preco_dependente_tipo_2) * parseFloat(funcionario.qtd_dependentes_tipo_2));
                valor = valor - descontoDep;

                return(
                
                    <tr key={i}>
                        <td></td>
                        <td>{funcionario.nome} </td>
                        <td>{funcionario.qtd_dependentes_tipo_1} Dep(s).</td>
                        <td>{funcionario.qtd_dependentes_tipo_2} Dep(s).</td>
                        <td>{(descontoDep > 0) ? 1 : 0} Dep(s).</td>
                        <td>R$ {valor.toFixed(2)}</td>
                        <td>
                            <Button outline size="sm" color="primary" onClick={() => history.push({ pathname: `/funcionario/${funcionario.id}`, state: {idEstipulante: funcionario.id_estipulante } } ) }>
                                <FaSearch/>
                            </Button> 
                        </td>
                        <td>
                            <Button outline size="sm" color="primary" onClick={ () => setStepHistorico(true) } >
                                <FaRegFileArchive />
                            </Button>
                        </td>
                        {
                            activeTab==1
                          ?
                            <td><Button size="sm" color="danger" disabled={disabledBtn} onClick={() => inativarFuncionario(funcionario.id)}>Inativar</Button></td>
                          :
                            <td><Button size="sm" color="info" disabled={disabledBtn} onClick={() => ativarFuncionario(funcionario.id)}>Ativar</Button></td>

                        }
                        <td></td>
                    </tr>
                )
            })
            }
        </tbody>
      </Table>
    )
  }

  useEffect(() => { setLoading(false) }, [loading]);

  return (
    <Form onSubmit={alterarFuncionarioApolice}>
        <ToastContainer />
        {
        stepHistorico
        ?
        <Row className="animate__animated animate__backInRight">
          <Col>
            {printBreadCrambs()}
          </Col>
        </Row>
        :
        <Row className="animate__animated animate__backInLeft">
            <Col md={12} className="mb-4">
              <h2><FaUsers /> Lista de Funcionários </h2>
            </Col>
            <Col md={12}>
            <Nav tabs>
                <NavItem>
                <NavLink style={{cursor: "pointer", border: "1px solid #d6d6d6"}} onClick={() => { toggle('1'); }}>
                    <span style={{fontSize: "14px", color: ((activeTab==1) ? '#212529' :  '#6d6d6d' ), fontWeight: ((activeTab==1)? 'bold' :  'normal' )}}>Ativos</span>
                </NavLink>
                </NavItem>
                <NavItem>
                <NavLink style={{cursor: "pointer", border: "1px solid #d6d6d6"}} onClick={() => { toggle('0'); }}>
                    <span style={{fontSize: "14px", color: ((activeTab==0)? '#212529' :  '#6d6d6d' ), fontWeight: ((activeTab==0)? 'bold' :  'normal' )}}>Inativos</span>
                </NavLink>
                </NavItem>
            
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          {
                            funcAtivos&&
                            renderFuncionariosTable(funcAtivos.output)
                          }
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="0">
                      <Row>
                        <Col sm="12">
                          {
                            funcInativos&&
                            renderFuncionariosTable(funcInativos.output)
                          }
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
            
                
            </Nav>
            </Col>
            <Input type="hidden" name="id_apolice" value={idApolice} />
            <Input type="hidden" name="id_produto" value={idProduto} />
        </Row>
        }
    </Form>
  )
}

const mapStateToProps = store => ({
  user: store.user,
})

export default connect(mapStateToProps)(AlterarFuncionariosApolice);
