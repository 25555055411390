import React from 'react';
import { Card, Col, Row, Table } from 'reactstrap';
import { FaFileArchive, FaSearch, FaExclamationTriangle } from 'react-icons/fa';
import { CardComponent, OverflowContainer } from '../styles';
import Loading from '../../components/Loading/Loading';

export default function ListArquivos(props) {

    const { archivesPolicies } = props;

    const setDate = fullDate => {
        let dateArr = fullDate.split(" ");
        let newDate = dateArr[0].split("-");
        return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
    }
    const setTime = fullDate => {
        let timeArr = fullDate.split(" ");
        return timeArr[1];
    }

  return (
        <Card className="mb-4">
            <CardComponent className="mb-3">
                <Col className="header-content text-left">
                    <h2><FaFileArchive className="mr-2"/>Documentos</h2>
                </Col>
            </CardComponent>
            <OverflowContainer>
                    {
                        !archivesPolicies.loading
                    ?
                        archivesPolicies.error
                        ?
                            <div className="ml-2 mb-4 text-left">
                                <span style={{color: "red"}}><FaExclamationTriangle/> {archivesPolicies.error}</span>
                            </div>
                        :
                            <Table>
                                <thead className="text-left">
                                    <tr>
                                        <th>Data Inclusão</th>
                                        <th>Hora Inclusão</th>
                                        <th>Documento</th>
                                        <th>Link Arq.</th>
                                    </tr>
                                </thead>
                                <tbody className="text-left">
                                    {
                                        archivesPolicies.data.length
                                        ?
                                        archivesPolicies.data.map((el, index) => (
                                            <tr key={index}>
                                                <td>{setDate(el.data_inclusao)}</td>
                                                <td>{setTime(el.data_inclusao)}</td>
                                                <td>{el.descricao}</td>
                                                <td >
                                                    <a target="_blank" 
                                                        rel="noopener noreferrer" 
                                                        href={`${process.env.REACT_APP_API_ERP}/files/docs/${el.link_arquivo}`}>
                                                        <FaSearch className="ml-4"/>
                                                    </a>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        <tr className="text-center">
                                            <td>Sem documentos</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                    :
                        <Loading></Loading>
                    }
            </OverflowContainer>
        </Card>
    );
}
