import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FaUsers, FaPlus } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import Segurados from '../../views/Segurados/Segurados';
import Loading from '../../components/Loading/Loading';

const titleHeader = (
  <>
    <FaUsers />
    &nbsp;&nbsp;Segurados
  </>
);

export default function Page(props) {
  return (
    <Container fluid>
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>

        <Col className="text-right mt-4" md={12}>
          &nbsp;
          <Button
            color="success"
            size="sm"
            onClick={() => props.history.push('/cadastrar-cliente-individual')}
          >
            <FaPlus />
            &nbsp;&nbsp;Cadastrar Cliente - Saúde
          </Button>
        </Col>
      </Row>
      <Row className="mb-4">
        {props.loadingClientes ? (
          <Col className="text-center">
            <Loading loading={props.loadingClientes} />
          </Col>
        ) : (
          <Segurados {...props} history={props.history} />
        )}
      </Row>
    </Container>
  );
}
