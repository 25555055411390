import React from 'react';
import { Card, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText  } from 'reactstrap';
import { CardComponent } from '../styles';
import { FaSearch } from 'react-icons/fa';

function BuscaFaturas(props) {
  const { handleOnChange, getInfosFaturas } = props ;
  return (
    <Card>
      <CardComponent>
        <Col className="header-content">
          <h2><FaSearch />&nbsp;Pesquisa por Mês</h2>
        </Col>
        <Form >
          <Col className="mt-4">
            <Input type="select" name="inputStatus" onChange={e => handleOnChange(e)}>
              <option value="">Status</option>
              <option>Pendente</option>
              <option>Expirada</option>
              <option>Paga</option>
              <option>Cancelada</option>
              <option>Estornada</option>
              <option>Parcialmente Paga</option>      
            </Input>
          </Col>
          <Col className="mt-4 mb-4">
            <InputGroup>
              <Input type="month" name="inputMesAno" onChange={e => {handleOnChange(e)}}/>
              <InputGroupAddon addonType="append">
                <InputGroupText style={{cursor: "pointer"}} onClick={() => getInfosFaturas()}>
                  <FaSearch/>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Form>
      </CardComponent>
    </Card>
  )
}

export default BuscaFaturas;
