import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { FaArrowLeft, FaFileContract } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import SeguradoProdutoApolice from '../../views/Apolice/SeguradoProdutoApolice';
import HistoricoEmail from '../../views/Apolice/HistoricoEmail';
import ListaArquivos from '../../views/Apolice/ListaArquivos';
import Movimentacao from '../../views/Apolice/Movimentacao';
import DetalhesConfigApolice from '../../views/Apolice/DetalhesConfigApolice';
import Faturas from '../../views/Apolice/Faturas';
import Anotacoes from '../../views/Apolice/Anotacoes';
import CadastrarDescontoEmitirProposta from '../../views/Apolice/CadastrarDescontoEmitirProposta';
import CadastrarAssinatura from '../../views/Apolice/CadastrarAssinaturas';
import Loading from '../../components/Loading/Loading';
import HistoricoStatus from '../../views/Apolice/HistoricoStatus';
import HistoricoEdicaoSegurado from '../../views/Apolice/HistoricoEdicaoSegurado';

export default function Page(props) {
  const {
    idEmpresa,
    client,
    policieProduct,
    empresa,
    funcionarios,
    idApolice,
    contatos,
    archivesPolicies,
    todosProdutos,
    loadingProdutosAdd,
    loadingApolices,
    loadingEmails,
    authStr,
    produtosAdicionais,
    setPolicieConfigPayment,
    getDetalhesApolice,
    historicoStatus,
    motivoCancelamento,
    getConfigPayment,
    nivelUsuario,
    idUsuario,
    historicoEdicao,
    funcAtivos,
    funcInativos,
    valorApolice,
    relacaoSeguradosValor,
    gerarCobrancaEmpresarial,
    loadingCobrancaEmpresarial,
    alterarFormaPagamento,
    confirmarSegundaViaCartao,
  } = props;

  const history = useHistory();

  const titleHeader = (
    <>
      <FaFileContract />
      &nbsp;&nbsp;Apólice Novo{' '}
    </>
  );

  const renderPolicieCard = () => {
    let formaPagamento = '';

    if (props.policieConfigPayment) {
      formaPagamento = props.policieConfigPayment.ok
        ? props.policieConfigPayment.output.forma_pagamento
        : '';
    }

    if (props.detalhesApolice && props.policieConfigPayment) {
      if (
        formaPagamento === 'assinatura-empresarial' ||
        formaPagamento === 'carne-impresso' ||
        formaPagamento === 'cartao-credito' ||
        formaPagamento === 'debito-em-folha'
      ) {
        return (
          <DetalhesConfigApolice
            idApolice={idApolice}
            idEmpresa={idEmpresa}
            detalhesApolice={props.detalhesApolice}
            configPayment={props.policieConfigPayment}
            motivoCancelamento={motivoCancelamento}
            alterarFormaPagamento={alterarFormaPagamento}
            confirmarSegundaViaCartao={confirmarSegundaViaCartao}
          />
        );
      }
      if (formaPagamento === 'assinatura-empresarial-nao-preenchida') {
        if (contatos) {
          return (
            <CadastrarAssinatura
              idApolice={idApolice}
              idEmpresa={idEmpresa}
              contatos={contatos}
              configPagamento={props.policieConfigPayment}
              getConfigPayment={getConfigPayment}
            />
          );
        }
      } else {
        return (
          <CadastrarDescontoEmitirProposta
            idApolice={idApolice}
            idEmpresa={idEmpresa}
            funcionarios={funcionarios}
            setPolicieConfigPayment={setPolicieConfigPayment}
          />
        );
      }
    }
  };

  return (
    <Container className="mb-4" fluid>
      <Row className="mt-4">
        <Col md={2}>
          <Button
            className="btn-back w-100"
            size="sm"
            outline
            color="danger"
            onClick={() => history.goBack()}
          >
            <FaArrowLeft />
            &nbsp;&nbsp;Voltar
          </Button>
        </Col>
      </Row>
      <Row className="text-left mt-4 mb-4">
        <Col lg={12}>
          {empresa && <h3>{empresa.nome}</h3>}
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col lg={6}>
          <Col>
            {loadingProdutosAdd ? (
              <Col className="text-center">
                <Loading loading={loadingProdutosAdd} />
              </Col>
            ) : (
              policieProduct &&
              props.policieConfigPayment && (
                <SeguradoProdutoApolice
                  policieProduct={policieProduct}
                  policieConfigPayment={props.policieConfigPayment}
                  idEmpresa={idEmpresa}
                  idApolice={idApolice}
                  funcionarios={funcionarios}
                  archivesPolicies={archivesPolicies}
                  todosProdutos={todosProdutos}
                  history={history}
                  client={client}
                  authStr={authStr}
                  produtosAdicionais={produtosAdicionais}
                  nivelUsuario={nivelUsuario}
                  funcAtivos={funcAtivos}
                  funcInativos={funcInativos}
                />
              )
            )}
          </Col>
          <Col className="mt-4">
            {loadingEmails ? (
              <Col className="text-center">
                <Loading loading={loadingEmails} />
              </Col>
            ) : (
              props.historyEmail && (
                <HistoricoEmail historyEmail={props.historyEmail} />
              )
            )}
          </Col>
          <Col className="mt-4">
            {historicoEdicao && (
              <HistoricoEdicaoSegurado
                historicoEdicao={historicoEdicao}
                idSegurado={client.id}
              />
            )}
          </Col>
          <Col className="mt-4">
            {historicoStatus && (
              <HistoricoStatus
                historicoStatus={historicoStatus}
                idApolice={idApolice}
              />
            )}
          </Col>
          <Col className="mt-4">
            {props.archivesPolicies && (
              <ListaArquivos archivesPolicies={props.archivesPolicies} />
            )}
          </Col>
          <Col className="mt-4">
            {nivelUsuario == 1 && props.movimentacao && (
              <Movimentacao
                movimentacao={props.movimentacao}
                idApolice={idApolice}
              />
            )}
          </Col>
          <Col className="mt-4">
            {props.anotacoes && (
              <Anotacoes idApolice={idApolice} anotacao={props.anotacoes} />
            )}
          </Col>
        </Col>
        <Col lg={6}>
          <Col>{renderPolicieCard()}</Col>
          <Col className="mt-4">
            {loadingApolices ? (
              <Col className="text-center">
                <Loading loading={loadingApolices} />
              </Col>
            ) : (
              props.detalhesApolice &&
              props.policieConfigPayment &&
              props.faturas &&
              props.archivesPolicies && (
                <Faturas
                  detalhesApolice={props.detalhesApolice}
                  configPayment={props.policieConfigPayment}
                  faturas={props.faturas}
                  getDetalhesApolice={getDetalhesApolice}
                  archivesPolicies={props.archivesPolicies}
                  idUsuario={idUsuario}
                  valorApolice={valorApolice}
                  relacaoSeguradosValor={relacaoSeguradosValor}
                  gerarCobrancaEmpresarial={gerarCobrancaEmpresarial}
                  loadingCobrancaEmpresarial={loadingCobrancaEmpresarial}
                />
              )
            )}
          </Col>
        </Col>
      </Row>
    </Container>
  );
}
