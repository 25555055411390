import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaBuilding, FaPlus } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import CadastroEmpresa from '../../views/CadastroEmpresa/CadastroEmpresa';

const titleHeader = (
  <>
    <FaBuilding />
    <FaPlus style={{ width: '20px', height: '20px' }} />
    &nbsp;&nbsp;Cadastrar Empresa
  </>
);

export default function Page(props) {
  return (
    <Container className="mb-4" fluid>
      <Row className="text-left" style={{ alignItems: 'center' }}>
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row>
        <Col>
          <CadastroEmpresa {...props} />
        </Col>
      </Row>
    </Container>
  );
}
