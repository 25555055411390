import React from 'react';
import { Modal, ModalHeader, ModalBody, Input, Row, Col, Button } from 'reactstrap';
import { SectionFormHeader, LabelForm  } from '../../views/styles';
import InputMask from 'react-input-mask';

export default function ModalCadastroParceiro(props) {
  const { toggle, modal, cadastrarBeneficio, handleOnChange,  parceiro } = props;
  return (
    <Modal size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}> <SectionFormHeader>Seu Benefício</SectionFormHeader> </ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={12}>
            <LabelForm>Nome do Benefício</LabelForm>
            <Input type="text" name="nome" value={parceiro.nome} onChange={e => handleOnChange(e) }/>
          </Col>
          <Col className="mt-2" lg={12}>
            <LabelForm>Descrição</LabelForm>
            <Input type="textarea" name="descricao" value={parceiro.descricao} onChange={e => handleOnChange(e)} />
          </Col>
          <Col className="mt-2" lg={6}>
            <LabelForm>Custo</LabelForm>
            <Input type="number" name="custo" value={parceiro.custo} onChange={e => handleOnChange(e)} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={6}>
            <LabelForm>Contato para Acionamento</LabelForm>
            <InputMask mask="(99) 99999-9999" className="form-control" name="contatoAcionamento" value={parceiro.contatoAcionamento} onChange={e => handleOnChange(e)}/>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={12}>
            <LabelForm>Cláusula Padrão</LabelForm>
            <Input name="clausulaPadrao" value={parceiro.clausulaPadrao} onChange={e => handleOnChange(e)}/>
          </Col>
        </Row>
        <Row className="text-right mt-4">
          <Col>
            <Button color="success" size="sm" onClick={() => cadastrarBeneficio()}>Salvar Benefício</Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
