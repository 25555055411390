import React from 'react';
import { Box, FormControl, makeStyles, TextField } from '@material-ui/core';
import { FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import MaterialButton from '../../components/Buttons/MaterialButton';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginRight: theme.spacing(2),
  },
}));

function Filtros(props) {
  const {
    loadingComissoes,
    setPeriodo,
    periodo,
    setFireGetComissoes,
    fireGetComissoes,
  } = props;

  const history = useHistory();

  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="start"
      mt="2rem"
      mb="1.5rem"
      flexWrap="wrap"
    >
      <FormControl className={classes.formControl}>
        <TextField
          name="periodo"
          label="Período"
          id="periodo"
          type="month"
          value={periodo}
          className={classes.textField}
          onChange={e => {
            setPeriodo(e.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      <Box mt="12px">
        <MaterialButton
          variant="contained"
          color="dashboard"
          onClick={() => {
            history.push(`?periodo=${periodo}`);
            setFireGetComissoes(!fireGetComissoes);
          }}
          loading={loadingComissoes}
        >
          <FaSearch />
          &nbsp;&nbsp;Buscar
        </MaterialButton>
      </Box>
    </Box>
  );
}

export default Filtros;
