import React from 'react';
import { TitleHeaderComponent } from './styles';

export default function TitleContent(props) {
    return (
        <TitleHeaderComponent>
            <div><h4>{props.titleHeader}</h4></div>
        </TitleHeaderComponent>
  ) ;
}
