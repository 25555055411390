import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from 'reactstrap';
import {
  FaShoppingCart,
  FaUser,
  FaCalendarAlt,
  FaRegKissWinkHeart,
  FaPhone,
  FaMobileAlt,
  FaEnvelope,
  FaRoad,
  FaInfo,
  FaMapMarkerAlt,
  FaMapMarkedAlt,
  FaGlobeAmericas,
  FaBuilding,
  FaNapster,
  FaSearch,
} from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { getAddress } from 'address-br';
import {
  BodyFormComponent,
  LabelForm,
  PrependInputLabel,
  SectionFormHeader,
} from '../styles';
import Api from '../../services/api';
import { alertError, alertSuccess } from '../../components/Alerts/Alerts';
import MaterialButton from '../../components/Buttons/MaterialButton';
import * as bancos from 'bancos-brasileiros';

function EditarAssessoria(props) {
  const { toggle, modal, getAssessoria, assessoria } = props;

  const [loading, setLoading] = useState(false);
  const [assessoriaInput, setAssessoriaInput] = useState({});

  const [comissaoInput, setComissaoInput] = useState({});
  const [dadosBancariosInput, setDadosBancariosInput] = useState({});

  const [checkSubconta, setCheckSubconta] = useState(false);

  const [readonly, setReadonly] = useState({
    liveApiToken: true,
    testApiToken: true,
    userToken: true,
  });

  useEffect(() => {
    console.log(assessoria);
    setAssessoriaInput({ ...assessoria.endereco, ...assessoria.data });
    setComissaoInput({
      ...assessoria.comissao,
      pagamentoAVista: assessoria.comissao.pagamento_a_vista,
      primeiraParcela: assessoria.comissao.primeira_parcela,
      idUsuario: assessoria.data.id_usuario,
    });
    setDadosBancariosInput({
      ...assessoria.dados_bancarios,
      banco: assessoria.dados_bancarios.banco,
      agencia: assessoria.dados_bancarios.agencia,
      conta: assessoria.dados_bancarios.conta,
      pix: assessoria.dados_bancarios.pix,
    });
  }, [assessoria]);

  const editarAssessoriaForm = async e => {
    setLoading(true);
    e.preventDefault();
    const authStr = `Bearer ${props.user.user.token}`;

    let idUsuario;
    try {
      const result = await Api.put('/assessoria', assessoriaInput, {
        headers: { Authorization: authStr },
      });
      // idUsuario = result.data.output.idUsuario;

      if (result.data.ok) {
        alertSuccess('Distribuidor cadastrado com sucesso');
      } else {
        throw new Error(result.data.message);
      }
    } catch (error) {
      alertError('Erro ao salvar distribuidor: ', error.message);
    }

    try {
      const response = await Api.put(
        `/comissao`,
        { ...comissaoInput },
        { headers: { Authorization: `Bearer ${props.user.user.token}` } },
      );
      if (response.data.ok) {
        alertSuccess('Comissão cadastrada com sucesso');
      } else {
        throw new Error(response.data.message);
      }
      toggle();
      getAssessoria();
    } catch (error) {
      alertError('Erro ao salvar comissão: ', error.message);
    }

    try {
      const response = await Api.put(
        `/assessoria/dados-bancarios/${assessoria.data.id}`,
        { ...dadosBancariosInput },
        { headers: { Authorization: `Bearer ${props.user.user.token}` } },
      );
      if (response.data.ok) {
        alertSuccess('Dados bancários atualizados com sucesso');
      } else {
        throw new Error(response.data.message);
      }
      toggle();
      getAssessoria();
    } catch (error) {
      alertError('Erro ao salvar os dados bancários: ', error.message);
    }


    //PUT DADOS BANCARIOS

    setLoading(false);
  };

  const handleOnChange = e => {
    const { name, value } = e.target;
    setAssessoriaInput({ ...assessoriaInput, [name]: value });
  };

  const handleComissaoChange = e => {
    const { name, value } = e.target;
    if (value < 0 || value > 100 || Number.isNaN(value)) {
      return;
    }
    setComissaoInput({ ...comissaoInput, [name]: value });
  };

  const handleDadosBancariosChange = e => {
    const { name, value } = e.target;
    setDadosBancariosInput({ ...dadosBancariosInput, [name]: value });
  };

  const handleIuguInput = e => {
    const { name, value } = e.target;
    setAssessoriaInput({
      ...assessoriaInput,
      iuguTokens: { ...assessoriaInput.iuguTokens, [name]: value },
    });
  };

  const handleCheckbox = e => {
    const { checked } = e.target;
    if (checked == true) {
      setReadonly({
        liveApiToken: false,
        testApiToken: false,
        userToken: false,
      });
    } else {
      setReadonly({ liveApiToken: true, testApiToken: true, userToken: true });
    }
    setCheckSubconta(checked);
  };

  const updateAdress = () => {
    const cepOnlyNumbers = assessoriaInput.cep.replace(/\D/g, '');
    getAddress(cepOnlyNumbers).then(vl => {
      setAssessoriaInput({
        ...assessoriaInput,
        cep: assessoriaInput.cep,
        logradouro: vl.rua || '',
        cidade: vl.cidade || '',
        bairro: vl.bairro || '',
        uf: vl.estado || '',
      });
    });
  };

  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <Form onSubmit={editarAssessoriaForm}>
        <ModalHeader toggle={toggle}>
          <Row>
            <Col md={12}>
              <FaShoppingCart />
              &nbsp;Editar Distribuidor
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <BodyFormComponent>
            <Row>
              <Col>
                <SectionFormHeader>
                  Informações do Distribuidor
                </SectionFormHeader>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaBuilding />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Razão Social"
                    name="razao_social"
                    value={assessoriaInput.razao_social}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>
                        <FaNapster />
                      </PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Nome Fantasia"
                    name="nome_fantasia"
                    value={assessoriaInput.nome_fantasia}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={12}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>CNPJ</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="99.999.999/9999-99"
                    className="form-control"
                    required
                    name="cnpj"
                    value={assessoriaInput.cnpj}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaUser />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Nome"
                    name="nome"
                    value={assessoriaInput.nome}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>CPF</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="999.999.999-99"
                    className="form-control"
                    required
                    name="cpf"
                    value={assessoriaInput.cpf}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={4}>
                <LabelForm>Data de Nascimento</LabelForm>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaCalendarAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="date"
                    required
                    name="data_nascimento"
                    value={assessoriaInput.data_nascimento}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={4}>
                <LabelForm>Estado Civil</LabelForm>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaRegKissWinkHeart />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="select"
                    name="estado_civil"
                    value={assessoriaInput.estado_civil}
                    onChange={e => handleOnChange(e)}
                  >
                    <option value="">Selecione</option>
                    <option>Solteiro(a)</option>
                    <option>Casado(a) /união estável</option>
                    <option>Divorciado(a)</option>
                    <option>Viúvo(a)</option>
                  </Input>
                </InputGroup>
              </Col>
              <Col lg={4}>
                <LabelForm>Sexo</LabelForm>
                <Input
                  type="select"
                  name="sexo"
                  value={assessoriaInput.sexo}
                  onChange={e => handleOnChange(e)}
                >
                  <option value="">Selecione</option>
                  <option>Masculino</option>
                  <option>Feminino</option>
                </Input>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaPhone />
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="Telefone"
                    required
                    name="telefone"
                    value={assessoriaInput.telefone}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>

              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaMobileAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="Telefone 2"
                    name="celular"
                    value={assessoriaInput.celular}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaEnvelope />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="email"
                    required
                    placeholder="E-mail"
                    name="email"
                    value={assessoriaInput.email}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <SectionFormHeader>Informações de Endereço</SectionFormHeader>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={4}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>CEP</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="CEP sem pontuação"
                    name="cep"
                    value={assessoriaInput.cep}
                    onChange={e => handleOnChange(e)}
                  />
                  <Button size="sm" onClick={() => updateAdress()}>
                    <FaSearch />
                  </Button>
                </InputGroup>
              </Col>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaRoad />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Rua"
                    name="logradouro"
                    value={assessoriaInput.logradouro}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={2}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>Nº</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Nº"
                    name="numero_endereco"
                    value={assessoriaInput.numero_endereco}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaInfo />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Complemento"
                    name="complemento_endereco"
                    value={assessoriaInput.complemento_endereco}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={3}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaMapMarkerAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Bairro"
                    name="bairro"
                    value={assessoriaInput.bairro}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={3}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaMapMarkedAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Cidade"
                    name="cidade"
                    value={assessoriaInput.cidade}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={3}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaGlobeAmericas />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Estado"
                    name="uf"
                    value={assessoriaInput.uf}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            {assessoriaInput.tipo_distribuidor == 0 ? (
              <>
                <Row className="mt-4">
                  <Col>
                    <SectionFormHeader>
                      Configurações de Subconta
                    </SectionFormHeader>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="ml-4" xs={12}>
                    <Input
                      type="checkbox"
                      name="checkSubconta"
                      value={checkSubconta}
                      onChange={e => handleCheckbox(e)}
                    />
                    <LabelForm>Possui Subconta?</LabelForm>
                  </Col>
                  <Col lg={4}>
                    <Input
                      placeholder="Token de Produção"
                      readOnly={readonly.liveApiToken}
                      name="live_api_token"
                      value={assessoriaInput.iuguTokens.live_api_token}
                      onChange={e => handleIuguInput(e)}
                    />
                  </Col>
                  <Col lg={4}>
                    <Input
                      placeholder="Token de Teste"
                      readOnly={readonly.testApiToken}
                      name="test_api_token"
                      value={assessoriaInput.iuguTokens.test_api_token}
                      onChange={e => handleIuguInput(e)}
                    />
                  </Col>
                  <Col lg={4}>
                    <Input
                      placeholder="Token de Usuário"
                      readOnly={readonly.userToken}
                      name="user_token"
                      value={assessoriaInput.iuguTokens.user_token}
                      onChange={e => handleIuguInput(e)}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="mt-4">
                  <Col>
                    <SectionFormHeader>Cadastro de Comissão</SectionFormHeader>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12} sm={6} className="mb-2">
                    <LabelForm>1ª Parcela</LabelForm>
                    <InputGroup>
                      <Input
                        name="primeiraParcela"
                        type="number"
                        value={comissaoInput.primeiraParcela}
                        onChange={e => handleComissaoChange(e)}
                        min={0}
                        max={100}
                        required
                        placeholder="10.0"
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <PrependInputLabel>%</PrependInputLabel>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col xs={12} sm={6} className="mb-2">
                    <LabelForm>Carteira</LabelForm>
                    <InputGroup>
                      <Input
                        name="carteira"
                        type="number"
                        value={comissaoInput.carteira}
                        onChange={e => handleComissaoChange(e)}
                        min={0}
                        max={100}
                        required
                        placeholder="10.0"
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <PrependInputLabel>%</PrependInputLabel>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col xs={12} sm={6} className="mb-2">
                    <LabelForm>Pagamento à vista</LabelForm>
                    <InputGroup>
                      <Input
                        name="pagamentoAVista"
                        type="number"
                        value={comissaoInput.pagamentoAVista}
                        onChange={e => handleComissaoChange(e)}
                        min={0}
                        max={100}
                        required
                        placeholder="10.0"
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <PrependInputLabel>%</PrependInputLabel>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <SectionFormHeader>Anotações</SectionFormHeader>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Input
                      type="textarea"
                      name="anotacoes"
                      value={assessoriaInput.anotacoes}
                      onChange={e => handleOnChange(e)}
                      placeholder="Informações adicionais..."
                    />
                  </Col>
                </Row>
              </>
            )}
           <Row className="mt-4">
              <Col>
                <SectionFormHeader>Dados bancários</SectionFormHeader>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={3}>
                <InputGroup>
                  <Input
                    required
                    type="select"
                    name="banco"
                    value={dadosBancariosInput.banco}
                    onChange={e => handleDadosBancariosChange(e)}
                  >
                    <option value="">Selecione o banco</option>
                    {bancos.default.map(b => (
                      <option key={b.COMPE} value={b.COMPE}>{b.COMPE} - {b.LongName}</option>
                    ))
                    }

                  </Input>
                </InputGroup>
              </Col>
              <Col lg={2}>
                <Input
                  placeholder="Agência"
                  name="agencia"
                  min={0}
                  type="number"
                  value={dadosBancariosInput.agencia}
                  onChange={e => handleDadosBancariosChange(e)}
                  />
              </Col>
              <Col lg={3}>
                <Input
                  placeholder="Conta"
                  name="conta"
                  value={dadosBancariosInput.conta}
                  onChange={e => handleDadosBancariosChange(e)}
                  />
              </Col>
              <Col lg={4}>
                <Input
                  placeholder="PIX"
                  name="pix"
                  value={dadosBancariosInput.pix}
                  onChange={e => handleDadosBancariosChange(e)}
                  />
              </Col>
            </Row>
          </BodyFormComponent>
        </ModalBody>
        <ModalFooter>
          <Row className="text-right">
            <Col className="d-flex align-items-center">
              <MaterialButton color="danger" onClick={toggle}>
                Fechar
              </MaterialButton>
              &nbsp;
              <MaterialButton loading={loading} type="submit">
                Salvar
              </MaterialButton>
            </Col>
          </Row>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

// Token de produção, teste, user token
const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(EditarAssessoria);
