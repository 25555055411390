import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { useHistory } from 'react-router-dom';
import Page from './Page';
import { connect } from 'react-redux';

function Controller(props) {
    let history = useHistory();
    const [ cupons, setCupons ] = useState();
    const [ loading, setLoading ] = useState(true);
    let token = props.user.user.token;

    useEffect(
        () => {
            getCupons();
        },[]
    )

    const getCupons = async _ => {
        
        const response = await Api.get(`cupom/`, { headers: {Authorization: `Bearer ${token}` }});
        console.log("Response Cupons - ",response)
        setCupons(response.data);
        setLoading(false);

    }

    return(
        <Page 
            history={history}
            cupons={cupons}
            loading={loading}
            token={token}
        />
    );
}

const mapStateToProps = store => ({
    user: store.user,
})

export default connect(mapStateToProps)(Controller);