import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Page from './Page';
import Api from '../../services/api';


function Controller(props) {

  const { user } = props;
  const authStr = `Bearer ${user.user.token}`;

  const [relacaoUsoCupons, setRelacaoUsoCupons] = useState({
    loading: true,
    data: []
  });

  useEffect(() => {
    if (user.user.nivel_usuario == 1) {
      getRelacaoUsoCupons();
    }
  }, []);

  const getRelacaoUsoCupons = async () => {
    setRelacaoUsoCupons({ ...relacaoUsoCupons, loading: true });

    const response = await Api.get(
      `/cupom/relacao-de-uso`,
      { headers: { Authorization: authStr } },
    );

    if (response.data.ok) {
      setRelacaoUsoCupons({
        loading: false,
        data: [...relacaoUsoCupons.data, ...response.data.output]
      });
    } else {
      console.log(`Erro ao buscar a relação de uso de cupons da API: ${response.data.message}`);
      setRelacaoUsoCupons({ ...relacaoUsoCupons, loading: false });
    }
  };

  return(
      <Page relacaoUsoCupons={relacaoUsoCupons} />
  );
}

const mapStateToProps = store => ({
    user: store.user,
})

export default connect(mapStateToProps)(Controller);
