import React from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import TitleContent from '../../components/TitleContent/TitleContent';
import BuscaMovimentacao from '../../views/Movimentacoes/BuscaMovimentacao';
import BuscaMesMovimentacoes from '../../views/Movimentacoes/BuscaMesMovimentacoes';
import { FaExchangeAlt, FaExclamationCircle } from 'react-icons/fa';
import { MsgErrorDataTable } from '../../views/styles';

function Pages(props) {

  const titleHeader = <><FaExchangeAlt/>&nbsp;&nbsp;Movimentações</>;
  const { inputs, handleOnChange, terceiros, getMovimentacoes, movimentacoes } = props;

  return (
    <Container fluid={true}>
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={12} className="mt-4">
          <BuscaMovimentacao
            handleOnChange={handleOnChange}
            inputs={inputs}
            terceiros={terceiros}
            getMovimentacoes={getMovimentacoes}
          />
        </Col>
        <Col md={12} className="mt-3">
          {
            movimentacoes
            ?
            <BuscaMesMovimentacoes movimentacoes={movimentacoes} inputs={inputs} />
            :
            <Card className="px-4 py-4">
              <Row>
                <MsgErrorDataTable><FaExclamationCircle />&nbsp;&nbsp;Realize a pesquisa por mês</MsgErrorDataTable>
              </Row>
            </Card>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default Pages;
