import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import api from '../../services/api';
import { alertError, alertWarning, alertSuccess } from '../../components/Alerts/Alerts';
import { useHistory } from 'react-router-dom';

import Page from './Page';

import { ToastContainer } from 'react-toastify';

function AcoesApolices(props) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const [ params, setParams ] = useState({
        apoliceStatus: urlParams.get('status') ? urlParams.get('status') : '',
        nextStatus : ''
    });
    const [ apolices, setApolices ] = useState([]);
    const [ checkedApolices, setCheckedApolices ] = useState([]);
    const [ renovacoes, setRenovacoes ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ loadingBaixarCartoes, setLoadingBaixarCartoes ] = useState(false);

    let history = useHistory();

    useEffect(
        () => {
            getApolices();
            getRenovacoes();
            baixarCartoesBoasVindas();
        },[params, checkedApolices]
    )

    const getApolices = async _ => {
        const {  token } = props.user.user;
        const authStr = `Bearer ${token}`;
        if(params.apoliceStatus){
            const res = await api.get(`segurados/apolices/?status=${params.apoliceStatus}`, { headers: { Authorization: authStr } });
            if(res.data.ok){
                setApolices(res.data.output)
            }else{
                alertError("Nenhuma apólice encontrada.");
                setApolices([]);
            }
        }else{
            setApolices([]);
        }
    }

    const getRenovacoes = async _ => {
        const {  token } = props.user.user;
        const authStr = `Bearer ${token}`;

        const res = await api.get(`segurados/apolices/periodo-renovacoes`, { headers: { Authorization: authStr } });
        if(res.data.ok){
            setRenovacoes(res.data.output)
        }else{
            alertError("Nenhuma apólice para renovação encontrada.");
            setRenovacoes([]);
        }
    }

    const handleAlterarEmMassa = async _ => {

        if (checkedApolices.length===0) {
            alertWarning("Nenhuma apólice selecionada.");
            return;
        }

        setLoading(true);
        const confirmChange = window.confirm(`Deseja mudar o status de ${checkedApolices.length} apólices para ${params.nextStatus} ?`);
        if (confirmChange) {
            const {  token } = props.user.user;
            const authStr = `Bearer ${token}`;
            const obj = { idsApolices: checkedApolices, status: params.nextStatus, idUsuario: props.user.user.usuario }
            const response = await  api.post(`apolices/mudar-status`, obj, { headers: { Authorization: authStr } });
            if (response.data.ok) {
                alertSuccess("Ação de apólices realizada com sucesso.");
                window.location.reload();
            }else{
                alertError("Erro na operação: "+response.data.message);
            }
        }
        setLoading(false);
    }

    const handleRenovarEmMassa = async _ => {

        if (checkedApolices.length===0) {
            alertWarning("Nenhuma apólice selecionada.");
            return;
        }

        setLoading(true);
        const confirmChange = window.confirm(`Deseja renovar ${checkedApolices.length} apólices ?`);
        if (confirmChange) {
            const {  token } = props.user.user;
            const authStr = `Bearer ${token}`;
            const obj = { idsApolices: checkedApolices, idUsuario: props.user.user.usuario }
            const response = await  api.post(`apolices/renovar`, obj, { headers: { Authorization: authStr } });
            if (response.data.ok) {
                alertSuccess("Ação de apólices realizada com sucesso.");
                window.location.reload();
            }else{
                alertError("Erro na operação: "+response.data.message);
            }
        }
        setLoading(false);
    }

    const handleGerarExcel = async _ => {
        if(window.confirm('Deseja mesmo baixar esta lista de segurados?')){
            const {  token } = props.user.user;
            const authStr = `Bearer ${token}`;
            const request = await api.get(`segurados/apolices/excel?status=${params.apoliceStatus}`, { headers: { Authorization: authStr } });
            if (request.data.ok) {
                window.open(`${process.env.REACT_APP_API_ERP}/${request.data.output}`);
            }else{
                alertError(`Erro no download: ${request.data.message}` )
            }
        }
    }

    const baixarCartoesBoasVindas = async () => {

        const {  token } = props.user.user;
        const authStr = `Bearer ${token}`;
        const obj = { idsApolices: checkedApolices, idUsuario: props.user.user.usuario }

        setLoadingBaixarCartoes(true);

        const response = await  api.post(`/segurados/apolices/gerar-cartao-boas-vindas`, obj, { headers: { Authorization: authStr } });
        if (response.data.ok) {
          window.location.href = process.env.REACT_APP_API_ERP+'/' + response.data.output.path;
        }else{
          // alertError("Erro na operação: "+response.data.message);
          console.log("Erro na operação: "+response.data.message);
        }

        setLoadingBaixarCartoes(false);
    }

    return (
        <>
            <ToastContainer />
            {
                <Page
                    apolices={apolices}
                    params={params}
                    setParams={setParams}
                    setApolices={setApolices}
                    history={history}
                    checkedApolices={checkedApolices}
                    setCheckedApolices={setCheckedApolices}
                    loading={loading}
                    handleAlterarEmMassa={handleAlterarEmMassa}
                    renovacoes={renovacoes}
                    handleRenovarEmMassa={handleRenovarEmMassa}
                    loadingBaixarCartoes={loadingBaixarCartoes}
                    baixarCartoesBoasVindas={baixarCartoesBoasVindas}
                    handleGerarExcel={handleGerarExcel}
                />
            }
        </>
    )
}

const mapStateToProps = store => ({
    user: store.user,
});

export default connect(mapStateToProps)(AcoesApolices);
