import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Card, Col, Row, Button, Spinner } from 'reactstrap';
import { FaUsers, FaSearch } from 'react-icons/fa';

import { CardComponent } from '../styles';

import SimpleTable from '../../components/TablesGrid/SimpleTable';
import Loading from '../../components/Loading/Loading';

function VendedoresLista(props) {
  const history = useHistory();

  const { vendedores, loadingChangeStatus, submitChangeStatus, openModal } =
    props;

  const [vendedoresEditados, setVendedoresEditados] = useState([]);

  useEffect(() => {
    setVendedoresEditados(vendedores.data);
  }, [vendedores]);

  const columns = [
    { name: 'nome', title: 'NOME', getCellValue: row => row.nome },
    { name: 'status', title: 'Status', getCellValue: row => changeStatus(row) },
    { name: 'tipo', title: 'Funções', getCellValue: row => actions(row) },
  ];

  const actions = vendedor => (
    <div>
      <Button
        outline
        className="mr-1"
        size="sm"
        color="primary"
        onClick={() => openModal(vendedor)}
      >
        <FaSearch />
      </Button>
    </div>
  );

  const changeStatus = vendedor => (
    <div className="text-center">
      {vendedor.status == 1 ? 'Habilitado' : 'Desabilitado'}
      {/* <Input
        type="select"
        onChange={event =>
          setVendedoresEditados(
            vendedoresEditados.map(vendedorEditado =>
              vendedorEditado.id == vendedor.id
                ? { ...vendedor, status: event.target.value }
                : vendedor,
            ),
          )
        }
        value={vendedor.status}
      >
        <option value="">Selecione</option>
        <option value={1}>Habilitar</option>
        <option value={0}>Desabilitar</option>
      </Input> */}
    </div>
  );

  return (
    <Card>
      <CardComponent>
        <Col className="header-content text-left">
          <h2>
            <FaUsers />
            &nbsp;&nbsp;Vendedores
          </h2>
        </Col>
      </CardComponent>
      <hr />
      <Row>
        <Col>
          {vendedores.loading ? (
            <Loading />
          ) : (
            <SimpleTable
              height="320px"
              rows={vendedoresEditados}
              columns={columns}
            />
          )}

          <Row className="my-4 justify-content-between">
            <Col xs="auto">
              <Button
                className="mr-2"
                color="danger"
                size="sm"
                onClick={() => setVendedoresEditados(vendedores.data)}
              >
                Cancelar
              </Button>
              <Button
                color="success"
                size="sm"
                onClick={() => submitChangeStatus()}
                disabled={loadingChangeStatus}
              >
                {loadingChangeStatus ? (
                  <>
                    <Spinner className="mr-2" color="white" size="sm" />
                    <span>Confirmando...</span>
                  </>
                ) : (
                  'Confirmar'
                )}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

export default VendedoresLista;
