import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row, Button } from 'reactstrap';
import {
  FaWrench,
  FaMapMarkedAlt,
  FaMapMarkerAlt,
  FaRoad,
  FaInfo,
} from 'react-icons/fa';
import { CardComponent } from '../styles';

function InformacoesEmpresa(props) {
  const history = useHistory();
  const { id, cnpj, logradouro, bairro, cidade, uf, numero_endereco } =
    props.infosEmpresas;

  return (
    <Card>
      <CardComponent>
        <Row className="justify-content-between m-0 p-0">
          <Col className="header-content mb-2" xs="auto">
            <h2 className="m-0 align-baseline">
              <FaInfo className="mr-2" />
              Informações
            </h2>
          </Col>
          <Col xs="auto">
            <Button
              color="success"
              outline
              size="sm"
              className="mb-2"
              onClick={() => history.push(`/editar-empresa/${id}`)}
            >
              <FaWrench />
              &nbsp;Editar
            </Button>
          </Col>
        </Row>
        <Row className="text-left mt-2">
          <Col xs={12}>
            <h6>CNPJ: {cnpj}</h6>
          </Col>
          <Col md={12} className="mt-2">
            <p>
              <FaRoad />
              &nbsp;&nbsp;<b>Rua:</b> {logradouro}, {numero_endereco}
            </p>
          </Col>
          <Col md={12}>
            <p>
              <FaMapMarkerAlt />
              &nbsp;&nbsp;<b>Bairro:</b> {bairro}
            </p>
          </Col>
          <Col md={6}>
            <p>
              <FaMapMarkedAlt />
              &nbsp;&nbsp;<b>Cidade:</b> {cidade} - {uf}
            </p>
          </Col>
        </Row>
      </CardComponent>
    </Card>
  );
}

export default InformacoesEmpresa;
