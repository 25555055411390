import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { alertError } from '../../components/Alerts/Alerts';
import api from '../../services/api';
import { padLeadingZeros } from '../../services/service';
import Page from './Page';

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

function Controller(props) {
  const { user } = props;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const periodoParams = urlParams.get('periodo');

  const [assessorias, setAssessorias] = useState({
    data: [],
    loading: true,
    error: '',
  });

  const [comissoes, setComissoes] = useState({
    data: [],
    loading: true,
    error: '',
  });

  const [periodo, setPeriodo] = useState(
    periodoParams ?? `${currentYear}-${padLeadingZeros(currentMonth, 2)}`,
  );

  const [fireGetComissoes, setFireGetComissoes] = useState(false);

  const getAssessorias = async () => {
    try {
      const response = await api.get('/assessorias/', {
        headers: { Authorization: `Bearer ${user.user.token}` },
      });

      if (response.data.ok) {
        setAssessorias({
          data: response.data.output,
          loading: false,
        });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setAssessorias({
        data: [],
        loading: false,
      });
      console.log(error.message);
    }
  };

  const getAssessoriasMaster = async () => {
    try {
      console.log('user.user.usuario', user.user.usuario);
      const response = await api.get(
        `assessoria/usuario/${user.user.usuario}`,
        {
          headers: { Authorization: `Bearer ${user.user.token}` },
        },
      );

      console.log('response', response);
      if (response.data.ok) {
        const responseAssessoriasMaster = await api.get(
          `assessorias-master/${response.data.output.id}`,
          {
            headers: { Authorization: `Bearer ${user.user.token}` },
          },
        );

        console.log('responseAssessoriasMaster', responseAssessoriasMaster);
        if (responseAssessoriasMaster.data.ok) {
          setAssessorias({
            data: responseAssessoriasMaster.data.output,
            loading: false,
          });
        }
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setAssessorias({
        data: [],
        loading: false,
      });
      console.log('Erro ao buscar distribuidor: ', error.message);
    }
  };

  const getComissoes = async distribuidorId => {
    try {
      const response = await api.get(
        `/comissao/relatorio/${distribuidorId}/${periodo}`,
        { headers: { Authorization: `Bearer ${user.user.token}` } },
      );
      if (response.data.ok) {
        return response.data.output;
      }
      console.log(response.data.message);
      return 'erro';
    } catch (error) {
      alertError('Erro ao buscar uma comissão', error.message);
      return 'erro';
    }
  };

  const getExcel = async distribuidorId => {
    try {
      const response = await api.get(
        `/relatorio-venda/${periodo}/assessoria/${distribuidorId}/comissoes/excel`,
        { headers: { Authorization: `Bearer ${user.user.token}` } },
      );
      if (response.data.ok) {
        return response.data.output;
      }
      throw new Error(response.data.message);
    } catch (error) {
      alertError('Falha ao baixa excel: ', error.message);
    }
  };

  useEffect(() => {
    if (user.user.nivel_usuario == 1) {
      getAssessorias();
    } else if (user.user.nivel_usuario == 5) {
      getAssessoriasMaster();
    } else {
      return false;
    }
  }, []);

  useEffect(() => {
    setComissoes({ data: [], loading: true });

    const getData = async () => {
      if (assessorias.data.length) {
        const comissoesData = await Promise.all(
          assessorias.data.map(async assessoria => {
            const comissao = await getComissoes(assessoria.id);
            Object.assign(comissao, {
              nome: assessoria.nome,
            });
            return comissao;
          }),
        );
        const comissoesDataFiltered = comissoesData.filter(
          comissao => comissao != 'erro',
        );
        setComissoes({ data: comissoesDataFiltered, loading: false });
      }
    };

    getData();
  }, [fireGetComissoes, assessorias]);

  return (
    <Page
      comissoes={comissoes}
      setPeriodo={setPeriodo}
      periodo={periodo}
      getExcel={getExcel}
      setFireGetComissoes={setFireGetComissoes}
      fireGetComissoes={fireGetComissoes}
    />
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
