import React from 'react';
import { Card, Col, Row, Container, Table } from 'reactstrap';
import { CardComponent } from '../styles'; 
import { FaUsers } from 'react-icons/fa';
import { TableContent } from '../styles';
import { formatDate } from '../../services/service';

export default function SeguradoDependente(props) {
    const { dependents } = props;

    return (
      <Card>
      <CardComponent>
        <Col className="header-content">
          <h2><FaUsers style={{color: "#DA2626" }} />&nbsp;&nbsp;Dependentes</h2>
        </Col>
        <Container>
          <TableContent>
          <Row>
            <Col>
            {
              dependents.output.length > 0
              ?
                <Table>
                  <thead>
                    <tr>
                      <th>CPF</th>
                      <th>Tipo</th>
                      <th>Nome</th>
                      <th>Parentesco</th>
                      <th>Titular</th>
                      <th>Data de Nascimento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      dependents.output.map((el, i) => {
                        return(
                          <tr key={i}>
                            <td>{el.cpf}</td>
                            <td>{el.tipo}</td>
                            <td>{el.nome}</td>
                            <td>{el.parentesco}</td>
                            <td>{el.titular}</td>
                            <td>{formatDate(el.data_nascimento)}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
              :
              <Col className="text-left mb-2">
                <span style={{color: "red"}}>{dependents.message}</span>
              </Col>
            }
            </Col>
          </Row>
          </TableContent>
        </Container>
      </CardComponent>
    </Card>
    );
}
