import React, { useState } from 'react';
import { NavbarComponent } from '../styles';
import { connect } from 'react-redux';
import { FaUserAlt, FaPowerOff, FaUnlockAlt, FaExclamationCircle, FaBars } from 'react-icons/fa';
import { clearUser } from '../../actions/user';
import { clearState } from '../../localStorage';
import ModalVersaoDetalhada from '../../components/Modal/ModalVersaoDetalhada';
import imgLogo from '../../assets/img/logo-viver-white.png';
import NavBarTopMobile from '../../components/NavBarTopMobile/NavBarTopMobile';
import dashboardRoutes from "../../routes/dashboard";
import Sidebar from "../../components/Sidebar/Sidebar";

import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card, 
    CardBody,
    Button,
    NavbarBrand,
    NavLink,
    NavItem,
    NavbarText,
    Col
  } from 'reactstrap';

function Header(props) {

    const { showInDesktop, clickMobileMenu } = props;
    const [ isOpen, setIsOpen ] = useState(false);
    const [ isOpenBtnBars, setIsOpenBtnBars ] = useState(false);
    const [ modalVersaoDetalhada, setModalVersaoDetalhada ] = useState(false);
    const [ widthSidePanel, setWidthSidePanel ] = useState('0px');
    const [ closeBtnClass ,setCloseBtnClass ] = useState('display: block');

    const toggle = () => setIsOpen(!isOpen);
    const toggleBtnBars = () => setIsOpenBtnBars(!isOpenBtnBars);
    const toggleModalVersao = () => setModalVersaoDetalhada(!modalVersaoDetalhada);


    const logoutUser = _ => {
        props.clearUser();
        clearState();
        window.location.href='/login'
    }

    return (
        <>
        <NavbarComponent>

        <Navbar expand="sm" className="navbar-content" dark>
            <img className="img-logo-in-navbar" src={imgLogo} height="40px" />
            
            <span className="novidades-content" onClick={() => setModalVersaoDetalhada(!modalVersaoDetalhada) }>
                <FaExclamationCircle/> &nbsp;Novidades
            </span>
            <Col className="btn-menu-responsive-content">
                <Button onClick={() => clickMobileMenu()}><span>Menu</span></Button>
            </Col>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav navbar>
                    {showInDesktop
                    ?
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret className="animate__animated animate__fadeIn">
                                <FaUserAlt/>&nbsp;&nbsp;Bem vindo(a) {props.user.user.primeiro_nome_usuario}
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem href="/alterar-senha">
                                    <FaUnlockAlt/> Alterar Senha
                                </DropdownItem>
                                {/* <DropdownItem>
                                    <FaUserAlt/> Meu Perfil
                                </DropdownItem> */}
                                <DropdownItem divider />
                                <DropdownItem onClick={logoutUser}>
                                    <FaPowerOff/> Logout
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    :
                        <>
                            <NavLink to='/alterar-senha'> <FaUnlockAlt/> Alterar Senha </NavLink>
                            <NavLink onClick={logoutUser}> <FaPowerOff/> Logout </NavLink>
                        </>
                    }
                </Nav>
            </Collapse>
        </Navbar>
        {
            modalVersaoDetalhada&&
            <ModalVersaoDetalhada toggle={toggleModalVersao} modal={modalVersaoDetalhada} />
        }
        </NavbarComponent>
{/* 
        <FaBars className="icon-toggle" onClick={() => setWidthSidePanel("250px")}/>
        <Collapse isOpen={isOpenBtnBars}>
            <div class="sidepanel" style={{width: "250px"}}>
                <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">×</a>
                <a href="#">About</a>
                <a href="#">Services</a>
                <a href="#">Clients</a>
                <a href="#">Contact</a>
            </div>
            
        </Collapse> */}
        
        </>
    );
}

const mapStateToProps = store => ({
    user: store.user,
});

const mapDispatchToProps = {
    clearUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);