import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Card } from 'reactstrap';
import { FaSearch, FaExclamationCircle, FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { MsgErrorDataTable } from '../styles';
import SimpleTable from '../../components/TablesGrid/SimpleTable';
import MaterialSelect from '../../components/Inputs/MaterialSelect';

export default function BuscaEmpresas(props) {
  const { empresas, nivel_usuario } = props;
  const history = useHistory();
  const columns = [
    { name: 'id', title: 'Id', getCellValue: row => parseInt(row.id, 10) },
    { name: 'cpf', title: 'Cnpj', getCellValue: row => row.cnpj },
    { name: 'nome', title: 'Nome', getCellValue: row => row.nome },
    {
      name: 'status',
      title: 'Status',
      getCellValue: row => (row.status == 1 ? 'Ativa' : 'Inativa'),
    },
    {
      name: 'nomes_funcionarios',
      title: 'Funcionários',
      getCellValue: row => row.nomes_funcionarios,
    },
    { name: '', title: '', getCellValue: row => actions(row) },
  ];

  const [filteredEmpresas, setFilteredEmpresas] = useState(empresas.output != undefined ? empresas.output : empresas);

  const actions = row => {
    return (
      <div className="btn-group">
        <Button
          size="sm"
          color="primary"
          outline
          onClick={() => props.history.push(`/empresa/${row.id}`)}
        >
          <FaSearch />
        </Button>
        &nbsp;
      </div>
    );
  };
  const handleChangeStatus = value => {
    const empresaArr =
      empresas.output != undefined ? empresas.output : empresas;

    if (value == -1) {
      setFilteredEmpresas(empresaArr);
      return;
    }
    setFilteredEmpresas(empresaArr.filter(empresa => empresa.status == value));
  };

  return (
    <Card className="my-4">
      <Row className="px-3 mt-3 justify-content-between align-items-center">
        {nivel_usuario != 2 && nivel_usuario != 5 && (
          <Button
            color="success"
            size="sm"
            outline
            onClick={() => history.push('/cadastrar-empresa')}
          >
            <FaPlus />
            &nbsp;&nbsp;Cadastrar Empresa
          </Button>
        )}
        <MaterialSelect
          label="Status"
          handleChange={handleChangeStatus}
          options={[
            { value: -1, label: 'Todos' },
            { value: 1, label: 'Ativa' },
            { value: 0, label: 'Inativa' },
          ]}
        />
      </Row>
      <Row className="mt-4">
        <Col md={12}>
          {empresas ? (
            <SimpleTable
              height="500px"
              rows={filteredEmpresas || []}
              columns={columns}
              hiddenColumns={['nomes_funcionarios']}
            />
          ) : (
            <MsgErrorDataTable>
              <FaExclamationCircle />
              &nbsp;&nbsp;{empresas.message}{' '}
            </MsgErrorDataTable>
          )}
        </Col>
      </Row>
    </Card>
  );
}
