import React, { useEffect, useState } from 'react';
import {
  Box,
  ButtonGroup,
  CircularProgress,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import { FaFileDownload, FaSearch } from 'react-icons/fa';
import CustomSkeleton from '../../components/Loading/Skeleton';
import formatValueToPrice from '../../utils/format';
import MaterialTable from '../../components/TablesGrid/MaterialTable';

function MaterialUITable(props) {
  const { rows, periodo, getExcel, columns } = props;

  const [rowsState, setRowsState] = useState(rows.data);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    downloadButton: {
      color: '#2e6d09',
    },
  });

  const handleBaixarExcel = async id => {
    setLoadingExcel(true);
    const excel = await getExcel(id);
    window.open(`${process.env.REACT_APP_API_ERP}/${excel.arquivo}`, '_blank');
    setLoadingExcel(false);
  };

  useEffect(() => {
    setRowsState(rows.data);
  }, [rows]);

  const classes = useStyles();

  return (
    <Box textAlign="left" mb="1rem">
      {/* <Box mb="1rem">
        <h4>Valores dos Vendedores:</h4>
      </Box> */}
      <TableContainer component={Paper} variant="outlined">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Distribuidor</TableCell>
              <TableCell>Primeiras Faturas</TableCell>
              <TableCell>Demais Faturas</TableCell>
              <TableCell>Total Carteira</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.loading ? (
              <>
                <TableRow>
                  <TableCell colSpan={5}>
                    <CustomSkeleton height={20} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5}>
                    <CustomSkeleton height={20} />
                  </TableCell>
                </TableRow>
              </>
            ) : rowsState.length ? (
              (rowsPerPage > 0
                ? rowsState.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                  )
                : rowsState
              ).map((row, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{row.nome}</TableCell>
                    <TableCell>{row.numPrimeirasFaturas}</TableCell>
                    <TableCell>{row.numDemaisFaturas}</TableCell>
                    <TableCell>{formatValueToPrice(row.valor)}</TableCell>
                    <TableCell>
                      {loadingExcel ? (
                        <Box
                          width={79}
                          height={24}
                          display="flex"
                          alignItems="center"
                        >
                          <CircularProgress size="1rem" />
                        </Box>
                      ) : (
                        // <CustomSkeleton height={24} width={79} />
                        <ButtonGroup>
                          <IconButton
                            className={classes.downloadButton}
                            size="small"
                            onClick={() => handleBaixarExcel(row.id_assessoria)}
                          >
                            <FaFileDownload />
                          </IconButton>
                          <IconButton
                            color="primary"
                            size="small"
                            href={`comissao-assessoria/${row.id_assessoria}?periodo=${periodo}`}
                          >
                            <FaSearch />
                          </IconButton>
                        </ButtonGroup>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={4}>Sem dados</TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={4}
                count={rowsState.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Vendedores por página:"
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {/* <MaterialTable rows={rowsState} columns={columns} /> */}
    </Box>
  );
}

export default MaterialUITable;
