import MinhasVendas from '../controllers/MinhasVendas/Controller';
import Segurado from '../controllers/Segurado/Controller';
import RegisterNewClient from '../controllers/CadastroClienteIndividual/Controller';
import SeguradosApolices from '../controllers/SeguradosApolices/Controller';
import Segurados from '../controllers/Segurados/Controller';
import Apolice from '../controllers/Apolice/Controller';
import AlterarSenha from '../controllers/AlterarSenha/Controller';
import Faturas from '../controllers/Faturas/Controller';
import Cobrancas from '../controllers/Cobrancas/Controller';
import Empresas from '../controllers/Empresas/Controller';
import CadastroEmpresa from '../controllers/CadastrarEmpresa/Controller';
import Convenios from '../controllers/Convenios/Controller';
import Convenio from '../controllers/Convenio/Controller';
import Empresa from '../controllers/Empresa/Controller';
import EditarEmpresa from '../controllers/EditarEmpresa/Controller';
import BaseTerceiros from '../controllers/BaseTerceiros/Controller';
import RelatorioVendas from '../controllers/RelatorioVendas/Controller';
import Movimentacoes from '../controllers/Movimentacoes/Controller';
import Funcionario from '../controllers/Funcionario/Controller';
import EditarFuncionario from '../controllers/EditarFuncionario/Controller';
import EditarClienteIndividual from '../controllers/EditarClienteIndividual/Controller';
import Cupons from '../controllers/Cupons/Controller';
import RelacaoUsoCupons from '../controllers/RelacaoUsoCupons/Controller';
import Assessorias from '../controllers/Assessorias/Controller';
import EditarConvenio from '../controllers/EditarConvenio/Controller';
import Dashboard from '../controllers/Dashboard/Controller';
// import DashboardAssessoria from '../controllers/DashboardAssessoria/Controller';
import Treinamentos from '../controllers/Treinamentos/Controller';
// import TodosMeusClientes from '../controllers/TodosMeusClientes/Controller';
import CadastroContatoEmpresa from '../controllers/CadastroContatoEmpresa/Controller';
import MaterialApoioVenda from '../controllers/MaterialApoioVenda/Controller';
import Produtos from '../controllers/Produtos/Controller';
import EditarProduto from '../controllers/EditarProduto/Controller';
import Beneficios from '../controllers/Beneficios/Controller';
import EmpresaFuncionarios from '../controllers/EmpresaFuncionarios/Controller';
import AcoesApolices from '../controllers/AcoesApolices/Controller';
import ComissaoAssessoria from '../controllers/ComissaoAssessoria/Controller';
import CadastroPlanoFuncionarios from '../controllers/CadastroPlanoFuncionarios/Controller';
import ApoliceEmpresarial from '../controllers/ApoliceEmpresarial/Controller';
import Comissoes from '../controllers/Comissoes/Controller';
import Assessoria from '../controllers/Assessoria/Controller';

const dashRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fas fa-chart-pie',
    component: Dashboard,
    acessoUsuario: [1, 2, 5],
  },
  {
    path: '/relatorio-vendas',
    name: 'Relatório de Vendas',
    icon: 'fas fa-chart-line',
    component: RelatorioVendas,
    acessoUsuario: [1, 2],
  },

  {
    path: '/treinamentos',
    name: 'Treinamentos',
    icon: 'fas fa-user-graduate',
    component: Treinamentos,
    acessoUsuario: [1, 2, 3, 4, 5],
  },
  {
    path: '/material-venda',
    name: 'Material de Venda',
    icon: 'fas fa-briefcase',
    component: MaterialApoioVenda,
    acessoUsuario: [1, 2, 3, 4, 5],
  },
  {
    name: 'Clientes',
    icon: 'fas fa-users',
    acessoUsuario: [1],
  },
  {
    path: '/home',
    name: 'Vendas Familiares',
    icon: 'fas fa-search-dollar',
    component: MinhasVendas,
    acessoUsuario: [2, 3, 4, 5],
  },
  {
    path: '/home',
    name: 'Home',
    icon: 'fas fa-hand-holding-usd',
    component: Cobrancas,
    acessoUsuario: [6],
  },
  {
    path: '/cadastro-contato-empresa/:idEmpresaCadastro',
    name: 'Cadastro Contato Empresa',
    icon: 'fas fa-search-dollar',
    invisible: true,
    component: CadastroContatoEmpresa,
    acessoUsuario: [1, 2, 4, 5],
  },
  {
    path: '/cadastrar-plano-funcionarios/:idEmpresaCadastro',
    name: 'Cadastro Plano Funcionários',
    invisible: true,
    component: CadastroPlanoFuncionarios,
    acessoUsuario: [1, 2, 3, 4, 5],
  },
  // Esse link não tem administrador porque já existe venda empresarial no Link Clientes(para o admin)
  {
    path: '/empresas',
    name: 'Vendas Empresariais',
    icon: 'fas fa-building',
    component: Empresas,
    acessoUsuario: [2, 4, 5],
  },
  {
    path: '/lista-funcionarios/:idEstipulante',
    name: 'Funcionários Empresas',
    icon: 'fas fa-users',
    invisible: true,
    component: EmpresaFuncionarios,
    acessoUsuario: [1, 2, 4, 5],
  },
  {
    path: '/cupons/relacao-de-uso',
    name: 'Relação de uso de Cupons',
    icon: 'fa fa-ticket',
    component: RelacaoUsoCupons,
    invisible: true,
    acessoUsuario: [1, 5],
  },
  {
    path: '/cupons',
    name: 'Cupons',
    icon: 'fa fa-ticket',
    component: Cupons,
    invisible: true,
    acessoUsuario: [1, 5],
  },
  {
    path: '/assessorias',
    name: 'Assessorias',
    icon: 'fas fa-users',
    component: Assessorias,
    invisible: true,
    acessoUsuario: [1, 5],
  },
  {
    path: '/assessoria/:idAssessoria',
    name: 'Assessoria',
    icon: 'fas fa-users',
    component: Assessoria,
    invisible: true,
    acessoUsuario: [1],
  },
  {
    path: '/produtos',
    name: 'Produtos',
    icon: 'fas fa-product-hunt',
    component: Produtos,
    invisible: true,
    acessoUsuario: [1, 5],
  },
  {
    path: '/beneficios',
    name: 'Benefícios',
    icon: 'fas fa-handshake',
    component: Beneficios,
    invisible: true,
    acessoUsuario: [1, 5],
  },
  {
    path: '/cobrancas',
    name: 'Cobranças',
    icon: 'fas fa-hand-holding-usd',
    component: Cobrancas,
    acessoUsuario: [1, 2, 5],
  },
  {
    path: '/faturas/:mesAno/:status',
    name: 'Faturas',
    component: Faturas,
    invisible: true,
    acessoUsuario: [1, 5],
  },
  {
    path: '/movimentacoes',
    name: 'Movimentações',
    icon: 'fas fa-exchange-alt',
    component: Movimentacoes,
    acessoUsuario: [1],
  },
  {
    path: '/segurados',
    component: Segurados,
    invisible: true,
    acessoUsuario: [1, 5],
  },
  {
    path: '/segurado/:idClient',
    name: 'Segurado',
    component: Segurado,
    invisible: true,
    acessoUsuario: [1, 2, 3, 4, 5, 6],
  },
  {
    path: '/acoes-apolices',
    component: AcoesApolices,
    invisible: true,
    acessoUsuario: [1, 5],
  },
  {
    path: '/segurados-apolices',
    component: SeguradosApolices,
    invisible: true,
    acessoUsuario: [1, 5],
  },
  {
    path: '/apolice/:id/:idEmpresa/:idClient',
    name: 'Apolice',
    component: Apolice,
    invisible: true,
    acessoUsuario: [1, 2, 3, 4, 5, 6],
  },
  {
    path: '/apolice-empresarial/:id/:idEmpresa',
    name: 'Apolice Empresarial',
    component: ApoliceEmpresarial,
    invisible: true,
    acessoUsuario: [1, 2, 3, 4, 5],
  },
  {
    path: '/empresas',
    name: 'Empresas',
    component: Empresas,
    invisible: true,
    acessoUsuario: [1, 2, 4, 5],
  },
  {
    path: '/editar-empresa/:id',
    name: 'Editar Empresa',
    component: EditarEmpresa,
    invisible: true,
    acessoUsuario: [1, 2, 4, 5],
  },
  {
    path: '/empresa/:idEstipulante',
    name: 'Empresa',
    component: Empresa,
    invisible: true,
    acessoUsuario: [1, 2, 4, 5],
  },
  {
    path: '/cadastrar-empresa',
    name: 'Cadastro de Empresas',
    invisible: true,
    component: CadastroEmpresa,
    acessoUsuario: [1, 2, 4, 5],
  },
  {
    path: '/funcionario/:idFuncionario',
    name: 'Funcionário',
    invisible: true,
    component: Funcionario,
    acessoUsuario: [1, 2, 4, 5],
  },
  {
    path: '/editar-funcionario/:idClient',
    name: 'Editar Funcionário',
    invisible: true,
    component: EditarFuncionario,
    acessoUsuario: [1, 2, 4, 5],
  },
  {
    path: '/convenios',
    name: 'Convenios',
    component: Convenios,
    invisible: true,
    acessoUsuario: [1, 5],
  },
  {
    path: '/convenio/:id',
    name: 'Convenio',
    component: Convenio,
    invisible: true,
    acessoUsuario: [1, 5],
  },
  {
    path: '/editar-convenio/:id',
    name: 'Editar Convênio',
    component: EditarConvenio,
    invisible: true,
    acessoUsuario: [1, 5],
  },
  {
    path: '/alterar-senha',
    name: 'Alterar Senha',
    invisible: true,
    component: AlterarSenha,
    acessoUsuario: [1, 2, 3, 4, 5],
  },
  {
    path: '/base-terceiros',
    name: 'Base Terceiros',
    icon: 'fas fa-user-shield',
    component: BaseTerceiros,
    acessoUsuario: [1],
  },
  {
    path: '/base-terceiros/:idTerceiro',
    name: 'Base Terceiros',
    invisible: true,
    component: BaseTerceiros,
    acessoUsuario: [1],
  },
  {
    path: '/cadastrar-cliente-individual',
    name: 'Cadastro Cliente Individual',
    invisible: true,
    component: RegisterNewClient,
    acessoUsuario: [1, 2, 3, 4, 5],
  },
  {
    path: '/editar-cliente-individual/:idClient',
    name: 'Editar Cliente Individual',
    invisible: true,
    component: EditarClienteIndividual,
    acessoUsuario: [1, 2, 3, 4, 5],
  },
  {
    name: 'Editar Produto',
    path: '/editar-produto/:id',
    invisible: true,
    component: EditarProduto,
    acessoUsuario: [1],
  },
  {
    name: 'Comissões',
    path: '/comissoes',
    component: Comissoes,
    icon: 'fa fa-percent',
    acessoUsuario: [1, 5],
  },
  {
    name: 'Comissões',
    path: '/comissao-assessoria/:id',
    component: ComissaoAssessoria,
    icon: 'fa fa-percent',
    acessoUsuario: [1, 2],
  },
  {
    redirect: true,
    path: '/',
    pathTo: '/Dashboard',
    acessoUsuario: [1, 5],
  },
  {
    name: 'Ajustes',
    icon: 'fas fa-wrench',
    acessoUsuario: [1],
  },
  {
    name: 'Novo Produto',
    path: '/novo-produto',
    icon: 'fas fa-boxes',
    invisible: true,
    acessoUsuario: [1, 5],
  },
];
export default dashRoutes;
