import React, { useState } from 'react';
import { Card, Container, Row, Col, Form, Input, InputGroupAddon, InputGroup, InputGroupText, Button, Spinner } from 'reactstrap';
import { BodyFormComponent, LabelForm, PrependInputLabel } from '../styles';
import TitleCardPolicies from '../../components/TitleContent/TitleCardPolicies';
import { FaFileContract, FaCalendar, FaFileInvoiceDollar } from 'react-icons/fa'; 
import Api from '../../services/api';
import { verificaCamposVaziosInput } from '../../services/service';
import { connect } from 'react-redux';
import { alertError } from '../../components/Alerts/Alerts';
import { ToastContainer } from 'react-toastify';

function CadastrarDescontoEmitirProposta(props) {
  
  const { idApolice, idEmpresa, funcionarios, setPolicieConfigPayment } = props;
  const { nivel_usuario } = props.user.user;
  const [ loading, setLoading ] = useState(false);

  const [ propostaInput, setPropostaInput ] = useState({
    dep_1_gratis: '',
    desconto_titular: '',
    primeiro_pagamento: new Date().toISOString().split('T')[0],
    id_apolice: idApolice,
    id_estipulante: idEmpresa,
  });

  // const getTodayDate = _ => {
  //   let hoje = new Date();
  //   let hojeFormatado = hoje.toISOString().split('T')[0];
  //   return hojeFormatado;
  // }

  const handleInputChange = e => {
    const { name, value } = e.target;
    setPropostaInput({ ...propostaInput, [name]: value });
  }

  const emitirProposta = async _ => {
    setLoading(true);
    let arrVerificaCampos = [
      {"nomeInput": "Data Primeiro Pagamento", "valorInput": propostaInput.primeiro_pagamento }
    ];

    if(Object.values(funcionarios.output).length > 0 ){
      if(nivel_usuario == 1){
        if(verificaCamposVaziosInput(arrVerificaCampos)){
          setLoading(false)
          return;
        };
      }
      
      if(nivel_usuario != 1){
        propostaInput.dep_1_gratis = '0';
        propostaInput.desconto_titular = '0';
      }

      const result = await Api.post("proposta-empresarial", propostaInput, { headers: { Authorization: `Bearer ${props.user.user.token}` }});

      if(result.data.ok){
        window.location.reload();
      }else{
        alertError(result.data.message);
      }
      
    }else{
      alertError("Adicione algum funcionário para gerar uma proposta.");
    }
    
    setLoading(false)

  }

  const titleCard = <><h3><FaFileContract/></h3>&nbsp;&nbsp;Apólice #{idApolice} </>
  return (
    <Card>
      <ToastContainer />
      <BodyFormComponent>
      <Container>
        <Row className="mt-4 ml-4 mb-4"><TitleCardPolicies titleCard={titleCard}/></Row>
        <hr/>
        <Row className="text-left"><Col><h5>Finalizar Dados para Proposta</h5></Col></Row>
        <hr/>
        <Form>
          <Row className="text-left mt-4">
            {
              (nivel_usuario == 1)&&
              (<>
              <Col md={12}>
                <LabelForm>Primeiro Dependente Gratuito?</LabelForm>
                <Input type="select" name="dep_1_gratis" value={propostaInput.dep_1_gratis} onChange={e => handleInputChange(e)}>
                  <option value="">Selecione</option>
                  <option value="0">Não</option>
                  <option value="1">Sim</option>
                </Input>
              </Col>
              <Col md={12} className="mt-2">
                <LabelForm>Desconto no Titular</LabelForm>
                <InputGroup>
                  <Input required name="desconto_titular" value={propostaInput.desconto_titular} onChange={e => handleInputChange(e)}/>
                  <InputGroupAddon addonType="append">
                    <InputGroupText><PrependInputLabel>%</PrependInputLabel></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              </>)
            }
            <Col className="mt-2">
              {/* <LabelForm>Primeiro Pagamento</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText><PrependInputLabel><FaCalendar /></PrependInputLabel></InputGroupText>
                </InputGroupAddon>
                <Input type="date" required name="primeiro_pagamento" value={propostaInput.primeiro_pagamento} onChange={e => handleInputChange(e)}/>
              </InputGroup> */}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              {
                loading
                ?
                (<Spinner color="primary" size="sm" />)
                :
                (<Button size="sm" color="success" onClick={() => emitirProposta()}><FaFileInvoiceDollar/> Gerar Proposta</Button>)
              }
            </Col>
          </Row>
        </Form>
      </Container>
      </BodyFormComponent>
    </Card>
  )
}

const mapStateToProps = store => ({
  user: store.user,
})

export default connect(mapStateToProps)(CadastrarDescontoEmitirProposta);
