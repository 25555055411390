import React from 'react';
import ListaTreinamentos from '../../views/Treinamentos/ListaTreinamentos';
import { FaUserGraduate } from 'react-icons/fa';
import { Container, Row, Col } from 'reactstrap';
import TitleContent from '../../components/TitleContent/TitleContent';

function Page(props) {
  
  const titleHeader = <><FaUserGraduate/>&nbsp;&nbsp; Comece a aprender agora! </> ;

  return (
    <Container className="mb-4" fluid={true}>
      <Row className="text-left mt-4 mb-4">
        <Col lg={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row>
        <ListaTreinamentos {...props} />
      </Row>
    </Container>
  )
}

export default Page;
