import styled from 'styled-components';

export const CardBodyComponent = styled.div`

    .card-header, .card-footer {
        background-color: transparent
    }

    .table > thead > tr > th {
        cursor: pointer
    }

    .table > tbody > tr {
        text-align: left;
    }

    .table > tbody > tr > td {
        font-size: 14px;
    }
`;