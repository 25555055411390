import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FaArrowLeft, FaPeopleCarry, FaWrench } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import TitleContent from '../../components/TitleContent/TitleContent';
import InformacoesAssessoria from '../../views/Assessoria/InformacoesAssessoria';
import ContatosAssessoria from '../../views/Assessoria/ContatosAssessoria';
import VendedoresLista from '../../views/Assessoria/VendedoresLista';
import ModalEditarVendedor from '../../views/Assessoria/ModalEditarVendedor';
import EditarAssessoria from '../../views/Assessoria/EditarAssessoria';
import ComissaoAssessoria from '../../views/Assessoria/ComissaoAssessoria';
import DadosBancariosAssessoria from '../../views/Assessoria/DadosBancariosAssessoria';

function Page(props) {
  const {
    assessoria,
    vendedores,
    submitChangeStatus,
    loadingChangeStatus,
    getVendedores,
    getDadosAssessoria,
  } = props;

  const history = useHistory();

  const [modalEditarVendedor, setModalEditarVendedor] = useState(false);
  const [modalEditarAssessoria, setModalEditarAssessoria] = useState(false);
  const [vendedorSelected, setVendedorSelected] = useState({});

  const openModal = vendedor => {
    setVendedorSelected(vendedor);
    setModalEditarVendedor(true);
  };

  const closeModal = () => {
    setModalEditarVendedor(!modalEditarVendedor);
  };

  const toggleModalEditar = () => {
    setModalEditarAssessoria(!modalEditarAssessoria);
  };
  const titleHeader = (
    <>
      <FaPeopleCarry />
      &nbsp;&nbsp;{assessoria.data.nome_fantasia}
    </>
  );
  return (
    <Container fluid>
      <Row className="mt-4">
        <Col md={2}>
          <Button
            className="btn-back w-100"
            outline
            color="danger"
            size="sm"
            onClick={() => history.goBack()}
          >
            <FaArrowLeft />
            &nbsp;&nbsp;Voltar
          </Button>
        </Col>
      </Row>
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row>
        <Col xs="auto" className="mt-3">
          <Button
            color="success"
            outline
            size="sm"
            className="mb-2"
            onClick={() => toggleModalEditar()}
            disabled={assessoria.loading}
          >
            <FaWrench />
            {assessoria.loading ? '  Carregando...' : '  Editar'}
          </Button>
        </Col>
      </Row>
      <Row className="mb-4 mt-2">
        <Col md={6} className="mb-4">
          <InformacoesAssessoria assessoria={assessoria} />
        </Col>
        <Col md={6} className="mb-4">
          <ContatosAssessoria assessoria={assessoria} />
        </Col>
        {assessoria.data.tipo != 'master' && (
          <Col md={6}>
            <ComissaoAssessoria assessoria={assessoria} />
          </Col>
        )}
        <Col md={6} className="mb-4">
          <DadosBancariosAssessoria assessoria={assessoria} />
        </Col>
      </Row>
      <Row className="pb-4">
        <Col>
          <VendedoresLista
            vendedores={vendedores}
            submitChangeStatus={submitChangeStatus}
            loadingChangeStatus={loadingChangeStatus}
            openModal={openModal}
          />
        </Col>
      </Row>
      <ModalEditarVendedor
        modal={modalEditarVendedor}
        toggle={closeModal}
        vendedor={vendedorSelected}
        getVendedores={getVendedores}
      />
      <EditarAssessoria
        toggle={toggleModalEditar}
        modal={modalEditarAssessoria}
        assessoria={assessoria}
        getAssessoria={getDadosAssessoria}
      />
    </Container>
  );
}

export default Page;
