import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import Page from './Page';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

function Segurados(props) {

    let history = useHistory();
    const [ segurados, setSegurados ] = useState();
    const [ loadingClientes, setLoadingClientes ]  = useState(true);
    const [ gruposVenda, setGruposVenda ] = useState(true);

    useEffect(
        () => {
            getClients();
            getGruposVenda();
        },[]
    )

    const getClients = async _ => {
        let fetchClients = await Api.get('segurados/', { headers: { Authorization: `Bearer ${props.user.user.token}` }});
        fetchClients.data.output.reverse();
        setSegurados(fetchClients.data);
        setLoadingClientes(false);
    }

    const getGruposVenda = async _ => {
        let fetchGrupoVendas = await Api.get('grupovendas/', { headers: { Authorization: `Bearer ${props.user.user.token}` }});
        if(fetchGrupoVendas.data.ok) {
            setGruposVenda(fetchGrupoVendas.data.output);
        }
    }

    return (
        <>
            {
                segurados&&
                <Page segurados={segurados} loadingClientes={loadingClientes} history={history} gruposVenda={gruposVenda} />
            }
        </>
    );
}

const mapStateToProps = store => ({
    user: store.user,
});

export default connect(mapStateToProps)(Segurados); 