import React from 'react';
import { Card, Container, Row, Table } from 'reactstrap';
import { TableContent } from '../styles';
import TitleCardPolicies from '../../components/TitleContent/TitleCardPolicies';
import { FaEnvelope, FaExclamationTriangle, FaSearch } from 'react-icons/fa';
import { formatDate } from '../../services/service';
import Api from '../../services/api';

const titleCard = <><h3><FaEnvelope/></h3>&nbsp;&nbsp;Histórico de Status</>

function HistoricoStatus(props) {
  
  const { historicoStatus, idApolice } = props;

  const baixarHistorico = async _ => {
    const response = await Api.get(`/historico-status/${idApolice}/exportar/`, '');

    if(response.data.ok){
     
      window.open(`${process.env.REACT_APP_API_ERP}/${response.data.output.path}`, "_blank");
      
      setTimeout((e) => {
        Api.post(`/historico-status/excluir-pdf/`, response.data.output.path)
        .then(function(response){});
      }, 5000);
      
    } 
    
  }

  return (
    <Card>
      <Container>
        <TableContent>
          <Row className="mt-4 ml-4 mb-4"><TitleCardPolicies titleCard={titleCard}/></Row>
          {
          historicoStatus.output.length > 0
          ?
            <Table>
              <thead className="text-left">
                <tr>
                  <th>Status</th>
                  <th>Motivo</th>
                  <th>Anotação</th>
                  <th>Data</th>
                  <th>Hora</th>
                  <th>Link arq.</th>
                </tr>
              </thead>
              <tbody className="text-left">
                {
                  historicoStatus.output.map((el, index) => (
                  <tr key={index}>
                    <td>{el.status}</td>
                    <td>{el.motivo}</td>
                    <td>{el.descricao}</td>
                    <td>{formatDate(el.created.split(" ")[0])}</td>
                    <td>{el.created.split(" ")[1]}</td>
                    <td className="text-center">
                      <FaSearch style={{cursor: "pointer", color: "#007bff"}} onClick={() => baixarHistorico()} />
                    </td>
                  </tr>
                  ))
                }
              </tbody>
            </Table>
          :
          <div className="mb-4 text-left">
            <span style={{color: "red"}}> <FaExclamationTriangle /> {historicoStatus.message}</span>
          </div>
          }
        </TableContent>
      </Container>
    </Card>
  )
}

export default HistoricoStatus;
