import React from 'react';
import CadastroClienteIndividual from '../../components/Forms/CadastroClienteIndividual';
import { Card } from 'reactstrap';

function EditarClienteIndividual(props) {
  
  const { clientInputEdit } = props;
  let mostraInfoEndereco = true;

  if(clientInputEdit.id_estipulante == 1){
    mostraInfoEndereco = true;
  }
  
  return (
    <Card style={{ marginTop: "30px", marginBottom: "30px", width: "100%" }}>
      <CadastroClienteIndividual 
        {...props} 
        mostraInfoEndereco={mostraInfoEndereco} 
        mostraInfoDependente={true} 
        mostraInfoPagamento={true}
      />
    </Card>
  )
}

export default EditarClienteIndividual;
