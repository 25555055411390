import React, { useCallback, useState } from 'react';
import {
  Card,
  Col,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Spinner,
} from 'reactstrap';
import {
  FaFileContract,
  FaTrash,
  FaSearch,
  FaExclamationCircle,
  FaRetweet,
  FaPlus,
} from 'react-icons/fa';
import { ToastContainer } from 'react-toastify';
import { CardComponent, MsgErrorDataTable, OverflowContainer } from '../styles';
import Api from '../../services/api';
import {
  alertError,
  alertSuccess,
  alertWarning,
} from '../../components/Alerts/Alerts';

import { renovaApolice } from '../../services/service';

function ApolicesEmpresa(props) {
  const {
    infosApolice,
    token,
    history,
    idEstipulante,
    resultBuscaProd,
    getApolices,
  } = props;
  const [idApolice, setIdApolice] = useState('');
  const [modal, setModal] = useState(false);
  const [renovarInput, setRenovarInput] = useState('');
  const [loadingDeleteApolice, setLoadingDeleteApolice] = useState(false);
  const [loadingRenovarApolice, setLoadingRenovarApolice] = useState(false);

  const toggle = () => setModal(!modal);

  const deletarApolice = async id => {
    setLoadingDeleteApolice(true);
    try {
      const response = await Api.delete(`apolice/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.ok) {
        alertSuccess('Apólice excluída com sucesso!');
        await getApolices();
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError('Falha ao excluir apólice: ', error.message);
    } finally {
      setLoadingDeleteApolice(false);
    }
  };

  const renovarApolice = idApol => {
    setModal(true);
    setIdApolice(idApol);
  };

  const confirmaRenovacao = async () => {
    setLoadingRenovarApolice(true);
    if (renovarInput === 'RENOVAR') {
      const response = await Api.post(`apolice/${idApolice}/renovar`, '', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.ok) {
        window.location.reload();
      } else {
        setLoadingRenovarApolice(false);
        alertError('Erro ao renovar apolice');
      }
    } else {
      setLoadingRenovarApolice(false);
      alertWarning('O campo deve ser igual a palavra RENOVAR');
    }
  };

  const handleOpenApolice = useCallback(
    idApol => {
      const { status } = infosApolice.output.find(
        apolice => apolice.id === idApol,
      );

      if (status === 'Não preenchida') {
        history.push(
          `/cadastrar-plano-funcionarios/${idEstipulante}?idApolice=${idApol}`,
        );
      } else {
        history.push(`/apolice-empresarial/${idApol}/${idEstipulante}`);
      }
    },
    [history, idEstipulante, infosApolice],
  );

  const handleDeleteApolice = id => {
    if (window.confirm(`Deseja mesmo excluir a apólice ${id}?`)) {
      deletarApolice(id);
    }
  };

  return (
    <Card style={{ borderTop: '2px solid #DA2626' }}>
      <ToastContainer />
      <CardComponent>
        <Row className="m-0 justify-content-between flex-wrap">
          <Col className="header-content mb-3" xs="auto">
            <h2>
              <FaFileContract style={{ color: '#DA2626' }} />
              &nbsp;&nbsp;Apólices
            </h2>
          </Col>
          {resultBuscaProd && (
            <Col xs="auto" className="d-flex justify-content-start mb-4">
              <Button
                className="font-weight-bold"
                color="success"
                outline
                size="md"
                onClick={() =>
                  history.push(`/cadastrar-plano-funcionarios/${idEstipulante}`)
                }
              >
                <FaPlus className="mr-2" />
                Cadastrar nova apólice
              </Button>
            </Col>
          )}
        </Row>
        {infosApolice.output && infosApolice.output.length > 0 ? (
          <OverflowContainer>
            <Table>
              <thead>
                <tr>
                  <th>#ID</th>
                  <th>Nome</th>
                  <th>Status</th>
                  <th>Vidas</th>
                  <th>Valor</th>
                  <th>Funcionalidades</th>
                </tr>
              </thead>
              <tbody>
                {infosApolice.output.map((el, i) => {
                  let renovar = '';
                  if (el.inicio_vigencia)
                    renovar = renovaApolice(
                      el.periodo_vigencia,
                      el.inicio_vigencia,
                    );

                  return (
                    <tr key={i}>
                      <td>{el.id}</td>
                      <td>{el.nome}</td>
                      <td>{el.status}</td>
                      <td>{el.qtd_segurados}</td>
                      <td>
                        {el.valor ? `R$ ${el.valor}` : 'Sem Config. Pgto.'}
                      </td>
                      <td>
                        <Button
                          size="sm"
                          color="primary"
                          outline
                          onClick={() => handleOpenApolice(el.id)}
                        >
                          <FaSearch />
                        </Button>
                        &nbsp;
                        {renovar /* && el.status === "Em Vigência" */ && (
                          <Button
                            size="sm"
                            color="warning"
                            outline
                            onClick={() => renovarApolice(el.id)}
                          >
                            <FaRetweet />
                          </Button>
                        )}
                        &nbsp;
                        <Button
                          size="sm"
                          color="danger"
                          outline
                          onClick={() => handleDeleteApolice(el.id)}
                          disabled={loadingDeleteApolice}
                        >
                          {loadingDeleteApolice ? (
                            <Spinner size="sm" />
                          ) : (
                            <FaTrash />
                          )}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>Renovar Apólice</ModalHeader>
              <ModalBody>
                <Label>
                  Se você deseja renovar essa fatura escreva &quot;RENOVAR&quot;
                </Label>
                <Input
                  type="text"
                  name="renovarInput"
                  value={renovarInput}
                  onChange={e => setRenovarInput(e.target.value)}
                />
                <Button
                  color="success"
                  className="mt-2 float-right"
                  size="sm"
                  onClick={() => confirmaRenovacao()}
                  disabled={loadingRenovarApolice || renovarInput != 'RENOVAR'}>
                  Confirmar {loadingRenovarApolice ? (<Spinner size="sm" />) : null}
                </Button>
                <Button
                  className="mt-2 mr-2 float-right"
                  color="danger"
                  size="sm"
                  onClick={() => setModal(false)}>
                  Cancelar
                </Button>
              </ModalBody>
            </Modal>
          </OverflowContainer>
        ) : (
          <MsgErrorDataTable
            style={{ marginLeft: '10px', marginBottom: '10px' }}
          >
            <FaExclamationCircle />
            &nbsp;&nbsp;{infosApolice.message}
          </MsgErrorDataTable>
        )}
      </CardComponent>
    </Card>
  );
}

export default ApolicesEmpresa;
