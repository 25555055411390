import Styled from 'styled-components';

export const PriceComponent = Styled.div`
    text-align: right;

    h1 {
        font-family: "Futura";
    }
    h2 {
        font-size: 35px;
    }
    h5 {
        color: #5d5d5d;
    }
    span {
        font-weight: 800;
    }
`;