import React, { useState } from 'react';
import {
  PagingState,
  IntegratedPaging,
  SearchState,
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  TableHeaderRow,
  TableColumnVisibility,
  PagingPanel,
  SearchPanel,
} from '@devexpress/dx-react-grid-bootstrap4';
import { Card, CardBody } from 'reactstrap';
import { CardBodyComponent } from './styles';

export default function TablesGrid(props) {
  const lng = props.rows.length;

  const [columns] = useState(props.columns);
  const [hiddenColumns] = useState(props.hiddenColumns);
  const [sortColumns] = useState([props.defaultSorting ? props.defaultSorting : {}]);
  const [searchValue, setSearchState] = useState('');
  const [pageSize] = useState(props.pageSize ? props.pageSize : 10);
  const searchMessages = { searchPlaceholder: 'Procurar ...' };

  const [tableColumnExtensions] = useState([
    { columnName: 'gender', width: 100 },
  ]);

  const [hiddenColumnNames, setHiddenColumnNames] = useState(hiddenColumns);

  return (
    <>
      {lng > 0 ? (
        <Card style={{ marginTop: '30px' }}>
          <CardBodyComponent>
            <CardBody>
              <Grid rows={props.rows} columns={columns}>
                <SearchState
                  value={searchValue}
                  onValueChange={setSearchState}
                />

                <SortingState defaultSorting={sortColumns} />

                <IntegratedFiltering />
                <PagingState defaultCurrentPage={0} pageSize={pageSize} />
                <IntegratedSorting />
                <IntegratedPaging />
                <Table columnExtensions={tableColumnExtensions} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                  hiddenColumnNames={hiddenColumnNames}
                  onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <Toolbar />
                <SearchPanel messages={searchMessages} />
                <PagingPanel />
              </Grid>
            </CardBody>
          </CardBodyComponent>
        </Card>
      ) : (
        <span>Sem Dados</span>
      )}
    </>
  );
}
