import React from 'react';
import { PriceComponent } from './styles';

export default function FinalPrice(props) {
  return (
    <PriceComponent>
      <h1>R$ {props.price}</h1>
      <h5>Total para o Plano <span>Viver</span></h5>
    </PriceComponent>
  );
}
