import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import TitleContent from '../../components/TitleContent/TitleContent';
import BuscaFaturas from '../../views/Faturas/BuscaFaturas';
import BuscaMesFaturas from '../../views/Faturas/BuscaMesFaturas';
import { FaHandHoldingUsd } from 'react-icons/fa';


function Pages(props) {

  const titleHeader = <><FaHandHoldingUsd/>&nbsp;&nbsp;Faturas</>;
  const { faturas, inputs, handleOnChange, getInfosFaturas, loading } = props;

  return (
    <Container fluid={true}>
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={4} className="mt-4">
          <BuscaFaturas handleOnChange={handleOnChange} inputs={inputs} getInfosFaturas={getInfosFaturas} />
        </Col>
        <Col md={8} className="mt-4">
          <BuscaMesFaturas faturas={faturas} inputs={inputs} loading={loading} />
        </Col>
      </Row>
    </Container>
  )
}

export default Pages;
