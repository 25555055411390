import React from 'react';
import { Button, Col, Badge } from 'reactstrap';
import TablesGrid from '../../components/TablesGrid/TablesGrid';
import { FaPowerOff } from 'react-icons/fa';
import { formatDate } from '../../services/service';
import { alertError } from '../../components/Alerts/Alerts';
import Api from '../../services/api';
import styled from 'styled-components';

const SpanValidade = styled.span`
    color: red;
    font-weight: 600;
`;

const BtnContent = styled.span`
    font-size: 12px;
`;

function ListaCupons(props) {

    const { cupons, token } = props;

    let columns = [
        { name: 'codigo', title: 'Código', getCellValue: row => (row.codigo) },
        { name: 'descricao', title: 'Descrição', getCellValue: row => (row.descricao) },
        { name: 'qtd', title: 'Quantidade', getCellValue: row => (row.qtd) },
        { name: 'ativo', title: 'Status', getCellValue: row => row.ativo===1 ? (<h6><Badge color="success">Ativo</Badge></h6>) : (<h6><Badge color="danger">Inativo</Badge></h6>) },
        { name: 'validade', title: 'Validade', getCellValue: row => <SpanValidade>{formatDate(row.validade)}</SpanValidade> },
        { name: '', title: '', getCellValue: row => actions(row.id, row.ativo) }
    ];
    
    const actions = (id, status) => {
        
        let color = "";
        let rota = "";
        let label = "";
        let novoStatus = "";
        if(status === 1){
            color="danger";
            rota=`cupom/${id}/desativar`;
            label="desativar";
            novoStatus="0";
        }else{
            color="success";
            rota=`cupom/${id}/ativar`;
            label="ativar";
            novoStatus="1";
        }

        return(
            <Button color={color} size="sm" onClick={() => atualizaStatus(rota, novoStatus)}>
                <BtnContent><FaPowerOff />&nbsp;{label}</BtnContent>
            </Button>
        );
    } 

    const atualizaStatus = async (rota, novoStatus) => {
        
        const response = await Api.patch(rota, {},{ headers: { Authorization: `Bearer ${token}`}});

        if(response.data.ok){
            window.location.reload();
        }else{
            alertError(response.data.message);
        }
    }

    return(
        <Col md={12}>
            <TablesGrid rows={cupons.output} columns={columns}/>
        </Col>
    );
}

export default ListaCupons;