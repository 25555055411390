import React from 'react';
import { FaFileInvoiceDollar, FaSearch } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { Box } from '@material-ui/core';
import MaterialTable from '../../components/TablesGrid/MaterialTable';
import { FormatValorAtrasadoFatura } from '../styles';
import formatValueToPrice from '../../utils/format';

export default function SeguradosApolices(props) {
  const { policies, loading, history } = props;

  const columns = [
    // {
    //   field: 'id_segurado',
    //   headerName: 'ID segurado',
    //   width: 150,
    // },
    {
      field: 'nome_segurado',
      headerName: 'Nome',
      width: 350,
    },
    {
      field: 'cpf_segurado',
      headerName: 'CPF',
      width: 150,
    },
    {
      field: 'vendedor',
      headerName: 'Consultor',
      width: 300,
    },
    // {
    //   field: 'id_apolice',
    //   headerName: 'ID apolice',
    //   width: 125,
    // },
    // {
    //   field: 'descricao_apolice',
    //   headerName: 'Descrição apólice',
    //   width: 150,
    // },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   width: 120,
    // },
    {
      field: 'faturasExpiradas',
      headerName: 'Status faturas',
      width: 175,
      valueGetter: params =>
        params.getValue(params.id, 'qtd_faturas_expiradas') > 0
          ? 'Faturas Expiradas'
          : 'Faturas em Dia',
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      width: 120,
      renderCell: params => (
        <div>
          <Button
            size="sm"
            outline
            color="primary"
            onClick={() =>
              history.push(
                `/segurado/${params.getValue(params.id, 'id_segurado')}`,
              )
            }
          >
            <FaSearch />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Box m={2}>
      <MaterialTable rows={policies} columns={columns} loading={loading} />
      <FormatValorAtrasadoFatura>
        <p>Valor Total: </p>
        <h2>
          <FaFileInvoiceDollar />
          <span>
            {formatValueToPrice(
              policies.reduce((acc, current) => acc + current.valor, 0),
            )}
          </span>
        </h2>
      </FormatValorAtrasadoFatura>
    </Box>
  );
}
