import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import api from '../../services/api';
import Page from './Page';
import { ToastContainer } from 'react-toastify';
import { alertError } from '../../components/Alerts/Alerts';
import { useHistory, useParams } from 'react-router-dom';

function SeguradosApolices(props) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const [ policies, setPolicies ] = useState();
    const [ loadingApolices, setLoadingApolices ] = useState(true);
    const [ params, setParams ] = useState({
        apoliceStatus: urlParams.get('status') ? urlParams.get('status') : '',
    });
    
    let history = useHistory();

    useEffect(
        () => {
            fetchSeguradosApolices();
        },[params]
    )

    const fetchSeguradosApolices = async _ => {
        const {  token } = props.user.user;
        const authStr = `Bearer ${token}`;
        const res = await api.get(`segurados/apolices/?status=${params.apoliceStatus}`, { headers: { Authorization: authStr } });
        if(res.data.ok){
            if(Object.keys(res.data.output).length > 0){
                let policies = [];
                for(const i in res.data.output) {
                    policies = [ ...policies, res.data.output[i] ];
                }
                setPolicies(policies);
            }
        }else{
            alertError("Nenhuma apólice encontrada.")
            setPolicies([]);
        }
        setLoadingApolices(false)

    }

    return (
        <>
            <ToastContainer />
            {
                policies&&
                <Page 
                    policies={policies} 
                    loadingApolices={loadingApolices} 
                    params={params}  
                    setParams={setParams} 
                    history={history}
                />
            }
        </>
    );
}


const mapStateToProps = store => ({
    user: store.user,
});

export default connect(mapStateToProps)(SeguradosApolices);