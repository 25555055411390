import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FaArrowLeft, FaUsers, FaWrench } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import InformacoesFuncionario from '../../views/Funcionario/InformacoesFuncionario'
import FuncionarioDependente from '../../views/Funcionario/FuncionarioDependente';
import { msg } from '../../json/msg';

function Page(props) {

  const { history, infosFuncionario, depFuncionario, idEmpresa } = props;
  const titleHeader = <><FaUsers />&nbsp;&nbsp;{infosFuncionario.nome}</>
  
  return (
    <Container fluid={true}>
      <Row className="mt-4">
        <Col md={2}>
          <Button className="btn-back w-100" size="sm" outline color="danger" onClick={() => history.goBack()}>
            <FaArrowLeft/>&nbsp;&nbsp;Voltar
          </Button>
        </Col>
      </Row>
      <Row className="text-left mt-4 mb-4">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader}/>
        </Col>
        <Col md={12} className="text-right mt-2">
          <Button color="warning" size="sm" onClick={() => history.push({ pathname: `/editar-funcionario/${infosFuncionario.id}`, state : { idEmpresa: idEmpresa } })}>
            <FaWrench />&nbsp;Editar
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          {
            infosFuncionario&&
            <InformacoesFuncionario infosFuncionario={infosFuncionario}/>
          }
        
          {
            depFuncionario
            ?
            (<FuncionarioDependente depFuncionario={depFuncionario} />) 
            :
            (msg.dependentes_not_found)
          }
        </Col>
      </Row>
    </Container>
  )
}

export default Page;
