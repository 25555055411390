import React from 'react';
import { FaArrowLeft, FaBuilding } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';

import { ToastContainer } from 'react-toastify';
import TitleContent from '../../components/TitleContent/TitleContent';
import ResumoFuncionarios from '../../views/ApoliceEmpresarial/ResumoFuncionarios';
import Faturas from '../../views/ApoliceEmpresarial/Faturas';
import Documentos from '../../views/ApoliceEmpresarial/Documentos';
import Movimentacao from '../../views/ApoliceEmpresarial/Movimentacao';
import Anotacoes from '../../views/ApoliceEmpresarial/Anotacoes';
import DetalhesConfigApolice from '../../views/ApoliceEmpresarial/DetalhesConfigApolice';

export default function Page(props) {
  const {
    segurados,
    produtos,
    detalhesApolice,
    idEstipulante,
    funcInativos,
    handleMudarProdutoFuncionario,
    handleAtivarProdutoFuncionario,
    configPayment,
    faturas,
    getDetalhesApolice,
    archivesPolicies,
    idUsuario,
    valorApolice,
    gerarCobrancaEmpresarial,
    idApolice,
    movimentacao,
    anotacoes,
    submitMudarProdutoFuncionarios,
    handleDesfazerAlteracaoFuncionario,
    loadingAlterarProdutos,
    handleCancelarAlteracoesFuncionarios,
    loadingAtivarProdutos,
    submitAtivarProdutosFuncionarios,
    handleCancelarAlteracoesFuncionariosInativos,
    getAnotacoes,
    idEmpresa,
    getArquivosApolice,
    user,
    motivoCancelamento,
    getValorApolice,
    getConfigPayment,
  } = props;

  const history = useHistory();

  const titleHeader = (
    <>
      <FaBuilding />
      &nbsp;&nbsp;Apólice {idApolice}
    </>
  );

  return (
    <Container fluid>
      <ToastContainer />
      <Row className="mt-4">
        <Col md={2}>
          <Button
            className="btn-back w-100"
            outline
            color="danger"
            size="sm"
            onClick={() => history.push(`/empresa/${idEmpresa}`)}
          >
            <FaArrowLeft />
            &nbsp;&nbsp;Voltar
          </Button>
        </Col>
      </Row>
      <Row className="text-left mt-2 mb-4">
        <Col>
          <TitleContent titleHeader={titleHeader} />
        </Col>
        {/* <Col lg={12} className="mt-4">
          <Button color="success" onClick={() => history.push(`/lista-funcionarios/${idEstipulante}`)}>Funcionários </Button>
        </Col> */}
      </Row>
      <Row>
        <Col>
          <ResumoFuncionarios
            produtos={produtos}
            segurados={segurados}
            idEstipulante={idEstipulante}
            handleMudarProdutoFuncionario={handleMudarProdutoFuncionario}
            handleAtivarProdutoFuncionario={handleAtivarProdutoFuncionario}
            funcInativos={funcInativos}
            loadingAtivarProdutos={loadingAtivarProdutos}
            loadingAlterarProdutos={loadingAlterarProdutos}
            handleDesfazerAlteracaoFuncionario={
              handleDesfazerAlteracaoFuncionario
            }
            handleCancelarAlteracoesFuncionarios={
              handleCancelarAlteracoesFuncionarios
            }
            submitMudarProdutoFuncionarios={submitMudarProdutoFuncionarios}
            submitAtivarProdutosFuncionarios={submitAtivarProdutosFuncionarios}
            handleCancelarAlteracoesFuncionariosInativos={
              handleCancelarAlteracoesFuncionariosInativos
            }
            idApolice={idApolice}
            getArquivosApolice={getArquivosApolice}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Faturas
            detalhesApolice={detalhesApolice}
            configPayment={configPayment}
            faturas={faturas}
            getDetalhesApolice={getDetalhesApolice}
            idUsuario={idUsuario}
            valorApolice={valorApolice}
            segurados={segurados}
            gerarCobrancaEmpresarial={gerarCobrancaEmpresarial}
            archivesPolicies={archivesPolicies}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Documentos archivesPolicies={archivesPolicies} />
        </Col>
        <Col xs={12}>
          <DetalhesConfigApolice
            detalhesApolice={detalhesApolice}
            configPayment={configPayment}
            valorApolice={valorApolice}
            user={user}
            motivoCancelamento={motivoCancelamento}
            idApolice={idApolice}
            getDetalhesApolice={getDetalhesApolice}
            getValorApolice={getValorApolice}
            getConfigPayment={getConfigPayment}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Movimentacao movimentacao={movimentacao} idApolice={idApolice} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Anotacoes
            idApolice={idApolice}
            anotacoes={anotacoes}
            getAnotacoes={getAnotacoes}
          />
        </Col>
      </Row>
    </Container>
  );
}
