import React from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import { Quantity } from './styles';

function TableSelect({
  pesquisaBox,
  setPesquisaBox,
  faturas,
  primeiras,
  suspensas,
  declinadas,
  empresariais,
}) {
  return (
    <ButtonGroup
      orientation={window.innerWidth > '1050' ? 'horizontal' : 'vertical'}
      color="primary"
      aria-label="vertical outlined primary button group"
    >
      <Button onClick={() => setPesquisaBox(0)} disabled={pesquisaBox === 0}>
        Apólices Suspensas
        <Quantity>{suspensas}</Quantity>
      </Button>
      <Button onClick={() => setPesquisaBox(1)} disabled={pesquisaBox === 1}>
        Faturas Pendentes
        <Quantity>{faturas}</Quantity>
      </Button>
      <Button onClick={() => setPesquisaBox(2)} disabled={pesquisaBox === 2}>
        Primeira Fatura Pendente
        <Quantity>{primeiras}</Quantity>
      </Button>
      <Button onClick={() => setPesquisaBox(3)} disabled={pesquisaBox === 3}>
        Declinada por desistência
        <Quantity>{declinadas}</Quantity>
      </Button>
      <Button onClick={() => setPesquisaBox(4)} disabled={pesquisaBox === 4}>
        Faturas Empresariais
        <Quantity>{empresariais}</Quantity>
      </Button>
    </ButtonGroup>
  );
}

export default TableSelect;
