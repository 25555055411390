import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
} from 'reactstrap';
import {
  FaShoppingCart,
  FaUser,
  FaRegKissWinkHeart,
  FaPhone,
  FaMobileAlt,
  FaEnvelope,
  FaRoad,
  FaInfo,
  FaMapMarkerAlt,
  FaMapMarkedAlt,
  FaGlobeAmericas,
  FaFemale,
  FaUserLock,
} from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { getAddress } from 'address-br';
import {
  BodyFormComponent,
  LabelForm,
  PrependInputLabel,
  SectionFormHeader,
} from '../styles';
import Api from '../../services/api';
import { alertError, alertSuccess } from '../../components/Alerts/Alerts';
import Loading from '../../components/Loading/Loading';
import MaterialButton from '../../components/Buttons/MaterialButton';

function CadastrarAssessoria(props) {
  const { toggle, modal, vendedor, getVendedores } = props;
  const [loading, setLoading] = useState(false);
  const [loadingGet, setLoadingGet] = useState(true);

  const [comissao, setComissao] = useState({
    primeiraParcela: '',
    carteira: '',
    pagamentoAVista: '',
    idUsuario: vendedor.id_usuario,
  });

  const [endereco, setEndereco] = useState({
    cep: '',
    logradouro: '',
    bairro: '',
    cidade: '',
    uf: '',
  });

  const [vendedorInputs, setVendedorInputs] = useState({
    ...vendedor,
    senha: '',
    cep: '',
    logradouro: '',
    bairro: '',
    cidade: '',
    uf: '',
  });

  const getData = async () => {
    setLoadingGet(true);
    try {
      const responseComissao = await Api.get(
        `/comissao/${vendedor.id_usuario}`,
        {
          headers: { Authorization: `Bearer ${props.user.user.token}` },
        },
      );

      setComissao({
        carteira: responseComissao.data.output.carteira,
        idUsuario: responseComissao.data.output.id_usuario,
        pagamentoAVista: responseComissao.data.output.pagamento_a_vista,
        primeiraParcela: responseComissao.data.output.primeira_parcela,
      });

      const responseEndereco = await Api.get(
        `/enderecos/${vendedor.id_pessoa}`,
        {
          headers: { Authorization: `Bearer ${props.user.user.token}` },
        },
      );
      setEndereco(responseEndereco.data.output[0]);
      setVendedorInputs({
        ...vendedor,
        cep: responseEndereco.data.output[0].cep,
        logradouro: responseEndereco.data.output[0].logradouro,
        bairro: responseEndereco.data.output[0].bairro,
        cidade: responseEndereco.data.output[0].cidade,
        uf: responseEndereco.data.output[0].uf,
      });
    } catch (error) {
      console.log(error.message);
    }
    setLoadingGet(false);
  };

  useEffect(() => {
    setVendedorInputs(vendedor);

    if (vendedor.id_usuario != undefined) {
      getData();
    }
  }, [vendedor]);

  const handleEditarVendedor = async e => {
    setLoading(true);
    e.preventDefault();

    if (
      vendedorInputs.senha != '' &&
      vendedorInputs.senha != vendedorInputs.confirm_senha
    ) {
      alertError('Senhas não conferem');
      setLoading(false);
      return;
    }

    const authStr = `Bearer ${props.user.user.token}`;

    try {
      const response = await Api.put(
        `/corretor-representante/${vendedorInputs.id}`,
        vendedorInputs,
        {
          headers: { Authorization: authStr },
        },
      );

      if (response.data.ok) {
        alertSuccess('Vendedor atualizado com sucesso');
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError('Erro ao salvar distribuidor: ', error.message);
    }

    try {
      let response = '';

      if (comissao.idUsuario > 0) {
        response = await Api.put(
          `/comissao`,
          { ...comissao, idUsuario: vendedor.id_usuario },
          { headers: { Authorization: `Bearer ${props.user.user.token}` } },
        );
      } else {
        response = await Api.post(
          `/comissao`,
          { ...comissao, idUsuario: vendedor.id_usuario },
          { headers: { Authorization: `Bearer ${props.user.user.token}` } },
        );
      }

      if (response.data.ok) {
        alertSuccess('Comissão cadastrada com sucesso');
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError('Erro ao salvar comissão: ', error.message);
    }

    setLoading(false);
    getData();
  };

  const handleOnChange = e => {
    const { name, value } = e.target;

    setVendedorInputs({ ...vendedorInputs, [name]: value });
  };

  const handleComissaoChange = e => {
    const { name, value } = e.target;
    if (value < 0 || value > 100 || Number.isNaN(value)) {
      return;
    }
    setComissao({ ...comissao, [name]: value });
  };

  const handleOnEnderecoChange = e => {
    const { name, value } = e.target;

    if (value.length === 8 && name === 'cep') {
      getAddress(value).then(vl => {
        setEndereco({
          [name]: value,
          logradouro: vl.rua || '',
          cidade: vl.cidade || '',
          bairro: vl.bairro || '',
          uf: vl.estado || '',
        });
        setVendedorInputs({
          ...vendedorInputs,
          [name]: value,
          logradouro: vl.rua,
          cidade: vl.cidade,
          bairro: vl.bairro,
          uf: vl.estado,
        });
      });
    } else {
      setEndereco({ ...vendedorInputs, [name]: value });
    }
  };

  console.log(comissao);
  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <Form onSubmit={handleEditarVendedor}>
        <ModalHeader toggle={toggle}>
          <Row>
            <Col md={12}>
              <FaShoppingCart />
              &nbsp;Editar Vendedor
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <BodyFormComponent>
            <Row>
              <Col>
                <SectionFormHeader>Informações Gerais</SectionFormHeader>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaUser />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Nome"
                    name="nome"
                    value={vendedorInputs.nome}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>CPF</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="999.999.999-99"
                    className="form-control"
                    required
                    name="cpf"
                    value={vendedorInputs.cpf}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>Data nasc.</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="date"
                    required
                    name="data_nascimento"
                    value={vendedorInputs.data_nascimento}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaRegKissWinkHeart />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="select"
                    name="estado_civil"
                    value={vendedorInputs.estado_civil}
                    onChange={e => handleOnChange(e)}
                  >
                    <option value="">Estado Civil</option>
                    <option>Solteiro(a)</option>
                    <option>Casado(a) /união estável</option>
                    <option>Divorciado(a)</option>
                    <option>Viúvo(a)</option>
                  </Input>
                </InputGroup>
              </Col>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaFemale />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="select"
                    name="sexo"
                    value={vendedorInputs.sexo}
                    onChange={e => handleOnChange(e)}
                  >
                    <option value="">Selecione o Sexo</option>
                    <option>Masculino</option>
                    <option>Feminino</option>
                  </Input>
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaPhone />
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="Telefone"
                    required
                    name="telefone"
                    value={vendedorInputs.telefone}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>

              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaMobileAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="Telefone 2"
                    name="celular"
                    value={vendedorInputs.celular}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaEnvelope />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="email"
                    required
                    placeholder="E-mail"
                    name="email"
                    value={vendedorInputs.email}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <SectionFormHeader>Informações de Endereço</SectionFormHeader>
              </Col>
            </Row>
            {loadingGet ? (
              <Loading />
            ) : (
              <>
                <Row className="mt-3">
                  <Col lg={4}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <PrependInputLabel>CEP</PrependInputLabel>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="CEP sem pontuação"
                        name="cep"
                        value={endereco.cep}
                        onChange={e => handleOnEnderecoChange(e)}
                      />
                    </InputGroup>
                  </Col>
                  <Col lg={6}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FaRoad />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Rua"
                        name="logradouro"
                        value={endereco.logradouro}
                        onChange={e => handleOnEnderecoChange(e)}
                      />
                    </InputGroup>
                  </Col>
                  <Col lg={2}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <PrependInputLabel>Nº</PrependInputLabel>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Nº"
                        name="numero_endereco"
                        value={vendedorInputs.numero_endereco}
                        onChange={e => handleOnChange(e)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FaInfo />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Complemento"
                        name="complemento_endereco"
                        value={vendedorInputs.complemento_endereco}
                        onChange={e => handleOnChange(e)}
                      />
                    </InputGroup>
                  </Col>
                  <Col lg={3}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FaMapMarkerAlt />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Bairro"
                        name="bairro"
                        value={endereco.bairro}
                        onChange={e => handleOnEnderecoChange(e)}
                      />
                    </InputGroup>
                  </Col>
                  <Col lg={3}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FaMapMarkedAlt />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Cidade"
                        name="cidade"
                        value={endereco.cidade}
                        onChange={e => handleOnEnderecoChange(e)}
                      />
                    </InputGroup>
                  </Col>
                  <Col lg={3}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FaGlobeAmericas />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Estado"
                        name="uf"
                        value={endereco.uf}
                        onChange={e => handleOnEnderecoChange(e)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </>
            )}
            <>
              <Row className="mt-4">
                <Col>
                  <SectionFormHeader>Cadastro de Comissão</SectionFormHeader>
                </Col>
              </Row>
              {loadingGet ? (
                <Loading />
              ) : (
                <Row className="mt-3">
                  <Col xs={12} sm={6} className="mb-2">
                    <LabelForm>1ª Parcela</LabelForm>
                    <InputGroup>
                      <Input
                        name="primeiraParcela"
                        type="number"
                        value={comissao.primeiraParcela}
                        onChange={e => handleComissaoChange(e)}
                        min={0}
                        max={100}
                        required
                        placeholder="10.0"
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <PrependInputLabel>%</PrependInputLabel>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col xs={12} sm={6} className="mb-2">
                    <LabelForm>Carteira</LabelForm>
                    <InputGroup>
                      <Input
                        name="carteira"
                        type="number"
                        value={comissao.carteira}
                        onChange={e => handleComissaoChange(e)}
                        min={0}
                        max={100}
                        required
                        placeholder="10.0"
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <PrependInputLabel>%</PrependInputLabel>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col xs={12} sm={6} className="mb-2">
                    <LabelForm>Pagamento à vista</LabelForm>
                    <InputGroup>
                      <Input
                        name="pagamentoAVista"
                        type="number"
                        value={comissao.pagamentoAVista}
                        onChange={e => handleComissaoChange(e)}
                        min={0}
                        max={100}
                        required
                        placeholder="10.0"
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <PrependInputLabel>%</PrependInputLabel>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              )}
              <Row className="mt-4">
                <Col>
                  <SectionFormHeader>Segurança</SectionFormHeader>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} sm={6} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FaUserLock />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="senha"
                      value={vendedorInputs.senha}
                      onChange={e => handleOnChange(e)}
                      placeholder="Nova senha"
                    />
                  </InputGroup>
                </Col>
                <Col xs={12} sm={6} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FaUserLock />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="confirm_senha"
                      value={vendedorInputs.confirm_senha}
                      onChange={e => handleOnChange(e)}
                      required={vendedorInputs.senha}
                      placeholder="Confirmação da senha"
                    />
                  </InputGroup>
                </Col>
              </Row>
            </>
          </BodyFormComponent>
        </ModalBody>
        <ModalFooter>
          <Row className="text-right">
            <Col className="d-flex align-items-center">
              <MaterialButton color="danger" onClick={toggle}>
                Fechar
              </MaterialButton>
              &nbsp;
              <MaterialButton loading={loading} type="submit">
                Salvar
              </MaterialButton>
            </Col>
          </Row>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

// Token de produção, teste, user token
const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(CadastrarAssessoria);
