import React, { useState } from 'react';
import { Button, Card, Col, Modal, ModalHeader, ModalBody, Row, Spinner } from 'reactstrap';
import TablesGrid from '../../components/TablesGrid/TablesGrid';
import { FaFileExcel, FaFileDownload, } from 'react-icons/fa';
import { connect } from 'react-redux';
import Api from '../../services/api';
import { alertError } from '../../components/Alerts/Alerts';
import { ToastContainer } from 'react-toastify';

function ClientesAtivosPorTerceiros(props) {
  const { inputSelect, seguradosAtivos, loading } = props;
  const [ linkBaixar, setLinkBaixar ] = useState("");

  const [ modal, setModal ] = useState(false);
  const toggle = () => setModal(!modal);
  
  const exportarMovimentacao = async idTerceiro => {
    const { token } = props.user.user;
    const authStr = `Bearer ${token}`;
    const response = await Api.get(`terceiro/${idTerceiro}/segurados/ativos/exportar`,  { headers: { Authorization: authStr }});
    
    if(response.data.ok){
      setLinkBaixar(response.data.output.url)
      setModal(true)
    }else{
      alertError(response.data.message);
    }

  }

  let columns = [
    { name: 'cpf', title: 'CPF', getCellValue: row => (row.cpf) },
    { name: 'nome', title: 'NOME', getCellValue: row => (row.nome) },
    { name: 'dataNascimento', title: 'DATA NASCIMENTO', getCellValue: row => (row.dataNascimento) },
    { name: 'titular', title: 'TITULAR', getCellValue: row => (row.titular) },
    { name: 'tipo', title: 'TIPO', getCellValue: row => (row.tipo) },
    { name: 'terceiro', title: 'TERCEIRO', getCellValue: row => (row.terceiro) },
  ];

  return (
    <Card className="py-4 pl-2 pr-2">
      <ToastContainer/>
        <Col className="text-left" md={12}>
          <h4>Terceiro</h4>
        </Col>
        {
          loading
          ?
            <Row><Col className="text-center"><Spinner color="primary" /></Col></Row>
          :
          seguradosAtivos&&
          <>
            <Col md={12} className="text-left">
              <Button color="secondary" outline size="sm" style={{marginTop:"20px", marginBottom:"20px"}} color="primary" onClick={() => exportarMovimentacao(inputSelect)}>
                <FaFileExcel/>&nbsp; Baixar XLSX
              </Button>
              {
                modal&&
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle}><FaFileDownload /> Seu arquivo está pronto!</ModalHeader>
                  <ModalBody>
                    <Row className="text-right">
                      <Col>
                        <a href={`${process.env.REACT_APP_API_ERP}/${linkBaixar}`} rel="noopener noreferrer" download={`${process.env.REACT_APP_API_ERP}/${linkBaixar}`}>
                          <Button color="success" size="sm" outline>Baixar Arquivo</Button>
                        </a>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
              }
            </Col>
            <TablesGrid rows={seguradosAtivos.output} columns={columns} />
          </>
        }
    </Card>
  )
}

const mapStateToProps = store => ({
  user: store.user,
})

export default connect(mapStateToProps)(ClientesAtivosPorTerceiros);