import React from 'react';
import Page from './Page';
import styled from 'styled-components';
import ImgBck from '../../assets/img/background-login2.jpg';

const BackgroundImage = styled.div`
  height: 100%;
  background:  linear-gradient(to bottom, rgba(255, 255, 255, 0.68) 0%,rgba(0, 0, 0, 0.34) 100%),url(${ImgBck});
  background-size: cover;
`;

export default function Login() {

  return (
    <BackgroundImage>
      <Page />
    </BackgroundImage>
  );
}
