import React from 'react';
import { Card, CardImg, CardFooter } from 'reactstrap';
import driveIcon from '../../assets/img/google-drive-icon.png';
import styled from 'styled-components';

const TextoCardFooter = styled.span`

  font: 700 15px  Poppins;

`
const CardConteudo = styled.div`
  .card-img-top {
    cursor: pointer;
    transition: transform .3s;
  }

  .card-img-top:hover{
    transform: scale(1.04);
  }

`;


export default function ListaMaterialVendas() {

  return (
    <CardConteudo>
      <a target="_blank" href="https://drive.google.com/drive/folders/1cF39vN70wlNcYwEFktOdsqRcYZV_Jz5m" style={{textDecoration: "none"}}>
        <Card>
          <CardImg top width="100%" src={driveIcon} alt="material de apoio Viver" style={{padding: "15px"}}/>
          <CardFooter>
            <TextoCardFooter>Clique aqui para ter acesso ao seu material</TextoCardFooter>
          </CardFooter>
        </Card>
      </a>
    </CardConteudo>
  )

}
