import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import { FaFileDownload } from 'react-icons/fa';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Api from '../../services/api';
import { alertSuccess, alertError } from '../../components/Alerts/Alerts';

function BotaoRelatorioFuncionariosAtivos(props) {
  const { idEmpresa, idApolice } = props;
  const [loading, setLoading] = useState(false);

  const gerarRelatorioFuncionariosAtivos = async () => {
    setLoading(true);
    const authStr = `Bearer ${props.user.user.token}`;

    try {
      const response = await Api.get(
        `empresa/${idEmpresa}/${idApolice}/relatorio/funcionarios`,
        { headers: { Authorization: authStr } },
      );

      if (response.data.ok) {
        alertSuccess(response.data.message);
        window.location.reload();
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError(error.message);
    }
    setLoading(false);
  };

  return (
    <>
      <ToastContainer />
      <Button
        disabled={loading}
        color="danger"
        size="sm"
        onClick={() => gerarRelatorioFuncionariosAtivos()}
      >
        {loading ? (
          <>
            <Spinner size="sm" /> Processando...
          </>
        ) : (
          <>
            <FaFileDownload /> Gerar Relatório Funcionários Ativos
          </>
        )}
      </Button>
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(BotaoRelatorioFuncionariosAtivos);
