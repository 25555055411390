import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaWrench } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import EditarProduto from '../../views/Produtos/EditarProduto';

export default function Page(props) {

  const { nome } = props.resultProduto;
  const titleHeader = <><FaWrench/>&nbsp;&nbsp;Editar: {nome}</>;
  return (
    <Container fluid={true}>
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Col md={12}>
        <EditarProduto {...props} />
      </Col>
    </Container>
  );
}
