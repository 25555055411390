import React, { useState } from 'react';
import { Card, CardImg, CardHeader, CardBody, Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CardConteudo = styled.div`
  .card {
    cursor: pointer;
    transition: transform .3s;
  }

  .card:hover{
    transform: scale(1.02);
  }
`;

const ConteudoTexto = styled.div`
  text-align: left;
  margin-bottom: 26px;
`;

const Titulo = styled.div`
  font: 1.3rem Poppins;
  margin-bottom: 14px;
`;

const Descricao = styled.p`
  font: 0.9rem Archivo;
`;

const ModalContainer = styled.div`
  span {
    font: 400 20px Poppins;
    text-transform: capitalize;
  }
  p {
    font-size: 20px;
  }
`;

function CardTreinamento(props) {

  const { img, nome, descricao, linkVideo, linkApresentacao } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => {
    if(linkApresentacao !== undefined){
      window.open(linkApresentacao, '_blank');
      window.focus();
      return;
    }
    setModal(!modal);
  }
  
  return (
    <>
      <CardConteudo onClick={() => toggle()}>  
        <Card>
          <CardImg top width="100%" src={img} alt={nome} />
          <CardBody>
            <ConteudoTexto>
              <Titulo>{nome}</Titulo>
              <Descricao>{descricao}</Descricao>
              {/* {
                linkApresentacao&&
                <Link to={linkApresentacao} />
              } */}
            </ConteudoTexto>
          </CardBody>
        </Card>
      </CardConteudo>
      {
        modal&&
        <ModalContainer>
          <Modal isOpen={modal} size="lg" toggle={toggle}>
            <ModalHeader toggle={toggle}><span>{nome}</span></ModalHeader>
            <ModalBody style={{ textAlign: "center" }}>
              {
                linkVideo&&
                // <video width="500" height="375" controls>
                //   <source src={linkVideo} />
                // </video>
                <iframe 
                  type="text/html" 
                  width="640" 
                  height="360" 
                  src={linkVideo}
                  frameborder="0"
                />
              }
              {/* <p>asd</p> */}
            </ModalBody>
          </Modal>
        </ModalContainer>
      }
    </>
  )
}

export default CardTreinamento;