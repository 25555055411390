import React from 'react';
import { Container, Row, Col, Alert } from 'reactstrap';
import TitleContent from '../../components/TitleContent/TitleContent';
import { FaUserShield } from 'react-icons/fa';
import SelecionaTerceiro from '../../views/BaseTerceiros/SelecionaTerceiro';
import ClientesAtivosPorTerceiros from '../../views/BaseTerceiros/ClientesAtivosPorTerceiros';

function Page(props) {

  const titleHeader = <><FaUserShield/>&nbsp;&nbsp;Base Terceiros</>;
  const { terceiros, seguradosAtivos, setInputSelect, inputSelect, getSeguradosAtivosPorTerceiro, msgAlert, setNomeTerceiros, loading } = props;

  return (
    <Container fluid={true}>
      {msgAlert&&
      (<Col className="text-center mt-4" md={6}>
          <Alert color="success">{msgAlert}</Alert>
        </Col>
      )}
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={4} className="mt-4">
          {
            terceiros.length>0
            ?
          
            <SelecionaTerceiro 
              terceiros={terceiros} 
              setInputSelect={setInputSelect} 
              getSeguradosAtivosPorTerceiro={getSeguradosAtivosPorTerceiro} 
              setNomeTerceiros={setNomeTerceiros}
            />
          :
            <span style={{color: "red"}}>{terceiros.message}</span>
          }
        </Col>
        <Col md={8} className="mt-4">
          <ClientesAtivosPorTerceiros seguradosAtivos={seguradosAtivos} inputSelect={inputSelect} loading={loading} />
        </Col>
      </Row>
    </Container>
  )
}

export default Page;
