import React from 'react';
import styled from 'styled-components';
import { FaRegSave } from 'react-icons/fa';
import { Spinner } from 'reactstrap';

const BtnContent = styled.button`
    background-color: ${props => props.color};
    color: #ffffff;
    width: 200px;
    height: 40px;
    border: 1px solid ${props => props.color};
    border-radius: 5px;
    font-size: 14px;
    letter-spacing: 0.07rem;
    font-family: "Titillium";
    text-transform: uppercase;
    transition: 0.3s;

    :hover {
        background-color: #074e4a;
    }

    :disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`

function SubmitBtn(props) {

    const { label, fnSalvar = () => {}, typeSubmit, labelOnClick, icon, loading } = props;

    let type = "";
    let onClickFn = fnSalvar;

    if( typeSubmit === "submit" ){
        type="submit";
    }else if(fnSalvar){
        onClickFn = fnSalvar;
    }

    return(
        <BtnContent
            color={props.color}
            type={type}
            onClick={onClickFn}
            disabled={loading}
        >
            {
                loading
                ?
                <>
                    <Spinner color="primary" size="sm"/> &nbsp; {labelOnClick}
                </>
                :
                <>
                    {icon ? icon : <FaRegSave /> } &nbsp; { label }
                </>
            }
        </BtnContent>
    );

}

export default SubmitBtn;
