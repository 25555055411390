import React, { useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody } from 'reactstrap';
import { Line, defaults } from 'react-chartjs-2';

import { CircularProgress } from '@material-ui/core';
import { CardDashboard } from '../../views/styles';

defaults.global.defaultFontFamily = 'Poppins';
defaults.global.defaultColor = '#ffffff';
defaults.global.defaultFontColor = '#ffffff';

const graphBackgroundColor = '#205a5657';
const graphBorderColor = '#205a56';

function GraficosDashboard(props) {
  const {
    metricaValor,
    dadosGrafico,
    cardTitle,
    nomeAtributoX,
    nomeAtributoY,
    loading,
  } = props;

  const [valores, setValores] = useState([]);
  const [datas, setDatas] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    setDatas(dadosGrafico.map(dado => dado[nomeAtributoX]));
    setValores(dadosGrafico.map(dado => dado[nomeAtributoY]));
  }, [dadosGrafico]);

  useEffect(() => {
    setData({
      labels: datas,
      datasets: [
        {
          label: cardTitle,
          backgroundColor: graphBackgroundColor,
          borderColor: graphBorderColor,
          data: valores,
        },
      ],
    });
  }, [datas, valores]);

  return (
    <CardDashboard>
      <Card>
        <CardHeader className="card-title">{cardTitle}</CardHeader>
        <CardBody>
          <Col sm={12}>
            {(metricaValor || metricaValor == 0.0) && (
              <div className="media-container">
                <span className="media-title">Média:</span>
                {metricaValor == 'loading' ? (
                  <CircularProgress
                    color="inherit"
                    size="1rem"
                    className="ml-2"
                  />
                ) : (
                  <span className="media-valor">{metricaValor}</span>
                )}
              </div>
            )}
            <article className="canvas-container card-graph">
              {loading ? (
                <CircularProgress color="inherit" size="4rem" />
              ) : (
                <Line data={data} height={60} />
              )}
            </article>
          </Col>
        </CardBody>
      </Card>
    </CardDashboard>
  );
}

export default GraficosDashboard;
