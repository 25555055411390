import React from 'react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';

const SpinnerContent = styled.div`
    margin: 32px 0;
`;

export default function Loading() {
  return (
    <SpinnerContent>
        <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
    </SpinnerContent>
  );
}
