import React from 'react';
import { Box } from '@material-ui/core';
import CustomSkeleton from '../../components/Loading/Skeleton';
import { RegrasContainer } from './styles';

function RegraComissao(props) {
  const { regraComissao, assessoria } = props;
  return (
    <Box>
      <RegrasContainer color="dashboard">
        {!Object.keys(assessoria).length ? (
          <div>
            <CustomSkeleton width={600} height={30} />
          </div>
        ) : (
          <>
            <h3>{assessoria.nome_fantasia}</h3>
            <p>
              Primeira Fatura:{' '}
              <span>{regraComissao.data.primeiraParcela}%</span>
            </p>
            <p>
              Carteira: <span>{regraComissao.data.carteira}%</span>
            </p>
            <p>
              Pagamento à vista:{' '}
              <span>{regraComissao.data.pagamentoAVista}%</span>
            </p>
          </>
        )}
      </RegrasContainer>
    </Box>
  );
}

export default RegraComissao;
