import React from 'react';
import { Col } from 'reactstrap';
import TablesGrid from '../../components/TablesGrid/TablesGrid';

export default function ListaParceiros(props) {
  
  const { terceirosList } = props;

  let columns = [
    { name: 'id', title: 'Id', getCellValue: row => parseInt(row.id) },
    { name: 'nome', title: 'Nome', getCellValue: row => (row.nome) },
    { name: 'created', title: 'Criado em', getCellValue: row => (row.created) },
    // { name: '', title: '', getCellValue: row => (actions(row))},
  ];

  return (
    <>
      <Col lg={12}>
        <TablesGrid rows={terceirosList} columns={columns} />
      </Col>
    </>
  )
}
