import React from 'react';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';

export default function TablesGrid(props) {
  return (
    <div>
        <Link to={{ pathname: props.pathname }}>
           <FaSearch />
        </Link>
    </div>
  );
}
