import React from 'react';
import { useHistory } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { Button, Col } from 'reactstrap';
import TablesGrid from '../../components/TablesGrid/TablesGrid';
import { formatDate } from '../../services/service';


function ListaProdutos(props) {

  const { produtos } = props;

  const history = useHistory();

  let columns = [
    { name: 'id', title: 'ID', getCellValue: row => parseInt(row.id) },
    { name: 'name', title: 'Nome', getCellValue: row => (row.nome) },
    { name: 'preco', title: 'Preço', getCellValue: row => (`R$ ${row.preco.toFixed(2)}`) },
    { name: 'ativo', title: 'Ativo', getCellValue: row => (row.ativo == 1 ? "Sim" : "Não") },
    { name: 'created', title: 'Criado em', getCellValue: row => (formatDate(row.created.split(" ")[0])) },
    { name: '', title: 'Editar', getCellValue: row => (actions(row))},
  ];

  const actions = row => {
    return (
      <>
        <Button
          color="primary"
          size="sm"
          outline
          onClick={() => history.push(`/editar-produto/${row.id}`)}
        >
          <FaEdit />
        </Button>
      </>
    );
  };

  return (
    <>
      <Col lg={12}>
        {
          (produtos.output)&&
          <TablesGrid rows={produtos.output} columns={columns} defaultSorting={{ columnName: 'id', direction: 'desc' }} />
        }
      </Col>
    </>
  )
}

export default ListaProdutos;
