import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Api from '../../services/api';
import Page from './Page';
import {
  alertError,
  alertSuccess,
  alertWarning,
} from '../../components/Alerts/Alerts';

function Controller(props) {
  const { id, idEmpresa, idClient } = useParams();

  const authStr = `Bearer ${props.user.user.token}`;
  const { nivel_usuario } = props.user.user;
  const idUsuario = props.user.user.usuario;

  const [loadingEmails, setLoadingEmails] = useState(false);
  const [loadingApolices, setLoadingApolices] = useState(false);
  const [loadingProdutosAdd, setLoadingProdutosAdd] = useState(false);
  const [loadingCobrancaEmpresarial, setLoadingCobrancaEmpresarial] =
    useState(false);
  const [detalhesApolice, setdetalhesApolice] = useState();
  const [policieConfigPayment, setPolicieConfigPayment] = useState();
  const [faturas, setFaturas] = useState();
  const [policieProduct, setPolicieProduct] = useState();
  const [archivesPolicies, setArchivePolicie] = useState();
  const [historyEmail, setHistoryEmail] = useState();
  const [empresa, setEmpresa] = useState();
  const [funcionarios, setFuncionarios] = useState({ ok: false });
  const [contatos, setContatos] = useState();
  const [produtos, setProdutos] = useState();
  const [movimentacao, setMovimentacao] = useState();
  const [anotacoes, setAnotacoes] = useState();
  const [produtosAdicionais, setProdutosAdicionais] = useState({ ok: false });
  const [historicoStatus, setHistoricoStatus] = useState();
  const [motivoCancelamento, setMotivoCancelamento] = useState();
  const [clienteInfo, setClienteInfo] = useState({
    nome_estipulante: '',
    cpf: '',
    nome: '',
  });
  const [historicoEdicao, setHistoricoEdicao] = useState();
  const [funcAtivos, setFuncAtivos] = useState();
  const [funcInativos, setFuncInativos] = useState();
  // const [opcoesPgto, setOpcoesPgto] = useState({
  //   formaPgto: '',
  //   proximosPgto: '',
  // });
  const [valorApolice, setValorApolice] = useState(0.0);
  const [relacaoSeguradosValor, setRelacaoSeguradosValor] = useState([]);

  useEffect(() => {
    getDetalhesApolice();
    getConfigPayment();
    getProductPolicie();
    getArquivosApolice();
    getHistoryEmail();
    getProdutos();
    getMovimentacao();
    getAnotacoes();
    getHistoricoStatus();
    getMotivosCancelamento();
    getHistoricoEdicao();
    getClienteInfo();
    getFuncionariosInativos();
    getFuncionariosAtivos();
    getValorApolice();

    if (idEmpresa) {
      getInfosEmpresa();
      getFuncionarios();
      getContatos();
      getMovimentacao();
      // getValorViver();
      getProdutoAdicionaisEmpresariais();
      getRelacaoCobrancaEmpresarial();
    }
  }, []);

  const getDetalhesApolice = async () => {
    setLoadingApolices(true);
    const request = await Api.get(`apolice/${id}`, {
      headers: { Authorization: authStr },
    });
    if (request.data.output.id) {
      const requestFaturas = await Api.get(
        `faturas/apolice/${request.data.output.id}`,
        { headers: { Authorization: authStr } },
      );
      setFaturas(requestFaturas.data);
    }
    setdetalhesApolice(request.data);
    setLoadingApolices(false);
  };

  const getConfigPayment = async () => {
    const requestConfigPay = await Api.get(`apolice/config-pagamento/${id}`, {
      headers: { Authorization: authStr },
    });
    setPolicieConfigPayment(requestConfigPay.data);
  };

  const getProductPolicie = async () => {
    setLoadingProdutosAdd(true);
    const requestProductPolicie = await Api.get(
      `segurado/apolice/${id}/produtos/`,
      { headers: { Authorization: authStr } },
    );
    setPolicieProduct(requestProductPolicie.data);
    setLoadingProdutosAdd(false);
  };

  const getArquivosApolice = async () => {
    const requestArchives = await Api.get(`apolice/arquivos/${id}`, {
      headers: { Authorization: authStr },
    });
    setArchivePolicie(requestArchives.data);
  };

  const getInfosEmpresa = async () => {
    const requestEmpresa = await Api.get(`empresa/${idEmpresa}`, {
      headers: { Authorization: authStr },
    });
    setEmpresa(requestEmpresa);
  };

  const getHistoryEmail = async () => {
    setLoadingEmails(true);
    const requestEmails = await Api.get(`historico-emails/${id}`, {
      headers: { Authorization: authStr },
    });
    setHistoryEmail(requestEmails.data);
    setLoadingEmails(false);
  };

  const getFuncionarios = async () => {
    const requestFunc = await Api.get(`segurados/${idEmpresa}`, {
      headers: { Authorization: authStr },
    });
    setFuncionarios(requestFunc.data);
  };

  const getContatos = async () => {
    const requestContatos = await Api.get(`empresa/contatos/${idEmpresa}`, {
      headers: { Authorization: authStr },
    });
    setContatos(requestContatos.data);
  };

  const getProdutos = async () => {
    const fetchProdutos = await Api.get(`/produtos/`, {
      headers: { Authorization: authStr },
    });
    setProdutos(fetchProdutos.data.output);
  };

  const getMovimentacao = async () => {
    const requestMovimentacao = await Api.get(`/movimentacoes/${id}`, {
      headers: { Authorization: authStr },
    });
    setMovimentacao(requestMovimentacao.data);
  };

  const getAnotacoes = async () => {
    const requestAnotacao = await Api.get(`anotacao/${id}`, {
      headers: { Authorization: authStr },
    });
    setAnotacoes(requestAnotacao.data);
  };

  const getProdutoAdicionaisEmpresariais = async () => {
    const requestProdAdicionais = await Api.get(`produtos/adicionais/`, {
      headers: { Authorization: authStr },
    });
    setProdutosAdicionais(requestProdAdicionais.data);
  };

  const getClienteInfo = async () => {
    const result = await Api.get(`segurado/${idClient}`, {
      headers: { Authorization: authStr },
    });

    setClienteInfo(result.data.output);
  };

  const getHistoricoStatus = async () => {
    const result = await Api.get(`/historico-status/${id}`, {
      headers: { Authorization: authStr },
    });
    setHistoricoStatus(result.data);
  };

  const getMotivosCancelamento = async () => {
    const result = await Api.get(`/motivos-cancelamento`, {
      headers: { Authorization: authStr },
    });
    setMotivoCancelamento(result.data);
  };

  const getHistoricoEdicao = async () => {
    const response = await Api.get(`/historico-edicao-segurado/${idClient}`, {
      headers: { Authorization: authStr },
    });
    setHistoricoEdicao(response.data);
  };

  // const editarOpcoesPgto = async () => {
  // const result = await Api.post({headers: { Authorization: authStr }})
  // };

  const getFuncionariosInativos = async () => {
    const request = await Api.get(`/segurados/${idEmpresa}?ativo=0`, {
      headers: { Authorization: authStr },
    });
    if (request.data.ok) setFuncInativos(request.data);
  };

  const getFuncionariosAtivos = async () => {
    const request = await Api.get(
      `/segurados/${idEmpresa}?ativo=1&apoliceProduto=${id}`,
      { headers: { Authorization: authStr } },
    );
    if (request.data.ok) setFuncAtivos(request.data);
  };

  const getValorApolice = async () => {
    const request = await Api.get(`/apolice/busca-valor/${id}`, {
      headers: { Authorization: authStr },
    });
    if (request.data.ok) setValorApolice(request.data.output.valor);
  };

  const getRelacaoCobrancaEmpresarial = async () => {
    const request = await Api.get(
      `/apolice/relacao-cobranca-empresarial/${id}`,
      { headers: { Authorization: authStr } },
    );
    if (request.data.ok) {
      setRelacaoSeguradosValor(request.data.output);
    } else {
      setRelacaoSeguradosValor([]);
    }
  };

  const gerarCobrancaEmpresarial = async dataVenc => {
    if (dataVenc === '') {
      return;
    }
    setLoadingCobrancaEmpresarial(true);
    const body = { dataVencimento: dataVenc };
    const request = await Api.post(`apolice/${id}/cobranca-empresarial`, body, {
      headers: { Authorization: authStr },
    });
    if (request.data.ok) {
      alertSuccess('Cobrança criada com sucesso.');
      window.location.reload();
    } else {
      alertWarning(request.data.message);
    }
    setLoadingCobrancaEmpresarial(false);
  };

  const alterarFormaPagamento = async () => {
    console.log(authStr);
    try {
      const response = await Api.post(
        `/apolice/${id}/mudar-para-assinatura`,
        {},
        {
          headers: { Authorization: authStr },
        },
      );

      if (response.data.ok) {
        getConfigPayment();
        alertSuccess('Forma de pagamento alterada com sucesso');
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError(error.message);
    }
  };

  const confirmarSegundaViaCartao = async () => {
    try {
      const response = await Api.post(
        `/apolice/${id}/segunda-via-cartao`,
        {},
        {
          headers: { Authorization: authStr },
        },
      );

      if (response.data.ok) {
        alertSuccess('Solicitação de segunda via do cartão realizada');
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError(error.message);
    }
  };

  return (
    <>
      <Page
        empresa={empresa}
        detalhesApolice={detalhesApolice}
        policieProduct={policieProduct}
        policieConfigPayment={policieConfigPayment}
        setPolicieConfigPayment={setPolicieConfigPayment}
        historyEmail={historyEmail}
        archivesPolicies={archivesPolicies}
        faturas={faturas}
        getDetalhesApolice={getDetalhesApolice}
        idEmpresa={idEmpresa}
        funcionarios={funcionarios}
        idApolice={id}
        contatos={contatos}
        todosProdutos={produtos}
        loadingProdutosAdd={loadingProdutosAdd}
        loadingApolices={loadingApolices}
        loadingEmails={loadingEmails}
        movimentacao={movimentacao}
        anotacoes={anotacoes}
        authStr={authStr}
        client={clienteInfo}
        produtosAdicionais={produtosAdicionais}
        historicoStatus={historicoStatus}
        motivoCancelamento={motivoCancelamento}
        getConfigPayment={getConfigPayment}
        nivelUsuario={nivel_usuario}
        idUsuario={idUsuario}
        historicoEdicao={historicoEdicao}
        funcAtivos={funcAtivos}
        funcInativos={funcInativos}
        valorApolice={valorApolice}
        relacaoSeguradosValor={relacaoSeguradosValor}
        gerarCobrancaEmpresarial={gerarCobrancaEmpresarial}
        loadingCobrancaEmpresarial={loadingCobrancaEmpresarial}
        alterarFormaPagamento={alterarFormaPagamento}
        confirmarSegundaViaCartao={confirmarSegundaViaCartao}
      />
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
