import React from 'react';
import { Card, Col, Row, Button } from 'reactstrap';
import { FaBuilding, FaWrench } from 'react-icons/fa';
import { CardComponent, TitleSection, Label } from '../styles';
import { formatDate } from '../../services/service';

export default function Segurado(props) {
    
    const { 
        nome_estipulante, 
        nome, 
        cpf, 
        data_nascimento, 
        estado_civil, 
        sexo, 
        origem, 
        observacao, 
        data_criacao, 
        email, 
        telefone, 
        celular, 
        logradouro, 
        bairro, 
        cidade, 
        cep, 
        complemento_endereco,
        numero_endereco,
    } = props.client;

    return (
        <Card>
            <Row style={{justifyContent: "flex-end", paddingTop: "2px", paddingRight: "2px" }}> 
                <Button size="sm" outline color="warning" onClick={() => props.history.push(`/editar-cliente-individual/${props.idClient}`)}><FaWrench /></Button>
            </Row>

            <CardComponent>

                <Col className="header-content">
                    <h2><FaBuilding/>&nbsp;&nbsp;Nome Estipulante</h2>
                    <h6>{nome_estipulante}</h6>
                </Col>
                <hr/>
                <Row>
                    <Col md={4}>
                        <TitleSection>Informações Pessoais</TitleSection>
                        <Col className="text-left mt-2" style={{ paddingLeft: "0px"}}>
                            <p><Label>Nome:</Label><span>&nbsp;{nome}</span></p>
                            <p><Label>CPF:</Label><span>&nbsp;{cpf}</span></p>
                            <p><Label>Data de Nascimento:</Label><span>&nbsp;{formatDate(data_nascimento)}</span></p>
                            <p><Label>Estado Civil:</Label><span>&nbsp;{estado_civil}</span></p>
                            <p><Label>Sexo:</Label><span>&nbsp;{sexo}</span></p>
                            <p><Label>Origem:</Label><span>&nbsp;{origem}</span></p>
                            <p><Label>Observação:</Label><span>&nbsp;{observacao}</span></p>
                            <p><Label>Data Cadastro:</Label><span>&nbsp;{formatDate(data_criacao.split(" ")[0])}</span></p>
                            <p><Label>Hora Cadastro:</Label><span>&nbsp;{data_criacao.split(" ")[1]}</span></p>
                        </Col>
                    </Col>
                    <Col md={4}>
                        <TitleSection>Contato</TitleSection>    
                        <Col className="text-left mt-2" style={{ paddingLeft: "0px"}}>
                            <p><Label>Telefone:</Label><span>&nbsp;{telefone}</span></p>
                            <p><Label>Celular:</Label><span>&nbsp;{celular}</span></p>
                            <p><Label>E-mail:</Label><span>&nbsp;{email}</span></p>
                        </Col>
                    </Col>
                    <Col md={4}>
                        <TitleSection>Endereço</TitleSection>
                        <Col className="text-left mt-2" style={{ paddingLeft: "0px"}}>
                            <p><Label>Logradouro:</Label><span>&nbsp;{logradouro}</span> <Label>Nº:</Label> {numero_endereco}</p>
                            {/* <p><Label>Nº {numero_endereco}</Label></p> */}
                            <p><Label>CEP:</Label><span>&nbsp;{cep}</span></p>
                            <p><Label>Complemento:</Label><span>&nbsp;{complemento_endereco}</span></p>
                            <p><Label>Bairro:</Label><span>&nbsp;{bairro}</span></p>
                            <p><Label>Cidade:</Label><span>&nbsp;{cidade}</span></p>
                        </Col>
                    </Col>
                </Row>
            </CardComponent>
        </Card>
    );
}
