import React, { useState, useEffect } from 'react';
import Page from './Page';
import { connect } from 'react-redux';
import Api from '../../services/api';
import { useParams, useHistory } from 'react-router-dom';

function Controller(props) {

  let { idFuncionario } = useParams();
  let history = useHistory();
  console.log("---- ",props.location);
  let idEstipulante = props.location.state.idEstipulante;

  const [ infosFuncionario, setInfosFuncionario ] = useState();
  const [ depFuncionario, setDepFuncionario ] = useState();
  const [ valorViver, setValorViver ] = useState();

  const authStr = `Bearer ${props.user.user.token}`;

  useEffect(() => {
    if(idFuncionario){
      getInfosFuncionario();
      getInfosDependentes();
      getValorViver();
    }
  }, []);

  const getInfosFuncionario = async _ => {
    const restApi = await Api.get(`segurado/${idFuncionario}`, { headers: { Authorization: authStr }});
    setInfosFuncionario(restApi.data);
  }

  const getInfosDependentes = async _ => {
    const request = await Api.get(`segurado/dependentes/${idFuncionario}`,  { headers: { Authorization: authStr }});
    setDepFuncionario(request.data);
  }   

  const getValorViver = async _ => {
    const restApi = await Api.get("produto/15", { headers: { Authorization: authStr }});
    
    if(restApi.data !== undefined){
      let produtoViver = restApi.data.output;
      let qtd_tipo_1 = 0;
      let qtd_tipo_2 = 0;
      
      let valorViver = produtoViver.preco;

      if(depFuncionario){
        depFuncionario.output.forEach(el => {
          if(el.tipo !== 2){
            qtd_tipo_1 ++;
          }else{
            qtd_tipo_2 ++;
          }
        });
        if(qtd_tipo_1 > 0) qtd_tipo_1 = qtd_tipo_1 - 1;
        valorViver += (produtoViver.preco_dependente_tipo_1 * qtd_tipo_1) + (produtoViver.preco_dependente_tipo_2 * qtd_tipo_2); 
      }
      setValorViver(valorViver);
    }
  }

  return (
    <div>
      {
        (infosFuncionario)&&
        <Page 
          infosFuncionario={infosFuncionario.output} 
          depFuncionario={depFuncionario} 
          valorViver={valorViver}
          history={history}
          idEmpresa={idEstipulante} 
        />
      }
    </div>
  )
}

const mapStateToProps = store => ({
  user: store.user
})

export default connect(mapStateToProps)(Controller);



