import React, { useState } from 'react';
import { Container, Row, Col, Button, Card } from 'reactstrap';
import { FaPlus, FaChartLine, FaFileExcel, FaUsers } from 'react-icons/fa';
import { connect } from 'react-redux';
import { BodyFormComponent } from '../../views/styles';
import MenuAssessorias from '../../views/RelatorioVendas/MenuAssessorias';
import CadastrarCorretorRespresentante from '../../views/RelatorioVendas/CadastrarCorretorRepresentante';
import RelatorioVendasCorretorRepresentante from '../../views/RelatorioVendas/RelatorioVendaCorretorRepresentante';
import RelatorioVendaAssessoria from '../../views/RelatorioVendas/RelatorioVendaAssessoria';
import RelatorioComissaoCarteira from '../../views/RelatorioVendas/RelatorioComissaoCarteira';
import RelatorioBaixasFaturas from '../../views/RelatorioVendas/RelatorioBaixasFaturas';
import GraficoRelatorioAssessoria from '../../views/RelatorioVendas/GraficoRelatorioAssessorias';
import RelatorioRenovacoesMes from '../../views/RelatorioVendas/RelatorioRenovacoesMes';
import RelatorioExClientes from '../../views/RelatorioVendas/RelatorioExClientes';
import CorretorLista from '../../views/RelatorioVendas/CorretorLista';
import RelatorioCancelamentosCorretorRepresentante from '../../views/RelatorioVendas/RelatorioCancelamentosCorretorRepresentante';

function Page(props) {
  const { nivel_usuario, usuario, token } = props.user.user;
  const [modal, setModal] = useState(false);
  const [modalCorretor, setModalCorretor] = useState(false);
  const toggle = _ => setModal(!modal);
  const toggleModalCorretor = _ => {
    setModalCorretor(!modalCorretor);
  };

  return (
    <Container fluid>
      <Row className="mt-4">
        <Col lg={12}>
          <BodyFormComponent>
            {props.assessorias.output ? (
              <MenuAssessorias
                assessorias={props.assessorias}
                idAssessoriaSelecionada={props.idAssessoriaSelecionada}
                handleOnChange={props.handleOnChange}
                nivelUsuario={nivel_usuario}
                usuario={usuario}
              />
            ) : (
              <span style={{ color: 'red' }}>{props.assessorias.message}</span>
            )}
          </BodyFormComponent>
        </Col>
        <Col lg={12}>
          <Card>
            <BodyFormComponent>
              <Row>
                <Col className="mb-4">
                  <h3>
                    <FaChartLine />
                    &nbsp;Relatório de Vendas:
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={toggleModalCorretor}
                  >
                    <FaPlus />
                    &nbsp; Cadastrar Novo Corretor Representante
                  </Button>
                  {modalCorretor && (
                    <CadastrarCorretorRespresentante
                      assessorias={props.assessorias}
                      modalCorretor={modalCorretor}
                      toggleModalCorretor={toggleModalCorretor}
                      gruposVenda={props.gruposVenda}
                    />
                  )}
                </Col>
                <Col md={12}>
                  <GraficoRelatorioAssessoria
                    relatorioAssessoriaSelecionada={
                      props.relatorioAssessoriaSelecionada
                    }
                    graficoValorTotalMes={props.graficoValorTotalMes}
                    loadingGraph={props.loadingGraph}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} />
              </Row>
            </BodyFormComponent>
          </Card>
          <Row>
            <Col md={4}>
              <Card className="mt-4">
                <BodyFormComponent>
                  <h4>
                    <FaFileExcel />
                    &nbsp;Exportar Relatório
                    <br /> por Distribuidor:
                  </h4>
                  <Row className="mt-4 text-left">
                    <Col>
                      <RelatorioVendaAssessoria
                        token={token}
                        idAssessoriaSelecionada={props.idAssessoriaSelecionada}
                        history={props.history}
                      />
                    </Col>
                  </Row>
                </BodyFormComponent>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mt-4">
                <BodyFormComponent>
                  <h4>
                    <FaFileExcel />
                    &nbsp;Exportar Comissão carteira
                    <br /> por Distribuidor:
                  </h4>
                  <Row className="mt-4 text-left">
                    <Col>
                      <RelatorioComissaoCarteira
                        token={token}
                        idAssessoriaSelecionada={props.idAssessoriaSelecionada}
                        history={props.history}
                      />
                    </Col>
                  </Row>
                </BodyFormComponent>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mt-4">
                <BodyFormComponent>
                  <h4>
                    <FaFileExcel />
                    &nbsp;Exportar Relatório
                    <br /> por Corretor Representante:
                  </h4>
                  <Row className="mt-4 text-center">
                    <Col>
                      {props.corretorRepresentante ? (
                        <RelatorioVendasCorretorRepresentante
                          token={token}
                          corretorRepresentante={props.corretorRepresentante}
                          history={props.history}
                        />
                      ) : (
                        <span style={{ color: 'red' }}>
                          Selecione um Assessor.
                        </span>
                      )}
                    </Col>
                  </Row>
                </BodyFormComponent>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Card className="mt-4">
                <BodyFormComponent>
                  <h4>
                    <FaFileExcel />
                    &nbsp;Relatório
                    <br /> de Cancelamentos por Corretor Representante:
                  </h4>
                  <Row className="mt-4 text-center">
                    <Col>
                      {props.corretorRepresentante ? (
                        <RelatorioCancelamentosCorretorRepresentante
                          token={token}
                          corretorRepresentante={props.corretorRepresentante}
                          history={props.history}
                          idAssessoriaSelecionada={
                            props.idAssessoriaSelecionada
                          }
                        />
                      ) : (
                        <span style={{ color: 'red' }}>
                          Selecione um Assessor.
                        </span>
                      )}
                    </Col>
                  </Row>
                </BodyFormComponent>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mt-4">
                <BodyFormComponent>
                  <h4>
                    <FaFileExcel />
                    &nbsp;Relatório
                    <br /> de Renovações por Mês e Estipulante:
                  </h4>
                  <Row className="mt-4 text-center">
                    <Col>
                      <RelatorioRenovacoesMes
                        token={token}
                        estipulantes={props.estipulantes}
                      />
                    </Col>
                  </Row>
                </BodyFormComponent>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mt-4">
                <BodyFormComponent>
                  <h4>
                    <FaFileExcel />
                    &nbsp;Relatório de Baixas de Faturas:
                  </h4>
                  <Row className="mt-4 text-center">
                    <Col>
                      <RelatorioBaixasFaturas token={token} />
                    </Col>
                  </Row>
                </BodyFormComponent>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Card className="mt-4">
                <BodyFormComponent>
                  <h4>
                    <FaFileExcel />
                    &nbsp;Relatório de Ex-Clientes:
                  </h4>
                  <Row className="mt-4 text-center">
                    <Col>
                      <RelatorioExClientes token={token} />
                    </Col>
                  </Row>
                </BodyFormComponent>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card className="mt-4 mb-4 pb-4">
                <Row className="text-left">
                  <Col className="mt-4">
                    <h4>
                      <FaUsers />
                      &nbsp;Corretores
                    </h4>
                  </Col>
                </Row>
                <Row className="mt-4 text-center">
                  <Col>
                    {props.corretorRepresentante &&
                    Object.keys(props.corretorRepresentante.output).length >
                      0 ? (
                      <CorretorLista
                        corretores={props.corretorRepresentante.output}
                      />
                    ) : (
                      <span style={{ color: 'red' }}>
                        Selecione um Assessor.
                      </span>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Page);
