import React from 'react';

import { Box } from '@material-ui/core';
import { formatDate } from '../../services/service';
import MaterialTable from '../../components/TablesGrid/MaterialTable';

function RelacaoUsoCupons(props) {
  const { relacaoUsoCupons, loading } = props;

  const columns = [
    {
      field: 'cliente',
      headerName: 'Cliente',
      width: 375,
    },
    {
      field: 'codigo',
      headerName: 'Código do cupom',
      width: 200,
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      width: 300,
    },
    {
      field: 'validade',
      headerName: 'Validade',
      type: 'date',
      width: 140,
      valueFormatter: ({ value }) => formatDate(value),
    },
  ];

  return (
    <Box m={2}>
      <MaterialTable rows={relacaoUsoCupons} columns={columns} loading={loading} />
    </Box>
  );
}

export default RelacaoUsoCupons;
