// import React from 'react';
// import {
//   Button,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
//   Spinner,
// } from 'reactstrap';

// const MaterialModal = ({ toggle, modal, handleConfirm, loading, children }) => {
//   return (
//     <Modal isOpen={modal} toggle={toggle}>
//       <ModalHeader toggle={toggle}>Alterar Forma de Pagamento</ModalHeader>
//       <ModalBody>{children}</ModalBody>
//       <ModalFooter>
//         <Button color="danger" onClick={toggle} disabled={loading}>
//           Cancelar
//         </Button>{' '}
//         <Button color="success" onClick={handleConfirm} disabled={loading}>
//           {loading ? (
//             <>
//               <Spinner size="sm" /> Confirmando...
//             </>
//           ) : (
//             'Confirmar'
//           )}
//         </Button>
//       </ModalFooter>
//     </Modal>
//   );
// };

// export default MaterialModal;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Modal from '@material-ui/core/Modal';

import { Modal } from '@material-ui/core';
import { ModalHeader, ModalFooter, ModalBody } from './styles';
import MaterialButton from '../Buttons/MaterialButton';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    width: '100%',
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ccc',
    borderRadius: 10,
    boxShadow: theme.shadows[5],
  },
  danger: {
    backgroundColor: theme,
  },
}));

export default function MaterialModal({
  toggle,
  modal,
  handleConfirm,
  loading,
  children,
  title,
}) {
  const classes = useStyles();

  return (
    <Modal
      open={modal}
      onClose={toggle}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <ModalHeader>
          <h1>{title}</h1>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <MaterialButton color="danger" onClick={toggle} disabled={loading}>
            Cancelar
          </MaterialButton>
          <div className="confirm">
            <MaterialButton
              color="success"
              onClick={handleConfirm}
              loading={loading}
            >
              Confirmar
            </MaterialButton>
          </div>
        </ModalFooter>
      </div>
    </Modal>
  );
}
