import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Page from './Page';
import Api from '../../services/api';

function Controller(props) {
  const [assessorias, setAssessorias] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAssessorias();
  }, []);

  const getAssessorias = async _ => {
    setLoading(true);
    const response = await Api.get('/assessorias/', {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });
    setAssessorias(response.data);
    setLoading(false);
  };

  return (
    <>
      <ToastContainer />
      <Page
        assessorias={assessorias}
        loading={loading}
        getAssessorias={getAssessorias} /* comissao={comissao} */
      />
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
