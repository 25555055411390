import React, { useState } from 'react';
import { Row, Col, Input, Form, Button, Label, FormGroup, Spinner } from 'reactstrap';
import Api from '../../services/api';
import { connect } from 'react-redux';
import { BodyFormComponent, LabelForm } from '../styles';
import { alertError } from '../../components/Alerts/Alerts';
import styled from 'styled-components';

const LabelProdutoNome = styled.span`
    font-size: 15px;
    text-transform: uppercase;
    color: #b90f0f;
`;

function AlteraProduto(props) {

    const { todosProdutos, idApolice, nomeProdAlterar, history, idEmpresa } = props;
    const [ selected, setSelected ] = useState('');
    const [ checkbox, setCheckbox ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const alterarProduto = async _ => {
        
        if(checkbox === false){
            alertError('Para prosseguir, selecione o checkbox para alterar o produto da apólice');
            return false;
        }
        
        let idsProduto = {
            "idsProduto":[
                parseInt(selected)
            ]
        }

        setLoading(true)
    
        const result = await Api.post(`apolice/${idApolice}/upsell`, idsProduto, { headers: {Authorization: `Bearer ${props.user.user.token}` }});
    
        if(result.data.ok){
            history.push(`/apolice/${parseInt(result.data.output)}`, idEmpresa);
            // window.location.reload();
        }else{
            alertError(result.data.message);
            setLoading(false)
        }
    }

    return (
        <>
            <Row>
                <Col className="mb-2"><LabelForm>Produto - <LabelProdutoNome>{nomeProdAlterar}</LabelProdutoNome> mudar para</LabelForm></Col> 
                <Col className="mb-4" md={12}>
                    {/* <LabelForm>Escolha o Produto</LabelForm> */}
                    <BodyFormComponent>
                        <Input type="select" name="selected" value={selected} onChange={e => setSelected(e.target.value)}>
                            <option value="">Selecione</option>
                            {
                                todosProdutos.map((el, i) => (
                                    
                                    <option value={el.id} key={i}>{el.nome}</option>
                                ))
                            }
                        </Input>
                    </BodyFormComponent>
                </Col>
            </Row>
            <Row>
                <Col className="mb-4" md={12}>
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" name="checkbox" value={checkbox} onChange={() => setCheckbox(!checkbox)}/>{' '}
                            Quero Alterar o produto desta apólice
                        </Label>
                    </FormGroup>
                </Col>
                <Col className="mt-2" md={12}>
                    <Button size="sm" color="success" outline onClick={() => alterarProduto()}>
                        {
                            loading
                            ?
                            <><Spinner color="success" size="sm" />&nbsp;Aguarde</>
                            :
                            <span>Enviar</span>
                        }
                    </Button>       
                </Col>
            </Row>
        </>
    );
}

const mapStateToProps = store => ({
    user: store.user,
})

export default connect(mapStateToProps)(AlteraProduto);