import React from 'react';
import { Card, Row, Col } from 'reactstrap';
import {
  FaBuilding,
  FaPhone,
  FaMobileAlt,
  FaEnvelope,
  FaRoad,
} from 'react-icons/fa';
import { CardComponent } from '../styles';

function InformacoesFuncionario(props) {
  const {
    // id_estipulante,
    nome_estipulante,
    nome,
    cpf,
    data_nascimento,
    estado_civil,
    sexo,
    telefone,
    celular,
    email,
    logradouro,
    numero_endereco,
    cep,
    bairro,
    cidade,
    uf,
  } = props.infosFuncionario;

  const dataNascimentoFormatted = new Date(data_nascimento).toLocaleDateString(
    'pt-BR',
    {
      timeZone: 'UTC',
    },
  );

  return (
    <Card className="pb-4">
      <CardComponent>
        <Row>
          <Col sm={12} className="header-content mb-4">
            <h2>
              <FaBuilding />
              &nbsp;&nbsp;{nome_estipulante}
            </h2>
            <hr />
          </Col>
          <Col md={7}>
            <Col className="header-content mb-4">
              <h2>Informações Pessoais</h2>
            </Col>
            <Col className="text-left">
              <p>
                <b>Nome:</b>&nbsp;{nome}
              </p>
              <p>
                <b>CPF:</b>&nbsp;{cpf}
              </p>
              <p>
                <b>Data de Nascimento:</b>&nbsp;{dataNascimentoFormatted}
              </p>
              <p>
                <b>Estado Civil:</b>&nbsp;{estado_civil}
              </p>
              <p>
                <b>Sexo:</b>&nbsp;{sexo}
              </p>
            </Col>
          </Col>

          <Col md={5}>
            <Col className="header-content mb-4">
              <h2>Contatos</h2>
            </Col>
            <Col className="text-left">
              <p>
                <b>
                  <FaPhone />
                  &nbsp;Telefone:{' '}
                </b>
                {telefone}
              </p>
              <p>
                <b>
                  <FaMobileAlt />
                  &nbsp;Celular:{' '}
                </b>
                {celular}
              </p>
              <p>
                <b>
                  <FaEnvelope />
                  &nbsp;E-mail:{' '}
                </b>
                {email}
              </p>
            </Col>
            <Col className="header-content mb-4 mt-4">
              <h2>Endereço</h2>
            </Col>
            <Col className="text-left">
              <p>
                <b>
                  <FaRoad />
                  &nbsp;Logradouro:{' '}
                </b>
                &nbsp;{logradouro}&nbsp;&nbsp;<b>nº</b>&nbsp;{numero_endereco}
              </p>
              <p>
                <b>Cep:</b>&nbsp;{cep}
              </p>
              <p>
                <b>Bairro:</b>&nbsp;{bairro}
              </p>
              <p>
                <b>Cidade:</b>&nbsp;{cidade} &nbsp;&nbsp; <b>UF:</b>&nbsp;&nbsp;
                {uf}
              </p>
            </Col>
          </Col>
        </Row>
      </CardComponent>
    </Card>
  );
}

export default InformacoesFuncionario;
