import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row } from 'reactstrap';
import styled from 'styled-components';

const ModalVersionController = styled.div`
  .title-modal {
    font: 700 18px Poppins;
    color: #1a534f;
  }
  .modal-body {
    font: 400 13px Poppins;
  }
  .section-title {
    text-transform: uppercase;
    font: 700 13px Poppins;
    color: white;
    margin-bottom: 20px;
  }
  .area-title {
    display: flex;
    background-color: #1a534f;
    padding-top: 10px;
    padding-left: 20px;
  }
`;

function ModalVersaoDetalhada(props) {

  const { modal, toggle } = props;
  const { nivel_usuario } = props.user.user;
  

  const features = [
    "Implementação da sessão de Treinamentos, com videos explicativos de vendas familiares e links para apresentação da cultura Viver.",
    "Implementação da sessão de Material de apoio para corretores."
  ]

  const bugs = [
    "Edição de dependentes",
  ]

  return (
    
      <Modal size="md" isOpen={modal} toggle={toggle}>
        <ModalVersionController>
        <ModalHeader toggle={toggle}><span className="title-modal">Atualizações Versão 2.1.0.</span></ModalHeader>

        <Row className="area-title">
          <span className="section-title">O que preparamos para vocês!</span>
        </Row>
        
        <ModalBody style={{paddingLeft: "40px"}}>
          
          <Row>
            {
              features.map(feature => {
                return(
                  <ul>
                    <li>{feature}</li>
                  </ul>
                )
              })
            }
          </Row>
        </ModalBody>
        {
          (nivel_usuario == 1)&&
          <>
          <Row className="area-title">
            <span className="section-title">Bugs Corrigidos</span>
          </Row>

          <ModalBody style={{paddingLeft: "40px"}}>
            <Row>
              {
                bugs.map( bug => {  
                  return(
                    <ul>
                      <li>{bug}</li>
                    </ul>
                  )
                })
              }
            </Row>
          </ModalBody>
          </>
        }
      </ModalVersionController>
    </Modal>
  )
}

const mapStateToProps = store => ({
  user: store.user
})

export default connect(mapStateToProps)(ModalVersaoDetalhada)