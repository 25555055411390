import React, { useState } from 'react';
import { Card, CardHeader, CardBody, UncontrolledCollapse, Row, Col, Table, Button , Container, Modal, Form, ModalHeader, ModalBody, ModalFooter, Spinner, Input } from 'reactstrap';
import { ProductsInfoContent } from './styles';
import { FaShoppingCart, FaWrench } from 'react-icons/fa';
import TitleCardPolicies from '../../components/TitleContent/TitleCardPolicies';
import { FaFileAlt, FaClipboardCheck, FaHandHoldingUsd, FaFileInvoiceDollar, FaPlus, FaDownload } from 'react-icons/fa';
import AlterarFuncionariosApolice from './AlterarFuncionariosApolice';
import BotaoRelatorioFuncionariosAtivos from './BotaoRelatorioFuncionariosAtivos';
import AdicionarProdutoFuncionarioApolice from './AdicionarProdutoFuncionarioApolice';
import AlteraProduto from './AlteraProduto';
import { alertError } from '../../components/Alerts/Alerts';
import Api from '../../services/api';

const titleCard = <><h3><FaShoppingCart/></h3>&nbsp;&nbsp;Produtos Adicionados</>

export default function SeguradoProdutoApolice(props) {

    const { 
        policieConfigPayment, 
        client, 
        idEmpresa, 
        idApolice, 
        funcionarios, 
        archivesPolicies, 
        todosProdutos, 
        authStr, 
        produtosAdicionais, 
        nivelUsuario,
        funcInativos,
        funcAtivos 
    } = props;
    let { nome_estipulante, nome, cpf } = '';

    if(client !== undefined){
        nome_estipulante = client.nome_estipulante;
        nome = client.nome;
        cpf = client.cpf;
    }

    const [ loading, setLoading ] = useState(false);
    const [ loadingSalvaAlteracoes, setLoadingSalvaAlteracoes ] = useState(false);
    const [ modal, setModal ] = useState(false);
    const [ modalProtocoloDownload, setModalProtocoloDownload ] = useState(false);
    const [ modalAdicionaProduto, setAdicionaProduto ] = useState(false);
    const [ modalAlteraProduto, setAlteraProduto ] = useState(false);
    const [ produtoSelecionado, setProdutoSelecionado ] = useState();
    const [ nomeProdAlterar, setNomeProdAlterar ] = useState('');
    const [ linkDownload, setLinkDownload ] = useState();

    const toggleModalAdiciona = () => setAdicionaProduto(!modalAdicionaProduto);
    const toggleModalAltera = nomeProd => {
        setAlteraProduto(!modalAlteraProduto);
        setNomeProdAlterar(nomeProd);
    }
    const toggleModal = (i) => {
        setProdutoSelecionado(i);
        setModal(!modal);
    }
    const toggleModalProtocolo = _ => setModalProtocoloDownload(!modalProtocoloDownload);

    let valorTotalApolice = 0;

    const baixarProtocoloAutorizacao = async valorViver => {
        setLoading(true);
        let data = {
          "cpf": cpf,
          "titular": nome,
          "valor": valorViver,
        }
        const response = await Api.post('protocolo/efetivacao-compra', data, { headers: { Authorization: authStr }});
        if(response.data.ok){
            setModalProtocoloDownload(true);
            setLinkDownload(`${process.env.REACT_APP_API_ERP}/${response.data.output.link}`);
        }else{
            alertError("Erro ao gerar protocolo. Tente novamente mais tarde.");
        }
        setLoading(false);
    }

    const fatorDescontado = (desconto) => {
        if (desconto) {
            return (100 - desconto) / 100
        }
        return 1
    }

    const salvarPlanosFuncionarios = async e => {
        e.preventDefault();
        setLoadingSalvaAlteracoes(true);

        let colunaPlanoPrataEmp = [];
        let colunaPlanoAdicional = [];
        let cboxes1 = document.getElementsByName('seguradosCheckColuna1');
        let cboxes2;
        
        let idProdutoAdicional = document.getElementById('hiddenIdProdutoAdcional').value;
        
        const lengthInputs = cboxes1.length;
        for (var i=0; i<lengthInputs; i++) {
            colunaPlanoPrataEmp.push({
                funcionarioId: cboxes1[i].value,
                checked: cboxes1[i].checked 
            })
        }

        if(produtosAdicionais.ok){
            cboxes2 = document.getElementsByName('seguradosCheckColunaAdicionalEmpresa');
            const lengthInputs2 = cboxes2.length;
            for (var i=0; i<lengthInputs2; i++) {
                colunaPlanoAdicional.push({
                    funcionarioId: cboxes2[i].value,
                    checked: cboxes2[i].checked
                })
            }
        }

        let colunaPlanoPrataSegurados = [];
        colunaPlanoPrataEmp.map(colPlanoPrata => {
            if(colPlanoPrata.checked) colunaPlanoPrataSegurados = [...colunaPlanoPrataSegurados, colPlanoPrata.funcionarioId ];
        })

        let data = { "id_apolice": idApolice, "id_produto": idProdutoAdicional, "segurados": colunaPlanoPrataSegurados };

        let colPlAdSegurados = [];
        colunaPlanoAdicional.map(colPlanoAdicional => {
            if(colPlanoAdicional.checked) colPlAdSegurados = [...colPlAdSegurados, colPlanoAdicional.funcionarioId ];
        })

        let dataPlanoAdicional = { "id_apolice": idApolice, "id_produto": idProdutoAdicional, "segurados": colPlAdSegurados }

        const result = await Api.post("apolice/produto-segurado/editar", data ,{ headers: { Authorization: authStr }});
        const resultAdicional = await Api.post("apolice/produto-segurado/editar", dataPlanoAdicional, { headers: { Authorization: authStr }});

        if(result.data.ok){
        //   alertSuccess(result.data.message);
          window.location.reload();
        }else{
          alertError(result.data.message);
        }

        setLoadingSalvaAlteracoes(false);
    }
    
    const renderProductsInfo = _ => (
        
        Object.values(props.policieProduct.output.produtos).map((produto, i) => {

        let preco = produto.preco;
        let precoDependenteTipo1 = produto.preco_dependente_tipo_1;
        let precoDependenteTipo2 = produto.preco_dependente_tipo_2;
        let valorTotal = 0;
        
        if(produto.plano_adicional == 1){
            return 
        }

        return (
            <Card className="product-card" key={i} >
                <CardHeader style={{cursor: "pointer"}} id={`toggler${i}`}>
                    <Col className="text-left">
                        <span><FaClipboardCheck/></span>
                        <span>&nbsp;Produto: {produto.nome}</span>
                    </Col>
                    <Col className="text-right">
                        <span>Ver mais&nbsp;&nbsp;<FaPlus/></span>
                    </Col>
                </CardHeader>

                <UncontrolledCollapse toggler={`#toggler${i}`}>

                    <CardBody>
                        <Col className="title-card-content"><FaFileAlt/>&nbsp;&nbsp;<span>Descrição</span></Col>
                        <Col><p>{produto.descricao}</p></Col>
                        <Col className="mt-3 card-content-footer"><FaHandHoldingUsd />&nbsp;&nbsp;Preço Titular: R$&nbsp;{produto.preco}</Col>
                        <Col className="mt-3">
                            {
                                (produto.nome === 'Plano Viver' || produto.nome === 'Plano Viver - 50%')&&
                                <p>
                                    <FaHandHoldingUsd />&nbsp;&nbsp;Preço Dependente:&nbsp; R$&nbsp;{produto.preco_dependente_tipo_1} |
                                    <FaHandHoldingUsd />&nbsp;&nbsp;Preço Titular 2:&nbsp; R$&nbsp;{produto.preco_dependente_tipo_2}
                                </p>
                            }
                        </Col>
                        
                        <Col>

                            <Table>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Qtd. Dep. 1</th>
                                        <th>Qtd. Titular 2</th>
                                        <th>Qtd. Dep. 1 Grátis</th>
                                        <th>Preço Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    produto.segurados.map((el,indexMap) => {
                                        
                                        let descontoDep = 0;
                                        let descontoTitular = 0;
                                        let valor = 0;
                                        
                                        if(policieConfigPayment.ok && (policieConfigPayment.output.DEP_1_GRATIS === "1") && (el.qtd_dependentes_tipo_1 > 0)){
                                            descontoDep = produto.preco_dependente_tipo_1;

                                            descontoTitular = (100 - policieConfigPayment.output.desconto_titular) / 100
                                        }else{
                                            descontoDep = 0; 
                                            descontoTitular = 1
                                        }

                                        valor = (preco) * fatorDescontado(policieConfigPayment.output.desconto_titular) + (precoDependenteTipo1 * el.qtd_dependentes_tipo_1) + (precoDependenteTipo2 * el.qtd_dependentes_tipo_2);
                                        valor = (valor - descontoDep).toFixed(2);
                                        
                                        valorTotal = valorTotal + parseFloat(valor);
                                        
                                        return (
                                            <tr key={indexMap}>
                                                <td>{el.nome}</td>
                                                <td>{el.qtd_dependentes_tipo_1} Dep(s).</td>
                                                <td>{el.qtd_dependentes_tipo_2} Dep(s).</td>
                                                <td>{(descontoDep > 0) ? descontoDep = 1 : descontoDep  = 0} Dep(s).</td>
                                                <td>R$ {valor}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </Table>
                            <div style={{display: 'none'}}>
                                {valorTotalApolice += valorTotal}
                            </div>
                            <Row className="w-100">
                                <Col sm={12}>
                                        {   
                                            (nivelUsuario == 1)
                                            ?
                                                (idEmpresa === "1" || nome_estipulante === "ASPMJ")
                                                && //?
                                                <Row className="text-left mb-4">
                                                    <Button outline color="success" size="sm" onClick={() => toggleModalAltera(produto.nome)}><FaPlus />&nbsp;&nbsp;Alterar Produto</Button>
                                                </Row>
                                                // :
                                                // <Button color="secondary" size="sm" onClick={() => toggleModal(produto.id)} ><FaWrench /> Inserir/Excluir funcionarios da apolice</Button>
                                            :
                                                ""
                                            }
                                    {
                                    nome_estipulante === "ASPMJ"&&
                                    <Row className="text-left mt-4 mb-4">
                                        
                                            <Button outline size="sm" color="success" onClick={() => baixarProtocoloAutorizacao(valorTotal)}>
                                                {
                                                    loading
                                                    ?
                                                    <><Spinner size="sm" color="success"/> Aguarde</> 
                                                    :
                                                    <><FaDownload/> Protocolo de Autorização</>

                                                }
                                            </Button>
                                        
                                    </Row>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </CardBody>
                </UncontrolledCollapse>
            </Card>
        );
        })
    ) 

    const renderPricePolicie = _ => {
        return (
            <>
                <h4> Valor Apólice </h4>
                <h2><FaFileInvoiceDollar/>&nbsp;&nbsp;R$ {valorTotalApolice.toFixed(2)}</h2>
            </>
        )
    }

    return (
        <Card>
            {
                modalProtocoloDownload&&
                <Modal isOpen={modalProtocoloDownload} toggle={toggleModalProtocolo} size="lg">
                    <ModalHeader toggle={toggleModalProtocolo}><FaDownload /> Protocolo</ModalHeader>
                    <ModalBody>
                        <Row className="text-right">
                            <Col>
                                <a href={linkDownload} download={linkDownload}>
                                    <Button color="success" size="sm" outline>Baixar</Button>
                                </a>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            }   
            {
                modal&&
                <Modal isOpen={modal} toggle={toggleModal} size="lg">
                    <ModalHeader toggle={toggleModal}><FaShoppingCart /> Inserir/Excluir funcionário da apólice</ModalHeader>
                    <ModalBody>
                        <AlterarFuncionariosApolice
                            produtos={props.policieProduct.output.produtos[produtoSelecionado]}
                            produtosSegurados={props.policieProduct.output.produtos[produtoSelecionado].segurados} 
                            funcionariosList={funcionarios.output}
                            policieConfigPayment={policieConfigPayment}
                            idApolice={idApolice}
                            funcInativos={funcInativos}
                            funcAtivos={funcAtivos}
                        />
                    </ModalBody>
                </Modal>
            }
            {
                modalAdicionaProduto&&
                ((Object.keys(funcionarios.output).length > 0)
                ?
                (<Modal isOpen={modalAdicionaProduto} size="lg" toggle={toggleModalAdiciona} >
                    <ModalHeader toggle={toggleModalAdiciona}><FaShoppingCart /> Adicionar Produto</ModalHeader>
                    <ModalBody>
                        <AdicionarProdutoFuncionarioApolice 
                            todosProdutos={todosProdutos}
                            idApolice={idApolice}
                            idEmpresa={idEmpresa}
                            funcionarios={funcionarios}
                            history={props.history}
                            produtosAdicionais={produtosAdicionais}
                            produtosNaApolice={props.policieProduct.output.produtos}
                        />
                    </ModalBody>
                </Modal>)
                :
                (alertError("A empresa precisa ter funcionários adicionados antes de adicionar um produto.")))
            }
            {
                modalAlteraProduto&&
                <Modal isOpen={modalAlteraProduto} size="lg" toggle={toggleModalAltera} >
                    <ModalHeader toggle={toggleModalAltera}><FaShoppingCart /> Alterar Produto</ModalHeader>
                    <ModalBody>
                        <AlteraProduto 
                            todosProdutos={todosProdutos} 
                            history={props.history} 
                            idApolice={idApolice} 
                            nomeProdAlterar={nomeProdAlterar} 
                            idEmpresa={idEmpresa}
                        />
                    </ModalBody>
                </Modal>
            }

            <Container>
                <ProductsInfoContent>
                    <Row className="mt-4 ml-4 mb-4"><TitleCardPolicies titleCard={titleCard}/></Row>
                    {   
                        (props.policieProduct.output)&&
                        (Object.values(props.policieProduct.output.produtos).length > 0)&&
                        <Form id="formProdutosSegurados" onSubmit={salvarPlanosFuncionarios}>
                            {renderProductsInfo()}
                        </Form>
                        // renderTabelaFuncionarios()
                    }
                    <Row className="mt-4 text-right">
                        <Col>{renderPricePolicie()}</Col>
                    </Row>
                    {
                        (archivesPolicies && nome_estipulante !== "PLANO VIVER" && nome_estipulante !== "ASPMJ")
                        // ((archivesPolicies && nome_estipulante !== "ASPMJ" || nome_estipulante !== "PLANO VIVER" ))
                        ?
                        <Row className="text-left mt-4">
                            <BotaoRelatorioFuncionariosAtivos idEmpresa={idEmpresa} nomeEstipulante={nome_estipulante} idApolice={idApolice}/>
                        </Row>
                        :
                        ""
                    }
                    {/* <Row className="mt-4">
                        {
                            (archivesPolicies && nome_estipulante !== "PLANO VIVER" && nome_estipulante !== "ASPMJ")&&
                            <Button
                                outline
                                color="success"
                                size="sm"
                                onClick={() => toggleModalAdiciona()}
                            >
                                <FaPlus />&nbsp;&nbsp;Adicionar Produto
                            </Button>
                        }
                        
                    </Row> */}
                </ProductsInfoContent>
            </Container>
        </Card>
    );
}
