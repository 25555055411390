import React, { useState } from 'react';
import { Col, Row, Input, Button, ModalHeader, Modal, ModalBody } from 'reactstrap';
import { FaDownload } from 'react-icons/fa';
import Api from '../../services/api';
import { alertError } from '../../components/Alerts/Alerts';
import { ToastContainer } from 'react-toastify';

function RelatorioVendaCorretorRepresentante(props) {

  const { corretorRepresentante, token, history } = props;

  const [ idCorretorRepresentante, setIdCorretorRepresentante ] = useState();
  const [ mesAno, setMesAno ] = useState("");
  const [ linkToDownload, setLinkToDownload ] = useState();

  const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

  const geraRelatorioCorretorRepresentante = async _ => {
    
    const result = await Api.get( `relatorio-venda/corretor-representante/excel/${idCorretorRepresentante}/${mesAno}` ,{ headers: { Authorization: `Bearer ${token}` }});
    if(result.data.ok){
      setModal(true)
      setLinkToDownload(result.data.output.arquivo);
    }else{
      alertError(result.data.message);
    }
  }

  return (
    <Row>
      <ToastContainer/>
      <Col md={12}>
        <Input type="select" name="idCorretorRepresentante" onChange={e => setIdCorretorRepresentante(e.target.value)}>
          <option>Corretor Representante</option>
          {
            corretorRepresentante.output.map((el, i) => (
              <option key={i} value={el.id}>{el.nome}</option>
            ))
          }
        </Input>
      </Col>
      <Col className="mt-2" md={12}>
        <Input type="month" name="mesAno" required value={mesAno} onChange={(e) => setMesAno(e.target.value)}/>
      </Col>
      <Col className="mt-4" md={12}>
        <Button size="sm" color="success" style={{width: "100%", height: "50px"}} onClick={() => geraRelatorioCorretorRepresentante()}><FaDownload /> Baixar Relatório</Button>
      </Col>
      {
        modal&&
        <Modal isOpen={modal} toggle={toggle}>
					<ModalHeader toggle={toggle}>Baixar arquivos</ModalHeader>
          <ModalBody className="text-right">
            <a href={`${process.env.REACT_APP_API_ERP}/${linkToDownload}`} download={`${process.env.REACT_APP_API_ERP}/${linkToDownload}`}>
							<Button size="sm" color="success"><FaDownload /> Baixar</Button>
						</a>
          </ModalBody>
        </Modal>
      }
    </Row>
  )
}

export default RelatorioVendaCorretorRepresentante;
