import React from 'react';
import { Card, Table, Col } from 'reactstrap';
import { CardComponent } from '../styles';
import { formatDate} from '../../services/service';

function FuncionarioDependente(props) {
  const { depFuncionario } = props;

  return (
    <Card className="mt-2 mb-4 pb-4">
      
      <CardComponent>
        <Col className="header-content mb-4">
          <h2>Dependentes</h2>
        </Col>
        {
          depFuncionario.output&&
          depFuncionario.output.length > 0
          ?
          <Table>
            <thead>
              <tr>
                <th>CPF</th>
                <th>Tipo</th>
                <th>Nome</th>
                <th>Parentesco</th>
                <th>Titular</th>
                <th>Data de Nascimento</th>
              </tr>
            </thead>
            <tbody>
              {
                depFuncionario.output.map((el, i) => (
                  <tr>
                    <td>{el.cpf}</td>
                    <td>{el.tipo}</td>
                    <td>{el.nome}</td>
                    <td>{el.parentesco}</td>
                    <td>{el.titular}</td>
                    <td>{el.data_nascimento}</td>
                  </tr>
                ))
              }
              <tr>
                <td></td>
              </tr>
            </tbody>
          </Table>
          :
            <span style={{color: "red"}}>{depFuncionario.message}</span>
          }
      </CardComponent>
    </Card>
  )
}

export default FuncionarioDependente;
