import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FaExclamationTriangle, FaArrowLeft } from 'react-icons/fa';
import { PanelEdited } from '../styles';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/animation/not-found.json';

export default function Page() {

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return(
        <Container className="mb-4" fluid={true}>
            <Row className="text-center">
                <Col className="mt-4" >
                    <Lottie options={defaultOptions}
                        height={200}
                        width={400}
                    />
                    <PanelEdited>
                        <h1><FaExclamationTriangle /> Página não encontrada</h1>
                        <p>Está página não existe ou não pode ser encontrada. Contate a equipe de suporte.</p>
                        <Link to="/"><FaArrowLeft/>&nbsp;Voltar para página anterior</Link>
                    </PanelEdited>
                </Col>
            </Row>
        </Container>
    );
}

