import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import TitleContent from '../../components/TitleContent/TitleContent';
import { FaUsers, FaArrowLeft } from 'react-icons/fa';
import SeguradoInfo from '../../views/Segurado/SeguradoInfo';
import SeguradoApolice from '../../views/Segurado/SeguradoApolice';
import SeguradoDependente from '../../views/Segurado/SeguradoDependente';
import EndossosEdicao from '../../views/Segurado/EndossosEdicao';
import Loading from '../../components/Loading/Loading';
import endossosEdicao from '../../views/Segurado/EndossosEdicao';

export default function Page(props) {
  const {assessorias} = props
  
  const titleHeader = props.client ? <><FaUsers/>&nbsp;&nbsp; {props.client.nome} </> : '';

  return (
    <Container className="pb-4" fluid={true}>
      <Row className="mt-4">
        <Col md={2}>
          <Button className="btn-back w-100" outline size="sm" color="danger" mouseUp onClick={() => props.history.goBack()}>
            <FaArrowLeft/>&nbsp;&nbsp;Voltar
          </Button>
        </Col>
      </Row>
      <Row className="text-left mt-4 mb-4">
        <Col lg={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={6}>
          <SeguradoInfo idClient={props.idClient} client={props.client} history={props.history} />
        </Col>
        <Col lg={6}>
          {
            props.loadingApolice
            ?
            <Col className="text-center">
              <Loading loading={props.loadingApolice} />
            </Col>
            :
            props.apolices&&
            <SeguradoApolice 
              apolices={props.apolices} 
              history={props.history} 
              client={props.client} 
              produtos={props.produtos}
              idEstipulante={props.client}
              idCliente={props.idClient}
              assessorias={assessorias}
            /> 
          }
          <div className="mt-4">
            {
              props.dependents&&
              <SeguradoDependente dependents={props.dependents}/>
            }
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={12}>
          <EndossosEdicao endossos={props.endossosEdicao} loading={props.loadingEndossos}/>
        </Col>
      </Row>
    </Container>
  );
}
