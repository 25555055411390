import React, { useState } from 'react';
import Pages from './Pages';
import  Api from '../../services/api';
import { connect } from 'react-redux';

function Controller(props) {

  const [ loading, setLoading ] = useState(false);
  const [ faturas, setFaturas ] = useState();
  const [ inputs, setInputs ] = useState({
    inputMesAno: '',
    inputStatus: ''
  });
  const authStr = `Bearer ${props.user.user.token}`;

  const handleOnChange = e => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  }
  
  const getInfosFaturas = async _ => {
    setLoading(true);
    const request = await Api.get(`/faturas/${inputs.inputMesAno}/${inputs.inputStatus}`, { headers: { Authorization: authStr }});
    setFaturas(request.data);
    setLoading(false);
  }
  
  return (
    <>
      <Pages faturas={faturas} loading={loading} inputs={inputs} handleOnChange={handleOnChange} getInfosFaturas={getInfosFaturas} />
    </>
  );
}

const mapStateToProps = store => (
  { user: store.user }
);

export default connect(mapStateToProps)(Controller);
