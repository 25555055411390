import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import TitleContent from '../../components/TitleContent/TitleContent';
import { FaWrench, FaArrowLeft } from 'react-icons/fa';
import EditarFuncionario from '../../views/EditarFuncionario/EditarFuncionario';

function Page(props) {
  const { history, nomeFuncionario, funcionarios, depClienteIndividual, idEmpresa } = props;

  const titleHeader = <><FaWrench />&nbsp;&nbsp;Editar: {nomeFuncionario}</>

  let clienteEnderecoInput = {
    cep: funcionarios.cep,
    logradouro: funcionarios.logradouro,
    numero_endereco: funcionarios.numero_endereco,
    complemento_endereco: funcionarios.complemento_endereco,
    bairro: funcionarios.bairro,
    cidade: funcionarios.cidade,
    uf: funcionarios.uf,
  }

  let depClienteIndividualArr = Array();
  
  // console.log("depClienteIndividual =",depClienteIndividual)

  // if(depClienteIndividual !== undefined){

  //   depClienteIndividual.map(el => {
  
  //     depClienteIndividualArr.push({
  //       id: el.id,
  //       id_segurado: el.id_segurado,
  //       tipoDependente: el.tipo,
  //       parentesco: el.parentesco,
  //       cpfDependente: el.cpf,
  //       nomeDependente: el.nome,
  //       dataNascimentoDependente: el.data_nascimento,
  //       titular: el.titular,
  //     }); 
  //   })
  // }

  return (
    <Container fluid={true}>
      <Row className="mt-4">
        <Col md={2}>
          <Button className="btn-back w-100" outline color="danger" onClick={() => history.goBack()}>
            <FaArrowLeft/>&nbsp;&nbsp;Voltar
          </Button>
        </Col>
      </Row>
      <Row className="text-left mt-4 mb-4">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader}/>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <EditarFuncionario
            edit={true}
            editFunc={true}
            clienteEmpresarial={true}
            clientInputEdit={funcionarios}
            // depClienteIndividual={depClienteIndividualArr}
            depClienteIndividual={depClienteIndividual}
            clienteEnderecoInput={clienteEnderecoInput}
            idEmpresa={idEmpresa}
          />
        </Col>
      </Row>
    </Container>
  )
}
export default Page;
