import React from 'react';
import { Link } from 'react-router-dom';
import { FaSearch, FaFileInvoiceDollar, FaLink } from 'react-icons/fa';
import { Box } from '@material-ui/core';
import { FormatValorAtrasadoFatura } from '../styles';
import { formatDate } from '../../services/service';
import formatValueToPrice from '../../utils/format';
import MaterialTableServerPagination from '../../components/TablesGrid/MaterialTableServerPagination';
import MaterialTable from '../../components/TablesGrid/MaterialTable';

function FaturasEmpresariais(props) {
  const { faturas, loading, setPage, rowsQuantity } = props;
  console.log(faturas);
  const columns = [
    {
      field: 'nome_segurado',
      headerName: 'Cliente',
      width: 150,
    },
    {
      field: 'valor',
      headerName: 'Valor do Boleto',
      type: 'number',
      width: 175,
      valueFormatter: ({ value }) => formatValueToPrice(value),
    },
    {
      field: 'data_vencimento',
      headerName: 'Data Vencimento',
      type: 'date',
      width: 150,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      field: 'nome_consultor',
      headerName: 'Consultor',
      width: 150,
    },
    {
      field: '',
      headerName: 'Link Boleto',
      width: 150,
      sortable: false,
      renderCell: params => (
        <a
          href={params.getValue(params.id, 'link_arquivo')}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaLink />
          &nbsp;Clique Aqui
        </a>
      ),
    },
    {
      field: 'apolice',
      headerName: 'Apólice',
      width: 150,
      sortable: false,
      renderCell: params => (
        <div>
          <Link to={`/empresa/${params.getValue(params.id, 'id_empresa')}`}>
            <FaSearch /> Ver mais
          </Link>
          &nbsp;
        </div>
      ),
    },
    // {
    //   field: 'link_whats',
    //   headerName: 'Link Whats',
    //   sortable: false,
    //   renderCell: params => {
    //     const numeroTratado = params
    //       .getValue(params.id, 'telefone')
    //       .replace(/[()-]/g, '');
    //     const primeiroNome = params
    //       .getValue(params.id, 'nome_segurado')
    //       .split(' ')[0];
    //     const nomeTratadoPascal =
    //       primeiroNome.charAt(0).toUpperCase() +
    //       primeiroNome.substr(1).toLowerCase();

    //     const anoMesDia = params
    //       .getValue(params.id, 'data_vencimento')
    //       .split('-');

    //     const valor = formatValueToPrice(params.getValue(params.id, 'valor'));

    //     const data = new Date(
    //       Date.UTC(anoMesDia[0], Number(anoMesDia[1]) - 1, anoMesDia[2], 12),
    //     ).toLocaleDateString();

    //     const mensagem = `Olá ${nomeTratadoPascal}, segue sua fatura no valor de ${valor} com vencimento ${data} 😅: ${params.getValue(
    //       params.id,
    //       'link_arquivo',
    //     )}`;

    //     return (
    //       <a
    //         href={`https://web.whatsapp.com/send?1=pt_BR&phone=55${numeroTratado}&text=${mensagem}`}
    //         rel="noopener noreferrer"
    //         target="_blank"
    //       >
    //         <FaWhatsapp />
    //         &nbsp;Enviar no Whats
    //       </a>
    //     );
    //   },
    //   width: 175,
    // },
  ];

  return (
    <Box m={2}>
      <MaterialTable rows={faturas} columns={columns} loading={loading} />
      {/* <MaterialTableServerPagination
        rows={faturas}
        columns={columns}
        loading={loading}
        setPage={setPage}
        rowsQuantity={rowsQuantity}

      /> */}
      <FormatValorAtrasadoFatura>
        <p>Valor Total: </p>
        <h2>
          <FaFileInvoiceDollar />
          <span>
            {formatValueToPrice(
              faturas.reduce((acc, current) => acc + current.valor, 0),
            )}
          </span>
        </h2>
      </FormatValorAtrasadoFatura>
    </Box>
  );
}

export default FaturasEmpresariais;
