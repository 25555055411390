import Dashboard from '../layouts/Dashboard/Dashboard';

var indexRoutes = [
    {
        path: "/",
        component: Dashboard,
    },
    {
        redirect: true,
        path: "/",
        to: "/login",
        name: "Acessar" 
    }
    
];

export default indexRoutes;