import React, { useState } from 'react';
import {
  Card,
  Container,
  Row,
  Table,
  Form,
  Col,
  Input,
  Button,
} from 'reactstrap';
import { FaFileContract, FaExclamationTriangle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { formatDate } from '../../services/service';
import TitleCardPolicies from '../../components/TitleContent/TitleCardPolicies';
import { TableContent, BodyFormComponent } from '../styles';
import Api from '../../services/api';

import { alertError } from '../../components/Alerts/Alerts';

const titleCard = (
  <>
    <h3>
      <FaFileContract />
    </h3>
    &nbsp;&nbsp;Anotações
  </>
);

function Anotacoes(props) {
  const { anotacao, idApolice } = props;
  const [anotacaoInput, setAnotacaoInput] = useState({
    descricao: '',
    idApolice,
    idUsuario: props.user.user.usuario,
  });

  const authStr = `Bearer ${props.user.user.token}`;

  const salvarAnotacao = async _ => {
    const request = await Api.post(`anotacao`, anotacaoInput, {
      headers: { Authorization: authStr },
    });
    if (request.data.ok) {
      window.location.reload();
    } else {
      alertError(request.data.message);
    }
  };
  return (
    <Card>
      <TableContent>
        <Container>
          <Row className="mt-4 ml-4 mb-4">
            <TitleCardPolicies titleCard={titleCard} />
          </Row>
          <Row className="text-right">
            <Col>
              <BodyFormComponent>
                <Form>
                  <Col>
                    <Input
                      type="textarea"
                      required
                      name="anotacaoInput"
                      value={anotacaoInput.descricao}
                      onChange={e =>
                        setAnotacaoInput({
                          ...anotacaoInput,
                          descricao: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Row className="text-right mt-2">
                    <Col>
                      <Button
                        size="sm"
                        color="success"
                        onClick={() => salvarAnotacao()}
                      >
                        Enviar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </BodyFormComponent>
            </Col>
          </Row>
          <Row>
            <Col>
              {anotacao.output.length > 0 ? (
                <Table>
                  <thead className="text-left">
                    <tr>
                      <th>Id Apólice</th>
                      <th>Descrição</th>
                      <th>Data Criação</th>
                      <th>Hora</th>
                    </tr>
                  </thead>
                  <tbody>
                    {anotacao.output.map((el, i) => {
                      const date = el.created.split(' ')[0];
                      const time = el.created.split(' ')[1];
                      return (
                        <tr key={i}>
                          <td>{el.id_apolice}</td>
                          <td>{el.descricao}</td>
                          <td>{formatDate(date)}</td>
                          <td>{time}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div className="mb-4 text-left">
                  <span style={{ color: 'red' }}>
                    <FaExclamationTriangle /> {anotacao.message}
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </TableContent>
    </Card>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Anotacoes);
