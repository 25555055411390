import React from 'react';
import { Col } from 'reactstrap';
import CardTreinamento from '../../components/Treinamentos/CardTreinamento';
import { treinamentos } from '../../json/treinamentos';

function Listatreinamentos() {

  return (
    <>
      {treinamentos.map((treinamento, i) => {
        return (
          <Col className="mt-4" md={4} key={i}>
            <CardTreinamento
              img={treinamento.imagem}
              nome={treinamento.nome}
              descricao={treinamento.descricao}
              linkVideo={treinamento.linkVideo}
              linkApresentacao={treinamento.linkApresentacao}
            />
          </Col>
        )   
      })}
    </>
  )
}

export default Listatreinamentos;