import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col } from 'reactstrap';
import {
  FaExclamationCircle,
  FaSearch,
  FaFileInvoiceDollar,
  FaLink,
} from 'react-icons/fa';
import { MsgErrorDataTable, FormatValorAtrasadoFatura } from '../styles';
import TablesGrid from '../../components/TablesGrid/TablesGrid';
import { formatDate } from '../../services/service';

function BuscaMesFaturas(props) {
  const { faturas, inputs } = props;

  const columns = [
    {
      name: 'nome_segurado',
      title: 'Cliente',
      getCellValue: row => row.nome_segurado,
    },
    {
      name: 'valor',
      title: 'Valor do Boleto',
      getCellValue: row => `R$ ${row.valor}`,
    },
    {
      name: 'data_vencimento',
      title: 'Data Vencimento',
      getCellValue: row => formatDate(row.data_vencimento),
    },
    { name: 'status', title: 'Status', getCellValue: row => row.status },
    {
      name: 'nome_consultor',
      title: 'Consultor',
      getCellValue: row => row.nome_consultor,
    },
    {
      name: 'link_arquivo',
      title: 'Link Boleto',
      getCellValue: row => (
        <a href={row.link_arquivo} rel="noopener noreferrer" target="_blank">
          <FaLink />
          &nbsp;Clique Aqui
        </a>
      ),
    },
    { name: '', title: 'Apólice', getCellValue: row => actions(row) },
  ];

  const actions = row => {
    return (
      <div>
        <Link to={`/segurado/${row.id_segurado}`}>
          <FaSearch /> Ver mais
        </Link>
        &nbsp;
      </div>
    );
  };

  const formatDateYearMonthDay = date => {
    const newDate = date.split('-');
    return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
  };

  const renderValorAtrasado = () => {
    let valorTotal = 0;
    let valorTotalAtrasado = 0;
    const hoje = new Date().toLocaleDateString('pt-br');

    faturas.map(el => {
      valorTotal += parseFloat(el.valor);

      if (
        formatDateYearMonthDay(el.data_vencimento) < hoje &&
        el.status === 'Pendente'
      ) {
        valorTotalAtrasado += parseFloat(el.valor);
      }
    });

    // arredonda valores
    valorTotal = Math.round(valorTotal * 100) / 100;
    valorTotalAtrasado = Math.round(valorTotalAtrasado * 100) / 100;

    return (
      <FormatValorAtrasadoFatura>
        <Col>
          <p>
            <b style={{ color: 'red' }}>
              {' '}
              <span style={{ fontFamily: 'Futura', fontSize: '25px' }}>
                R$ {valorTotalAtrasado}
              </span>{' '}
            </b>{' '}
            Atrasado de
          </p>
          <h2>
            {' '}
            <FaFileInvoiceDollar />
            &nbsp;{' '}
            <span style={{ fontFamily: 'Futura' }}> R$ {valorTotal}</span>{' '}
          </h2>
          <p>
            Faturas <b> {inputs.inputStatus}</b>
          </p>
        </Col>
      </FormatValorAtrasadoFatura>
    );
  };

  return (
    <Card className="py-4 pl-4">
      {faturas.length ? (
        <>
          <TablesGrid rows={faturas} columns={columns} />
          {renderValorAtrasado()}
        </>
      ) : (
        <MsgErrorDataTable>
          <FaExclamationCircle />
          &nbsp;&nbsp;Realize a pesquisa por mês
        </MsgErrorDataTable>
      )}
    </Card>
  );
}

export default BuscaMesFaturas;
