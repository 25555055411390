import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Page from './Page';
import { useHistory } from 'react-router-dom';
import { formatDate } from '../../services/service';

function Controller(props) {
  
  let { idClient } = useParams();
  let history = useHistory();

  const authStr = `Bearer ${props.user.user.token}`;
  const [ infosClienteIndividual, setInfosClienteIndividual ] = useState('');
  const [ depClienteIndividual, setDepClienteIndividual ] = useState([]);

  useEffect(() => {
    getInfosClienteIndividual();
  },[]);

  const getInfosClienteIndividual = async _ => {
    const restApi = await Api.get(`/segurado/${idClient}`, { headers: { Authorization: authStr }});
    const restApiClientDependente = await Api.get(`/segurado/dependentes/${idClient}`, { headers: { Authorization: authStr }});
    restApi.data_nascimento = formatDate(restApi.data.output.data_nascimento)

    setInfosClienteIndividual(restApi.data);
    setDepClienteIndividual(restApiClientDependente.data);
  }

  return (
    <>
      {
        infosClienteIndividual.output&&
        (<Page clientInputEdit={infosClienteIndividual.output} depClienteIndividual={depClienteIndividual} history={history} />)
      }    
    </>
  )
}

const mapStateToProps = store => ({
  user: store.user
})

export default connect(mapStateToProps)(Controller);
    