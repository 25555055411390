import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Input,
  Card,
  Col,
  Row,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import {
  FaUsers,
  FaPlus,
  FaSearch,
  FaExclamationCircle,
  FaFileDownload,
} from 'react-icons/fa';
import classnames from 'classnames';

import { CardComponent, MsgErrorDataTable } from '../styles';

import ModalEnviaCsv from './ModalEnviaCsv';
import PopoverMulti from '../../components/Popover/Popover';
import SimpleTable from '../../components/TablesGrid/SimpleTable';

function ResumosFuncionario(props) {
  const history = useHistory();

  const {
    resultBuscaProd,
    infosFuncionarios,
    openModalCadastroFuncionario,
    token,
    idEstipulante,
    infosApolice,
    handleMudarProdutoFuncionario,
    btnDisabled,
    modalImportar,
    toggleImportar,
    funcInativos,
    funcAtivos,
    params,
    setParams,
    getFuncionarioApolices,
    inactivatePlanoFuncionario,
  } = props;

  const [modalApolicesFunc, setModalApolicesFunc] = useState(false);
  const [modalApolicesFuncActiveTab, setModalApolicesFuncActiveTab] =
    useState('1');
  const [funcionarioApolices, setFuncionarioApolices] = useState([]);
  const [currentFunc, setCurrentFunc] = useState(0);
  const [loadingInactivate, setLoadingInactivate] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      if (currentFunc != 0) {
        setFuncionarioApolices([
          { id_apolice: '', nome_produto: 'Carregando...' },
        ]);
        const data = await getFuncionarioApolices(currentFunc);
        if (data.ok) {
          setFuncionarioApolices(data.output);
        }
      }
    };

    loadData();
  }, [currentFunc, getFuncionarioApolices]);

  const toggleModalApolicesFunc = () => {
    setModalApolicesFunc(!modalApolicesFunc);
  };

  const toggleModalApolicesFuncActiveTab = tab => {
    if (modalApolicesFuncActiveTab !== tab) setModalApolicesFuncActiveTab(tab);
  };

  // const handleSelectAcoesFunc = idFuncionario => {
  //   setCurrentFunc(idFuncionario);

  //   setModalApolicesFunc(true);
  // };

  const handleInactivatePlanoFuncionario = async planoFuncionario => {
    setLoadingInactivate(true);

    const data = await inactivatePlanoFuncionario(planoFuncionario);

    if (data.ok) {
      setFuncionarioApolices(
        funcionarioApolices.filter(
          item => item.id_apolice != planoFuncionario.id_apolice,
        ),
      );
    }

    setLoadingInactivate(false);
  };

  const columns = [
    { name: 'cpf', title: '#ID', getCellValue: row => row.id },
    { name: 'nome', title: 'NOME', getCellValue: row => row.nome },
    {
      name: 'qtd_dependentes_tipo_1',
      title: 'Qtd. Dep.',
      getCellValue: row => row.qtd_dependentes_tipo_1,
    },
    {
      name: 'qtd_dependentes_tipo_2',
      title: 'Qtd. Titular 2',
      getCellValue: row => row.qtd_dependentes_tipo_2,
    },
    { name: 'tipo', title: 'Funções', getCellValue: row => actions(row.id) },
  ];

  const tableColumnExtensions = [
    { columnName: 'cpf', align: 'center', width: '50' },
    { columnName: 'nome', align: 'center', width: '300' },
    { columnName: 'qtd_dependentes_tipo_1', align: 'center', width: '150' },
    { columnName: 'qtd_dependentes_tipo_2', align: 'center', width: '150' },
    { columnName: 'tipo', align: 'center', width: '100' },
  ];

  const [activeTab, setActiveTab] = useState('2');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const actions = idFuncionario => (
    <div>
      <Button
        outline
        className="mr-2"
        size="sm"
        color="primary"
        onClick={() =>
          history.push({
            pathname: `/funcionario/${idFuncionario}`,
            state: { idEstipulante },
          })
        }
      >
        <FaSearch />
      </Button>
      {/* <Button outline className="mr-2" size="sm" color="warning" onClick={()=>handleSelectAcoesFunc(idFuncionario)} >
        <FaWrench />
      </Button> */}
    </div>
  );

  const handleChangeProduto = e => {
    const id = e.target.value;
    setParams({ ...params, idProduto: id });
  };

  let produtoSelecionado = [];
  if (params.idProduto !== 0) {
    produtoSelecionado = resultBuscaProd.output.find(
      produto => produto.id == params.idProduto,
    );
  }

  let modalFuncionario = [];
  if (currentFunc) {
    modalFuncionario = infosFuncionarios.output.find(
      func => func.id == currentFunc,
    );
  }

  return (
    <Card>
      <CardComponent>
        <Col className="header-content text-left">
          <h2>
            <FaUsers className="mr-2" />
            Funcionários
          </h2>
        </Col>
        {activeTab == 1 && (
          <Row className="text-left">
            <Col md={2}>
              <Input type="select" onChange={handleChangeProduto}>
                <option value="">Todos</option>
                {resultBuscaProd.output.map((el, i) => {
                  return (
                    <option key={i} value={el.id}>
                      {' '}
                      {el.nome}{' '}
                    </option>
                  );
                })}
              </Input>
            </Col>
          </Row>
        )}
      </CardComponent>
      <Row className="justify-content-center mt-3 flex-wrap">
        {infosFuncionarios.output && infosFuncionarios.output.length > 0 ? (
          <Col style={{ maxWidth: '800px' }} className="mb-4">
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{
                    fontSize: '14px',
                    cursor: 'pointer',
                    color: activeTab == 2 ? '#212529' : '#6d6d6d',
                    fontWeight: activeTab == 2 ? 'bold' : 'normal',
                  }}
                  onClick={() => {
                    toggle('2');
                    setParams({ ...params, apoliceProduto: 0 });
                  }}
                  title="Todos os funcionários vinculados a este estipulante"
                  className={classnames({ active: activeTab === '2' })}
                >
                  Todos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{
                    fontSize: '14px',
                    cursor: 'pointer',
                    color: activeTab == 1 ? '#212529' : '#6d6d6d',
                    fontWeight: activeTab == 1 ? 'bold' : 'normal',
                  }}
                  onClick={() => {
                    toggle('1');
                  }}
                  title="Todos os funcionários vinculados a este estipulante e a uma apólice dele"
                  className={classnames({ active: activeTab === '1' })}
                >
                  Ativos{' '}
                  {params.idProduto !== 0 &&
                    produtoSelecionado &&
                    ` - ${produtoSelecionado.nome}`}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{
                    fontSize: '14px',
                    cursor: 'pointer',
                    color: activeTab == 0 ? '#212529' : '#6d6d6d',
                    fontWeight: activeTab == 0 ? 'bold' : 'normal',
                  }}
                  onClick={() => {
                    toggle('0');
                    setParams({ ...params, apoliceProduto: 0 });
                  }}
                  title="Todos os funcionários vinculados a este estipulante mas sem nenhuma apólice"
                  className={classnames({ active: activeTab === '0' })}
                >
                  Inativos
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="2">
                {infosFuncionarios && (
                  <SimpleTable
                    height="320px"
                    rows={infosFuncionarios.output}
                    columns={columns}
                    tableColumnExtensions={tableColumnExtensions}
                  />
                )}
              </TabPane>
              <TabPane tabId="1">
                <SimpleTable
                  height="320px"
                  rows={funcAtivos}
                  columns={columns}
                  tableColumnExtensions={tableColumnExtensions}
                />
              </TabPane>
              <TabPane tabId="0">
                {funcInativos && (
                  <SimpleTable
                    height="320px"
                    rows={funcInativos}
                    columns={columns}
                    tableColumnExtensions={tableColumnExtensions}
                  />
                )}
              </TabPane>
            </TabContent>
          </Col>
        ) : (
          <MsgErrorDataTable>
            <FaExclamationCircle />
            &nbsp;&nbsp;{infosFuncionarios.message}
          </MsgErrorDataTable>
        )}
        <Col className="mb-2 text-right" style={{ minWidth: '250px' }}>
          <Button
            className="mb-2"
            outline
            size="sm"
            color="success"
            onClick={() => toggleImportar()}
          >
            <FaFileDownload />
            &nbsp;Importar Funcionários
          </Button>
          {modalImportar && (
            <ModalEnviaCsv
              modalImportar={modalImportar}
              toggleImportar={toggleImportar}
              idEmpresa={idEstipulante}
              token={token}
            />
          )}
          <Button
            className="mb-2 ml-2"
            color="success"
            size="sm"
            outline
            onClick={() => openModalCadastroFuncionario()}
          >
            <FaPlus />
            &nbsp;&nbsp;Cadastrar Funcionário
          </Button>
        </Col>
      </Row>

      {modalApolicesFunc && (
        <Modal
          isOpen={modalApolicesFunc}
          toggle={toggleModalApolicesFunc}
          className="mt-4"
        >
          <ModalHeader toggle={toggleModalApolicesFunc}>
            {modalFuncionario.nome} - Ações
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{
                        fontSize: '14px',
                        cursor: 'pointer',
                        color:
                          modalApolicesFuncActiveTab == 1
                            ? '#212529'
                            : '#6d6d6d',
                        fontWeight:
                          modalApolicesFuncActiveTab == 1 ? 'bold' : 'normal',
                      }}
                      className={classnames({
                        active: modalApolicesFuncActiveTab === '1',
                      })}
                      onClick={() => {
                        toggleModalApolicesFuncActiveTab('1');
                      }}
                    >
                      Produto
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        fontSize: '14px',
                        color: activeTab == 2 ? '#212529' : '#6d6d6d',
                        fontWeight:
                          modalApolicesFuncActiveTab == 2 ? 'bold' : 'normal',
                        cursor: 'pointer',
                      }}
                      className={classnames({
                        active: modalApolicesFuncActiveTab === '2',
                      })}
                      onClick={() => {
                        toggleModalApolicesFuncActiveTab('2');
                      }}
                    >
                      Inativar
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <TabContent activeTab={modalApolicesFuncActiveTab}>
                  <TabPane tabId="1">
                    <form name="formMudarProduto">
                      <Row>
                        <Col md={12}>
                          <p>
                            Esta ação irá primeiro remover este funcionário de
                            outros produtos da apólice selecionada e depois
                            adiciona-lo ao produto desejado.
                          </p>
                        </Col>
                        <input
                          type="hidden"
                          name="idSegurado"
                          value={modalFuncionario.id}
                        />
                        <Col md={6}>
                          <Input type="select" name="idApolice">
                            <option value="">Selecione a apólice</option>
                            {infosApolice.output &&
                            infosApolice.output.length > 0
                              ? infosApolice.output.map((el, i) => {
                                  return (
                                    <option value={el.id} key={i}>
                                      Apólice {el.id} - {el.status}
                                    </option>
                                  );
                                })
                              : ''}
                          </Input>
                        </Col>
                        <Col md={6}>
                          <Input type="select" name="idProduto">
                            <option value="">Selecione o produto</option>
                            {resultBuscaProd.output.map((el, i) => {
                              return (
                                <option key={i} value={el.id}>
                                  {' '}
                                  {el.nome}{' '}
                                </option>
                              );
                            })}
                          </Input>
                        </Col>
                      </Row>
                    </form>
                    <Row className="mt-4">
                      <Col md={12} className="text-right">
                        <Button
                          size="sm"
                          color="danger"
                          className="mr-2"
                          onClick={toggleModalApolicesFunc}
                        >
                          Fechar
                        </Button>
                        <Button
                          size="sm"
                          color="warning"
                          disabled={btnDisabled}
                          onClick={handleMudarProdutoFuncionario}
                        >
                          Confirmar
                        </Button>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <PopoverMulti
                      funcionarioApolices={funcionarioApolices}
                      handleInactivatePlanoFuncionario={
                        handleInactivatePlanoFuncionario
                      }
                      loadingInactivate={loadingInactivate}
                    />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </Card>
  );
}

export default ResumosFuncionario;
