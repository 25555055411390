import React, { useState } from 'react';
import { Card, Container, Row, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Button, Spinner, Form } from 'reactstrap';
import { BodyFormComponent, LabelForm, PrependInputLabel } from '../styles';
import TitleCardPolicies from '../../components/TitleContent/TitleCardPolicies';
import { FaFileContract, FaCalendar, FaRegSave, FaExclamationTriangle } from 'react-icons/fa';
import Api from '../../services/api';
import { connect } from 'react-redux';
import { alertError } from '../../components/Alerts/Alerts';
import { ToastContainer } from 'react-toastify';

function CadastrarAssinaturas(props) {

  const { idApolice, idEmpresa, contatos, configPagamento, getConfigPayment } = props;
  const titleCard = <><h3><FaFileContract/></h3>&nbsp;&nbsp;Apólice #{idApolice} </>

  const [ disabled, setDisabled ] = useState({
    cliente_iugu: true,
    assinatura_iugu: true
  });

  const [ pagamentoInput, setPagamentoInput ] = useState({
    possuiAssinatura: '0',
    assinatura_iugu: '',
    cliente_iugu: '',
    email: '',
    primeiro_pagamento: configPagamento.primeiro_pagamento,
    documento: [],
    id_empresa: idEmpresa,
    id_apolice: idApolice
  })

  const [ loading, setLoading ] = useState(false);
  
  const verificaContatosEmpresa = _ => {
    if(Object.keys(contatos.output).length > 0){
      return(
        <Form >
          <Row className="mt-4 text-left">
            {/* <Col md={12}>
              <LabelForm>Possui assinatura criada no IUGU?</LabelForm>
              <Input type="select" name="possuiAssinatura" value={pagamentoInput.possuiAssinatura} onChange={e => handleInputChange(e)}>
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </Input>
            </Col>
            <Col md={12} className="mt-4">
              <LabelForm>ID Assinatura IUGU</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="append">
                  <InputGroupText><PrependInputLabel>ID</PrependInputLabel></InputGroupText>
                </InputGroupAddon>
                <Input disabled={disabled.assinatura_iugu} name="assinatura_iugu" value={pagamentoInput.assinatura_iugu} onChange={e => handleInputChange(e)} />
              </InputGroup>
            </Col>
            <Col md={12} className="mt-4">
              <LabelForm>ID Cliente IUGU</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="append">
                  <InputGroupText><PrependInputLabel>IUGU</PrependInputLabel></InputGroupText>
                </InputGroupAddon>
                <Input disabled={disabled.cliente_iugu} name="cliente_iugu" value={pagamentoInput.cliente_iugu} onChange={e => handleInputChange(e)} />
              </InputGroup>
            </Col> */}
            {
              (pagamentoInput.possuiAssinatura === '0')&&
              <Col md={12} className="mt-4">
                <LabelForm>E-mail para cobrança</LabelForm>
                <Input type="select" name="email" value={pagamentoInput.email} onChange={e => handleInputChange(e)}>
                <option value="">Selecione</option>
                {
                  contatos.output&&
                  contatos.output.map((el, i) => (
                    <option key={i} value={el.email}>{el.nome_responsavel} ({el.email})</option>
                  ))
                }
              </Input>
              </Col>
            } 
            <Col md={12} className="mt-4">
              <LabelForm>Primeiro Pagamento</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText><FaCalendar /></InputGroupText>
                </InputGroupAddon>
                <Input type="date" required name="primeiro_pagamento" value={pagamentoInput.primeiro_pagamento} onChange={e => handleInputChange(e)} />
              </InputGroup>
            </Col>
            {/* <Col md={12} className="mt-4">
              <LabelForm>Contrato Assinado</LabelForm>
              <Input type="file" required name="documento" onChange={e => handleInputChange(e)}/>
            </Col> */}
          </Row>
          <Row className="text-right mt-4">
            <Col>
              <Button size="sm" color="success" onClick={() => cadastrarAssinaturasFunc()}>
                {
                  loading
                  ?
                  <Spinner animation="border" size="sm" variant="primary" />
                  :
                  <><FaRegSave /> Transmitir</>
                }
              </Button>
            </Col>
          </Row>
        </Form>
      );
    }else{
      return(
        <div style={{color: "red"}}>
          <LabelForm> <FaExclamationTriangle/> Adicione um contato para a empresa antes de configurar um pagamento</LabelForm>
        </div>
      )
    }
  }

  const handleInputChange = e => {
    const { name, value } = e.target;

    if(name === 'possuiAssinatura'){
      if(value === '1'){
        setDisabled({ ...disabled, cliente_iugu: false, assinatura_iugu: false });
      }else{
        setDisabled({ ...disabled, cliente_iugu: true, assinatura_iugu: true });
      }
    }
    if(name === 'documento'){
      setPagamentoInput({ ...pagamentoInput, [name]: e.target.files[0] });
    }

    setPagamentoInput({ ...pagamentoInput, [name]: value });
  }

  const cadastrarAssinaturasFunc = async _ => {
    setLoading(true);
    
    const dataForm = new FormData();

    Object.keys(pagamentoInput).map((el) => {
      dataForm.append(el, pagamentoInput[el] );
    })

    const result = await Api.post(`empresa/apolice/${idApolice}/criar-assinatura`, dataForm, { headers: { Authorization: `Bearer ${props.user.user.token}` }} );

    if(result.data.ok){
      // window.location.reload();
      getConfigPayment();
    }else{
      
      alertError(result.data.message);
    }
    setLoading(false);
  }

  return (
    <Card>
      <ToastContainer/>
      <BodyFormComponent>
      <Container>
        <Row className="mt-4 ml-4 mb-4"><TitleCardPolicies titleCard={titleCard}/></Row>
        <hr/>
        <Row className="text-left"><Col><h5>Configurar Pagamento</h5></Col></Row>
        <hr/>
        {verificaContatosEmpresa()}
      </Container>
      </BodyFormComponent>
    </Card>
  )
}

const mapStateToProps = store => ({
  user: store.user,
})

export default connect(mapStateToProps)(CadastrarAssinaturas);